import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";

const PaymentRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();

  console.log("location from payment redirection", location);

  useEffect(() => {
    // Function to parse query parameters
    const searchParams = new URLSearchParams(location.search);
    const paymentStatus = searchParams.get("payment_status");
    const needComplete = searchParams.get("need_complete");
    const bookingId = searchParams.get("booking_id");
    const complete_data_token = searchParams.get("complete_data_token");

    if (paymentStatus === "failure") {
      // Redirect to the payment page with an error
      navigate("/payment?error=Payment-failed");
    } else if (paymentStatus === "success") {
      if (needComplete === "1" && !user && complete_data_token)  {
        // Redirect to /complete-with-token page with the token as query parameter
        navigate(`/complete-with-token/${complete_data_token}`,{ state: { bookingId } });
        // navigate("/complete-registeration");
      } else if (needComplete === "0" && bookingId && !user) {
        // navigate(`/payment-success?booking_id=${bookingId}`);
        navigate("/payment-success", { state: { bookingId } });
      }
    }
  }, [location, navigate, user]);

  // Render nothing as it's just for redirection
  return null;
};

export default PaymentRedirect;
