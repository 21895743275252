import React, { useRef, useEffect } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'

const Reveal = ({ children, slideAnimate = false }) => {
    const ref = useRef(null)
    const isInView = useInView(ref, {
        once: true,
    })

    const mainControls = useAnimation()
    const slideControls = useAnimation()

    useEffect(() => {
        if (isInView) {
            mainControls.start('visible')
            slideControls.start('visible')
        }
    }, [isInView])

    return (
        <div className='relative overflow-hidden w-full'>
            <motion.div
                ref={ref}
                variants={{
                    hidden: { opacity: 0, y: 75 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial='hidden'
                animate={mainControls}
                transition={{ duration: 0.5, delay: 0.25 }}
            >
                {children}
            </motion.div>
            {slideAnimate && <motion.div
                variants={
                    {
                        hidden: { left: 0 },
                        visible: { left: '100%' },

                    }}
                initial='hidden'
                animate={slideControls}
                transition={{ duration: 0.5, ease: 'easeIn', delay: 0.25 }}
                style={{
                    position: 'absolute',
                    top: 4,
                    bottom: 4,
                    left: 0,
                    right: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#fcd12a',
                    zIndex: 20,
                }}

            >

            </motion.div>}

        </div>
    )
}

export default Reveal
