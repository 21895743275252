import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../../contexts/BookingContext";
import { BookingInfoSummary } from "../activity/booking/BookingInfoSummary";
import UpdateBookingSummary from "../update-booking/UpdateBookingSummary";
import pic from "../../assets/beach-access/beach-access-grid-1.webp";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import axiosInstance from "../../axios/axiosInstance";
import creditIcon from "../../assets/payment-icons/credit-icon.svg";
import creditIconActive from "../../assets/payment-icons/credit-icon-active.svg";
import appleIcon from "../../assets/payment-icons/apple-icon.svg";
import appleIconActive from "../../assets/payment-icons/apple-icon-active.svg";
import madaIcon from "../../assets/payment-icons/mada-icon.svg";
import madaIconActive from "../../assets/payment-icons/mada-icon-active.svg";
import CardInfo from "./CardInfo";
import ButtonPrimary from "../../shared/buttons/ButtonPrimary";
import PromoCodeInput from "./PromoCodeInput";
import { useLanguage } from "../../contexts/LanguageContext";
import { useLocation } from "react-router-dom";
import { useUpdateBooking } from "../../contexts/UpdateBookingContext";
import { useUser } from "../../contexts/UserContext";
import { notification } from "antd";

export const Payment = () => {
  const { user } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentError = searchParams.get("error");
  const { language, translate } = useLanguage();
  const navigate = useNavigate();
  const {
    booking,
    updatePaymentType,
    bookingSummary,
    setBookingSummary,
    // updatePaymentStatus,
    selectedUsersForPayment,
    handleUserCheckboxToggle,
    transformBookingForSummary,
    setPaymentMethod,
    updateCouponCode,
    onRemoveCouponFromBooking,
    getCouponCode,
  } = useBooking();

  const [isPayButtonDisabled, setIsPayButtonDisabled] = useState(true);

  const [totalPrice, setTotalPrice] = useState();
  const {
    updateBookingData,
    updatedBookingSummary,
    transformUpdatedBookingForSummary,
  } = useUpdateBooking();
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([
    {
      id: 1,
      name: {
        en: "creditcard",
        ar: "بطاقة الائتمان",
      },
      icon: creditIcon,
      activeIcon: creditIconActive,
    },
    {
      id: 2,
      name: {
        en: "applepay",
        ar: "أبل باي",
      },
      icon: appleIcon,
      activeIcon: appleIconActive,
    },
    {
      id: 3,
      name: {
        en: "mada",
        ar: "مادا",
      },
      icon: madaIcon,
      activeIcon: madaIconActive,
    },
  ]);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  // State to keep track of selected users for split payment
  // const [selectedUsersForPayment, setSelectedUsersForPayment] = useState([]);

  const [coupon, setCoupon] = useState(
    // if there is coupon_code inside booking then set the coupon to the coupon_code else set it to empty string
    booking?.coupon_code || ""
  );
  const [couponApplied, setCouponApplied] = useState(
    booking?.coupon_code ? true : false
  );
  const [couponError, setCouponError] = useState(null);
  const [appliedCouponCode, setAppliedCouponCode] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    termsAndConditions: false,
    cancellationPolicy: false,
    responsibilityAcknowledge: false,
    waiverAgreement: false,
  });
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      message: errorMessage,
      description: null,
      placement: "top",
      className: "mb-0",
      duration: 3,
    });
  };

  useEffect(() => {
    // if there is location.state.isUpdating then  its if not return
    if (!location.state?.isUpdating) return;
    setIsUpdating(location.state.isUpdating);
  }, [location.state]);

  useEffect(() => {
    //  wanna check if the bookingSummary is not null and the total_summery is not null then set the total price to the total_summery.total
    if (bookingSummary && bookingSummary.total_summery) {
      setTotalPrice(bookingSummary.total_summery.total);
    }
  }, []);
  console.log("total price", totalPrice);
  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await axiosInstance.get(`/global-data/payment-types`);
        // console.log("response summary", response);
        if (response.status === 200) {
          setPaymentTypes(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSummary();
  }, []);

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Default to the first payment type if available
    if (paymentTypes.length > 0) {
      setSelectedPaymentType(paymentTypes[0]);
    }
  }, [paymentTypes]);

  const handlePaymentSelection = (paymentType) => {
    setSelectedPaymentType(paymentType);
    updatePaymentType(paymentType);
  };

  const handlePaymentMethodSelection = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setPaymentMethod(paymentMethod?.en);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const getPaymentIcon = (method) => {
    return selectedPaymentMethod === method.name
      ? method.activeIcon
      : method.icon;
  };

  // Handler for when the payment button is clicked
  const handlePayment = async () => {
    setIsPayButtonDisabled(true); // Disable button when payment process starts

    try {
      const response = await axiosInstance.post(
        "/sports/booking/store-with-register",
        booking
      );
      console.log("response booking", response);
      if (response.status === 200) {
        // Clear the booking state after successful booking and from the local storage
        // localStorage.removeItem("booking");
        // redirect the user to payment url as external link to pay response.data.data.payment_url
        // window.location.href = response.data.data.payment_url;
        window.open(response.data.data.payment_url, "_blank");
        // if there is user already logged in and token stored in the localstorage then do nothing but if not then store the response.data.data.token in the localstorage
        if (!user) {
          localStorage.setItem("token", response.data.data.token);
        }
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon("error", error?.response?.data?.message);
    } finally {
      setIsPayButtonDisabled(false); // Re-enable button after the response
    }
  };

  const onCouponChange = (e) => {
    setCoupon(e.target.value);
  };

  const onApplyCoupoun = async (coupon) => {
    console.log("coupon from on apply coupon", coupon);

    if (!coupon) {
      setCouponError("Please enter a coupon code.");
      return;
    }

    if (!isUpdating) {
      if (!booking || !booking.booking_date) {
        console.log(
          "Booking date is not set or booking is null/undefined, skipping summary update."
        );
        return;
      }
      const summaryObject = transformBookingForSummary();
      // Filter out empty users
      const filteredUsers = summaryObject?.booking_users?.filter(
        (user) => user.name && user.name.trim() !== ""
      );
      // Filter out empty dependents
      const filteredDependents = summaryObject?.booking_dependents?.filter(
        (dependent) => dependent.name && dependent.name.trim() !== ""
      );

      try {
        const applyCouponResult = await axiosInstance.post(`/booking/summery`, {
          booking_platform: "Web",
          booking_date: booking?.booking_date,
          payment_type: "pay in full" || selectedPaymentType,
          coupon_code: coupon || null,
          booking_users: filteredUsers,
          booking_dependents: filteredDependents,
        });

        console.log("response apply coupon,", applyCouponResult);
        if (applyCouponResult.status === 200) {
          setBookingSummary(applyCouponResult.data.data);
          setCouponError(null);
          setCouponApplied(true);
          setAppliedCouponCode(coupon);
          updateCouponCode(coupon);
        }
      } catch (error) {
        console.error(error);
        // Update the following line to match the error structure you're getting from Axios
        setCouponError(error.response?.data?.message);
      }
    } else {
      if (!updateBookingData || !updateBookingData.booking_date) {
        console.log(
          "Booking date is not set or booking is null/undefined, skipping summary update."
        );
        return;
      }
      const summaryObject = transformUpdatedBookingForSummary();
      // Filter out empty users
      const filteredUsers = summaryObject?.booking_users?.filter(
        (user) => user.name && user.name.trim() !== ""
      );
      // Filter out empty dependents
      const filteredDependents = summaryObject?.booking_dependents?.filter(
        (dependent) => dependent.name && dependent.name.trim() !== ""
      );

      try {
        const applyCouponResult = await axiosInstance.post(`/booking/summery`, {
          booking_platform: "Web",
          booking_date: updateBookingData?.booking_date,
          payment_type: "pay in full" || selectedPaymentType,
          coupon_code: coupon || null,
          booking_users: filteredUsers,
          booking_dependents: filteredDependents,
        });

        console.log("response apply coupon,", applyCouponResult);
        if (applyCouponResult.status === 200) {
          updateCouponCode(coupon);
          setBookingSummary(applyCouponResult.data.data);
          setCouponError(null);
          setCouponApplied(true);
          setAppliedCouponCode(coupon);
          updateCouponCode(coupon);
        }
      } catch (error) {
        console.error(error);
        // Update the following line to match the error structure you're getting from Axios
        setCouponError(error.response?.data?.message);
      }
    }
  };
  const onRemoveCoupon = async () => {
    if (!isUpdating) {
      // If you need to make an API call to remove the coupon and get the updated summary:
      try {
        const updateSummaryResult = await axiosInstance.post(
          `/booking/summery`,
          {
            booking_platform: "Web",
            booking_date: booking?.booking_date,
            payment_type: selectedPaymentType,
            coupon_code: null,
            booking_users: booking?.booking_users, // or filteredUsers if you need to filter them again
            booking_dependents: booking?.booking_dependents, // or filteredDependents if needed
          }
        );

        console.log("response after removing coupon,", updateSummaryResult);
        if (updateSummaryResult.status === 200) {
          onRemoveCouponFromBooking();
          setBookingSummary(updateSummaryResult.data.data); // Update the summary without the coupon
          setCoupon(""); // Clear the coupon input
          setCouponError(false);
          setCouponApplied(false);
          setAppliedCouponCode("");
        }
      } catch (error) {
        console.error("Error removing coupon:", error);
        // Update the following line to match the error structure you're getting from Axios
        setCouponError(error.response?.data?.message);
      }
    } else {
      try {
        const updateSummaryResult = await axiosInstance.post(
          `/booking/summery`,
          {
            booking_platform: "Web",
            booking_date: updateBookingData?.booking_date,
            payment_type: selectedPaymentType,
            coupon_code: null,
            booking_users: updateBookingData?.booking_users, // or filteredUsers if you need to filter them again
            booking_dependents: updateBookingData?.booking_dependents, // or filteredDependents if needed
          }
        );

        console.log("response after removing coupon,", updateSummaryResult);
        if (updateSummaryResult.status === 200) {
          onRemoveCouponFromBooking();
          setBookingSummary(updateSummaryResult.data.data); // Update the summary without the coupon
          setCoupon(""); // Clear the coupon input
          setCouponError(false);
          setCouponApplied(false);
          setAppliedCouponCode("");
        }
      } catch (error) {
        console.error("Error removing coupon:", error);
        // Update the following line to match the error structure you're getting from Axios
        setCouponError(error.response?.data?.message);
      }
    }
  };

  const allCheckboxesChecked = () => {
    return Object.values(checkboxStates).every((checked) => checked);
  };

  useEffect(() => {
    setIsPayButtonDisabled(!allCheckboxesChecked());
  }, [checkboxStates]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxStates((prevState) => ({ ...prevState, [name]: checked }));
  };

  return (
    <>
      {contextHolder}
      <section className="w-full h-auto">
        <div className="container mx-auto">
          <button
            onClick={handleBack}
            className="w-full flex items-start justify-start gap-[16px] my-[2rem]"
          >
            <svg
              className="self-center xs:w-[18px] xs:h-[18px] rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              fill="none"
              viewBox="0 0 25 24"
            >
              <path
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="3"
                d="M15.418 4.08l-6.52 6.52c-.77.77-.77 2.03 0 2.8l6.52 6.52"
              ></path>
            </svg>

            <span className="text-[#2A2A2A] text-[18px] xs:text-[16px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-semibold leading-7">
              {language === "en" ? "Payment" : "الدفع"}
            </span>
          </button>

          <div className="w-full flex items-start justify-start gap-1">
            <div className="w-[35px] h-[22px] sm:w-[19px] sm:h-[22px] ">
              <svg
                className="w-full h-full self-start sm:self-center"
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.6671 11.3337C9.23981 11.3337 8.83002 11.1932 8.52787 10.9431C8.22573 10.6931 8.05599 10.3539 8.05599 10.0003C8.05599 9.26033 8.77293 8.66699 9.6671 8.66699C10.0944 8.66699 10.5042 8.80747 10.8063 9.05752C11.1085 9.30757 11.2782 9.6467 11.2782 10.0003C11.2782 10.3539 11.1085 10.6931 10.8063 10.9431C10.5042 11.1932 10.0944 11.3337 9.6671 11.3337ZM14.5004 13.3337V6.66699H4.83377V13.3337H14.5004ZM14.5004 5.33366C14.9277 5.33366 15.3375 5.47413 15.6397 5.72418C15.9418 5.97423 16.1115 6.31337 16.1115 6.66699V13.3337C16.1115 13.6873 15.9418 14.0264 15.6397 14.2765C15.3375 14.5265 14.9277 14.667 14.5004 14.667H4.83377C4.40647 14.667 3.99668 14.5265 3.69454 14.2765C3.3924 14.0264 3.22266 13.6873 3.22266 13.3337V6.66699C3.22266 5.92699 3.9396 5.33366 4.83377 5.33366H5.63932V4.00033C5.63932 3.11627 6.06368 2.26842 6.81903 1.6433C7.57439 1.01818 8.59887 0.666992 9.6671 0.666992C10.196 0.666992 10.7198 0.753211 11.2085 0.920727C11.6971 1.08824 12.1412 1.33377 12.5152 1.6433C12.8892 1.95283 13.1859 2.3203 13.3883 2.72471C13.5907 3.12913 13.6949 3.56259 13.6949 4.00033V5.33366H14.5004ZM9.6671 2.00033C9.02616 2.00033 8.41147 2.21104 7.95826 2.58611C7.50505 2.96118 7.25043 3.46989 7.25043 4.00033V5.33366H12.0838V4.00033C12.0838 3.46989 11.8292 2.96118 11.3759 2.58611C10.9227 2.21104 10.308 2.00033 9.6671 2.00033Z"
                  fill="#565656"
                />
              </svg>
            </div>

            <p className="text-[14px] md:text-[16px] text-[#565656] leading-4  font-normal self-center">
              {language === "en"
                ? "This form is encrypted and your information is always guaranteed secure."
                : "هذا النموذج مشفر ومعلوماتك دائمًا مضمونة بأمان."}
            </p>
          </div>

          <div className="kn-payment-grid xs:my-[14px] my-[40px]">
            {/* left */}
            <div className="w-full flex flex-col items-start justify-start gap-[24px]">
              {paymentError === "Payment-failed" && (
                <div class="w-full h-auto mb-[2rem] rounded-[6px] p-[16px] bg-[#FDE8E8]">
                  <div class="w-full flex flex-col items-start justify-start gap-2">
                    <div class="flex items-start justify-start gap-2">
                      <svg
                        className="self-center"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 15.75C7.66498 15.75 6.35994 15.3541 5.2499 14.6124C4.13987 13.8707 3.27471 12.8165 2.76382 11.5831C2.25292 10.3497 2.11925 8.99251 2.3797 7.68314C2.64015 6.37377 3.28303 5.17104 4.22703 4.22703C5.17104 3.28303 6.37377 2.64015 7.68314 2.3797C8.99251 2.11925 10.3497 2.25292 11.5831 2.76382C12.8165 3.27471 13.8707 4.13987 14.6124 5.2499C15.3541 6.35994 15.75 7.66498 15.75 9C15.748 10.7896 15.0362 12.5054 13.7708 13.7708C12.5054 15.0362 10.7896 15.748 9 15.75ZM9 3.6C7.93198 3.6 6.88795 3.91671 5.99992 4.51007C5.1119 5.10343 4.41977 5.94679 4.01105 6.93351C3.60234 7.92023 3.4954 9.00599 3.70376 10.0535C3.91212 11.101 4.42642 12.0632 5.18163 12.8184C5.93683 13.5736 6.89902 14.0879 7.94651 14.2962C8.99401 14.5046 10.0798 14.3977 11.0665 13.989C12.0532 13.5802 12.8966 12.8881 13.4899 12.0001C14.0833 11.1121 14.4 10.068 14.4 9C14.3984 7.56833 13.8289 6.19575 12.8166 5.1834C11.8043 4.17105 10.4317 3.60161 9 3.6Z"
                          fill="#C81E1E"
                        />
                        <path
                          d="M9.95445 9L11.5022 7.45223C11.6252 7.32492 11.6932 7.15441 11.6917 6.97743C11.6901 6.80045 11.6192 6.63115 11.494 6.506C11.3689 6.38085 11.1996 6.30986 11.0226 6.30832C10.8456 6.30678 10.6751 6.37482 10.5478 6.49778L9 8.04555L7.45223 6.49778C7.32492 6.37482 7.15441 6.30678 6.97743 6.30832C6.80045 6.30986 6.63115 6.38085 6.506 6.506C6.38085 6.63115 6.30986 6.80045 6.30832 6.97743C6.30678 7.15441 6.37482 7.32492 6.49778 7.45223L8.04555 9L6.49778 10.5478C6.43331 10.61 6.38188 10.6845 6.34651 10.7669C6.31113 10.8492 6.29251 10.9378 6.29173 11.0274C6.29095 11.1171 6.30803 11.2059 6.34197 11.2889C6.37591 11.3719 6.42603 11.4472 6.48941 11.5106C6.55279 11.574 6.62815 11.6241 6.71111 11.658C6.79406 11.692 6.88295 11.709 6.97257 11.7083C7.0622 11.7075 7.15077 11.6889 7.23313 11.6535C7.31548 11.6181 7.38996 11.5667 7.45223 11.5022L9 9.95445L10.5478 11.5022C10.6751 11.6252 10.8456 11.6932 11.0226 11.6917C11.1996 11.6901 11.3689 11.6192 11.494 11.494C11.6192 11.3689 11.6901 11.1996 11.6917 11.0226C11.6932 10.8456 11.6252 10.6751 11.5022 10.5478L9.95445 9Z"
                          fill="#C81E1E"
                        />
                      </svg>

                      <h5 class="text-[#C81E1E] text-[16px] font-semibold leading-6">
                        {language === "en" ? "Payment Failure" : "فشل الدفع"}
                      </h5>
                    </div>
                    <p class="text-[#C81E1E] text-[14px] font-normal leading-5">
                      {language === "en"
                        ? "Please try another payment method."
                        : " يرجى تجربة طريقة دفع أخرى"}
                    </p>
                  </div>
                </div>
              )}
              {/* payment type */}
              {!isUpdating && (
                <div className="w-full flex flex-col items-start justify-start gap-4">
                  <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6 ">
                    {language === "en"
                      ? "How Would You Like to Pay?"
                      : "كيف ترغب في الدفع؟"}
                  </h3>

                  <div className="w-full flex flex-col md:flex-row items-start justify-start gap-4">
                    {paymentTypes.map((type, index) => (
                      <div
                        className={`kn-payment-type ${
                          selectedPaymentType === type
                            ? "kn-payment-type-active"
                            : ""
                        } `}
                      >
                        <label key={index} className={`kn-payment-type-radio `}>
                          <span>
                            {" "}
                            {type === "pay in full"
                              ? `${
                                  language === "en"
                                    ? "Pay Full Bill"
                                    : "الدفع بالكامل"
                                } (${totalPrice} ${translate("currency")})`
                              : language === "en"
                              ? "Split Payment"
                              : "الدفع المقسم"}
                          </span>
                          <input
                            className="self-center"
                            type="radio"
                            name="paymentType"
                            value={type}
                            checked={
                              selectedPaymentType === type ? true : false
                            }
                            onChange={() => handlePaymentSelection(type)}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* divider */}
              {!isUpdating && (
                <div className="w-full h-[1px] bg-[#DADADA] my-[0.5rem]"></div>
              )}
              {/* who are you paying for */}
              {selectedPaymentType === "split" && (
                <div className="w-full flex flex-col items-start justify-start gap-4">
                  <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6 ">
                    {language === "en"
                      ? "Who are you paying for?"
                      : "لمن تدفع؟"}
                  </h3>
                  {booking?.booking_users?.map((user, index) => (
                    <div
                      className={`kn-payment-user-checkbox ${
                        index === 0 ? "kn-payment-user-checkbox-disabled" : ""
                      }`}
                      key={index}
                    >
                      <label className="kn-custom-checkbox w-full flex flex-row items-start justify-between cursor-pointer">
                        <span className="flex items-center justify-start gap-2">
                          <svg
                            className="self-center"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.99935 8C9.8403 8 11.3327 6.50762 11.3327 4.66667C11.3327 2.82572 9.8403 1.33334 7.99935 1.33334C6.1584 1.33334 4.66602 2.82572 4.66602 4.66667C4.66602 6.50762 6.1584 8 7.99935 8Z"
                              stroke="#2A2A2A"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                              stroke="#2A2A2A"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>{" "}
                          <span className="flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                            {user.name}
                            {/* {index === 0 && " (YOU)"} -{" "}  */}
                            {/* <span className="text-[#2A2A2A] text-[14px] font-semibold leading-5 ">
                              {" "}
                              {user.price_after_discount}{" "}
                              {translate("currency")}
                            </span> */}
                          </span>
                        </span>
                        <input
                          className="kn-payment-checkbox self-center"
                          type="checkbox"
                          checked={
                            selectedUsersForPayment.includes(index) ||
                            index === 0
                          }
                          onChange={(e) =>
                            handleUserCheckboxToggle(index, e.target.checked)
                          }
                          disabled={index === 0}
                        />
                      </label>
                    </div>
                  ))}
                  {bookingSummary?.summery?.dependents?.map(
                    (dependent, index) => (
                      <div
                        className="kn-payment-user-checkbox kn-payment-user-checkbox-disabled"
                        key={index}
                      >
                        <label className="kn-custom-checkbox w-full flex flex-row items-start justify-between cursor-not-allowed">
                          <span className="flex items-center justify-start gap-2">
                            <svg
                              className="self-center"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.99935 8C9.8403 8 11.3327 6.50762 11.3327 4.66667C11.3327 2.82572 9.8403 1.33334 7.99935 1.33334C6.1584 1.33334 4.66602 2.82572 4.66602 4.66667C4.66602 6.50762 6.1584 8 7.99935 8Z"
                                stroke="#2A2A2A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                                stroke="#2A2A2A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>{" "}
                            <span className="flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                              {dependent.child_name} -{" "}
                              <span className="text-[#2A2A2A] text-[14px] font-semibold leading-5 ">
                                {" "}
                                {dependent.price_after_discount}{" "}
                                {translate("currency")}
                              </span>
                            </span>
                          </span>
                          <input
                            className="kn-payment-checkbox self-center"
                            type="checkbox"
                            checked={true}
                            disabled={true}
                          />
                        </label>
                      </div>
                    )
                  )}

                  <div className="w-full flex  items-start justify-start gap-2 my-2">
                    <div className="w-[35px] h-[30px] sm:w-[19px] sm:h-[22px] ">
                      <svg
                        className="w-full h-full self-start sm:self-center"
                        viewBox="0 0 19 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.39929 14.5897H9.873V10.1686H8.39929V14.5897ZM9.13615 8.69484C9.34492 8.69484 9.52005 8.6241 9.66153 8.48263C9.803 8.34115 9.87349 8.16627 9.873 7.95798C9.873 7.74921 9.80227 7.57408 9.66079 7.4326C9.51931 7.29113 9.34443 7.22064 9.13615 7.22113C8.92737 7.22113 8.75224 7.29186 8.61077 7.43334C8.46929 7.57482 8.3988 7.7497 8.39929 7.95798C8.39929 8.16676 8.47003 8.34189 8.6115 8.48336C8.75298 8.62484 8.92786 8.69533 9.13615 8.69484ZM9.13615 18.274C8.11683 18.274 7.15891 18.0804 6.2624 17.6933C5.3659 17.3062 4.58606 16.7814 3.92288 16.1187C3.25971 15.4555 2.73483 14.6757 2.34822 13.7792C1.96162 12.8826 1.76807 11.9247 1.76758 10.9054C1.76758 9.88609 1.96113 8.92818 2.34822 8.03167C2.73532 7.13516 3.2602 6.35532 3.92288 5.69215C4.58606 5.02898 5.3659 4.50409 6.2624 4.11749C7.15891 3.73088 8.11683 3.53733 9.13615 3.53684C10.1555 3.53684 11.1134 3.73039 12.0099 4.11749C12.9064 4.50458 13.6862 5.02947 14.3494 5.69215C15.0126 6.35532 15.5377 7.13516 15.9248 8.03167C16.3119 8.92818 16.5052 9.88609 16.5047 10.9054C16.5047 11.9247 16.3112 12.8826 15.9241 13.7792C15.537 14.6757 15.0121 15.4555 14.3494 16.1187C13.6862 16.7818 12.9064 17.307 12.0099 17.6941C11.1134 18.0812 10.1555 18.2745 9.13615 18.274ZM9.13615 16.8003C10.7818 16.8003 12.1757 16.2292 13.3178 15.0871C14.4599 13.9449 15.031 12.5511 15.031 10.9054C15.031 9.25976 14.4599 7.86588 13.3178 6.72375C12.1757 5.58162 10.7818 5.01056 9.13615 5.01056C7.4905 5.01056 6.09661 5.58162 4.95448 6.72375C3.81236 7.86588 3.24129 9.25976 3.24129 10.9054C3.24129 12.5511 3.81236 13.9449 4.95448 15.0871C6.09661 16.2292 7.4905 16.8003 9.13615 16.8003Z"
                          fill="#565656"
                        />
                      </svg>
                    </div>
                    <p className="text-[#565656] text-[14px] font-normal leading-5 self-center mt-[2px]">
                      {language === "en"
                        ? "We will send an email to the other guest(s) with the payment link and booking information."
                        : " سنرسل بريدًا إلكترونيًا إلى الضيف (الضيوف) الآخرين برابط الدفع ومعلومات الحجز."}
                    </p>
                  </div>
                </div>
              )}
              {/* select payment method */}
              <div className="w-full flex flex-col items-start justify-start gap-4">
                <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6 ">
                  {language === "en"
                    ? "Select Payment Method?"
                    : "اختر طريقة الدفع"}
                </h3>
                <div className="w-full flex flex-col items-start justify-start gap-4">
                  {paymentMethods.map((type, index) => (
                    <div
                      key={index}
                      className={`kn-payment-type ${
                        selectedPaymentMethod === type.name
                          ? "kn-payment-type-active"
                          : ""
                      } `}
                    >
                      <label key={index} className={`kn-payment-type-radio `}>
                        <div className="flex items-start justify-start gap-3">
                          {/* to add icon */}
                          <img
                            className="self-center"
                            src={getPaymentIcon(type)}
                            alt={`${type.name} icon`}
                          />

                          <span className="self-center">
                            {type?.name[language]}
                          </span>
                        </div>
                        <input
                          className="self-center"
                          type="radio"
                          name={`paymentType${type.id}`}
                          value={type}
                          checked={
                            selectedPaymentMethod === type.name ? true : false
                          }
                          onChange={() =>
                            handlePaymentMethodSelection(type.name)
                          }
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {/* divider */}
              <div className="w-full h-[1px] bg-[#DADADA] my-[0.5rem]"></div>
              {/* credit cards payment details */}

              {/* {selectedPaymentMethod.en === "creditcard" && <CardInfo />}
              {selectedPaymentMethod.en === "applepay" && (
                <div className="w-full flex items-center justify-center md:items-start md:justify-start ">
                  <ButtonPrimary className="w-[15rem]">
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.6371 17.28C11.6571 18.23 10.5871 18.08 9.55708 17.63C8.46708 17.17 7.46708 17.15 6.31708 17.63C4.87708 18.25 4.11708 18.07 3.25708 17.28C-1.62292 12.25 -0.902922 4.59 4.63708 4.31C5.98708 4.38 6.92708 5.05 7.71708 5.11C8.89708 4.87 10.0271 4.18 11.2871 4.27C12.7971 4.39 13.9371 4.99 14.6871 6.07C11.5671 7.94 12.3071 12.05 15.1671 13.2C14.5971 14.7 13.8571 16.19 12.6271 17.29L12.6371 17.28ZM7.61708 4.25C7.46708 2.02 9.27708 0.18 11.3571 0C11.6471 2.58 9.01708 4.5 7.61708 4.25Z"
                        fill="white"
                      />
                    </svg>
                    {language === "en" ? "Pay" : "دفع"}
                  </ButtonPrimary>
                </div>
              )} */}
              {/* have a promo code */}
              <PromoCodeInput
                error={couponError}
                handleChange={onCouponChange}
                onApply={() => onApplyCoupoun(coupon)}
                onRemove={onRemoveCoupon}
                coupon={coupon}
                couponApplied={couponApplied}
              />
              {/* checklist */}
              <div className="w-full flex flex-col items-start justify-start gap-3 my-[1.6rem]">
                <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                  <input
                    className="kn-payment-checkbox self-center"
                    type="checkbox"
                    checked={checkboxStates.termsAndConditions}
                    name="termsAndConditions"
                    onChange={handleCheckboxChange}
                  />

                  <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                    {language === "en" ? "I agree to the" : "أوافق على "}
                    <button
                      onClick={() => navigate("/terms-and-conditions")}
                      className="underline  font-semibold"
                    >
                      {" "}
                      {language === "en"
                        ? "Terms & Conditions"
                        : "الشروط والأحكام"}
                    </button>
                  </span>
                </label>
                <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                  <input
                    className="kn-payment-checkbox self-center"
                    type="checkbox"
                    checked={checkboxStates.cancellationPolicy}
                    name="cancellationPolicy"
                    onChange={handleCheckboxChange}
                  />

                  <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                    {language === "en" ? "I agree to the" : "أوافق على "}
                    <button
                      className="underline  font-semibold"
                      onClick={() => navigate("/cancellation-policy")}
                    >
                      {" "}
                      {language === "en"
                        ? "Cancellation Policy"
                        : "سياسة الإلغاء"}
                    </button>
                  </span>
                </label>
                <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                  <input
                    className="kn-payment-checkbox self-center"
                    type="checkbox"
                    checked={checkboxStates.responsibilityAcknowledge}
                    name="responsibilityAcknowledge"
                    onChange={handleCheckboxChange}
                  />

                  <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                    {language === "en"
                      ? "I understand that kona will not be held responsible for any accidents that may occur."
                      : "أنا أفهم أن كونا لن تكون مسؤولة عن أي حوادث قد تحدث."}
                  </span>
                </label>
                <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                  <input
                    className="kn-payment-checkbox self-center"
                    type="checkbox"
                    checked={checkboxStates.waiverAgreement}
                    name="waiverAgreement"
                    onChange={handleCheckboxChange}
                  />

                  <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                    {language === "en"
                      ? "I agree to sign a waiver."
                      : "أوافق على توقيع إعفاء."}
                  </span>
                </label>
              </div>
            </div>

            {/* right  */}
            {isUpdating ? (
              <UpdateBookingSummary
                data={{
                  activity_image: pic,
                  activity_name: "Aqua Park Ticket",
                }}
              />
            ) : (
              <BookingInfoSummary
                data={{
                  activity_image: pic,
                  activity_name: "Aqua Park Ticket",
                }}
              />
            )}

            {/* Procceed */}
            <div className="w-full flex items-center md:items-start justify-center md:justify-start my-[0.5rem]">
              <ButtonSecondary
                className={`xs:w-[200px] md:w-[250px] lg:w-[300px]`}
                onClick={handlePayment}
                disabled={isPayButtonDisabled}
              >
                Pay
              </ButtonSecondary>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
