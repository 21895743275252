import React, { useState } from "react";
import Input from "../../shared/inputs/Input";
import Reveal from "../../shared/reveal/Reveal";

const CardInfo = () => {
  return (
    <>
      <Reveal>
        <form className="w-full h-auto">
          <Input
            ID="credit-card-number-input"
            type="number"
            name="creditCardNumberInput"
            placeholder="Card Number"
            max={16}
            // value={passportNumber}
            // onChange={(e) => setPassportNumber(e.target.value)}
            // error={passportNumberError}
            // errorMessage={passportNumberErrorMessage}
          />
          <div className="flex flex-col md:flex-row items-start justify-between gap-[1rem]">
            <Input
              ID="expiration-date-input"
              type="text"
              name="expirationDate"
              placeholder="Expiration Date"

              // value={passportNumber}
              // onChange={(e) => setPassportNumber(e.target.value)}
              // error={passportNumberError}
              // errorMessage={passportNumberErrorMessage}
            />
            <Input
              ID="cvv-input"
              type="number"
              name="cvvInput"
              placeholder="CVV"
              // value={passportNumber}
              // onChange={(e) => setPassportNumber(e.target.value)}
              // error={passportNumberError}
              // errorMessage={passportNumberErrorMessage}
            />
          </div>
          <Input
            ID="zip-code-input"
            type="number"
            name="zipCodeInput"
            placeholder="ZIP Code"
            // value={passportNumber}
            // onChange={(e) => setPassportNumber(e.target.value)}
            // error={passportNumberError}
            // errorMessage={passportNumberErrorMessage}
          />
        </form>
      </Reveal>
    </>
  );
};

export default CardInfo;
