import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthRouteGuard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      navigate("/account/account-settings");
    } else if (token && !user) {
      navigate("/complete-registeration");
    } else if (!token) {
      navigate("/login");
    }
  }, [navigate]);
};

export default AuthRouteGuard;
