import "./App.css";
import { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./components/root/Root";
import Signin from "./components/auth/Signin";
import Signup from "./components/auth/Signup";
import Error from "./components/error/Error";
import AccountSettingsInfo from "./components/accout-settings/account-settings/AccountSettingsInfo";
import MyBookings from "./components/accout-settings/account-booking/MyBookings";
import AccountRoot from "./components/accout-settings/AccountRoot";
import BookingDetails from "./components/accout-settings/account-booking/active-bookings/BookingDetails";
import Navigate from "./shared/navigate/Navigate";
import ActivityPage from "./components/activity/activity-page/ActivityPage";
import Booking from "./components/activity/booking/Booking";
import BookingInformation from "./components/activity/booking/BookingInformation";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import DynamicPage from "./components/dynamic-page/DynamicPage";
import OTP from "./components/auth/OTP";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import PublicRoute from "./components/auth/PublicRoute";
import { Payment } from "./components/payment/Payment";
import UpdateBooking from "./components/update-booking/UpdateBooking";
import PaymentRedirect from "./components/payment/PaymentRedirect";
import PaymentSuccess from "./components/payment/PaymentSuccess";
import CompleteRegisteration from "./components/auth/CompleteSignup";
import UserSplitPayment from "./components/payment/UserSplitPayment";
import TokenVerfication from "./components/payment/TokenVerfication";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Event handler for updating online status
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    // Add event listeners
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Clean up event listeners
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <Navigate to="/home" id="51" slug="/home" />,
        },
        // Nested slug pattern
        { path: "/:parentSlug/:slug", element: <DynamicPage /> },
        // Single slug pattern
        { path: "/:slug", element: <DynamicPage /> },
        {
          path: "/account",
          element: (
            <ProtectedRoute>
              <AccountRoot />
            </ProtectedRoute>
          ),
          errorElement: <Error />,
          children: [
            {
              index: true,
              element: <Navigate to="/account/my-booking" />, // Redirect to default route
            },
            {
              path: "/account/my-booking",
              element: <MyBookings />,
            },
            {
              path: "/account/my-booking/booking-details",
              element: <BookingDetails />,
            },
            {
              path: "/account/account-settings",
              element: <AccountSettingsInfo />,
            },
          ],
        },

        // {
        //   path: "/terms-and-conditions",
        //   element: <TermsAndConditions />,
        // },
        // {
        //   path: "/cancellation-policy",
        //   element: <CancellationPolicy />,
        // },
        // {
        //   path: "/privacy-policy",
        //   element: <PrivacyPolicy />,
        // },
        {
          path: "/user-payment",
          element: <UserSplitPayment />,
        },

        {
          path: "/activities/:slug",
          element: <ActivityPage />,
        },
        {
          path: "/booking",
          element: <Booking />,
        },
        {
          path: "/booking-information",
          element: <BookingInformation />,
        },
        {
          path: "/update-booking",
          element: <UpdateBooking />,
        },
        {
          path: "/payment",
          element: <Payment />,
        },
        {
          path: "/callback",
          element: <PaymentRedirect />,
        },
        {
          path: "/payment-success",
          element: <PaymentSuccess />,
        },
      ],
    },
    {
      path: "/login",
      element: (
        <PublicRoute>
          {" "}
          <Signin />
        </PublicRoute>
      ),
    },
    {
      path: "/signup",
      element: (
        <PublicRoute>
          <Signup />
        </PublicRoute>
      ),
    },

    {
      path: "/complete-registeration",
      element: <CompleteRegisteration />,
    },
    {
      path: "/complete-with-token/:complete_data_token",
      element: <TokenVerfication />,
    },

    {
      path: "/forget-password",
      element: (
        <PublicRoute>
          <ForgetPassword />
        </PublicRoute>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      ),
    },
    {
      path: "/otp",
      element: (
        <PublicRoute>
          <OTP />
        </PublicRoute>
      ),
    },
    { path: "page-not-found", element: <Error /> },

    { path: "*", element: <Error /> },
  ]);

  if (!isOnline) {
    // If offline, render the OfflineUI component only
    return (
      <div className="w-full flex flex-col gap-[2rem] items-center justify-center my-[10rem] mx-auto offline">
        {/* <img
          style={{
            aspectRatio: "1/4",
          }}
          src={noInternetSvg}
          alt="No internet"
        /> */}

        <h1 className="text-[#2A2A2A] text-[22px] md:text-[28px] lg:text-[32px] font-bold  leading-6">
          No internet Connection
        </h1>

        <p>
          You are offline. Please check your network cables, modem, and router
          then try to reconnect to Wi-Fi .
        </p>
      </div>
    );
  }

  // When online, render the application as usual
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
