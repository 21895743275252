import React, { useState, useEffect } from "react";
import Input from "../../../shared/inputs/Input";
import { useBooking } from "../../../contexts/BookingContext";
import { useLanguage } from "../../../contexts/LanguageContext";

const DependentForm = React.memo(({ index, type }) => {
  const { language } = useLanguage();
  const {
    updateBookingDependent,
    booking,
    removeBookingDependent,
    getPersonByIndexAndType,
  } = useBooking();

  const [currentDependent, setCurrentDependent] = useState(
    getPersonByIndexAndType(index, type)
  );
  const [firstName, setFirstName] = useState(
    currentDependent?.name?.split(" ")[0] || ""
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const [lastName, setLastName] = useState(
    currentDependent?.name?.split(" ")[1] || ""
  );
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  // if first and last name are not empty, combine them or if the first name exists, use it as the full name
  const fullName =
    firstName !== "" && lastName !== ""
      ? `${firstName} ${lastName}`
      : firstName;

  const [dateOfBirth, setDateOfBirth] = useState(
    currentDependent?.birthdate || ""
  );
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState("");

  const handleFirstNameBlur = () => {
    if (!firstName) {
      setFirstNameError(true);
      setFirstNameErrorMessage("First name is required");
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage("");

      updateBookingDependent(index, {
        ...booking.booking_dependents[index],
        name: `${firstName} ${lastName}`,
      });
    }
  };

  const handleLastNameBlur = () => {
    if (!lastName) {
      setLastNameError(true);
      setLastNameErrorMessage("Last name is required");
    } else {
      setLastNameError(false);
      setLastNameErrorMessage("");

      updateBookingDependent(index, {
        ...booking.booking_dependents[index],
        name: `${firstName} ${lastName}`,
      });
    }
  };

  const handleDateOfBirthBlur = () => {
    if (!dateOfBirth) {
      setDateOfBirthError(true);
      setDateOfBirthErrorMessage("Date of birth is required");
    } else {
      setDateOfBirthError(false);
      setDateOfBirthErrorMessage("");
      updateBookingDependent(index, {
        ...booking.booking_dependents[index],
        birthdate: dateOfBirth,
      });
    }
  };


  return (
    <div className="kn-booking-info">
      <div className="w-full flex items-start justify-between">
        <span className="w-auto inline-block bg-[#EBEBEB] py-[8px] px-[16px] rounded-[8px]">
          <svg
            className="self-center inline-block mr-[8px]"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            fill="none"
            viewBox="0 0 16 17"
          >
            <path
              stroke="#2A2A2A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
              d="M8 8.5a3.333 3.333 0 100-6.667A3.333 3.333 0 008 8.5zM13.727 15.167c0-2.58-2.567-4.667-5.727-4.667s-5.727 2.087-5.727 4.667"
            ></path>
          </svg>
          <span className="text-[#2A2A2A] text-[14px] font-medium leading-5 self-center">
            {fullName !== null && fullName !== ""
              ? fullName
              : "Child " + (index + 1)}
          </span>
        </span>

        <button
          className="self-center"
          onClick={() => removeBookingDependent(index)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            fill="none"
            viewBox="0 0 23 24"
          >
            <g fill="#E63232" clipPath="url(#clip0_510_4999)">
              <path d="M12.275 11.879l3.042-3.042a.626.626 0 00-.882-.883l-3.042 3.011-3.061-3.06a.626.626 0 00-.883.882l3.055 3.092-2.992 2.955a.626.626 0 10.882.882l2.986-2.986 2.967 2.967a.626.626 0 00.883-.882l-2.955-2.936z"></path>
              <path d="M11.267 21.894a10.016 10.016 0 110-20.032 10.016 10.016 0 010 20.032zm0-18.779a8.763 8.763 0 100 17.527 8.763 8.763 0 000-17.527z"></path>
            </g>
            <defs>
              <clipPath id="clip0_510_4999">
                <path
                  fill="#fff"
                  d="M0 0H22.534V22.534H0z"
                  transform="translate(0 .612)"
                ></path>
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <form className="w-full h-auto mt-[2rem] ">
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID="first-name-input"
            type="text"
            name="firstnameInput"
            placeholder={language === "en" ? "Enter your first name" : "أدخل اسمك الأول"}
            label={language === "en" ? "First Name" : "الاسم الأول"}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={firstNameError}
            errorMessage={firstNameErrorMessage}
            redStar={true}
            onBlur={handleFirstNameBlur}
          />
          <Input
            ID="last-name-input"
            type="text"
            name="lastnameInput"
            placeholder={language === "en" ? "Enter your last name" : "أدخل اسمك الأخير"}
            label={language === "en" ? "Last Name" : "الاسم الأخير"}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={lastNameError}
            errorMessage={lastNameErrorMessage}
            redStar={true}
            onBlur={handleLastNameBlur}
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID="date-input"
            type="date"
            name="dateInput"
            placeholder={language === "en" ? "Enter your date of birth" : "أدخل تاريخ ميلادك"}
            label={language === "en" ? "Date of Birth" : "تاريخ الميلاد"}
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            error={dateOfBirthError}
            errorMessage={dateOfBirthErrorMessage}
            redStar={true}
            onBlur={handleDateOfBirthBlur}
          />
        </div>
      </form>
    </div>
  );
});

export default DependentForm;
