import React, { useState } from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import MinusIcon from "./MinusIcon";
import PlusIcon from "./PlusIcon";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { useUser } from "../../contexts/UserContext";

const FAQ = ({ data, isTwoCols = true }) => {
  const { user } = useUser();

  const navigate = useNavigate();
  const { language } = useLanguage();
  const [expanded, setExpanded] = useState([]);

  const handleNavigation = (button_type, page_slug) => {
    console.log("🚀 ~ handleNavigation ~ page_slug:", page_slug);
    console.log("🚀 ~ handleNavigation ~ button_type:", button_type);
    if (button_type === "bookings") {
      navigate("/booking");
    } else if (button_type === "page") {
      navigate(`/${page_slug}`);
    }
  };

  // Handle change event
  const handleChange = (keys) => {
    console.log("keys", keys);
    // If keys array is empty, or the key of the current item is not included (it has been collapsed),
    // then set the expanded state to the new array of keys.
    if (keys.length === 0 || !expanded.includes(keys[0])) {
      setExpanded(keys);
    } else {
      // If the key is already in the expanded state, it means we want to collapse it.
      // So we remove it from the array.
      setExpanded(expanded.filter((key) => key !== keys[0]));
    }
  };
  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15 * index,
      },
    }),
  };

  console.log("FAQ data", data);
  return (
    <>
      <section className="w-full h-auto pt-[32px] pb-0 md:py-[42px] lg:py-[65px] my-[1.5rem]">
        <div className="w-full container mx-auto">
          <div
            className={`w-full grid grid-cols-1 ${
              isTwoCols ? "md:grid-cols-2" : ""
            } gap-[3rem]`}
          >
            <div className="w-full flex flex-col items-start justify-start gap-1">
              <div>
                <h2 className="text-[40px] text-[#2A2A2A] font-semibold leading-5 mb-[1.4rem] ">
                  {data?.title[language]}
                </h2>
              </div>

              {/* {data.description && (
                <p className="text-[16px] text-[#767676] font-normal leading-6">
                  {data?.description[language]}
                </p>

              )} */}
              {data.faq_category && (
                <p className="text-[16px] text-[#767676] font-normal leading-6">
                  {data?.faq_category?.description[language]}
                </p>
              )}

              {data?.has_button === "yes" && (
                <ButtonPrimary
                  onClick={() => {
                    if (user) {
                      handleNavigation(data?.button_type, data?.page_slug);
                    } else {
                      navigate("/contact-us");
                    }
                  }}
                  className={`mt-[3rem]`}
                >
                  {data?.button_text[language]}
                </ButtonPrimary>
              )}
            </div>

            <div className="w-full flex flex-col items-start justify-start gap-2">
              <Accordion
                preExpanded={expanded} // Pass the expanded state here
                onChange={handleChange} // Set the handler that updates the state
                // allowMultipleExpanded
                allowZeroExpanded
                className="w-full flex flex-col items-start justify-start "
              >
                {data?.faq_category?.faqs.map((item, index) => (
                  <motion.div
                    className="w-full "
                    variants={fadeInAnimationsVariants}
                    initial="initial"
                    whileInView="animate"
                    viewport={{
                      once: true,
                    }}
                    custom={index}
                    key={index}
                  >
                    <AccordionItem
                      uuid={item.id}
                      className={`w-full border-b border-[#E2E2E2] ${
                        index === 0 ? "pt-0 pb-[1.2rem]" : "py-[1.2rem]"
                      } `}
                    >
                      <AccordionItemHeading className="w-full">
                        <AccordionItemButton className="w-full flex items-start justify-between">
                          <p className="text-[#3A3A3A] text-[14px] md:text-[16px] font-medium leading-5 ">
                            {item.question[language]}
                          </p>
                          <AccordionItemState>
                            {({ expanded }) =>
                              expanded ? <MinusIcon /> : <PlusIcon />
                            }
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="w-full my-[1rem]">
                        <p className="text-[#3A3A3A] text-[14px] font-normal">
                          {item.answer[language]}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </motion.div>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
