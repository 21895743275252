import React, { useState, useEffect } from "react";
import signUpPic from "../../assets/signup.webp";
import logo from "../../assets/kona-logo.webp";
import Input from "../../shared/inputs/Input";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import Reveal from "../../shared/reveal/Reveal";
import { ThreeDots } from "react-loader-spinner";
import axiosInstance from "../../axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useLanguage } from "../../contexts/LanguageContext";
import { useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import AuthRouteGuard from "./AuthRouteGuard";

const CompleteRegisteration = () => {
  AuthRouteGuard();
  const { language } = useLanguage();
  const location = useLocation();
  const { login } = useUser();

  // state to store the user token and user data in location state
  const [userToken, setUserToken] = useState("");
  console.log("🚀 ~ CompleteRegisteration ~ userToken:", userToken)
  const [bookingDataId, setBookingDataId] = useState("");
  console.log("🚀 ~ CompleteRegisteration ~ bookingDataId:", bookingDataId)
  const navigate = useNavigate();
  // states
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");

  const [identityCardType, setIdentityCardType] = useState("id card");

  // states inside the tabs
  const [nationalId, setNationalId] = useState("");
  const [nationalIdError, setNationalIdError] = useState(false);
  const [nationalIdErrorMessage, setNationalIdErrorMessage] = useState("");

  const [birthDate, setBirthDate] = useState("");
  const [birthDateError, setBirthDateError] = useState(false);
  const [birthDateErrorMessage, setBirthDateErrorMessage] = useState("");

  const [passportNumber, setPassportNumber] = useState("");
  const [passportNumberError, setPassportNumberError] = useState(false);
  const [passportNumberErrorMessage, setPassportNumberErrorMessage] =
    useState("");

  const [residencyNumber, setResidencyNumber] = useState("");
  const [residencyNumberError, setResidencyNumberError] = useState(false);
  const [residencyNumberErrorMessage, setResidencyNumberErrorMessage] =
    useState("");

  const [registerStatus, setRegisterStatus] = useState("");

  const [loading, setLoading] = useState(false);

  const [selectedGender, setSelectedGender] = useState();
  console.log("🚀 ~ CompleteRegisteration ~ selectedGender:", selectedGender);
  const [selectedGenderError, setSelectedGenderError] = useState(false);
  const [selectedGenderErrorMessage, setSelectedGenderErrorMessage] =
    useState("");

  const onSelectedGender = (e) => {
    setSelectedGender(e.target.value);
  };
  const handleDivClick = (gender) => {
    const fakeEvent = {
      target: {
        value: gender,
        name: "gender",
      },
    };
    onSelectedGender(fakeEvent);
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      message: `${
        type === "success"
          ? language === "en"
            ? "Registration successful! Welcome aboard"
            : "تم التسجيل بنجاح! مرحبًا بك على متن الطائرة"
          : language === "en"
          ? `Registration failed. Please try again`
          : `فشل التسجيل. حاول مرة أخرى`
      }`,
      description: null,
      placement: "top",
      className: "mb-0",
      // duration: 3000,
    });
  };

  // use effect to store complete_data_token in the state
  useEffect(() => {
    setUserToken(location?.state?.complete_data_token);
    setBookingDataId(location?.state?.bookingId);

    // wanna set booking data id in the local storage
    localStorage.setItem("bookingDataId", location?.state?.bookingId);
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const now = new Date();
  const year = now.getFullYear();

  const handleTabClick = (value) => {
    setIdentityCardType(value);
  };

  const validateForm = () => {
    // Validate password
    if (!password) {
      setPasswordError(true);
      language === "en"
        ? setPasswordErrorMessage("Please enter your password")
        : setPasswordErrorMessage("الرجاء إدخال كلمة المرور");
      return false;
    }
    if (password.length < 8) {
      setPasswordError(true);
      language === "en"
        ? setPasswordErrorMessage("Password must be at least 8 characters")
        : setPasswordErrorMessage(
            "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل"
          );
      return false;
    }
    setPasswordError(false);
    setPasswordErrorMessage("");

    // Validate confirm password
    if (!confirmPassword) {
      setConfirmPasswordError(true);
      language === "en"
        ? setConfirmPasswordErrorMessage("Please confirm your password")
        : setConfirmPasswordErrorMessage("الرجاء تأكيد كلمة المرور");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      language === "en"
        ? setConfirmPasswordErrorMessage("Passwords do not match")
        : setConfirmPasswordErrorMessage("كلمات المرور غير متطابقة");
      return false;
    }
    setConfirmPasswordError(false);
    setConfirmPasswordErrorMessage("");

    // Validate birth date
    if (!birthDate) {
      setBirthDateError(true);
      language === "en"
        ? setBirthDateErrorMessage("Please enter your birth date")
        : setBirthDateErrorMessage("الرجاء إدخال تاريخ ميلادك");
      return false;
    }
    setBirthDateError(false);
    setBirthDateErrorMessage("");

    if (!selectedGender) {
      setSelectedGenderError(true);
      language === "en"
        ? setSelectedGenderErrorMessage("Please select your gender")
        : setSelectedGenderErrorMessage("الرجاء تحديد جنسك");
      return false;
    }

    // Validate national ID based on selected identity card type
    if (identityCardType === "id card" && !nationalId) {
      setNationalIdError(true);
      language === "en"
        ? setNationalIdErrorMessage("Please enter your national id")
        : setNationalIdErrorMessage("الرجاء إدخال الهوية الوطنية");
      return false;
    }
    if (identityCardType === "passport" && !passportNumber) {
      setPassportNumberError(true);
      language === "en"
        ? setPassportNumberErrorMessage("Please enter your passport number")
        : setPassportNumberErrorMessage("الرجاء إدخال رقم جواز سفرك");
      return false;
    }
    if (identityCardType === "iqama" && !residencyNumber) {
      setResidencyNumberError(true);
      language === "en"
        ? setResidencyNumberErrorMessage("Please enter your residency number")
        : setResidencyNumberErrorMessage("الرجاء إدخال رقم الإقامة");
      return false;
    }

    // If we get this far, all validations have passed
    setNationalIdError(false);
    setNationalIdErrorMessage("");
    setPassportNumberError(false);
    setPassportNumberErrorMessage("");
    setResidencyNumberError(false);
    setResidencyNumberErrorMessage("");
    setSelectedGenderError(false);
    setSelectedGenderErrorMessage("");

    return true;
  };

  const onSignup = async () => {
    try {
      const response = await axiosInstance.post("/sports/complete-users-data", {
        password: password,
        identity_card_type: identityCardType,
        identity_card_number: nationalId,
        birthdate: birthDate,
        gender: selectedGender,
        lang: language,
        token: userToken,
      });
      console.log("res from complete user profile", response);
      if (response.status === 200) {
        openNotificationWithIcon("success");
        setRegisterStatus("success");
        console.log("response", response);
        // login(response.data.data.user, response.data.data.token);
        navigate("/payment-success", {
          state: localStorage.getItem("bookingDataId"),
        });
        setPassword("");
        setConfirmPassword("");
        setBirthDate("");
        setNationalId("");
        setPassportNumber("");
        setResidencyNumber("");
       
      }
      console.log("response", response);
    } catch (error) {
      console.log("error", error);
      console.log("errors", error.response.data.errors);
      setRegisterStatus("error");
      openNotificationWithIcon("error");
    }
  };

  const onCompleteRegisteration = (e) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (!isFormValid) return;
    else {
      onSignup();
    }
  };

  console.log("location from complete sign up", location);
  console.log("user token from complete sign up", userToken);

  return (
    <>
      {contextHolder}
      {loading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <Reveal>
          <div className="w-full h-auto  grid grid-flow-col lg:grid-cols-2  ">
            {/* Right: Login Form */}
            <div className=" md:p-8 container mx-auto w-full  ">
              <Link to="/">
                <img src={logo} alt="" srcSet="" />
              </Link>
              <div className="w-full h-auto my-[2rem] rounded-[6px] p-[16px] bg-[#DEF7EC]">
                <div className="w-full flex flex-col items-start justify-start gap-2">
                  <div className="flex items-start justify-start gap-2">
                    <svg
                      className="self-center"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 2.25C7.66498 2.25 6.35994 2.64588 5.2499 3.38758C4.13987 4.12928 3.27471 5.18348 2.76382 6.41689C2.25292 7.65029 2.11925 9.00749 2.3797 10.3169C2.64015 11.6262 3.28303 12.829 4.22703 13.773C5.17104 14.717 6.37377 15.3598 7.68314 15.6203C8.99251 15.8807 10.3497 15.7471 11.5831 15.2362C12.8165 14.7253 13.8707 13.8601 14.6124 12.7501C15.3541 11.6401 15.75 10.335 15.75 9C15.748 7.21039 15.0362 5.49464 13.7708 4.2292C12.5054 2.96375 10.7896 2.25197 9 2.25ZM11.5022 8.12722L8.80223 10.8272C8.67565 10.9538 8.50399 11.0249 8.325 11.0249C8.14602 11.0249 7.97436 10.9538 7.84778 10.8272L6.49778 9.47722C6.37482 9.34992 6.30678 9.17941 6.30832 9.00243C6.30986 8.82545 6.38085 8.65615 6.506 8.531C6.63115 8.40585 6.80045 8.33486 6.97743 8.33332C7.15441 8.33178 7.32492 8.39982 7.45223 8.52277L8.325 9.39555L10.5478 7.17277C10.6751 7.04982 10.8456 6.98178 11.0226 6.98332C11.1996 6.98486 11.3689 7.05585 11.494 7.181C11.6192 7.30615 11.6901 7.47545 11.6917 7.65243C11.6932 7.82941 11.6252 7.99992 11.5022 8.12722Z"
                        fill="#046C4E"
                      />
                    </svg>
                    <h5 className="text-[#046C4E] text-[16px] font-semibold leading-6">
                      {language === "en" ? "Payment Success" : "نجاح الدفع"}
                    </h5>
                  </div>
                  <p className="text-[#046C4E] text-[14px] font-normal leading-5">
                    {language === "en"
                      ? "We have successfully received your payment, complete your account to be able to access your booking."
                      : "لقد تلقينا دفعتك بنجاح، أكمل حسابك لتتمكن من الوصول إلى حجوزاتك."}
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-[90%]  mx-auto py-8 px-0 sm:p-20  md:p-14 lg:pt-10 lg:pb-0   ">
                <div className="w-full flex flex-col items-start justify-start gap-2">
                  {/* tracking-[10px] */}
                  <p className="text-[#2A2A2A] text-[14px] font-normal leading-4  tracking-[1px]  mb-1 ">
                    {language === "en"
                      ? "Account Information"
                      : " معلومات الحساب"}
                  </p>

                  <h1 className="text-[#2a2a2a] text-[20px] md:text-[28px] font-bold leading-7 md:leading-6 lg:leading-10 tracking-[0px] mb-1">
                    {language === "en"
                      ? "Complete Account Information"
                      : "أكمل معلومات الحساب الخاصة بك"}
                  </h1>
                  <p className="text-[#494949] text-[14px] font-normal leading-5  tracking-[1px]  mb-2 ">
                    {language === "en"
                      ? "Complete your account to be able to access your booking, order online from your favorite restaurants and enjoy easier future bookings."
                      : " أكمل حسابك لتتمكن من الوصول إلى حجوزاتك وطلب الطعام عبر الإنترنت من مطاعمك المفضلة والاستمتاع بحجوزات مستقبلية أسهل."}
                  </p>
                </div>
                {/* signin form */}
                <form
                  onSubmit={onCompleteRegisteration}
                  action="#"
                  method="POST"
                  className="mt-[3rem]"
                >
                  <Input
                    ID="password-input"
                    type="password"
                    name="phoneInput"
                    placeholder={
                      language === "en"
                        ? "Enter your password"
                        : "أدخل كلمة المرور"
                    }
                    label={language === "en" ? "Password" : "كلمة السر"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordError}
                    errorMessage={passwordErrorMessage}
                  />
                  <Input
                    ID="password-input-confirm"
                    type="password"
                    name="phoneInputConfirm"
                    placeholder={
                      language === "en"
                        ? "Confirm your password"
                        : "تأكيد كلمة المرور"
                    }
                    label={
                      language === "en"
                        ? "Confirm Password"
                        : "تأكيد كلمة المرور"
                    }
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={confirmPasswordError}
                    errorMessage={confirmPasswordErrorMessage}
                  />
                  <Input
                    ID="birth-date-input"
                    type="date"
                    name="birthDateInput"
                    placeholder={
                      language === "en"
                        ? "Enter your birth date"
                        : "أدخل تاريخ ميلادك"
                    }
                    label={language === "en" ? "Birth Date" : "تاريخ الميلاد"}
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                    error={birthDateError}
                    errorMessage={birthDateErrorMessage}
                  />

                  <div className="w-full flex flex-col items-start justify-start mb-8">
                    <label
                      htmlFor="bordered-radio-1"
                      className="relative inline-block mb-4 text-black font-semibold leading-4 text-[16px] "
                    >
                      {language === "en" ? "Gender" : "الجنس"}
                    </label>
                    <div className="w-full flex  items-start justify-between gap-4 mb-2">
                      <div
                        onClick={() => handleDivClick("male")} // Call the new function on click
                        style={{ cursor: "pointer" }}
                        className={`kn-radio-input ${
                          selectedGenderError ? "kn-inputField-error" : ""
                        } `}
                      >
                        <label
                          htmlFor="gender-radio"
                          className="text-[12px] text-[#353535] font-medium leading-4"
                        >
                          {language === "en" ? "Male" : "ذكر"}
                        </label>
                        <input
                          id="gender-radio"
                          type="radio"
                          value="male"
                          name="gender"
                          style={{
                            widows: "100%",
                            height: "100%",
                            cursor: "pointer",
                            alignSelf: "center",
                          }}
                          onChange={onSelectedGender}
                          checked={selectedGender === "male"}
                        />
                      </div>
                      <div
                        onClick={() => handleDivClick("female")} // Call the new function on click
                        style={{ cursor: "pointer" }}
                        className={`kn-radio-input ${
                          selectedGenderError ? "kn-inputField-error" : ""
                        } `}
                      >
                        <label
                          htmlFor="female-radio"
                          className="text-[12px] text-[#353535] font-medium leading-4"
                        >
                          {language === "en" ? "Female" : "أنثى"}
                        </label>
                        <input
                          id="female-radio"
                          type="radio"
                          value="female"
                          name="gender"
                          style={{
                            widows: "100%",
                            height: "100%",
                            cursor: "pointer",
                            alignSelf: "center",
                          }}
                          // className="w-full h-full cursor-pointer self-center"
                          onChange={onSelectedGender}
                          checked={selectedGender === "female"}
                        />
                      </div>
                    </div>
                    {selectedGenderError && (
                      <div className="w-full mt-2 flex items-start justify-start gap-2">
                        <svg
                          className="self-center"
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          fill="none"
                          viewBox="0 0 9 9"
                        >
                          <rect
                            width="9"
                            height="9"
                            fill="#C81E1E"
                            rx="4.5"
                          ></rect>
                          <path
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="0.692"
                            d="M6.115 2.86L3 5.975M3 2.86l3.115 3.115"
                          ></path>
                        </svg>
                        <span className="text-[#D03C32] text-[12px] font-normal leading-5 tracking-[0.1px]">
                          {selectedGenderErrorMessage}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="w-full flex flex-col items-start justify-start mb-2">
                    <label
                      htmlFor="id-type-input"
                      className="inline-block mb-4 text-black font-semibold leading-4 text-[16px] "
                    >
                      {language === "en" ? "ID Type" : "نوع الهوية"}
                    </label>
                    <Tabs className="kn-signup-id-type-tabs-list">
                      <TabList
                        className={`w-full flex  items-start justify-start flex-wrap gap-2 mb-[2rem]`}
                      >
                        <Tab onClick={() => handleTabClick("id card")}>
                          {language === "en" ? "National ID" : "الهوية الوطنية"}
                        </Tab>
                        <Tab onClick={() => handleTabClick("passport")}>
                          <p className="hidden sm:block">
                            {language === "en"
                              ? "Passport Number"
                              : "رقم الجواز"}
                          </p>
                          <p className="block sm:hidden">
                            {language === "en" ? "Passport No" : "رقم الجواز"}
                          </p>
                        </Tab>
                        <Tab onClick={() => handleTabClick("iqama")}>
                          <p className="hidden sm:block">
                            {language === "en"
                              ? "Residency Number"
                              : "رقم الإقامة"}
                          </p>
                          <p className="block sm:hidden">
                            {language === "en" ? "Residency No" : "رقم الإقامة"}
                          </p>
                        </Tab>
                      </TabList>

                      <TabPanel>
                        <>
                          <Input
                            ID="national-id-input"
                            type="number"
                            name="nationalIdInput"
                            placeholder={
                              language === "en"
                                ? "Enter your national id"
                                : "أدخل الهوية الوطنية"
                            }
                            label={
                              language === "en"
                                ? "National ID"
                                : "الهوية الوطنية"
                            }
                            value={nationalId}
                            onChange={(e) => setNationalId(e.target.value)}
                            error={nationalIdError}
                            errorMessage={nationalIdErrorMessage}
                          />
                        </>
                      </TabPanel>

                      <TabPanel>
                        <Input
                          ID="passport-number-input"
                          type="number"
                          name="passportNumberInput"
                          placeholder={
                            language === "en"
                              ? "Enter your passport number"
                              : "أدخل رقم جواز سفرك"
                          }
                          label={
                            language === "en" ? "Passport Number" : "رقم الجواز"
                          }
                          value={passportNumber}
                          onChange={(e) => setPassportNumber(e.target.value)}
                          error={passportNumberError}
                          errorMessage={passportNumberErrorMessage}
                        />
                      </TabPanel>

                      <TabPanel>
                        <Input
                          ID="residency-number-input"
                          type="number"
                          name="residencyNumberInput"
                          placeholder={
                            language === "en"
                              ? "Enter your residency number"
                              : "أدخل رقم الإقامة"
                          }
                          label={
                            language === "en"
                              ? "Residency Number"
                              : "رقم الإقامة"
                          }
                          value={residencyNumber}
                          onChange={(e) => setResidencyNumber(e.target.value)}
                          error={residencyNumberError}
                          errorMessage={residencyNumberErrorMessage}
                        />
                      </TabPanel>
                    </Tabs>
                  </div>

                  <div className="w-full md:w-[75%] mx-auto flex flex-col items-center justify-center gap-6 ">
                    <ButtonSecondary
                      type="submit"
                      className={`w-full md:w-[20rem] `}
                    >
                      {language === "en"
                        ? "Complete Registration"
                        : "اكمل التسجيل"}
                    </ButtonSecondary>
                    <p className="text-black text-[14px] font-normal leading-4">
                      {language === "en" ? "Have an account?" : "لديك حساب؟"}{" "}
                      {"  "}
                      <Link
                        to="/login"
                        className="self-center text-[#FCD12A] text-[14px] font-bold leading-5"
                      >
                        {" "}
                        {language === "en" ? "Sign In" : "تسجيل الدخول"}
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            {/* Left: Image */}
            <div className="kn-signin w-full h-full overflow-hidden hidden lg:block ">
              {/* <div className='h-full w-full ' style={signInStyle}></div> */}
              <div className="sign-in-photo-wrapper">
                <img
                  src={signUpPic}
                  alt="sign in cover"
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>

          <div className="w-full h-auto border-t border-black py-6 ">
            <div className="container mx-auto">
              <div className="w-full flex flex-col md:flex-row items-start justify-between gap-4">
                <p className="text-black text-[16px] font-normal leading-6 tracking-[0px]">
                  © {year}{" "}
                  {language === "en"
                    ? "Kona. All rights reserved."
                    : "كونا. كل الحقوق محفوظة."}
                </p>
                <div className="flex items-start justify-start gap-4">
                  <a
                    href="#"
                    className="text-black font-normal text-[16px] leading-6 underline"
                  >
                    {language === "en" ? "Privacy Policy" : "سياسة الخصوصية"}
                  </a>
                  <a
                    href="#"
                    className="text-black font-normal text-[16px] leading-6 underline"
                  >
                    {language === "en" ? "Terms of Service" : "شروط الخدمة"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Reveal>
      )}
    </>
  );
};

export default CompleteRegisteration;
