import React, { useRef, useEffect } from "react";
import PlanItem from "./PlanItem";
import { motion, useInView, useAnimation } from "framer-motion";
import { useLanguage } from "../../contexts/LanguageContext";

const Plans = ({ data }) => {
  const { language } = useLanguage();

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const plansData = [
    {
      title: data?.first_title[language],
      description: data?.first_subtitle[language],
      listItems: data?.first_checklist,
      // to: '/memberships/athlete-plan',
      isDark: true, // Assuming you want to set this manually
      id: data?.first_page_id,
      slug: data?.first_page_slug,
    },
    {
      title: data?.second_title[language],
      description: data?.second_subtitle[language],
      listItems: data?.second_checklist,
      // to: '/memberships/federation-athlete-plan',
      isDark: false, // Assuming you want to set this manually
      id: data?.second_page_id,
      slug: data?.second_page_slug,
    },
  ];

  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
      },
    }),
  };

  return (
    <section className="w-full py-[2rem] md:py-[6rem] kn-linear-gradient mb-[2rem] ">
      <div className="container mx-auto">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={mainControls}
          className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8"
        >
          {plansData.map((planItem, index) => (
            <motion.div
              key={index}
              variants={fadeInAnimationsVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              custom={index}
            >
              <PlanItem isDark={planItem.isDark} planItemData={planItem} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Plans;
