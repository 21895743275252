import React from 'react'

const UnorderedList = ({ listTitle, listItems }) => {
    return (
        <>
            <h4 className='text-[#1E1E1E] text-[32px] font-bold leading-[48px]'>
                {listTitle}
            </h4>


            <ul className='list-disc flex flex-col items-start justify-start gap-2 pl-4'>
                {listItems.map((item, index) => (
                    <li key={index} className='text-[#1E1E1E] text-[16px] font-normal  leading-6 opacity-80'>
                        {item}
                    </li>
                ))}

            </ul>
        </>
    )
}

export default UnorderedList
