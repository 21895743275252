import React from "react";
import CtaAction from "../../shared/cta-action/CtaAction";
import Grid from "../../shared/grid/Grid";
import Plans from "../../shared/plan/Plans";
import Gallery from "../../shared/gallery/Gallery";
import DownloadApp from "../../shared/download-app/DownloadApp";
import Reveal from "../../shared/reveal/Reveal";
import Sports from "../../components/home/sports/Sports";
import Services from "../../components/home/services/Services";
import Stats from "../../components/about/stats/Stats";
import Testimonials from "../../components/about/testimonial/Testimonials";
import FAQ from "../faq/FAQ";
import ContactUs from "../contact-us/ContactUs";
import GalleryCarousel from "../carousel/GalleryCarousel";
import Features from "../features/Features";
import Activities from "../../components/activities/all-activities-list";
const RenderComponent = (addon) => {
  const GalleryLearnMoreHandler = (e) => {
    console.log("learn more clicked from Gallery ");
  };
  const { name, content } = addon;

  switch (name) {
    case "all-activities-list":
      return <Activities data={content} />;
    case "home-activities-list":
      return <Sports data={content} />;
    case "home-title-image-desc-repeater-content":
      return (
        <Reveal>
          <Services data={content} />
        </Reveal>
      );
    case "home-background-image":
      return (
        <Reveal>
          <CtaAction data={content} />
        </Reveal>
      );
    case "img-title-desc":
      if (addon.type === "repeater") {
        // Check if 'content' is an array before calling map
        if (Array.isArray(content)) {
          return content.map((item, index) => (
            <Reveal key={index}>
              <Grid data={item} GridWrapperClasses="py-[12px]" />
            </Reveal>
          ));
        } else {
          // Handle the case where 'content' is not an array
          console.error("Expected content to be an array, but got:", content);
          return null; // or a fallback component/message
        }
      } else if (addon.type === "single") {
        return (
          <Reveal>
            <Grid data={content} GridWrapperClasses="py-[20px] md:py-[40px]" />
          </Reveal>
        );
      }
    case "home-plans":
      return <Plans data={content} />;
    case "gallery":
      if (content.show_style === "slider") {
        return <GalleryCarousel data={content} />;
      } else {
        return <Gallery data={content} ctaOnClick={GalleryLearnMoreHandler} />;
      }

    case "mobile-app-store":
      return (
        <Reveal>
          <DownloadApp data={content} />
        </Reveal>
      );
    case "title-description-repeater-statistics":
      return (
        <Reveal>
          <Stats data={content} />
        </Reveal>
      );

    case "title-description-repeater-cards":
      return (
        <Reveal>
          <Testimonials data={content} />
        </Reveal>
      );

    case "faq":
      return <FAQ data={content} />;
    case "contact":
      return <ContactUs data={content} />;
    case "header-repeater-img-title-desc":
      return (
        <Reveal>
          <Features data={content} />
        </Reveal>
      );

    default:
      return null;
  }
};

export default RenderComponent;
