import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GuestBookingInfo from "./GuestBookingInfo";
import pic from "../../../assets/beach-access/beach-access-grid-1.webp";
import ButtonSecondary from "../../../shared/buttons/ButtonSecondary";
import { useBooking } from "../../../contexts/BookingContext";
import { BookingInfoSummary } from "./BookingInfoSummary";
import { DependentBookingInfo } from "./DependentBookingInfo";
import axiosInstance from "../../../axios/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import { useLanguage } from "../../../contexts/LanguageContext";
import { notification } from "antd";

const BookingInformation = React.memo(() => {
  const [api, contextHolder] = notification.useNotification();
  const { language, translate } = useLanguage();
  const location = useLocation();
  const { state } = location;
  console.log("state location", state);
  const navigate = useNavigate();
  const {
    booking,
    addBookingUser,
    addBookingDependent,
    guestPrice,
    dependentPrice,
  } = useBooking();
  const [guest, setGuest] = useState();
  const [dependent, setDependent] = useState();
  const [activities, setActivities] = useState([]);
  const [food, setFood] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [guestPricePerPerson, setGuestPricePerPerson] = useState(null);
  const [dependentPricePerPerson, setDependentPricePerPerson] = useState(null);

  useEffect(() => {
    if (state) {
      setGuestPricePerPerson(state.guestPrice);
      setDependentPricePerPerson(state.dependentPrice);
    }
  }, []);
  // const [guestPrice, setGuestPrice] = useState(null);
  // const [dependentPrice, setDependentPrice] = useState(null);

  // useEffect(() => {
  //   if (location.state) {
  //     setGuestPrice(location?.state.adultPrice);
  //     setDependentPrice(location.state.kidsPrice);
  //   }
  // }, [location.state]);

  // wanna when this page loads for the first time to clear formErrors from local storage
  useEffect(() => {
    localStorage.removeItem("formErrors");
  }, []);
  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      message: errorMessage,
      description: null,
      placement: "top",
      className: "mb-0",
      duration: 3,
    });
  };

  useEffect(() => {
    if (booking) {
      const newGuestCount = booking.booking_users.length;
      const newDependentCount = booking.booking_dependents.length;

      // Only update state if counts have actually changed
      if (guest !== newGuestCount) {
        setGuest(newGuestCount);
      }
      if (dependent !== newDependentCount) {
        setDependent(newDependentCount);
      }
    }
  }, [booking]);

  // fetch activities
  useEffect(() => {
    const getActivities = async () => {
      setIsLoading(true);
      // disable body scroll
      document.body.style.overflow = "hidden";

      try {
        const activitiesData = await axiosInstance.get(
          `/booking/pre-requests/data/activities-list`,
          {
            params: {
              date: booking?.booking_date,
            },
          }
        );
        if (activitiesData.status === 200) {
          setActivities(activitiesData.data.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        // enable body scroll
        document.body.style.overflow = "auto";
      }
    };
    getActivities();
  }, []);

  // fetch Food
  useEffect(() => {
    const getActivities = async () => {
      setIsLoading(true);
      // disable body scroll
      document.body.style.overflow = "hidden";

      try {
        const foodList = await axiosInstance.get(
          `/booking/pre-requests/data/food-category-list`,
          {
            params: {
              date: booking?.booking_date,
            },
          }
        );
        if (foodList.status === 200) {
          setFood(foodList.data.data);
          // console.log("food list", foodList.data.data);
          //   enable body scroll
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        // enable body scroll
        document.body.style.overflow = "auto";
      }
    };
    getActivities();
  }, []);

  const handleBack = () => {
    navigate("/booking");
  };

  const [formErrors, setFormErrors] = useState({});

  const proceedToPaymentHandler = async () => {
    // validate booking object data
    try {
      // wanna add language as query param to the request
      const response = await axiosInstance.post(
        `/booking/validate-users?lang=${language}`,
        {
          booking_users: booking?.booking_users,
          booking_dependents: booking?.booking_dependents,
        }
      );
      if (response.status === 200) {
        navigate("/payment");
      }
    } catch (error) {
      console.error(error);

      setFormErrors(error?.response?.data?.errors || {});

      // set form errors at local storage
      localStorage.setItem(
        "formErrors",
        JSON.stringify(error?.response?.data?.errors)
      );

      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  console.log("formErrors", formErrors);

  return (
    <>
      {contextHolder}

      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto">
          <div className="container mx-auto">
            <button
              onClick={handleBack}
              className="w-full flex items-start justify-start gap-[16px] my-[0.5rem] lg:my-[2rem]"
            >
              <svg
                className="self-center rtl:rotate-180 xs:w-[18px] xs:h-[18px]"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
                viewBox="0 0 25 24"
              >
                <path
                  stroke="#292D32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  d="M15.418 4.08l-6.52 6.52c-.77.77-.77 2.03 0 2.8l6.52 6.52"
                ></path>
              </svg>

              <span className="text-[#2A2A2A] text-[18px] xs:text-[16px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-semibold leading-7">
                {language === "en" ? "Booking Information" : "معلومات الحجز"}
              </span>
            </button>

            <div className="kn-booking-info-grid xs:my-[14px] my-[40px]">
              <div className="w-full flex flex-col items-start justify-start gap-[24px]">
                {/* guest item */}
                {/* render  adultsQuantity based on number of guests */}
                {guest > 0 &&
                  [...Array(guest)].map((_, index) => (
                    <GuestBookingInfo
                      activities={activities}
                      food={food}
                      index={index}
                      date={booking?.booking_date}
                      key={index}
                      type="adult"
                    />
                  ))}
                {/* dependent item */}
                {/* render  dependentQuantity based on number of dependents */}
                {dependent > 0 &&
                  [...Array(dependent)].map((_, index) => (
                    <DependentBookingInfo
                      activities={activities}
                      food={food}
                      date={booking?.booking_date}
                      index={index}
                      key={index}
                      type="dependent"
                    />
                  ))}

                <button
                  onClick={addBookingUser}
                  className="w-full flex items-start justify-between  gap-[1.5rem] xs:py-[6px] py-[16px] xs:px-[12px] px-[24px] border border-[#E8E8E8] rounded-[9px]"
                >
                  <span className="text-start text-[#2A2A2A] xs:text-[10px] text-[12px] md:text-[14px] font-medium leading-5 self-center">
                    {language === "en"
                      ? "Add Another Adult"
                      : "أضف شخصًا بالغًا آخر "}{" "}
                    - ({guestPricePerPerson} {translate("currency")})
                  </span>
                  <div className="xs:w-[20px] xs:h-[18px] w-[20px] h-[18px] md:w-[25px] md:h-[23px] self-center overflow-hidden">
                    <svg
                      className="self-center w-full h-full"
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="27"
                      fill="none"
                      viewBox="0 0 29 27"
                    >
                      <rect
                        width="28.8"
                        height="27"
                        x="0.199"
                        fill="#FCD12A"
                        rx="7.2"
                      ></rect>
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.8"
                        d="M9.2 13.735h5.4m5.4 0h-5.4m0 0V8.1v10.8"
                      ></path>
                    </svg>
                  </div>
                </button>
                <button
                  onClick={addBookingDependent}
                  className="w-full flex items-start justify-between gap-[1.5rem] xs:py-[6px] py-[16px] xs:px-[12px] px-[24px] border border-[#E8E8E8] rounded-[9px]"
                >
                  <span className="text-start text-[#2A2A2A] xs:text-[10px] text-[12px] md:text-[14px] font-medium leading-5 self-center">
                    {language === "en" ? "Add Another Child" : "إضافة طفل آخر"}{" "}
                    - ({dependentPricePerPerson} {translate("currency")})
                  </span>
                  <div className="xs:w-[20px] xs:h-[18px] w-[20px] h-[18px] md:w-[25px] md:h-[23px] self-center overflow-hidden">
                    <svg
                      className="self-center w-full h-full"
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="27"
                      fill="none"
                      viewBox="0 0 29 27"
                    >
                      <rect
                        width="28.8"
                        height="27"
                        x="0.199"
                        fill="#FCD12A"
                        rx="7.2"
                      ></rect>
                      <path
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.8"
                        d="M9.2 13.735h5.4m5.4 0h-5.4m0 0V8.1v10.8"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>

              <BookingInfoSummary
                data={{
                  activity_image: pic,
                  activity_name: "Aqua Park Ticket",
                }}
              />

              {/* Procceed */}
              <div className="w-full flex items-center md:items-start justify-center md:justify-start my-[0.5rem]">
                <ButtonSecondary
                  onClick={proceedToPaymentHandler}
                  className={`xs:w-[200px] md:w-[250px] lg:w-[300px]`}
                >
                  {language === "en" ? "Proceed To Payment" : "المتابعة للدفع"}
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default BookingInformation;
