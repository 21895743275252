import React from 'react'
const ContactInfo = ({ icon, infoTitle, infoDesc }) => {
    return (
        <>
            <div className='w-full flex flex-col items-start justify-start gap-3'>
                <div className='flex items-start justify-start gap-2'>
                    <img className='self-center w-[35px] h-[35px] md:w-[40px] md:h-[40px] object-cover' src={icon} alt="" srcSet="" />
                    <p className='self-center text-[#2A2A2A] text-[16px] md:text-[18px]  leading-8 font-bold' >
                        {infoTitle}
                    </p>

                </div>
                <p className='text-[#1D1D1D] text-[14px] md:text-[16px] lg:text-[18px] font-normal leading-7 underline'>
                    {infoDesc}
                </p>

            </div>

        </>
    )
}

export default ContactInfo
