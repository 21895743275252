import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hero from "../../shared/hero/Hero";
import axiosInstance from "../../axios/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import { useLanguage } from "../../contexts/LanguageContext";
import RenderComponent from "../../shared/render/RenderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import parse, { domToReact } from "html-react-parser";

const DynamicPage = () => {
  const { user, token } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { id: stateId } = location.state || {};
  const { language } = useLanguage();
  const params = useParams();
  const [homeData, setHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [heroState, setHeroState] = useState(null);
  console.log("🚀 ~ DynamicPage ~ heroState:", heroState);
  // const [desc, setDesc] = useState(null);
  const slug = params.slug || params.parentSlug;
  // Use stateId if available, otherwise fallback to 15
  const id = stateId || 51;

  const handleNavigation = (button_type, page_slug) => {
    console.log("🚀 ~ handleNavigation ~ page_slug:", page_slug);
    console.log("🚀 ~ handleNavigation ~ button_type:", button_type);
    if (button_type === "bookings") {
      navigate("/booking");
    } else if (button_type === "page") {
      navigate(`/${page_slug}`);
    }
  };
  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = {
    replace: ({ name, attribs, children }) => {
      if (!attribs) return;

      // Add classes to specific tags
      if (name === "h1") {
        return (
          <h1 className="mb-3 text-3xl font-bold text-gray-900 lg:text-4xl">
            {domToReact(children)}
          </h1>
        );
      }
      if (name === "p") {
        return (
          <p className="text-[16px] text-black font-normal leading-7">
            {domToReact(children)}
          </p>
        );
      }
      if (name === "ol") {
        return (
          <ol className="page-list list-decimal flex flex-col items-start justify-start gap-2">
            {domToReact(children)}
          </ol>
        );
      }
      if (name === "li") {
        return (
          <li className="font-normal text-[16px] leading-6 text-[#1E1E1E]">
            {domToReact(children)}
          </li>
        );
      }
      if (name === "h2") {
        return (
          <h2 className="font-bold text-[24px] leading-[36px] text-[#1E1E1E] mb-[12px] mt-[2rem]">
            {domToReact(children)}
          </h2>
        );
      }
      if (name === "h3") {
        return (
          <h2 className="font-bold text-[24px] leading-[36px] text-[#1E1E1E] mb-[12px] mt-[2rem]">
            {domToReact(children)}
          </h2>
        );
      }
      if (name === "h4") {
        return (
          <h2 className="font-bold text-[24px] leading-[36px] text-[#1E1E1E] mb-[12px] mt-[2rem]">
            {domToReact(children)}
          </h2>
        );
      }
      if (name === "h5") {
        return (
          <h2 className="font-bold text-[24px] leading-[36px] text-[#1E1E1E] mb-[12px] mt-[2rem]">
            {domToReact(children)}
          </h2>
        );
      }
    },
  };

  useEffect(() => {
    const getPageData = async () => {
      setIsLoading(true);
      try {
        const pageData = await axiosInstance.get(`/pages/${slug}`);
        if (pageData.status === 200) {
          console.log("🚀 ~ getPageData ~ pageData:", pageData);
          const homeData = pageData.data.data;
          setHomeData(homeData);

          // set hero state
          if (homeData.image) {
            setHeroState({
              heroImage: homeData.image[language],
              sectiohTitle: homeData.inner_title[language],
              sectionDescription: parse(homeData.description[language]),
              isThereSectionCtaButton: homeData.button?.has_button,
              sectionCtaButtonTitle: homeData.button?.button_text[language],
              buttonType: homeData.button?.button_type,
              page_slug: homeData.button?.page_slug,
            });
          } else {
            setHeroState(null);
          }

          // ... set other data states as well
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getPageData();
  }, [language, slug]);

  console.log("data from dynamic page", homeData);

  return (
    <>
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col items-start justify-start ">
          {heroState && (
            <Hero
              heroHeight={slug === "home" ? 470 : 400}
              heroImage={heroState.heroImage}
              sectiohTitle={heroState.sectiohTitle}
              sectionDescription={heroState.sectionDescription}
              isThereSectionCtaButton={heroState.isThereSectionCtaButton}
              sectionCtaButtonTitle={heroState.sectionCtaButtonTitle}
              buttonType={heroState.buttonType}
              page_slug={heroState.page_slug}
              onClick={() => {
                if (user) {
                  handleNavigation(heroState.buttonType, heroState.page_slug);
                } else {
                  navigate("/contact-us");
                }
              }}
            />
          )}

          {/* Dynamically render addons */}
          {homeData?.addons.map((addon, index) => (
            <React.Fragment key={addon.id}>
              {RenderComponent(addon)}
            </React.Fragment>
          ))}

          {/* if there is no addons and no image then dispaly homeData.description */}
          {homeData?.addons.length === 0 && !homeData?.image && (
            <div className="container mx-auto pr-0 sm:pr-[3rem] md:pr-[6rem] lg:pr-[10rem]">
              <div className="w-full flex flex-col items-start justify-start gap-1">
                {parse(homeData?.description[language], options)}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DynamicPage;
