import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useLanguage } from "../../contexts/LanguageContext";
import { motion } from "framer-motion";

const GalleryCarousel = ({ data }) => {
  const { language } = useLanguage();

  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.05 * index,
      },
    }),
  };
  const sliderRef = useRef(null);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <section className="w-full h-auto py-[64px]">
        <div className="container mx-auto">
          <div className="w-full flex flex-col items-center justify-center gap-2 md:gap-6">
            <h1 className="text-[#2A2A2A] font-bold text-[24px] md:text-[32px] lg:text-[40px] leading-6 md:leading-8 lg:leading-10">
              {data?.title[language]}
            </h1>
            <p className="text-[#767676] font-normal text-[14px] md:text-[16px] leading-7 md:leading-6 ">
              {data?.description[language]}
            </p>
          </div>

          <div className="w-full relative">
            {data?.gallery?.length > 0 && (
              <Slider
                ref={sliderRef}
                className="kn-gallery-carousel kn-slick-carousel w-full h-[400px] flex items-start justify-start gap-4 my-[2rem]  "
                {...settings}
              >
                {data?.gallery?.map((image, index) => {
                  return (
                    <motion.div
                      variants={fadeInAnimationsVariants}
                      initial="initial"
                      whileInView="animate"
                      viewport={{
                        once: true,
                      }}
                      custom={index}
                      key={index}
                      className="w-[400px] h-[400px] overflow-hidden mr-[1rem]"
                    >
                      <img
                        key={index}
                        className="w-full h-auto object-cover"
                        src={image}
                        alt="image1"
                        srcSet="gallery image"
                      />
                    </motion.div>
                  );
                })}
              </Slider>
            )}
            {/* slick arrows */}
            <div
              onClick={nextSlide}
              className="btn-next absolute left-[-1%] bottom-[50%] translate-y-[60%] flex items-center justify-center"
            >
              <button className="carousel-next-arrow">
                <svg
                  className="w-full h-[70%]"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7858 4.83936H2.74489L6.43828 1.14596L5.50007 0.214355L0.214355 5.50007L5.50007 10.7858L6.43168 9.85418L2.74489 6.16078H10.7858V4.83936Z"
                    fill="#979494"
                  />
                </svg>
              </button>
            </div>
            <div
              onClick={previousSlide}
              className="btn-prev absolute right-[-1%] bottom-[50%] translate-y-[60%] flex items-center justify-center"
            >
              <button className="carousel-prev-arrow">
                <svg
                  className="w-full h-[70%]"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GalleryCarousel;
