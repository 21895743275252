import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../contexts/LanguageContext";
import QRCode from "react-qr-code";
import activeBookPhoto from "../../../../assets/activeBookPhoto.png";

const ActiveBookingItem = ({ data }) => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  //   console.log("active booking item data", data);

  const onBookingDetails = (e) => {
    e.preventDefault();
    navigate("/account/my-booking/booking-details", { state: data.id });
  };
  //   console.log("booking_reference", data.booking_reference);

  return (
    <>
      <section className="kn-active-booking-item">
        <div className="kn-active-booking-item-info border-bottom-none radius-bottom-none ">
          <div className="w-full flex items-start justify-between">
            <div className="flex items-start justify-start gap-4 self-center">
              <div className="self-center flex flex-col items-start justify-start gap-[15px]">
                <h2 className="text-[#2A2A2A] text-[16px] md:text-[18px] lg:text-[22px] font-semibold leading-5">
                  {language === "en"
                    ? "Sports Club Ticket"
                    : "تذكرة نادي الرياضة"}
                </h2>
                <div className="flex items-start justify-start gap-[6px]">
                  <div className="kn-booking-item-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="1.079"
                        d="M5.756 2.153v2.159M11.512 2.153v2.159M2.519 7.254h12.23M15.108 6.83v6.115c0 2.159-1.079 3.597-3.597 3.597H5.755c-2.518 0-3.597-1.439-3.597-3.597V6.83c0-2.159 1.08-3.598 3.597-3.598h5.756c2.518 0 3.597 1.44 3.597 3.598z"
                      ></path>
                      <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.439"
                        d="M11.292 10.57h.007M11.292 12.73h.007M8.63 10.57h.007M8.63 12.73h.007M5.967 10.57h.007M5.967 12.73h.007"
                      ></path>
                    </svg>
                    <p>{data?.booking_date}</p>
                  </div>

                  <div className="kn-booking-item-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      fill="none"
                      viewBox="0 0 17 17"
                    >
                      <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="0.959"
                        d="M8.358 8.389a3.198 3.198 0 100-6.395 3.198 3.198 0 000 6.395zM13.851 14.784c0-2.475-2.462-4.477-5.493-4.477-3.032 0-5.494 2.002-5.494 4.477"
                      ></path>
                    </svg>
                    <p>
                      {data?.adult_count}{" "}
                      {language === "en" ? "Adults" : " بالغين"}
                    </p>
                    {/* أطفال */}
                  </div>
                </div>

                <div className="flex items-start justify-start gap-[6px]">
                  <div className="kn-booking-item-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="15"
                      fill="none"
                      viewBox="0 0 9 15"
                    >
                      <path
                        fill="#1D1D1D"
                        d="M2.7 2.018a1.8 1.8 0 113.6 0 1.8 1.8 0 01-3.6 0zm1.35 9v2.7a.899.899 0 11-1.8 0V8.312l-.587.934a.898.898 0 01-1.24.281.898.898 0 01-.285-1.237l1.123-1.78a3.826 3.826 0 016.48-.003L8.863 8.29a.9.9 0 01-1.522.959l-.59-.937v5.406a.899.899 0 11-1.8 0v-2.7h-.9z"
                      ></path>
                    </svg>
                    <p>
                      {data?.child_count}{" "}
                      {language === "en" ? "Children" : "أطفال"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-1">
              <div className="w-[120px] h-[120px] overflow-hidden border-[6px] rounded-[12px] border-black p-[0.6rem] ">
                <QRCode
                  size={256}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  value={data?.booking_reference}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <button
                onClick={data.onViewTicket}
                className="cursor-pointer underline text-[#2F383F] text-[12px] font-semibold leading-3 mt-2"
              >
                {language === "en" ? "View Ticket" : "عرض التذكرة"}
              </button>
            </div>
          </div>
        </div>
        <button onClick={onBookingDetails} className="kn-active-booking-cta">
          <span className="text-white text-[14px] md:text-[16px] font-semibold leading-5">
            {language === "en" ? " View Booking Details" : "عرض تفاصيل الحجز"}
          </span>
          <svg
            className="self-center rtl:rotate-180"
            width="31"
            height="15"
            viewBox="0 0 31 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.7071 8.12898C31.0976 7.73846 31.0976 7.10529 30.7071 6.71477L24.3431 0.350807C23.9526 -0.0397172 23.3195 -0.0397172 22.9289 0.350807C22.5384 0.741332 22.5384 1.3745 22.9289 1.76502L28.5858 7.42187L22.9289 13.0787C22.5384 13.4693 22.5384 14.1024 22.9289 14.4929C23.3195 14.8835 23.9526 14.8835 24.3431 14.4929L30.7071 8.12898ZM0 8.42188L30 8.42187V6.42187L0 6.42188L0 8.42188Z"
              fill="white"
            />
          </svg>
        </button>
      </section>
    </>
  );
};

export default ActiveBookingItem;
