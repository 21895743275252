import React from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import galleryPhoto5 from "../../assets/dining-experience/gallery5.webp";
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";

const Gallery = ({ data }) => {
  const { user } = useUser();
  const { language } = useLanguage();
  const navigate = useNavigate();

  const ctaOnClick = (e) => {
    e.preventDefault();
    if (data.button_type === "bookings") {
      navigate("/booking");
      return;
    }
    console.log("ctaOnClick", data.id);
    navigate("/dining", { state: { id: data.page_id } });
  };
  return (
    <>
      <section className="kn-dining-section-desktop w-full h-auto py-[24px] lg:py-[42px] mt-[2rem] overflow-hidden">
        <div className="container mx-auto">
          <div
            className={`${
              language === "en" ? "oneSideContainerEN" : "oneSideContainerAR"
            } h-auto`}
          >
            <div className="w-full gallery-grid gap-2   md:gap-[1rem]">
              <div className="w-full md:w-[90%] flex flex-col items-start justify-center gap-3 mb-6 md:mb-0">
                <h2 className="section-title">{data?.title[language]}</h2>

                <p className="section-desc">{data?.description[language]}</p>

                {data?.has_button === "yes" && (
                  <ButtonPrimary
                    className={`mt-[1rem] md:mt-[2rem]`}
                    onClick={(e) => {
                      if (user) {
                        ctaOnClick(e); // If the user exists, perform the original booking action
                      } else {
                        navigate("/contact-us"); // If the user doesn't exist, navigate to contact-us
                      }
                    }}
                  >
                    {user
                      ? data?.button_text[language] // If the user exists, display the original ctaTitle
                      : language === "en"
                      ? "Book a Tour"
                      : "احجز جولة"}{" "}
                  </ButtonPrimary>
                )}
              </div>
              {data?.gallery.length > 0 &&
                data?.gallery.map((item, index) => {
                  return (
                    <img
                      key={index}
                      className="w-[125px] h-[100px] md:w-[420px] md:h-[340px] object-cover rounded-[12px] md:rounded-[24px]"
                      src={item}
                      alt=""
                      srcSet=""
                    />
                  );
                })}

              {/* <img className='w-[125px] h-[100px] md:w-[420px] md:h-[340px] object-cover rounded-[24px]' src={galleryPhoto5} alt="" srcSet="" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
