import React from 'react';
import Header from '../../shared/header/Header'
import Footer from '../../shared/footer/Footer'
import { Outlet } from 'react-router-dom'

const Root = () => {

    return (
        <>
            <Header />
            <div className="main-outlet pt-[100px]">
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default Root
