import React from 'react'
import SectionHeader from '../../../shared/section-header/SectionHeader'
import CountUp from 'react-countup';
import { useLanguage } from '../../../contexts/LanguageContext';
const Stats = ({ data }) => {
    const { language } = useLanguage();


    // const formatter = (value) => <CountUp end={value} separator="," />;

    // This formatter function will find numbers in the value and separate them from text
    const formatter = (value) => {
        // Use a regular expression to extract the number
        const numberMatch = value.match(/\d+/);
        const number = numberMatch ? parseInt(numberMatch[0], 10) : 0;

        // Extract non-numeric characters that may be present at the start or end of the string
        const prefix = value.match(/^\D+/)?.[0] ?? '';
        const suffix = value.match(/\D+$/)?.[0] ?? '';

        // Return the formatted component with the non-numeric characters appended accordingly
        return (
            <>
                {prefix}
                <CountUp end={number} separator="," />
                {suffix}
            </>
        );
    };
    return (
        <>
            <section className="w-full h-auto py-[4rem]">
                <div className="w-full container mx-auto">
                    <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-10 md:gap-6">
                        <SectionHeader
                            subtitle={data?.name[language]}
                            title={data?.title[language]}
                            desc={data?.description[language]}

                        />

                        <div className='w-full grid grid-cols-2 gap-[1.5rem] md:gap-[2rem] lg:gap-[3rem] place-content-start lg:place-items-center'>
                            {/* <div className='flex flex-col items-start jusify-start gap-2'>
                                <h1 className='text-[#2A2A2A] text-[22px] sm:text-[32px] md:text-[36px] lg:text-[42px] font-bold leading-5 sm:leading-7 lg:leading-10'>
                                    {
                                        formatter(2022)
                                    }
                                </h1>
                                <p className='text-[#2A2A2A] text-[14px] md:text-[16px] font-normal leading-6'>
                                    Founded
                                </p>

                            </div>
                            <div className='flex flex-col items-start jusify-start gap-2'>
                                <h1 className='text-[#2A2A2A] text-[22px] sm:text-[32px] md:text-[36px] lg:text-[42px] font-bold leading-5 sm:leading-7 lg:leading-10'>
                                    {formatter(500)}
                                </h1>
                                <p className='text-[#2A2A2A] text-[14px] md:text-[16px] font-normal leading-6'>
                                    Club Members
                                </p>

                            </div>
                            <div className='flex flex-col items-start jusify-start gap-2'>
                                <h1 className='text-[#2A2A2A] text-[22px] sm:text-[32px] md:text-[36px] lg:text-[42px] font-bold leading-5 sm:leading-7 lg:leading-10'>
                                    {formatter(100)}
                                </h1>
                                <p className='text-[#2A2A2A] text-[14px] md:text-[16px] font-normal leading-6'>
                                    Employees
                                </p>

                            </div>
                            <div className='flex flex-col items-start jusify-start gap-2'>
                                <h1 className='text-[#2A2A2A] text-[22px] sm:text-[32px] md:text-[36px] lg:text-[42px] font-bold leading-5 sm:leading-7 lg:leading-10'>

                                    {
                                        formatter(2005)
                                    }
                                    {' '}  Stars+
                                </h1>
                                <p className='text-[#2A2A2A] text-[14px] md:text-[16px] font-normal leading-6'>
                                    Rating From Our Members
                                </p>

                            </div> */}
                            {/* wanna loop over data.items => has an array of items of stats each stat has  title and value */}
                            {
                                data?.items?.map((item, index) => (
                                    <div key={index} className='flex flex-col items-start jusify-start gap-2'>
                                        <h1 className='text-[#2A2A2A] text-[22px] sm:text-[32px] md:text-[36px] lg:text-[42px] font-bold leading-5 sm:leading-7 lg:leading-10'>
                                            {
                                                formatter(item?.value[language])

                                            }
                                        </h1>
                                        <p className='text-[#2A2A2A] text-[14px] md:text-[16px] font-normal leading-6'>
                                            {item?.title[language]}
                                        </p>

                                    </div>
                                ))
                            }

                        </div>


                    </div>



                </div>

            </section>


        </>
    )
}

export default Stats
