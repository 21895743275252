import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { notification } from "antd";
import { useBooking } from "../../contexts/BookingContext";
import { useUpdateBooking } from "../../contexts/UpdateBookingContext";
import { useUser } from "../../contexts/UserContext";
import { useLanguage } from "../../contexts/LanguageContext";
import { useBookingTracking } from "../../contexts/BookingTrackingContext";
import creditIcon from "../../assets/payment-icons/credit-icon.svg";
import creditIconActive from "../../assets/payment-icons/credit-icon-active.svg";
import appleIcon from "../../assets/payment-icons/apple-icon.svg";
import appleIconActive from "../../assets/payment-icons/apple-icon-active.svg";
import madaIcon from "../../assets/payment-icons/mada-icon.svg";
import madaIconActive from "../../assets/payment-icons/mada-icon-active.svg";
import PaymentMethodSelection from "./PaymentMethodSelection";
import TermsAndConditions from "./TermsAndConditions";
import PaymentTypeSelection from "./PaymentTypeSelection";
import SplitPaymentUserSelection from "./SplitPaymentUserSelection";
import PromoCodeInput from "./PromoCodeInput";
import { BookingInfoSummary } from "../activity/booking/BookingInfoSummary";
import UpdateBookingSummary from "../update-booking/UpdateBookingSummary";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import axiosInstance from "../../axios/axiosInstance";
import PaymentFreeBookingUI from "./PaymentFreeBookingUI";
import pic from "../../assets/beach-access/beach-access-grid-1.webp";

const Payment = () => {
  const navigate = useNavigate(); // Hook to navigate between pages
  const location = useLocation(); // Hook to get the current location (used for state management)
  const { user } = useUser(); // Retrieves the logged-in user details from UserContext
  const { language, translate } = useLanguage(); // Retrieves the current language and translation function from LanguageContext
  const { currentPage, previousPage } = useBookingTracking(); // Retrieves the booking flow navigation information

  // Access booking data and utility functions from BookingContext
  const {
    booking,
    updatePaymentType,
    bookingSummary,
    setBookingSummary,
    selectedUsersForPayment,
    handleUserCheckboxToggle,
    transformBookingForSummary,
    setPaymentMethod,
    updateCouponCode,
    onRemoveCouponFromBooking,
  } = useBooking();

  // Access update-related booking data and functions from UpdateBookingContext
  const { updateBookingData, transformUpdatedBookingForSummary } =
    useUpdateBooking();

  // Component-level state for managing coupon code, payment type/method selection, etc.
  const [appliedCouponCode, setAppliedCouponCode] = useState(""); // Tracks the applied coupon code
  const [paymentTypes, setPaymentTypes] = useState([]); // Stores the available payment types fetched from the backend
  const [selectedPaymentType, setSelectedPaymentType] = useState(""); // Tracks the currently selected payment type
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(""); // Tracks the selected payment method
  const [coupon, setCoupon] = useState(booking?.coupon_code || ""); // Coupon code entered by the user
  const [couponApplied, setCouponApplied] = useState(!!booking?.coupon_code); // Whether a coupon is applied
  const [couponError, setCouponError] = useState(null); // Error message for invalid coupon codes
  const [isPayButtonDisabled, setIsPayButtonDisabled] = useState(true); // Controls the Pay button state
  const [checkboxStates, setCheckboxStates] = useState({
    termsAndConditions: false,
    cancellationPolicy: false,
    responsibilityAcknowledge: false,
    waiverAgreement: false,
  }); // Tracks the state of the terms and conditions checkboxes
  const [isUpdating, setIsUpdating] = useState(false); // Whether the booking is being updated

  const totalPaymentPrice = bookingSummary?.total_summery?.total; // Calculates the total payment price from the booking summary
  const paymentMethods = useMemo(() => {
    if (totalPaymentPrice === 0) {
      return [
        {
          id: 1,
          value: "Membership Credit",
          name: { en: "Membership Credit", ar: "رصيد العضوية" },
          icon: creditIcon, // Adjust the icon for membership credit or a default icon
          activeIcon: creditIconActive,
        },
      ];
    }
    return [
      {
        id: 1,
        value: "creditcard",
        name: { en: "creditcard", ar: "بطاقة الائتمان" },
        icon: creditIcon,
        activeIcon: creditIconActive,
      },
      {
        id: 2,
        value: "applepay",
        name: { en: "applepay", ar: "أبل باي" },
        icon: appleIcon,
        activeIcon: appleIconActive,
      },
      {
        id: 3,
        value: "mada",
        name: { en: "mada", ar: "مادا" },
        icon: madaIcon,
        activeIcon: madaIconActive,
      },
    ];
  }, [totalPaymentPrice]);

  const [api, contextHolder] = notification.useNotification(); // Ant Design's notification handler

  // Fetch payment types from the backend when the component mounts
  useEffect(() => {
    const fetchPaymentTypes = async () => {
      try {
        const response = await axiosInstance.get(`/global-data/payment-types`);
        if (response.status === 200) {
          setPaymentTypes(response.data.data); // Set available payment types in the state
        }
      } catch (error) {
        console.error("Error fetching payment types", error); // Log any errors during API call
      }
    };
    fetchPaymentTypes();
  }, []);

  // Check if the booking is being updated via the location state
  useEffect(() => {
    if (location.state?.isUpdating) setIsUpdating(true); // Set updating flag if detected in location state
  }, [location.state]);

  // Automatically select the first payment type once payment types are loaded
  useEffect(() => {
    if (paymentTypes.length > 0) {
      setSelectedPaymentType(paymentTypes[0]); // Set default payment type to the first in the list
    }
  }, [paymentTypes]);

  // Updates checkbox state for terms and conditions acknowledgments
  const handleCheckboxChange = useCallback((event) => {
    const { name, checked } = event.target;
    setCheckboxStates((prev) => ({ ...prev, [name]: checked })); // Updates the respective checkbox state
  }, []);

  // Disables the Pay button if all the required checkboxes are not selected
  useEffect(() => {
    const allChecked = Object.values(checkboxStates).every(
      (checked) => checked
    );
    setIsPayButtonDisabled(!allChecked); // If all checkboxes are checked, enable the Pay button
  }, [checkboxStates]);

  // Payment Component - Handle Payment Method Reset when Price is Zero
  useEffect(() => {
    if (totalPaymentPrice === 0) {
      setSelectedPaymentMethod("Membership Credit"); // Automatically select "Membership Credit" when the price is 0
      setPaymentMethod("Membership Credit"); // Ensure that it is also reflected in the booking context
    }
  }, [totalPaymentPrice]);

  // Shows a notification with error or success messages
  const openNotificationWithIcon = useCallback(
    (type, errorMessage) => {
      api[type]({
        message: errorMessage,
        description: null,
        placement: "top",
        duration: 3, // Show the notification for 3 seconds
      });
    },
    [api]
  );

  // Handle payment type selection change
  const handlePaymentSelection = useCallback(
    (paymentType) => {
      setSelectedPaymentType(paymentType); // Update the selected payment type in the component state
      updatePaymentType(paymentType); // Update the booking context with the new payment type
    },
    [updatePaymentType]
  );

  // Handle payment method selection change
  const handlePaymentMethodSelection = useCallback(
    (method) => {
      console.log("🚀 ~ Payment ~ method:", method);
      setSelectedPaymentMethod(method); // Update selected payment method
      setPaymentMethod(method); // Update payment method in booking context
    },
    [setPaymentMethod]
  );

  // Navigate back to the previous page
  const handleBack = useCallback(() => {
    if (previousPage) {
      navigate(previousPage); // Go back to the previous page in the booking flow
    } else {
      navigate(-1); // Navigate back in the browser history if no previous page is recorded
    }
  }, [navigate, previousPage]);

  // Submit payment information
  const handlePayment = async () => {
    setIsPayButtonDisabled(true); // Disable the Pay button to prevent multiple submissions
    try {
      const response = await axiosInstance.post(
        "/sports/booking/store-with-register",
        booking // Submit the booking data
      );
      console.log("🚀 ~ handlePayment ~ response:", response);
      if (response.status === 200) {
        if (response.data.data.need_payment === false) {
          navigate("/payment-success", {
            state: { bookingId: response.data.data.booking_id },
          }); // Redirect to the payment success page
        } else if (response.data.data.need_payment === true) {
          window.open(response.data.data.payment_url, "_blank"); // Open the payment URL in a new tab
          if (!user) {
            localStorage.setItem("token", response.data.data.token); // Store token if user is not logged in
          }
        }
      }
    } catch (error) {
      console.error("Error during payment", error); // Log any errors during the payment submission
      openNotificationWithIcon("error", error?.response?.data?.message); // Show error notification
    } finally {
      setIsPayButtonDisabled(false); // Re-enable the Pay button
    }
  };

  // Handle coupon input changes
  const onCouponChange = (e) => setCoupon(e.target.value); // Update the coupon input value

  // Apply a coupon code and update the booking summary
  const onApplyCoupon = async (coupon) => {
    try {
      const summaryObject = !isUpdating
        ? transformBookingForSummary() // Transform booking data for summary display
        : transformUpdatedBookingForSummary();
      const filteredUsers = summaryObject?.booking_users?.filter(
        (user) => user.name.trim() !== ""
      );
      const filteredDependents = summaryObject?.booking_dependents?.filter(
        (dependent) => dependent.name.trim() !== ""
      );

      const applyCouponResult = await axiosInstance.post("/booking/summery", {
        booking_platform: "Web",
        booking_date: isUpdating
          ? updateBookingData?.booking_date
          : booking?.booking_date,
        payment_type: selectedPaymentType,
        coupon_code: coupon || null,
        booking_users: filteredUsers,
        booking_dependents: filteredDependents,
      });

      if (applyCouponResult.status === 200) {
        setBookingSummary(applyCouponResult.data.data); // Update the booking summary with the applied coupon
        setCouponError(null); // Clear any previous coupon errors
        setCouponApplied(true); // Mark the coupon as applied
        updateCouponCode(coupon); // Update coupon in the booking context
      }
    } catch (error) {
      setCouponError(error.response?.data?.message); // Set the error message if coupon application fails
    }
  };

  // Remove the applied coupon code
  const onRemoveCoupon = async () => {
    if (!isUpdating) {
      // If not updating the booking, remove the coupon by making an API call
      try {
        const updateSummaryResult = await axiosInstance.post(
          `/booking/summery`,
          {
            booking_platform: "Web",
            booking_date: booking?.booking_date,
            payment_type: selectedPaymentType,
            coupon_code: null, // Remove the coupon code
            booking_users: booking?.booking_users,
            booking_dependents: booking?.booking_dependents,
          }
        );
        if (updateSummaryResult.status === 200) {
          onRemoveCouponFromBooking(); // Remove the coupon from the booking context
          setBookingSummary(updateSummaryResult.data.data); // Update the booking summary
          setCoupon(""); // Clear the coupon input field
          setCouponError(false); // Clear any error state
          setCouponApplied(false); // Mark the coupon as removed
          setAppliedCouponCode("");
        }
      } catch (error) {
        console.error("Error removing coupon:", error);
        setCouponError(error.response?.data?.message); // Set an error message if coupon removal fails
      }
    }
  };

  return (
    <>
      {contextHolder}
      <section className="w-full h-auto">
        <div className="container mx-auto">
          <button
            onClick={handleBack}
            className="w-full flex items-start justify-start gap-[16px] mt-[2rem] mb-4"
          >
            {/* Back button with an SVG arrow */}
            <svg
              className="self-center xs:w-[18px] xs:h-[18px] rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              fill="none"
              viewBox="0 0 25 24"
            >
              <path
                stroke="#292D32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="3"
                d="M15.418 4.08l-6.52 6.52c-.77.77-.77 2.03 0 2.8l6.52 6.52"
              ></path>
            </svg>
            <span className="text-[#2A2A2A] text-[18px] xs:text-[16px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-semibold leading-7">
              {language === "en" ? "Payment" : "الدفع"} {/* Language support */}
            </span>
          </button>

          {/* Information about secure payment */}
          <div className="w-full flex items-start justify-start gap-1">
            {/* Icon */}
            <svg
              className="self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="16"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                fill="#565656"
                d="M9.667 11.334c-.427 0-.837-.14-1.14-.39-.301-.25-.471-.59-.471-.944 0-.74.717-1.333 1.611-1.333.427 0 .837.14 1.14.39.301.25.471.59.471.943 0 .354-.17.693-.472.943-.302.25-.712.39-1.139.39zm4.833 2V6.667H4.834v6.667H14.5zm0-8c.428 0 .838.14 1.14.39.302.25.471.59.471.943v6.667c0 .353-.17.692-.471.943-.303.25-.712.39-1.14.39H4.834c-.428 0-.837-.14-1.14-.39-.302-.25-.471-.59-.471-.943V6.667c0-.74.717-1.333 1.61-1.333h.806V4c0-.884.425-1.732 1.18-2.357.755-.625 1.78-.976 2.848-.976.529 0 1.053.086 1.542.254.488.167.932.413 1.306.722.374.31.67.677.873 1.082.203.404.307.838.307 1.275v1.334h.805zM9.667 2c-.64 0-1.256.211-1.709.586-.453.375-.708.884-.708 1.414v1.334h4.834V4c0-.53-.255-1.039-.708-1.414C10.923 2.211 10.308 2 9.667 2z"
              ></path>
            </svg>
            <p className="text-[14px] md:text-[16px] text-[#565656] leading-4 font-normal self-center">
              {language === "en"
                ? "This form is encrypted and your information is always guaranteed secure."
                : "هذا النموذج مشفر ومعلوماتك دائمًا مضمونة بأمان."}
            </p>
          </div>

          {/* Payment grid layout */}
          <div className="kn-payment-grid xs:my-[14px] my-[40px]">
            <div className="w-full flex flex-col items-start justify-start gap-[24px]">
              {/* Error notification in case of payment failure */}
              {location.search.includes("error=Payment-failed") && (
                <div className="w-full h-auto mb-[2rem] rounded-[6px] p-[16px] bg-[#FDE8E8]">
                  <h5 className="text-[#C81E1E] text-[16px] font-semibold leading-6">
                    {language === "en" ? "Payment Failure" : "فشل الدفع"}
                  </h5>
                  <p className="text-[#C81E1E] text-[14px] font-normal leading-5">
                    {language === "en"
                      ? "Please try another payment method."
                      : "يرجى تجربة طريقة دفع أخرى"}
                  </p>
                </div>
              )}
              {/* Payment Type Selection */}
              {totalPaymentPrice !== 0 ? (
                <PaymentTypeSelection
                  paymentTypes={paymentTypes} // List of payment types fetched from the backend
                  selectedPaymentType={selectedPaymentType} // The currently selected payment type
                  handlePaymentSelection={handlePaymentSelection} // Function to handle payment type changes
                  totalPaymentPrice={totalPaymentPrice} // The total price to be paid
                  booking={booking} // Current booking data
                  language={language} // Current language for translations
                  translate={translate} // Translation function
                  isUpdating={isUpdating} // Whether the booking is being updated
                />
              ) : (
                <PaymentFreeBookingUI />
              )}
              {/* Split Payment User Selection */}
              {selectedPaymentType === "split" && (
                <SplitPaymentUserSelection
                  booking={booking} // Current booking data
                  selectedUsersForPayment={selectedUsersForPayment} // Users selected for split payment
                  handleUserCheckboxToggle={handleUserCheckboxToggle} // Function to handle user selection for split payment
                />
              )}
              {/* Payment Method Selection */}
              <PaymentMethodSelection
                // if totalPaymentPrice is not 0 show 3 payment methods if not only one
                paymentMethods={paymentMethods}
                selectedPaymentMethod={selectedPaymentMethod} // The currently selected payment method
                setSelectedPaymentMethod={handlePaymentMethodSelection} // Function to handle method selection changes
                disableSelection={totalPaymentPrice === 0} // Disable if the total price is 0
                totalPaymentPrice={totalPaymentPrice} // Total price of the booking
              />
              {/* Promo Code Input */}
              {totalPaymentPrice !== 0 && (
                <PromoCodeInput
                  error={couponError} // Error message for coupon validation
                  handleChange={onCouponChange} // Function to handle coupon code input changes
                  onApply={() => onApplyCoupon(coupon)} // Apply coupon when button is clicked
                  onRemove={onRemoveCoupon} // Remove applied coupon
                  coupon={coupon} // Current coupon code input
                  couponApplied={couponApplied} // Whether the coupon is applied
                />
              )}
              {/* Terms and Conditions */}
              <TermsAndConditions
                checkboxStates={checkboxStates} // Terms and conditions checkbox states
                handleCheckboxChange={handleCheckboxChange} // Function to handle checkbox state changes
              />
            </div>

            {/* Booking Summary Panel */}
            <div className="payment-summary-container">
              {isUpdating ? (
                <UpdateBookingSummary
                  data={{
                    activity_image: pic, // Example activity image
                    activity_name: "Aqua Park Ticket", // Example activity name
                  }}
                />
              ) : (
                <BookingInfoSummary
                  data={{
                    activity_image: pic, // Example activity image
                    activity_name: "Aqua Park Ticket", // Example activity name
                  }}
                />
              )}
            </div>

            {/* Pay Button */}
            <div className="w-full flex items-center md:items-start justify-center md:justify-start my-[0.5rem]">
              <ButtonSecondary
                className="xs:w-[200px] md:w-[250px] lg:w-[300px]"
                onClick={handlePayment} // Handle the payment submission
                disabled={isPayButtonDisabled} // Disable the button if terms are not acknowledged
              >
                Pay
              </ButtonSecondary>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Payment;
