import React from 'react'

import { useLanguage } from '../../../contexts/LanguageContext';
const TestimonialItem = ({ testimonialItemData }) => {
    const { language } = useLanguage();


    return (

        <div className='kn-testimonial-item'>
            <div className='flex flex-col items-start justify-start gap-1 md:gap-3'>
                <h3 className='text-[#FCD12A]  text-[16px] md:text-[18px] font-semibold leading-7 tracking-[-0.02em]'>
                    {testimonialItemData.title[language]}
                </h3>


                <p className='text-[#5A5A5A] text-[14px] md:text-[16px] font-normal leading-6 tracking-[-0.02em]'>
                    {testimonialItemData.description[language]}
                </p>
            </div>

            <div className='w-full border-t border-[#90A3BF] py-6'>
                <div className='w-full flex items-start justify-start gap-3'>
                    <div className='w-[74px] h-[74px] overflow-hidden rounded-full'>
                        <img className='w-full h-full object-cover' src={testimonialItemData?.image} alt="person avatar" />
                    </div>
                    <div className='self-center flex flex-col items-start justify-start gap-0'>
                        <h3 className='text-[#FCD12A]  text-[14px] md:text-[16px] font-bold leading-7 tracking-[-0.02em]'>
                            {testimonialItemData.author_title[language]}
                        </h3>

                        <p className='text-[#505050] text-[12px]  font-normal leading-5 tracking-[-0.02em]'>
                            {testimonialItemData.author_description[language]}
                        </p>

                    </div>

                </div>

            </div>


        </div>

    )
}

export default TestimonialItem
