import React, { useRef } from "react";
import featureIcon1 from "../../assets/features/sunset (2) 1.svg";
import featureIcon2 from "../../assets/features/feature-icon2.svg";
import featureIcon3 from "../../assets/features/feature-icon3.svg";
import featureIcon4 from "../../assets/features/feature-icon4.svg";
import FeatureItem from "./FeatureItem";
import { motion } from "framer-motion";
import Reveal from "../../shared/reveal/Reveal";
import { useLanguage } from "../../contexts/LanguageContext";

const Features = ({ data }) => {
  const { language } = useLanguage();

  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15 * index,
      },
    }),
  };

  return (
    <>
      <section className="w-full h-auto py-[64px]">
        <div className="container mx-auto">
          <Reveal>
            <div className="w-full flex flex-col items-center justify-center gap-2 md:gap-6 mb-[70px]">
              <h1 className="text-[#2A2A2A] font-bold text-[24px] md:text-[32px] lg:text-[40px] leading-6 md:leading-8 lg:leading-10">
                {data?.header[language]}
              </h1>
            </div>
          </Reveal>

          {data?.items?.length > 0 && (
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-[2rem] gap-3 my-[2rem]">
              {data?.items?.map((featureItemData, index) => (
                <motion.div
                  variants={fadeInAnimationsVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{
                    once: true,
                  }}
                  custom={index}
                >
                  <FeatureItem featureItemData={featureItemData} />
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Features;
