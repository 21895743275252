import React from "react";

const TextArea = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  className,
  label,
  required,
  error,
  errorMessage,
  ID,
}) => {
  return (
    <>
      {/* Will Create Generic Text Area field */}
      <div className="w-full flex flex-col items-start justify-start ">
        <label
          htmlFor={ID}
          className="inline-block mb-4 text-black font-semibold leading-4 text-[16px] "
        >
          {label}
        </label>
        <textarea
          id={ID}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`kn-textArea ${className} ${
              error ? "kn-inputField-error" : ""
            }`}
          label={label}
          required={required}
        />
        { errorMessage && (
          <div className="w-full mt-2 flex items-start justify-start gap-2">
            <svg
              className="self-center"
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              fill="none"
              viewBox="0 0 9 9"
            >
              <rect width="9" height="9" fill="#C81E1E" rx="4.5"></rect>
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="0.692"
                d="M6.115 2.86L3 5.975M3 2.86l3.115 3.115"
              ></path>
            </svg>
            <span className="text-[#D03C32] text-[14px] font-normal leading-5 tracking-[0.1px]">
              {errorMessage}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default TextArea;
