import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import ButtonPrimaryOutline from "../../../shared/buttons/ButtonPrimaryOutline";
import { ActivityItem } from "./ActivityItem";
import { useLanguage } from "../../../contexts/LanguageContext";
import { useBooking } from "../../../contexts/BookingContext";
import { useUpdateBooking } from "../../../contexts/UpdateBookingContext";

export const ActivitiesDrawer = React.memo(
  ({
    user = null,
    activities,
    currentIndex,
    currentUserType,
    isUpdating = false,
  }) => {
    const { language } = useLanguage();
    const { booking, clearActivitiesForUserByIndex } = useBooking();
    const { updateBookingData, clearUserActivitiesById } = useUpdateBooking();
    const [activityDrawer, setActivityDrawer] = useState(false);
    const [activityCountForCurrentUser, setActivityCountForCurrentUser] =
      useState(0);

    const [currentUser, setCurrentUser] = useState(null);

    // useEffect to get the current user based on the currentUserType and currentIndex
    useEffect(() => {
      if (currentUserType === "adult") {
        setCurrentUser(booking?.booking_users[currentIndex]);
      } else if (currentUserType === "dependent") {
        setCurrentUser(booking?.booking_dependents[currentIndex]);
      }
    }, [currentUserType, currentIndex]);

    const onOpenActivityDrawer = () => {
      setActivityDrawer(true);
      // disable body scroll
      document.body.style.overflow = "hidden";
    };
    const onCloseActivityDrawer = () => {
      setActivityDrawer(false);
      // enable body scroll
      document.body.style.overflow = "auto";
    };

    // Function to get the number of activities for the current user
    useEffect(() => {
      if (isUpdating === false) {
        // Choose the correct array based on currentUserType
        const currentUsersArray =
          currentUserType === "adult"
            ? booking.booking_users
            : booking.booking_dependents;

        // Check if the user/dependent at currentIndex exists
        if (booking && currentUsersArray && currentUsersArray[currentIndex]) {
      
          const numberOfActivities = currentUsersArray[
            currentIndex
          ].booking_activities.map((activity) => activity.time_slots.length);
          setActivityCountForCurrentUser((prevState) => {

            return numberOfActivities.reduce((a, b) => a + b, 0);

          });
          // const numberOfActivities =
          //   currentUsersArray[currentIndex].booking_activities.map;
          // setActivityCountForCurrentUser(numberOfActivities);
        }
      } else {
        const getCurrentUser =
          updateBookingData?.booking_users?.find(
            (userToUpdate) => userToUpdate.user_id === user.id
          ) ||
          updateBookingData?.booking_dependents?.find(
            (userToUpdate) => userToUpdate.user_id === user.id
          );
        const activities = getCurrentUser?.booking_activities;
        if (activities) {
          const numberOfActivities = activities.map((activity) =>
            activity.time_slots.length
          );
          setActivityCountForCurrentUser(
            numberOfActivities.reduce((a, b) => a + b, 0)
          );
        }
        // setActivityCountForCurrentUser(activities ? activities.length : 0);
      }
    }, [booking, currentIndex, currentUserType, updateBookingData, user]);

    const handleDeleteActivitiesClick = (index, userType) => {
      if (isUpdating) {
        clearUserActivitiesById(user.id);
      } else {
        clearActivitiesForUserByIndex(index, userType);
      }
    };

    return (
      <>
        <div className="w-full border-t border-[#E8E8E8] p-[16px] flex xs:flex-col flex-row xs:gap-5 items-start justify-between">
          <div className="xs:self-start self-center flex items-start justify-start gap-[12px]">
            <div className="w-[17px] h-[19px] overflow-hidden self-center">
              <svg
                className="h-full w-full xs:w-[16px] xs:h-[16px] self-center"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="19"
                fill="none"
                viewBox="0 0 17 19"
              >
                <path
                  fill="#2A2A2A"
                  d="M0 18.497v-1.639h.82c.436 0 .86-.068 1.27-.205a4.48 4.48 0 001.187-.614 4.26 4.26 0 001.188.605 4.279 4.279 0 002.55 0c.417-.131.81-.333 1.178-.605a4.26 4.26 0 001.188.605 4.278 4.278 0 002.55 0c.417-.131.81-.333 1.177-.605a4.083 4.083 0 002.458.82h.82v1.638h-.82c-.423 0-.84-.052-1.25-.154a5.604 5.604 0 01-1.208-.46 5.576 5.576 0 01-1.208.46 5.118 5.118 0 01-2.499 0 5.603 5.603 0 01-1.208-.46 5.575 5.575 0 01-1.209.46 5.118 5.118 0 01-2.499 0 5.603 5.603 0 01-1.208-.46 5.575 5.575 0 01-1.208.46c-.41.103-.826.154-1.25.154H0zM5.325 2.93l4.547.84c.191.027.376.102.553.225.178.123.328.293.45.512l.718 1.27a4.18 4.18 0 001.474 1.495 3.94 3.94 0 002.09.573v1.639a5.48 5.48 0 01-2.909-.799 5.943 5.943 0 01-2.069-2.089c-.013.014-.109.079-.286.195-.178.116-.372.246-.584.39l-.604.409c-.191.13-.314.214-.369.255l.564.45c.307.247.641.51 1.003.79a507.276 507.276 0 011.567 1.218v3.154c.218.15.43.307.635.471.204.164.402.321.594.471a3.78 3.78 0 01-.943.594c-.34.15-.71.226-1.106.226-.491 0-.949-.116-1.372-.349a3.8 3.8 0 01-1.085-.88 3.8 3.8 0 01-1.086.88 2.809 2.809 0 01-1.772.318 2.429 2.429 0 01-.378-.092c-1.175-.806-2.158-1.618-2.95-2.438C1.215 11.84.82 11.13.82 10.53c0-.423.164-.703.492-.84.328-.136.669-.204 1.024-.204.396 0 .853.058 1.372.174.519.117 1.072.284 1.66.502l-.43-2.54a1.326 1.326 0 01.092-.809c.116-.266.297-.48.542-.645l1.761-1.188c-.04 0-.14-.017-.296-.052a13.011 13.011 0 00-.523-.102 12.75 12.75 0 01-.522-.103 2.006 2.006 0 00-.297-.05L3.379 6.249l-.921-1.352 2.867-1.966zM6.8 8.748l.369 2.17c.368.178.826.414 1.372.708.546.293.976.536 1.29.726v-1.229c-.04-.027-.211-.16-.512-.399-.3-.238-.628-.494-.983-.768a68.096 68.096 0 01-.994-.778 46.58 46.58 0 00-.542-.43zm5.489-4.588c-.45 0-.836-.16-1.158-.481a1.574 1.574 0 01-.48-1.158c0-.45.16-.836.481-1.157A1.575 1.575 0 0112.29.883c.45 0 .836.16 1.158.481.32.321.481.707.48 1.157 0 .45-.16.837-.481 1.158a1.574 1.574 0 01-1.157.48z"
                ></path>
              </svg>
            </div>
            <h4 className="text-[#2A2A2A]  text-[12px] md:text-[14px] font-medium leading-5 self-center">
              {language === "en" ? "Book Sports Activity" : "احجز نشاط رياضي"}
            </h4>
          </div>
          <div>
            {activityCountForCurrentUser === 0 ? (
              <ButtonPrimaryOutline onClick={onOpenActivityDrawer}>
                {language === "en"
                  ? " Choose Sport Activity"
                  : " اختر نشاط رياضي"}
              </ButtonPrimaryOutline>
            ) : (
              <div className="flex items-center justify-start gap-4">
                <div className="flex items-start justify-start gap-2">
                  <p className="w-[20px] h-[20px] rounded-full  bg-[#FCD12A] text-[11px] text-white flex items-center justify-center">
                    {activityCountForCurrentUser}
                  </p>
                  <span className="text-[12px] text-black leading-5 font-normal self-center mt-[1px]">
                    Activities
                  </span>
                </div>
                <div className="flex items-start justify-start gap-2">
                  <button
                    className="self-center"
                    onClick={onOpenActivityDrawer}
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.3348 6.10566C22.4298 6.24987 22.4721 6.42245 22.4546 6.59425C22.4371 6.76606 22.3609 6.92656 22.2388 7.04866L13.0458 16.2407C12.9517 16.3347 12.8344 16.4019 12.7058 16.4357L8.87678 17.4357C8.75021 17.4687 8.6172 17.468 8.49097 17.4337C8.36474 17.3995 8.24966 17.3328 8.15717 17.2403C8.06468 17.1478 7.99798 17.0327 7.96371 16.9065C7.92943 16.7802 7.92877 16.6472 7.96178 16.5207L8.96178 12.6927C8.99097 12.5778 9.04604 12.471 9.12278 12.3807L18.3498 3.15966C18.4904 3.01921 18.681 2.94032 18.8798 2.94032C19.0785 2.94032 19.2692 3.01921 19.4098 3.15966L22.2388 5.98766C22.2747 6.02367 22.3068 6.0632 22.3348 6.10566ZM20.6478 6.51766L18.8798 4.75066L10.3618 13.2687L9.73678 15.6617L12.1298 15.0367L20.6478 6.51766Z"
                        fill="#414141"
                      />
                      <path
                        d="M20.5205 17.8497C20.7938 15.5136 20.8811 13.1595 20.7815 10.8097C20.7793 10.7543 20.7886 10.6991 20.8087 10.6475C20.8288 10.5959 20.8594 10.5489 20.8984 10.5097L21.8825 9.52567C21.9093 9.49863 21.9435 9.47993 21.9807 9.47181C22.018 9.4637 22.0568 9.46652 22.0925 9.47993C22.1282 9.49334 22.1593 9.51678 22.182 9.54742C22.2047 9.57807 22.218 9.61461 22.2204 9.65267C22.4057 12.4439 22.3354 15.2462 22.0105 18.0247C21.7745 20.0467 20.1504 21.6317 18.1374 21.8567C14.6428 22.2437 11.1161 22.2437 7.62145 21.8567C5.60945 21.6317 3.98445 20.0467 3.74845 18.0247C3.33385 14.4801 3.33385 10.8993 3.74845 7.35467C3.98445 5.33267 5.60845 3.74767 7.62145 3.52267C10.2738 3.22855 12.9462 3.1573 15.6104 3.30967C15.6486 3.31241 15.6851 3.32601 15.7158 3.34888C15.7464 3.37175 15.7698 3.40291 15.7833 3.43869C15.7968 3.47446 15.7997 3.51335 15.7918 3.55074C15.7838 3.58813 15.7653 3.62246 15.7385 3.64967L14.7455 4.64167C14.7066 4.68042 14.6601 4.71079 14.6091 4.73091C14.558 4.75103 14.5033 4.76048 14.4485 4.75867C12.2252 4.68309 9.99941 4.76831 7.78845 5.01367C7.14239 5.08518 6.5393 5.37239 6.0766 5.82892C5.61391 6.28545 5.31862 6.88463 5.23845 7.52967C4.8375 10.958 4.8375 14.4214 5.23845 17.8497C5.31862 18.4947 5.61391 19.0939 6.0766 19.5504C6.5393 20.0069 7.14239 20.2942 7.78845 20.3657C11.1435 20.7407 14.6154 20.7407 17.9714 20.3657C18.6175 20.2942 19.2206 20.0069 19.6833 19.5504C20.146 19.0939 20.4403 18.4947 20.5205 17.8497Z"
                        fill="#414141"
                      />
                    </svg>
                  </button>
                  <button
                    className="self-center"
                    onClick={() =>
                      handleDeleteActivitiesClick(currentIndex, currentUserType)
                    }
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.87528 0.666534H11.0033C11.2041 0.666534 11.379 0.666535 11.5438 0.692447C11.8646 0.743849 12.169 0.869634 12.4325 1.05974C12.6961 1.24984 12.9115 1.49899 13.0615 1.78725C13.1392 1.93532 13.1938 2.10098 13.2577 2.29069L13.3604 2.60072C13.4313 2.85259 13.5854 3.07299 13.7977 3.22595C14.01 3.37891 14.2679 3.45538 14.5292 3.44287H17.3056C17.4897 3.44287 17.6662 3.516 17.7964 3.64617C17.9265 3.77633 17.9997 3.95288 17.9997 4.13696C17.9997 4.32104 17.9265 4.49758 17.7964 4.62775C17.6662 4.75792 17.4897 4.83104 17.3056 4.83104H1.57299C1.38891 4.83104 1.21237 4.75792 1.0822 4.62775C0.952033 4.49758 0.878906 4.32104 0.878906 4.13696C0.878906 3.95288 0.952033 3.77633 1.0822 3.64617C1.21237 3.516 1.38891 3.44287 1.57299 3.44287H4.43262C4.67981 3.43694 4.9186 3.35197 5.11396 3.20041C5.30932 3.04885 5.45099 2.83868 5.51817 2.60072L5.62182 2.29069C5.68475 2.10098 5.73935 1.93532 5.81616 1.78725C5.96626 1.49888 6.1818 1.24966 6.44551 1.05955C6.70923 0.869437 7.01379 0.743714 7.33482 0.692447C7.49955 0.666535 7.67446 0.666534 7.87436 0.666534H7.87528ZM6.67035 3.44287C6.73533 3.31561 6.78951 3.18311 6.8323 3.04678L6.92485 2.76915C7.00906 2.5165 7.0285 2.4656 7.04793 2.42858C7.09789 2.33235 7.1697 2.24915 7.25761 2.18567C7.34552 2.12219 7.44708 2.08019 7.55415 2.06303C7.67479 2.05284 7.79594 2.05006 7.91693 2.0547H10.9616C11.2282 2.0547 11.2837 2.05655 11.3244 2.06396C11.4314 2.08101 11.5329 2.12288 11.6208 2.18619C11.7087 2.24951 11.7806 2.33252 11.8306 2.42858C11.8501 2.4656 11.8695 2.5165 11.9537 2.77007L12.0463 3.04771L12.0824 3.15136C12.1185 3.25316 12.161 3.3494 12.2082 3.44287H6.67035Z"
                        fill="#D03C32"
                      />
                      <path
                        d="M3.80686 6.40427C3.79459 6.22056 3.70984 6.04924 3.57125 5.92801C3.43267 5.80679 3.25161 5.74557 3.06789 5.75785C2.88418 5.77012 2.71286 5.85487 2.59163 5.99345C2.47041 6.13204 2.40919 6.3131 2.42147 6.49682L2.85087 12.9305C2.92954 14.1169 2.99339 15.0757 3.14331 15.829C3.29972 16.611 3.56439 17.2644 4.11226 17.7762C4.6592 18.2889 5.32922 18.51 6.12048 18.6128C6.88119 18.7127 7.84181 18.7127 9.03193 18.7127H9.8454C11.0346 18.7127 11.9961 18.7127 12.7569 18.6128C13.5472 18.51 14.2172 18.2889 14.7651 17.7762C15.312 17.2644 15.5767 16.6101 15.7331 15.829C15.883 15.0766 15.9459 14.1169 16.0255 12.9305L16.4549 6.49682C16.4672 6.3131 16.406 6.13204 16.2848 5.99345C16.1635 5.85487 15.9922 5.77012 15.8085 5.75785C15.6248 5.74557 15.4437 5.80679 15.3051 5.92801C15.1666 6.04924 15.0818 6.22056 15.0695 6.40427L14.6438 12.7899C14.5605 14.0364 14.5013 14.9045 14.3718 15.5569C14.245 16.1909 14.0691 16.5259 13.8165 16.7628C13.5629 16.9997 13.2168 17.1533 12.5764 17.2366C11.9165 17.3227 11.0466 17.3245 9.79635 17.3245H9.08005C7.8307 17.3245 6.96078 17.3227 6.30001 17.2366C5.6596 17.1533 5.31349 16.9997 5.05991 16.7628C4.80727 16.5259 4.63143 16.1909 4.50465 15.5579C4.37508 14.9045 4.31586 14.0364 4.23257 12.7889L3.80686 6.40427Z"
                        fill="#D03C32"
                      />
                      <path
                        d="M7.05608 8.0738C7.23917 8.05545 7.42205 8.11055 7.56452 8.227C7.707 8.34344 7.7974 8.51169 7.81587 8.69477L8.27859 13.322C8.29215 13.5025 8.23462 13.6812 8.11829 13.82C8.00196 13.9587 7.83602 14.0465 7.65589 14.0646C7.47575 14.0827 7.29566 14.0297 7.15404 13.9169C7.01242 13.8041 6.92048 13.6404 6.89783 13.4608L6.4351 8.83359C6.41675 8.6505 6.47186 8.46762 6.5883 8.32514C6.70475 8.18267 6.873 8.09226 7.05608 8.0738ZM11.8221 8.0738C12.005 8.09227 12.1731 8.18254 12.2895 8.32481C12.406 8.46708 12.4612 8.64972 12.4431 8.83266L11.9804 13.4599C11.9574 13.6392 11.8654 13.8024 11.724 13.915C11.5826 14.0275 11.4028 14.0804 11.2229 14.0624C11.0431 14.0445 10.8773 13.957 10.7609 13.8188C10.6445 13.6805 10.5867 13.5023 10.5996 13.322L11.0623 8.69477C11.0808 8.51187 11.1711 8.34377 11.3133 8.22735C11.4556 8.11093 11.6392 8.05571 11.8221 8.0738Z"
                        fill="#D03C32"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
            <div className="activity-drawer">
              <Drawer
                onClose={onCloseActivityDrawer}
                width={600}
                closeIcon={null}
                open={activityDrawer}
              >
                <div className="w-full h-auto">
                  <div className="w-full flex items-start justify-between">
                    <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6">
                      {language === "en"
                        ? "  Choose Sports Activity"
                        : " اختر نشاط رياضي"}
                    </h3>
                    <button
                      onClick={onCloseActivityDrawer}
                      className="self-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="23"
                        fill="none"
                        viewBox="0 0 23 23"
                      >
                        <g fill="#E63232" clipPath="url(#clip0_366_2657)">
                          <path d="M12.275 11.267l3.042-3.042a.626.626 0 00-.882-.883l-3.042 3.011-3.061-3.06a.626.626 0 00-.883.882l3.055 3.092-2.992 2.954a.625.625 0 10.882.883l2.986-2.986 2.967 2.967a.626.626 0 00.883-.882l-2.955-2.936z"></path>
                          <path d="M11.267 21.281a10.015 10.015 0 110-20.03 10.015 10.015 0 010 20.03zm0-18.778a8.763 8.763 0 100 17.526 8.763 8.763 0 000-17.526z"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_366_2657">
                            <path fill="#fff" d="M0 0H22.534V22.534H0z"></path>
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                  <div>
                    <h2 className="text-[#1D1D1D] font-semibold text-[20px] leading-7 my-[2rem]">
                      {language === "en"
                        ? `Select Activities for ${
                            currentUser?.name.split(" ")[0] || user?.name
                          }`
                        : ` ${
                            currentUser?.name.split(" ")[0] || user?.name
                          } اختر الأنشطة `}
                    </h2>
                    <div className="w-full flex flex-col items-start justify-start gap-4">
                      {/* loop over activity and render activityItem  */}
                      {activities.map((activity, index) => (
                        <ActivityItem
                          user={user}
                          isUpdating={isUpdating}
                          currentUserType={currentUserType}
                          key={index}
                          currentIndex={currentIndex}
                          data={activity}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default ActivitiesDrawer;
