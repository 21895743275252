import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import axiosInstance from "../../../axios/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import CountdownTimer from "./CountdownTimer";
import { useOutletContext } from "react-router-dom";
import { useLanguage } from "../../../contexts/LanguageContext";
import ButtonSecondary from "../../../shared/buttons/ButtonSecondary";
import { Oval } from "react-loader-spinner";
import BlurFade from "../../ui/blur-fade";

const QRCODE = () => {
  const { userProfile } = useOutletContext();
  console.log("🚀 ~ QRCODE ~ userProfile:", userProfile);
  const { language } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState("");
  console.log("🚀 ~ QRCODE ~ qrCode:", qrCode);
  const [expDate, setExpDate] = useState("");
  const [nanyLoading, setNanyLoading] = useState(false);
  const [expired, setExpired] = useState(false);

  console.log("is qr exipred", new Date(expDate) <= new Date());
  useEffect(() => {
    if (userProfile) {
      setQrCode(userProfile.instant_qr);
      setExpDate(userProfile.instant_qr_expiration_time);
    }
  }, [userProfile]);

  const onGenerateQrCode = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/sports/instant-qr");
      // setQrCode(response.data.data);
      setQrCode(response.data.data.qr);
      setExpDate(response.data.data.expiration_time);
      setExpired(false); // Reset expiration status when a new QR code is generated
      setLoading(false);
      // refresh page
      // window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onGenerateNannyQrCode = async () => {
    setNanyLoading(true);
    try {
      const response = await axiosInstance.post("/sports/nanny-instant-qr");
      console.log("🚀 ~ onGenerateNannyQrCode ~ response:", response);
    } catch (error) {
      console.log(error);
    } finally {
      setNanyLoading(false);
    }
  };

  const handleExpire = () => {
    console.log("QR code expired");
    setExpired(true); // Set the expired state to true when the QR code expires
  };

  if (loading || !userProfile) {
    return (
      <div className="w-full h-full flex items-center justify-center ">
        <div className="">
          <ThreeDots color="#FCD12A" />
        </div>
      </div>
    );
  }
  return (
    <>
      <section className="w-full h-auto">
        <div className="w-full flex flex-col items-center justify-center">
          <BlurFade delay={0.07} inView>
            <div className="relative w-[220px] h-[220px]">
              {(!qrCode || expired) && userProfile?.can_create_qr && (
                <button
                  onClick={onGenerateQrCode}
                  className="w-[75%] bg-white rounded-[30px] py-2 px-4 text-black font-medium text-[16px] absolute transform -translate-x-1/2 -translate-y-1/2 border top-1/2 left-1/2  z-[99999]"
                >
                  Generate QR
                </button>
              )}
              {((!userProfile?.can_create_qr && expired) || !qrCode) && (
                <p className="w-[75%] bg-white rounded-[30px] py-2 px-4 text-black font-medium text-[12px] absolute transform -translate-x-1/2 -translate-y-1/2 border top-1/2 left-1/2  z-[99999] text-center">
                  Request QR From Owner
                </p>
              )}
              <div
                className={`${
                  (qrCode && !expired) || !qrCode ? undefined : "blur-sm"
                } 	 w-[220px] h-[220px] overflow-hidden border-[6px] rounded-[12px] border-[#FCD12AB8] p-[1rem] mb-[32px]`}
              >
                <QRCode
                  size={256}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  value={qrCode ? qrCode : "default-value"} // Use qrCode.qr if defined, otherwise a fallback value
                  viewBox={`0 0 256 256`}
                  level="H"
                  title="Ticket Booking Reference Code"
                />
              </div>
            </div>
          </BlurFade>

          <BlurFade delay={0.07} inView>
            {qrCode && !expired && (
              <CountdownTimer
                expirationTime={expDate}
                onExpire={handleExpire}
              />
            )}
          </BlurFade>

          <BlurFade delay={0.07} inView>
            <p className="text-[#1D1D1D] text-[16px] font-normal leading-6 text-center mt-[2rem]">
              Scan this QR code to join kona, Your QR codde is private. Do not
              share it with anyone.
            </p>
          </BlurFade>

          <div className="w-full md:w-[90%] lg:w-[630px] h-auto flex flex-col items-start justify-start gap-6 bg-[#F9FAFB] border border-[#F2F2F2] rounded-[20px] py-[22px] px-[36px] mt-[2rem]">
            <BlurFade className={`w-full`} delay={0.05} inView>
              <div className="w-full flex items-start justify-between">
                <h3 className="text-[#565656] text-[16px] font-normal leading-5 ">
                  Name
                </h3>
                <p className="text-black text-[16px] font-medium leading-5  ">
                  {userProfile.name}
                </p>
              </div>
            </BlurFade>
            <BlurFade className={`w-full`} delay={0.06} inView>
              <div className="w-full flex items-start justify-between">
                <h3 className="text-[#565656] text-[16px] font-normal leading-5 ">
                  Membership Type
                </h3>
                <p className="text-black text-[16px] font-medium leading-5  ">
                  {userProfile?.membership_type[language]}
                </p>
              </div>
            </BlurFade>
            <BlurFade className={`w-full`} delay={0.07} inView>
              <div className="w-full flex items-start justify-between">
                <h3 className="text-[#565656] text-[16px] font-normal leading-5 ">
                  Membership Name
                </h3>
                <p className="text-black text-[16px] font-medium leading-5  ">
                  {userProfile?.membership_name[language]}
                </p>
              </div>
            </BlurFade>
            <BlurFade className={`w-full`} delay={0.08} inView>
              <div className="w-full flex items-start justify-between">
                <h3 className="text-[#565656] text-[16px] font-normal leading-5 ">
                  Date
                </h3>
                <p className="text-black text-[16px] font-medium leading-5  ">
                  {new Date(userProfile?.date).toLocaleDateString("en-CA")}
                </p>
              </div>
            </BlurFade>
            <BlurFade className={`w-full`} delay={0.09} inView>
              <div className="w-full flex items-start justify-between">
                <h3 className="text-[#565656] text-[16px] font-normal leading-5 ">
                  Expire Date
                </h3>
                <p className="text-black text-[16px] font-medium leading-5  ">
                  {userProfile?.subscription_end_date}
                </p>
              </div>
            </BlurFade>
          </div>
          <BlurFade delay={0.05} inView>
            {userProfile?.can_create_nanny_code && (
              <div className="w-full md:w-[90%] lg:w-[630px] h-auto flex flex-col items-start justify-start gap-6 bg-[#F9FAFB] border border-[#F2F2F2] rounded-[20px] py-[22px] px-[36px] mt-[2rem]">
                <div className="w-full flex items-center justify-between">
                  <h3 className="text-[#565656] text-[16px] font-normal leading-5 ">
                    Nanny
                  </h3>
                  <ButtonSecondary
                    disabled={nanyLoading}
                    onClick={onGenerateNannyQrCode}
                  >
                    {nanyLoading ? (
                      <Oval color="#FCD12A" height={20} width={20} />
                    ) : (
                      "Give Access"
                    )}
                  </ButtonSecondary>
                </div>
              </div>
            )}
          </BlurFade>
        </div>
      </section>
    </>
  );
};

export default QRCODE;
