import React from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import ticketBook from "../../assets/ticketIcon.svg";
import { useUser } from "../../contexts/UserContext";
import { useLanguage } from "../../contexts/LanguageContext";

const GridItem = ({
  image,
  subTitle = null,
  title = "",
  desc = "",
  isThereCtaBtn = false,
  ctaTitle = null,
  ctaIcon = null,
  isReverse = false,
  button_type,
  page_slug,
}) => {
  const { user } = useUser();
  const { language } = useLanguage();

  const navigate = useNavigate();
 

  const handleNavigation = (button_type, page_slug) => {
    console.log("🚀 ~ handleNavigation ~ page_slug:", page_slug);
    console.log("🚀 ~ handleNavigation ~ button_type:", button_type);
    if (button_type === "bookings") {
      navigate("/booking");
    } else if (button_type === "page") {
      navigate(`/${page_slug}`);
    }
  };

 
  return (
    <div
      className={`grid-item ${
        isReverse ? "flex-row-reverse grid-item-reverse" : ""
      }`}
    >
      <div className="grid-image">
        <img className="" src={image} alt={title} />
      </div>
      <div className="w-full self-center">
        <div className="w-full flex flex-col items-start justify-start gap-0 lg:gap-2">
          {subTitle && <h4 className="grid-subTitle">{subTitle}</h4>}
          <h2 className="grid-title">{title}</h2>
        </div>
        <p className="grid-desc">{desc}</p>
        {isThereCtaBtn && (
          <ButtonPrimary
            onClick={() => {
              if (user) {
                handleNavigation(button_type, page_slug); // If the user exists, perform the original booking action
              } else {
                navigate("/contact-us"); // If the user doesn't exist, navigate to contact-us
              }
            }}
            className="mt-[1rem]"
          >
            {button_type === "bookings" && (
              <span className="self-center">
                <svg
                  className="self-center"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.49412 9.0601V8.02628M8.49412 13.7123V12.6785M8.49412 18.3645V17.3307M7.22001 21.466H18.2624C20.0463 21.466 20.9383 21.466 21.6196 21.1279C22.219 20.8306 22.7063 20.3561 23.0117 19.7726C23.3588 19.1091 23.3588 18.2406 23.3588 16.5036V9.88716C23.3588 8.15017 23.3588 7.28168 23.0117 6.61824C22.7063 6.03466 22.219 5.56019 21.6196 5.26284C20.9383 4.9248 20.0463 4.9248 18.2624 4.9248H7.22001C5.43607 4.9248 4.54411 4.9248 3.86274 5.26284C3.26338 5.56019 2.7761 6.03466 2.47071 6.61824C2.12354 7.28168 2.12354 8.15017 2.12354 9.88716V16.5036C2.12354 18.2406 2.12354 19.1091 2.47071 19.7726C2.7761 20.3561 3.26338 20.8306 3.86274 21.1279C4.54411 21.466 5.43607 21.466 7.22001 21.466Z"
                    stroke="white"
                    strokeWidth="2.68235"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            )}
            <p className="self-center">
              {user
                ? ctaTitle // If the user exists, display the original ctaTitle
                : language === "en"
                ? "Book a Tour"
                : "احجز جولة"}{" "}
              {/* If no user, display "Book a Tour" or its Arabic equivalent */}
            </p>
          </ButtonPrimary>
        )}
      </div>
    </div>
  );
};

export default GridItem;
