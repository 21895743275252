import React, { useRef } from 'react'
import SectionHeader from '../../../shared/section-header/SectionHeader'
import TestimonialItem from './TestimonialItem'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from 'framer-motion'
import { useLanguage } from '../../../contexts/LanguageContext';


const Testimonials = ({ data }) => {
    const { language, direction } = useLanguage();

    const sliderRef = useRef(null);

    const nextSlide = () => {
        sliderRef.current.slickPrev();
    };

    const previousSlide = () => {
        sliderRef.current.slickNext();
    };

    const testimonialsConfig = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
        swipeToSlide: true,
        swipeToScroll: true,
        speed: 500,
        rtl: direction === 'rtl' ? true : false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                }
            },

            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]

    };
    const fadeInAnimationsVariants = {
        initial: {
            opacity: 0,
            y: 100,

        },
        animate: (index) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.15 * index,
            }
        })
    }
    return (
        <>
            <div className='w-full h-auto'>
                <div className="container mx-auto">
                    {/* header */}
                    <div className='w-full lg:w-[45%]'>
                        <SectionHeader
                            subtitle={data?.name[language]}
                            title={data?.title[language]}
                            desc={data?.description[language]}
                        />
                    </div>
                    {/* testimonials */}
                    <div className='w-full h-auto my-[2rem]'>
                        {/* testimonials actions */}
                        {(data && data.items.length > 0) && <div className='w-full hidden md:flex items-end justify-end gap-2'>
                            <div className='flex items-start justify-start gap-2'>
                                <button className='self-center rtl:rotate-180' onClick={nextSlide}>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.0001 9.66675L9.66675 15.0001M9.66675 15.0001L15.0001 20.3334M9.66675 15.0001H20.3334M28.3334 15.0001C28.3334 22.3639 22.3639 28.3334 15.0001 28.3334C7.63628 28.3334 1.66675 22.3639 1.66675 15.0001C1.66675 7.63628 7.63628 1.66675 15.0001 1.66675C22.3639 1.66675 28.3334 7.63628 28.3334 15.0001Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </button>
                                <button className='self-center rtl:rotate-180' onClick={previousSlide} >
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0001 21.3334L21.3334 16.0001M21.3334 16.0001L16.0001 10.6667M21.3334 16.0001H10.6667M29.3334 16.0001C29.3334 23.3639 23.3639 29.3334 16.0001 29.3334C8.63628 29.3334 2.66675 23.3639 2.66675 16.0001C2.66675 8.63628 8.63628 2.66675 16.0001 2.66675C23.3639 2.66675 29.3334 8.63628 29.3334 16.0001Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </button>

                            </div>

                        </div>}

                        <div className='w-full relative'>
                            {(data && data?.items?.length > 0) &&
                                <Slider ref={sliderRef} className='kn-testimonials kn-slick-carousel w-full   ' {...testimonialsConfig}>
                                    {data?.items?.map((testimonial, index) => {
                                        return (
                                            <motion.div

                                                variants={fadeInAnimationsVariants}
                                                initial='initial'
                                                whileInView='animate'
                                                viewport={
                                                    {
                                                        once: true
                                                    }
                                                }
                                                key={index}
                                                custom={index}
                                            >

                                                <TestimonialItem
                                                    testimonialItemData={testimonial} />
                                            </motion.div>
                                        )
                                    }
                                    )}
                                </Slider>

                            }

                        </div>

                    </div>
                </div>

            </div>

        </>
    )
}

export default Testimonials
