import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import ArrowUp from "../../shared/icons/ArrowUp";
import ArrowDown from "../../shared/icons/ArrowDown";
import Reveal from "../../shared/reveal/Reveal";
import axiosInstance from "../../axios/axiosInstance";
import { useBooking } from "../../contexts/BookingContext";
import { useUpdateBooking } from "../../contexts/UpdateBookingContext";
import { TailSpin } from "react-loader-spinner";
import { useLanguage } from "../../contexts/LanguageContext";

const UpdateBookingSummary = React.memo(({ data, booking_date }) => {
  const { language, translate } = useLanguage();
  const { updateBookingData } = useUpdateBooking();
  const [updatedBookingSummary, setUpdatedBookingSummary] = useState(null);

  useEffect(() => {
    const updateBookingSummary = async () => {
      // Guard clause to check for booking date or if the booking object is null/undefined
      if (!updateBookingData || !booking_date) {
        console.log(
          "Booking date is not set or booking is null/undefined, skipping summary update."
        );
        return;
      }

      try {
        const response = await axiosInstance.post(`/booking/summery`, {
          booking_platform: "Web",
          booking_date: booking_date,
          payment_type: "pay in full",
          is_update: true,
          coupon_code: null,
          booking_users: updateBookingData.booking_users,
        });
        // console.log("response summary", response);
        if (response.status === 200) {
          setUpdatedBookingSummary(response.data.data);
          console.log("response from update booking summary", response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Call the function only if there is a valid booking date
    if (updateBookingData && booking_date) {
      updateBookingSummary();
    }
  }, [updateBookingData, booking_date]);

  console.log("updatedBookingSummary", updatedBookingSummary);
  console.log("booking date", booking_date);

  return (
    <>
      <Reveal>
        <div className="w-full h-full">
          <div className="kn-booking-summary">
            <div className="w-full  flex flex-col md:flex-row items-start justify-start gap-[20px]">
              {data?.activity_image && (
                <div className="w-full h-[160px] lg:w-[130px] lg:h-[120px] rounded-[9px] overflow-hidden">
                  <img
                    className="w-full h-full object-cover rounded-[9px]"
                    src={data?.activity_image}
                    alt=""
                    srcSet=""
                  />
                </div>
              )}
              <div className="w-full lg:w-auto flex flex-col items-start justify-start gap-3 self-center">
                <h3 className="text-[#2A2A2A] text-[18px] md:text-[22px] leading-4 font-medium">
                  {language === "en"
                    ? "Sports Club Ticket"
                    : "تذكرة نادي الرياضة"}
                </h3>
                <div className="flex items-start justify-start gap-[6px]">
                  <div className="kn-booking-item-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        strokeWidth="1.079"
                        d="M5.756 2.153v2.159M11.512 2.153v2.159M2.519 7.254h12.23M15.108 6.83v6.115c0 2.159-1.079 3.597-3.597 3.597H5.755c-2.518 0-3.597-1.439-3.597-3.597V6.83c0-2.159 1.08-3.598 3.597-3.598h5.756c2.518 0 3.597 1.44 3.597 3.598z"
                      ></path>
                      <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.439"
                        d="M11.292 10.57h.007M11.292 12.73h.007M8.63 10.57h.007M8.63 12.73h.007M5.967 10.57h.007M5.967 12.73h.007"
                      ></path>
                    </svg>
                    <p>{booking_date}</p>
                  </div>
                  <div className="kn-booking-item-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      fill="none"
                      viewBox="0 0 17 17"
                    >
                      <path
                        stroke="#292D32"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="0.959"
                        d="M8.358 8.389a3.198 3.198 0 100-6.395 3.198 3.198 0 000 6.395zM13.851 14.784c0-2.475-2.462-4.477-5.493-4.477-3.032 0-5.494 2.002-5.494 4.477"
                      ></path>
                    </svg>
                    <p>
                      {updatedBookingSummary?.summery?.users?.length || 0}{" "}
                      {language === "en" ? "Adults" : " الكبار"}
                    </p>
                  </div>
                </div>
                <div className="flex items-start justify-start gap-[6px]">
                  <div className="kn-booking-item-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="15"
                      fill="none"
                      viewBox="0 0 9 15"
                    >
                      <path
                        fill="#1D1D1D"
                        d="M2.7 2.018a1.8 1.8 0 113.6 0 1.8 1.8 0 01-3.6 0zm1.35 9v2.7a.899.899 0 11-1.8 0V8.312l-.587.934a.898.898 0 01-1.24.281.898.898 0 01-.285-1.237l1.123-1.78a3.826 3.826 0 016.48-.003L8.863 8.29a.9.9 0 01-1.522.959l-.59-.937v5.406a.899.899 0 11-1.8 0v-2.7h-.9z"
                      ></path>
                    </svg>
                    <p>
                      {updatedBookingSummary?.summery?.dependents?.length || 0}{" "}
                      {language === "en" ? "Children" : "أطفال"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full h-[1px] bg-[#DDDDDD] my-4"></div>
            {updatedBookingSummary == null ? (
              <div className="w-full flex items-center justify-center">
                <TailSpin color="#FCD12A" height="35" width="35" />
              </div>
            ) : (
              <>
                <div className="w-full  flex flex-col items-start justify-start gap-2">
                  <h2 className="text-[#2A2A2A]  text-[14px] md:text-[18px] lg:text-[20px] font-medium leading-6 ">
                    {language === "en" ? "Payment Summary" : "ملخص الدفع"}
                  </h2>
                  <div className="mt-[24px] w-full flex flex-col items-start justify-start gap-4">
                    {/* gonna add payment summary of the guests based of the guests array */}
                    {updatedBookingSummary.summery?.users.length > 0 &&
                      updatedBookingSummary.summery?.users.map(
                        (user, index) => (
                          <div
                            className="relative w-full flex items-start justify-between gap-[3rem]"
                            key={index}
                          >
                            {/* accordion with all the guest activites and meals */}
                            <Accordion
                              className="w-full h-auto "
                              allowZeroExpanded
                              key={index}
                            >
                              <AccordionItem className="w-full  ">
                                <AccordionItemHeading className="w-auto">
                                  <AccordionItemButton className="w-full flex items-start justify-start md:gap-[0.6rem] lg:gap-[1rem]">
                                    <p className="text-[#3A3A3A] text-[12px] md:text-[14px] lg:text-[16px] font-medium leading-5 ">
                                      {user?.user_name
                                        ? user?.user_name
                                        : "Guest " + (index + 1)}
                                    </p>

                                    <AccordionItemState>
                                      {({ expanded }) =>
                                        expanded ? <ArrowUp /> : <ArrowDown />
                                      }
                                    </AccordionItemState>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="w-full my-[1rem]">
                                  <ul className="w-full flex flex-col items-start justify-start gap-2 ">
                                    {/* loop over adult.booking_activities */}
                                 
                                    {user?.activities_summery?.map(
                                      (activity, index) => (
                                        <li
                                          className="w-full flex items-start justify-between "
                                          key={index}
                                        >
                                          <div className="flex items-start justify-start gap-3">
                                            <p className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                              {activity?.name}
                                            </p>
                                            <div className="flex items-start justify-start gap-2 self-center">
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                -
                                              </span>
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                {activity?.time_slots_count}x
                                              </span>
                                            </div>
                                          </div>
                                          <h5 className="text-[#2A2A2A] text-[12px] font-semibold leading-5">
                                            {activity?.price_after_discount}{" "}
                                            {translate("currency")}
                                          </h5>
                                        </li>
                                      )
                                    )}
                                    {/* loop over adult.booking_food */}
                                    {user?.food_summery?.map(
                                      (foodItem, index) => (
                                        <li
                                          className="w-full flex items-start justify-between "
                                          key={index}
                                        >
                                          <div className="flex items-start justify-start gap-3">
                                            <p className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                              {foodItem?.name}
                                            </p>
                                            <div className="flex items-start justify-start gap-2 self-center">
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                -
                                              </span>
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                {foodItem?.quantity}x
                                              </span>
                                            </div>
                                          </div>
                                          <h5 className="text-[#2A2A2A] text-[12px] font-semibold leading-5">
                                            {foodItem?.price_after_discount}{" "}
                                            {translate("currency")}
                                          </h5>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>

                            <p className="absolute top-0 rtl:left-0 ltr:right-0 text-[#2A2A2A] text-[12px] md:text-[14px] lg:text-[16px] font-medium leading-6 self-start">
                              {user?.price_after_discount}{" "}
                              {translate("currency")}
                            </p>
                          </div>
                        )
                      )}

                    {updatedBookingSummary.summery?.dependents &&
                      updatedBookingSummary.summery?.dependents?.map(
                        (dependent, index) => (
                          <div
                            className="relative w-full flex items-start justify-between gap-[3rem]"
                            key={index}
                          >
                            {/* accordion with all the guest activites and meals */}
                            <Accordion
                              className="w-full h-auto "
                              allowZeroExpanded
                              key={index}
                            >
                              <AccordionItem className="w-full  ">
                                <AccordionItemHeading className="w-auto">
                                  <AccordionItemButton className="w-full flex items-start justify-start gap-[0.6rem] md:gap-[1rem]">
                                    <p className="text-[#3A3A3A] text-[12px] md:text-[14px] lg:text-[16px] font-medium leading-5 ">
                                      {dependent?.child_name
                                        ? dependent?.child_name
                                        : "Child " + (index + 1)}
                                    </p>
                                    <AccordionItemState>
                                      {({ expanded }) =>
                                        expanded ? <ArrowUp /> : <ArrowDown />
                                      }
                                    </AccordionItemState>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className="w-full my-[1rem]">
                                  <ul className="w-full flex flex-col items-start justify-start gap-2 ">
                                    {/* loop over adult.booking_activities */}
                                    <li
                                      className="w-full flex items-start justify-between "
                                      key={index}
                                    >
                                      <p className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                        {language === "en"
                                          ? "Entrance Ticket"
                                          : "تذكرة الدخول"}
                                      </p>
                                      <h5 className="text-[#2A2A2A] text-[12px] font-semibold leading-5">
                                        {dependent?.ticket_price}{" "}
                                        {translate("currency")}
                                      </h5>
                                    </li>
                                    {dependent?.activities_summery?.map(
                                      (activity, index) => (
                                        <li
                                          className="w-full flex items-start justify-between "
                                          key={index}
                                        >
                                          <div className="flex items-start justify-start gap-3">
                                            <p className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                              {activity?.name}
                                            </p>
                                            <div className="flex items-start justify-start gap-2 self-center">
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                -
                                              </span>
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                {activity?.time_slots_count}x
                                              </span>
                                            </div>
                                          </div>
                                          <h5 className="text-[#2A2A2A] text-[12px] font-semibold leading-5">
                                            {activity?.price_after_discount}{" "}
                                            {translate("currency")}
                                          </h5>
                                        </li>
                                      )
                                    )}
                                    {/* loop over adult.booking_food */}
                                    {dependent?.food_summery?.map(
                                      (foodItem, index) => (
                                        <li
                                          className="w-full flex items-start justify-between "
                                          key={index}
                                        >
                                          <div className="flex items-start justify-start gap-3">
                                            <p className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                              {foodItem?.name}
                                            </p>
                                            <div className="flex items-start justify-start gap-2 self-center">
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                -
                                              </span>
                                              <span className="text-[#2A2A2A] text-[12px] font-medium leading-5">
                                                {foodItem?.quantity}x
                                              </span>
                                            </div>
                                          </div>
                                          <h5 className="text-[#2A2A2A] text-[12px] font-semibold leading-5">
                                            {foodItem?.price_after_discount}{" "}
                                            {translate("currency")}
                                          </h5>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>

                            <p className="absolute top-0 rtl:left-0 ltr:right-0 text-[#2A2A2A] text-[12px] md:text-[14px] lg:text-[16px] font-medium leading-6 self-start">
                              {dependent?.price_after_discount}{" "}
                              {translate("currency")}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                </div>
                {updatedBookingSummary.total_summery?.discount !== 0 && (
                  <div className="w-full h-[1px] bg-[#DDDDDD] my-3"></div>
                )}
                {updatedBookingSummary.total_summery?.discount !== 0 && (
                  <>
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                      <div className="w-full flex items-start justify-between xs:pb-[1rem]">
                        <h6 className="text-[#525252] text-[14px] font-medium leading-6 self-center">
                          {language === "en" ? " Subtotal" : "المجموع الفرعي"}
                        </h6>
                        <p className="text-[#2A2A2A] text-[14px]   font-medium self-center">
                          {updatedBookingSummary.total_summery?.subtotal}{" "}
                          {translate("currency")}
                        </p>
                      </div>
                      <div className="w-full flex items-start justify-between xs:pb-[1rem]">
                        <h6 className="text-[#525252] text-[14px] font-medium leading-6 self-center">
                          {language === "en" ? "Discount" : "خصم"}
                        </h6>
                        <p className="text-[#3abd4e] text-[14px]   font-medium self-center">
                          - {updatedBookingSummary.total_summery?.discount}{" "}
                          {translate("currency")}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div className="w-full h-[1px] bg-[#DDDDDD] my-3"></div>

                <div className="w-full flex items-start justify-between xs:pb-[1rem]">
                  <h6 className="text-[#2A2A2A] text-[14px] font-semibold leading-6 self-center">
                    {language === "en" ? "Total" : "المجموع"}
                  </h6>
                  <p className="text-[#D03C32] text-[14px] md:text-[16px] lg:text-[20px] leading-[30px]  font-semibold self-center">
                    {updatedBookingSummary.total_summery?.total}{" "}
                    {translate("currency")}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </Reveal>
    </>
  );
});

export default UpdateBookingSummary;
