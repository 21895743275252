import React, { useState, useEffect } from "react";
import Input from "../../../shared/inputs/Input";
import { useBooking } from "../../../contexts/BookingContext";
import { useLanguage } from "../../../contexts/LanguageContext";

const GuestForm = React.memo(({ index }) => {
  const { language } = useLanguage();
  const { updateCurrentUser, booking, removeBookingUser } = useBooking();

  // so if the user is logged in, the first name, last, phone and email fields will be pre-filled with the user's details
  // so i wanna by default set the states by default if exists if not empty
  const [firstName, setFirstName] = useState(
    booking?.booking_users[index]?.name?.split(" ")[0] || ""
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");

  const [lastName, setLastName] = useState(
    booking?.booking_users[index]?.name?.split(" ")[1] || ""
  );

  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");

  // if first and last name are not empty, combine them or if the first name exists, use it as the full name
  const fullName =
    firstName !== "" && lastName !== ""
      ? `${firstName} ${lastName}`
      : firstName;

  console.log("firstName", firstName);
  console.log("lastName", lastName);
  console.log("fullName", fullName);
  const [email, setEmail] = useState(
    booking?.booking_users[index]?.email || ""
  );
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [whatsappNumber, setWhatsappNumber] = useState(
    booking?.booking_users[index]?.phone || ""
  );
  const [whatsappNumberError, setWhatsappNumberError] = useState(false);
  const [whatsappNumberErrorMessage, setWhatsappNumberErrorMessage] =
    useState("");

  //     updateFieldData: This function updates the booking user's specific field when it changes. It finds the correct user by the index, updates the field with the new value, and then calls updateCurrentUser to update the global state or context.
  // onChange: The onChange handler for each input is modified to call updateFieldData along with setting the local state. This keeps both the local component state and the global booking state synchronized.
  const updateFieldData = (index, field, value) => {
    const updatedUsers = booking.booking_users.map((user, idx) => {
      if (idx === index) {
        return { ...user, [field]: value };
      }
      return user;
    });

    // Call a context or state update function
    updateCurrentUser(index, updatedUsers[index]);
  };

  const handleFirstNameBlur = () => {
    if (!firstName) {
      setFirstNameError(true);
      setFirstNameErrorMessage("First name is required");
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage("");
      // Update the user only if the first name field is not empty
      updateCurrentUser(index, {
        ...booking.booking_users[index],
        name: `${firstName} ${lastName}`,
      });
    }
  };

  const handleLastNameBlur = () => {
    if (!lastName) {
      setLastNameError(true);
      setLastNameErrorMessage("Last name is required");
    } else {
      setLastNameError(false);
      setLastNameErrorMessage("");
      // Update the user only if the last name field is not empty
      updateCurrentUser(index, {
        ...booking.booking_users[index],
        name: `${firstName} ${lastName}`,
      });
    }
  };

  const handleEmailBlur = () => {
    if (!email) {
      setEmailError(true);
      setEmailErrorMessage("Email is required");
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
      // Update the user only if the email field is not empty
      updateCurrentUser(index, {
        ...booking.booking_users[index],
        email: email,
      });
    }
  };

  const handleWhatsappNumberBlur = () => {
    if (!whatsappNumber) {
      setWhatsappNumberError(true);
      setWhatsappNumberErrorMessage("Whatsapp number is required");
    } else {
      setWhatsappNumberError(false);
      setWhatsappNumberErrorMessage("");
      // Update the user only if the whatsapp number field is not empty
      updateCurrentUser(index, {
        ...booking.booking_users[index],
        phone: whatsappNumber,
      });
    }
  };

  // console.log("booking", booking);
  // console.log("full Name", fullName);

  return (
    <div className="kn-booking-info">
      <div className="w-full flex items-start justify-between ">
        <span className="w-auto inline-block bg-[#EBEBEB] py-[8px] px-[16px] rounded-[8px]">
          <svg
            className="self-center inline-block ltr:mr-[8px] rtl:ml-[8px]"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            fill="none"
            viewBox="0 0 16 17"
          >
            <path
              stroke="#2A2A2A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.2"
              d="M8 8.5a3.333 3.333 0 100-6.667A3.333 3.333 0 008 8.5zM13.727 15.167c0-2.58-2.567-4.667-5.727-4.667s-5.727 2.087-5.727 4.667"
            ></path>
          </svg>
          <span className="text-[#2A2A2A] text-[14px] font-medium leading-5 self-center">
            {fullName !== null && fullName !== ""
              ? fullName
              : "Guest " + (index + 1)}
          </span>
        </span>
        {index !== 0 && (
          <button
            className="self-center"
            onClick={() => removeBookingUser(index)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="24"
              fill="none"
              viewBox="0 0 23 24"
            >
              <g fill="#E63232" clipPath="url(#clip0_510_4999)">
                <path d="M12.275 11.879l3.042-3.042a.626.626 0 00-.882-.883l-3.042 3.011-3.061-3.06a.626.626 0 00-.883.882l3.055 3.092-2.992 2.955a.626.626 0 10.882.882l2.986-2.986 2.967 2.967a.626.626 0 00.883-.882l-2.955-2.936z"></path>
                <path d="M11.267 21.894a10.016 10.016 0 110-20.032 10.016 10.016 0 010 20.032zm0-18.779a8.763 8.763 0 100 17.527 8.763 8.763 0 000-17.527z"></path>
              </g>
              <defs>
                <clipPath id="clip0_510_4999">
                  <path
                    fill="#fff"
                    d="M0 0H22.534V22.534H0z"
                    transform="translate(0 .612)"
                  ></path>
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
      </div>
      <form className="w-full h-auto mt-[2rem] ">
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID="first-name-input"
            type="text"
            name="firstnameInput"
            placeholder={
              language === "en" ? "Enter your first name" : "ادخل الاسم الأول"
            }
            label={language === "en" ? "First Name" : "الاسم الأول"}
            value={firstName}
            // onChange={(e) => setFirstName(e.target.value)}
            onChange={(e) => {
              setFirstName(e.target.value);
              updateFieldData(index, "name", `${e.target.value} ${lastName}`);
            }}
            error={firstNameError}
            errorMessage={firstNameErrorMessage}
            redStar={true}
            onBlur={handleFirstNameBlur}
          />
          <Input
            ID="last-name-input"
            type="text"
            name="lastnameInput"
            placeholder={
              language === "en" ? "Enter your last name" : "ادخل الاسم الأخير"
            }
            label={language === "en" ? "Last Name" : "الاسم الأخير"}
            value={lastName}
            // onChange={(e) => setLastName(e.target.value)}
            onChange={(e) => {
              setLastName(e.target.value);
              updateFieldData(index, "name", `${firstName} ${e.target.value}`);
            }}
            error={lastNameError}
            errorMessage={lastNameErrorMessage}
            redStar={true}
            onBlur={handleLastNameBlur}
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row items-start justify-between gap-[24px]">
          <Input
            ID="emil-input"
            type="email"
            name="emailInput"
            placeholder={
              language === "en"
                ? "Enter your email address"
                : "ادخل البريد الإلكتروني"
            }
            label={language === "en" ? "Email" : "البريد الإلكتروني"}
            value={email}
            // onChange={(e) => setEmail(e.target.value)}
            onChange={(e) => {
              setEmail(e.target.value);
              updateFieldData(index, "email", e.target.value);
            }}
            error={emailError}
            errorMessage={emailErrorMessage}
            redStar={true}
            onBlur={handleEmailBlur}
          />
          <Input
            ID="whatsappNumber-input"
            type="number"
            name="whatsappNumberInput"
            placeholder={
              language === "en"
                ? "Enter your whatsapp number"
                : "ادخل رقم الواتساب"
            }
            label={language === "en" ? "Whatsapp Number" : "رقم الواتساب"}
            value={whatsappNumber}
            // onChange={(e) => setWhatsappNumber(e.target.value)}
            onChange={(e) => {
              setWhatsappNumber(e.target.value);
              updateFieldData(index, "phone", e.target.value);
            }}
            error={whatsappNumberError}
            errorMessage={whatsappNumberErrorMessage}
            redStar={true}
            onBlur={handleWhatsappNumberBlur}
          />
        </div>
      </form>
    </div>
  );
});

export default GuestForm;
