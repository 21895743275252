import React, { useState, useEffect } from "react";
import { useLanguage } from "../../../contexts/LanguageContext";

const DayItem = React.memo(
  ({ date, availability, onClick, isActive, price }) => {
    //   const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const daysOfWeekEN = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const daysOfWeekAR = [
      "الأحد",
      "الاثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ];
    const monthsEN = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthsAR = [
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ];
    const [dayTitle, setDayTitle] = useState("");
    const [dayDate, setDayDate] = useState("");
    const { language, translate } = useLanguage();

    useEffect(() => {
      if (date) {
        const newDate = new Date(date);
        const dayIndex = newDate.getDay();
        const monthIndex = newDate.getMonth();
        const dayOfMonth = newDate.getDate();
        const formattedDate = `${
          dayOfMonth < 10 ? `0${dayOfMonth}` : dayOfMonth
        } ${language === "en" ? monthsEN[monthIndex] : monthsAR[monthIndex]}`;

        // Set the day title and date based on the language
        setDayTitle(
          language === "en" ? daysOfWeekEN[dayIndex] : daysOfWeekAR[dayIndex]
        );
        setDayDate(formattedDate);
      }
    }, [date, language]); // Make sure to add `language` as a dependency here

    const handleItemClick = () => {
      onClick(date); // Pass the dayDate to the parent component
    };

    let availabilityClass = "";

    if (availability === "High Availability") {
      availabilityClass = "high";
    } else if (availability === "Low Availability") {
      availabilityClass = "low";
    } else if (availability === "Fully Booked") {
      availabilityClass = "not";
    }

    return (
      <>
        <div
          className={`kn-day-item ${isActive ? "kn-day-item-active" : ""} ${
            availability === "Fully Booked" ? "kn-day-item-disabled" : ""
          } `}
          onClick={handleItemClick}
        >
          <p className="text-[#8F8F8F] text-[14px] font-medium leading-5">
            {dayTitle}
          </p>
          <h4 className="text-[#35332B] text-[20px] font-semibold leading-[30px]">
            {dayDate}
          </h4>
          <div className={`kn-${availabilityClass}-border`}></div>
          <p className="text-[#666666] text-[10px] font-medium">
            {language==='en'?'Starts from':'يبدأ من'}
            <span className="text-[#454545] text-[10px] font-semibold">
              {" "}
              - {price} {translate("currency")}
            </span>
          </p>
        </div>
      </>
    );
  }
);

export default DayItem;
