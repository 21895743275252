// TermsAndConditions.js
import React from "react";
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
// Terms and Conditions Component
const TermsAndConditions = ({ checkboxStates, handleCheckboxChange }) => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col items-start justify-start gap-3 my-[1.6rem]">
      <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
        <input
          className="kn-payment-checkbox self-center"
          type="checkbox"
          checked={checkboxStates.termsAndConditions}
          name="termsAndConditions"
          onChange={handleCheckboxChange}
        />

        <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
          {language === "en" ? "I agree to the" : "أوافق على "}
          <button
            onClick={() => navigate("/terms-and-conditions")}
            className="underline  font-semibold"
          >
            {" "}
            {language === "en" ? "Terms & Conditions" : "الشروط والأحكام"}
          </button>
        </span>
      </label>
      <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
        <input
          className="kn-payment-checkbox self-center"
          type="checkbox"
          checked={checkboxStates.cancellationPolicy}
          name="cancellationPolicy"
          onChange={handleCheckboxChange}
        />

        <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
          {language === "en" ? "I agree to the" : "أوافق على "}
          <button
            className="underline  font-semibold"
            onClick={() => navigate("/cancellation-policy")}
          >
            {" "}
            {language === "en" ? "Cancellation Policy" : "سياسة الإلغاء"}
          </button>
        </span>
      </label>
      <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
        <input
          className="kn-payment-checkbox self-center"
          type="checkbox"
          checked={checkboxStates.responsibilityAcknowledge}
          name="responsibilityAcknowledge"
          onChange={handleCheckboxChange}
        />

        <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
          {language === "en"
            ? "I understand that kona will not be held responsible for any accidents that may occur."
            : "أنا أفهم أن كونا لن تكون مسؤولة عن أي حوادث قد تحدث."}
        </span>
      </label>
      <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
        <input
          className="kn-payment-checkbox self-center"
          type="checkbox"
          checked={checkboxStates.waiverAgreement}
          name="waiverAgreement"
          onChange={handleCheckboxChange}
        />

        <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
          {language === "en"
            ? "I agree to sign a waiver."
            : "أوافق على توقيع إعفاء."}
        </span>
      </label>
    </div>
  );
};

export default TermsAndConditions;
