import React from 'react';

const PaymentTypeSelection = ({
  paymentTypes,
  selectedPaymentType,
  handlePaymentSelection,
  totalPaymentPrice,
  booking,
  language,
  translate,
  isUpdating, // New prop to control rendering for update flow
}) => {
  if (isUpdating) {
    // Don't render anything for updates
    return null;
  }

  return (
    <>
      <div className="w-full flex flex-col items-start justify-start gap-4">
        <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6">
          {language === 'en'
            ? 'How Would You Like to Pay?'
            : 'كيف ترغب في الدفع؟'}
        </h3>

        <div className="w-full flex flex-col md:flex-row items-start justify-start gap-4">
          {paymentTypes.map((type, index) => (
            <div
              key={index}
              className={`kn-payment-type ${
                selectedPaymentType === type ? 'kn-payment-type-active' : ''
              }
               ${
                 type === 'split' && booking?.booking_users?.length === 1
                   ? 'kn-payment-type-radio--disabled'
                   : ''
               }
              `}
            >
              <label
                className={`kn-payment-type-radio ${
                  type === 'split' && booking?.booking_users?.length === 1
                    ? 'kn-payment-type-radio--disabled'
                    : ''
                }`}
              >
                <span>
                  {type === 'pay in full'
                    ? `${
                        language === 'en'
                          ? 'Pay Full Bill'
                          : 'الدفع بالكامل'
                      } (${totalPaymentPrice} ${translate('currency')})`
                    : language === 'en'
                    ? 'Split Payment'
                    : 'الدفع المقسم'}
                </span>
                <input
                  className="self-center"
                  type="radio"
                  name="paymentType"
                  value={type}
                  disabled={
                    type === 'split' && booking?.booking_users?.length === 1
                  }
                  checked={selectedPaymentType === type}
                  onChange={() => handlePaymentSelection(type)}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentTypeSelection;
