import React from 'react'
import { useLanguage } from '../../../contexts/LanguageContext'
const ServiceItem = ({ serviceItem }) => {
  const { language } = useLanguage();

  return (
    <>
      <div className='w-full bg-[#FCD12A33] py-[24px] px-[32px] flex flex-col items-start justify-start gap-4 '>
        <div className='w-full flex items-start justify-between'>
          <h4 className='self-center text-black text-[18px] md:text-[22px] lg:text-[24px] font-bold  leading-6'>
            {serviceItem?.title[language]}
          </h4>
          <img src={serviceItem?.image} className='w-[42px] h-[42px] self-center object-cover' alt="" srcSet="" />

        </div>
        <p className='text-black font-normal text-[16px] leading-6 mt-4'>
          {serviceItem?.description[language]}
        </p>
      </div>


    </>
  )
}

export default ServiceItem
