import React, { useState, useEffect, useCallback, useMemo } from "react";
import ButtonSecondary from "../../../../shared/buttons/ButtonSecondary";
import ButtonCancel from "../../../../shared/buttons/ButtonCancel";
import ButtonPrimary from "../../../../shared/buttons/ButtonPrimary";
import ButtonPrimaryOutline from "../../../../shared/buttons/ButtonPrimaryOutline";
import sportsPic from "../../../../assets/beach-access/beach-access-grid-1.webp";
import sportsPic2 from "../../../../assets/beach-access/beach-access-grid-2.webp";
import Meal1 from "../../../../assets/dining-experience/gallery4.webp";
import { Modal } from "antd";
import Input from "../../../../shared/inputs/Input";
import Reveal from "../../../../shared/reveal/Reveal";
import { useNavigate, useLocation } from "react-router-dom";
import { useLanguage } from "../../../../contexts/LanguageContext";
import axiosInstance from "../../../../axios/axiosInstance";
import QRCode from "react-qr-code";
import { ThreeDots } from "react-loader-spinner";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import ArrowUp from "../../../../shared/icons/ArrowUp";
import ArrowDown from "../../../../shared/icons/ArrowDown";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { useUpdateBooking } from "../../../../contexts/UpdateBookingContext";
const BookingDetails = ({}) => {
  const { setRequestedData, setUpdateBookingData } = useUpdateBooking();
  const navigate = useNavigate();
  const location = useLocation();
  const { language, translate } = useLanguage();
  const [bookingId, setBookingId] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderAllFoodOpen, setOrderAllFoodOpen] = useState(false);
  const [onCancelModal, setOnCancelModal] = useState(false);
  const [isFoodOrderForAll, setIsFoodOrderForAll] = useState(false);

  const [orderFoodForUserOpen, setOrderFoodForUserOpen] = useState(false);
  const [umbrellaNumber, setUmbrellaNumber] = useState(null);

  const [currentUserForOrder, setCurrentUserForOrder] = useState(null);
  const [menuItemsForOrder, setMenuItemsForOrder] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const can_cancel_booking = bookingDetails?.can_cancel_booking;
  const can_order_all_food = bookingDetails?.can_order_all_food;

  // Handler to toggle checkbox selection
  const toggleItemSelection = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        // If item is already selected, remove it from the array
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        // If item is not selected, add it to the array
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const onBookingCancellationSucess = () => {
    message.success("Booking has been cancelled successfully!", 5);
  };
  const onFoodOrderAllSuccess = () => {
    message.success("Food ordered for all successfully!", 5);
  };
  const onFoodOrderUserSuccess = () => {
    message.success("Food ordered successfully!", 5);
  };

  // useEffect to get booking details id
  useEffect(() => {
    if (location.state) {
      setBookingId(location.state);
    }
  }, [location.state]);

  const updateBookingObject = (bookingDetails) => {
    // Assuming bookingDetails is the complete booking object received from your API
    const transformedUsers = bookingDetails.booking_users
      .filter((user) => user.can_add_items && user.can_order_food)
      .map((user) => ({
        user_id: user.id, // Or any other relevant user details you want to keep
        name: user.name,
        booking_activities: [],
        booking_food: [],
      }));

    const updatedBooking = {
      booking_platform: "Web",
      payment_method: "creditcard",
      booking_users: transformedUsers,
    };

    // Update the update booking object
    setUpdateBookingData(updatedBooking);
  };

  // useEffect to get booking details info and set the update booking obj  from booking details page
  const getBookings = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/booking/show/${bookingId}`);
      if (response.status === 200) {
        setBookingDetails(response.data.data);
        // here to set booking details.requested_data wide state
        updateBookingObject(response.data.data);
        // setRequestedData(response.data.data.requested_data);
        //  set requested data at local storage
        localStorage.setItem(
          "requestedData",
          JSON.stringify(response.data.data.requested_data)
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (bookingId) {
      getBookings();
    }
  }, [bookingId]);

  const onOrderAllFoodModalClose = () => {
    setOrderAllFoodOpen(false);
  };
  const onOrderAllFoodModalShow = () => {
    setOrderAllFoodOpen(true);
  };
  const onOrderFoodForAll = useCallback(async () => {
    try {
      const response = await axiosInstance.post(
        `/booking/request-food-delivery`,
        {
          umbrella: "ko-2020",
          booking_id: bookingDetails?.id,
        }
      );
      console.log("response from order food for all", response);
      if (response.status === 200) {
        onFoodOrderAllSuccess();
        setIsFoodOrderForAll(true);
        getBookings();
        setOrderAllFoodOpen(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [umbrellaNumber]);

  const onOrderFoodForUser = useCallback(async () => {
    try {
      const response = await axiosInstance.post(
        `/booking/request-food-delivery`,
        {
          umbrella: "ko-2020",
          requested_food: selectedItems,
        }
      );
      console.log("response from order food for user", response);
      if (response.status === 200) {
        onFoodOrderUserSuccess();
        getBookings();
        setOrderFoodForUserOpen(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [selectedItems, umbrellaNumber]);
  const onOrderFoodForUserModalClose = () => {
    setOrderFoodForUserOpen(false);
  };

  const onOrderFoodForUserModalShow = useCallback(
    (userId) => {
      const user = bookingDetails.booking_users.find(
        (user) => user.id === userId
      );
      const menuItems = user.items_in_details.filter(
        (item) => item.status === "not used" && item.type === "Food"
      );
      setCurrentUserForOrder(user);
      setMenuItemsForOrder(menuItems);
      setSelectedItems([]); // Reset the selection every time the modal opens
      setOrderFoodForUserOpen(true);
    },
    [bookingDetails]
  );

  const onCancelModalShow = () => {
    setOnCancelModal(true);
  };
  const onCancelModalClose = () => {
    setOnCancelModal(false);
  };
  const onBookingCancel = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `/booking/cancel/${bookingDetails?.id}`
      );
      console.log("response from cancel booking", response);
      if (response.status === 200) {
        onBookingCancellationSucess();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      // setTimeout to navigate to account bookings page
      setTimeout(() => {
        navigate("/account/my-booking");
      }, 2000);
      setOnCancelModal(false);
    }
  }, [bookingDetails]);

  const allowedUsers = useMemo(() => {
    return (
      bookingDetails?.booking_users.filter(
        (user) => user.can_order_food && user.can_add_items
      ) || []
    );
  }, [bookingDetails]);
  const handleAddActivitiesAndMealsClick = (bookingDetails) => {
    // This function will be called when the "+Add activities and meals" button is clicked
    navigate("/update-booking", {
      state: {
        booking_date: bookingDetails?.booking_date,
        allowedUsers: allowedUsers,
        bookingId: bookingDetails?.id,
      },
    });
  };

  console.log("bookingDetails", bookingDetails);
  console.log(
    "🚀 ~ BookingDetails ~ can_cancel_booking:",
    can_cancel_booking
  );
  console.log(
    "🚀 ~ BookingDetails ~ can_order_all_food:",
    can_order_all_food
  );
  return (
    <>
      {" "}
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <Reveal>
          <section className="w-full h-auto">
            <h1 className="text-[#2A2A2A] text-[28px] font-semibold leading-7 mb-[40px]">
              {language === "en" ? "Booking Details" : "تفاصيل الحجز"}
            </h1>
            <div className="kn-active-booking-item-info ">
              <div className="w-full flex items-start justify-between">
                <div className="flex items-start justify-start gap-4 self-center">
                  <div className="self-center flex flex-col items-start justify-start gap-[15px]">
                    <h2 className="text-[#2A2A2A] text-[16px] md:text-[18px] lg:text-[22px] font-semibold leading-5">
                      {language === "en"
                        ? "Sports Club Ticket"
                        : "تذكرة نادي الرياضة"}
                    </h2>
                    <div className="flex items-start justify-start gap-[6px]">
                      <div className="kn-booking-item-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="#292D32"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.079"
                            d="M5.756 2.153v2.159M11.512 2.153v2.159M2.519 7.254h12.23M15.108 6.83v6.115c0 2.159-1.079 3.597-3.597 3.597H5.755c-2.518 0-3.597-1.439-3.597-3.597V6.83c0-2.159 1.08-3.598 3.597-3.598h5.756c2.518 0 3.597 1.44 3.597 3.598z"
                          ></path>
                          <path
                            stroke="#292D32"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.439"
                            d="M11.292 10.57h.007M11.292 12.73h.007M8.63 10.57h.007M8.63 12.73h.007M5.967 10.57h.007M5.967 12.73h.007"
                          ></path>
                        </svg>
                        <p>{bookingDetails?.booking_date}</p>
                      </div>

                      <div className="kn-booking-item-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          fill="none"
                          viewBox="0 0 17 17"
                        >
                          <path
                            stroke="#292D32"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.959"
                            d="M8.358 8.389a3.198 3.198 0 100-6.395 3.198 3.198 0 000 6.395zM13.851 14.784c0-2.475-2.462-4.477-5.493-4.477-3.032 0-5.494 2.002-5.494 4.477"
                          ></path>
                        </svg>
                        <p>
                          {bookingDetails?.adults_users_count}{" "}
                          {language === "en" ? "Adults" : " بالغين"}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start justify-start gap-[6px]">
                      <div className="kn-booking-item-info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="15"
                          fill="none"
                          viewBox="0 0 9 15"
                        >
                          <path
                            fill="#1D1D1D"
                            d="M2.7 2.018a1.8 1.8 0 113.6 0 1.8 1.8 0 01-3.6 0zm1.35 9v2.7a.899.899 0 11-1.8 0V8.312l-.587.934a.898.898 0 01-1.24.281.898.898 0 01-.285-1.237l1.123-1.78a3.826 3.826 0 016.48-.003L8.863 8.29a.9.9 0 01-1.522.959l-.59-.937v5.406a.899.899 0 11-1.8 0v-2.7h-.9z"
                          ></path>
                        </svg>
                        <p>
                          {bookingDetails?.children_users_count}{" "}
                          {language === "en" ? "Children" : " أطفال"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-1">
                  <div className="w-[120px] h-[120px] overflow-hidden border-[6px] rounded-[12px] border-black p-[0.6rem] ">
                    {bookingDetails?.booking_reference && (
                      <QRCode
                        size={256}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        value={bookingDetails?.booking_reference}
                        viewBox={`0 0 256 256`}
                        level="H"
                        title="Ticket Booking Reference Code"
                      />
                    )}
                  </div>
                  <button className="cursor-pointer underline text-[#2F383F] text-[12px] font-semibold leading-3 my-2">
                    {language === "en" ? "View Ticket" : "عرض التذكرة"}
                  </button>
                </div>
              </div>
            </div>
            {/* actions buttons  */}
            <div className="w-full flex items-center justify-center gap-[15px] mt-[32px] mb-[48px]">
              <ButtonSecondary
                onClick={() => handleAddActivitiesAndMealsClick(bookingDetails)}
              >
                {" "}
                {language === "en"
                  ? "+ Add Activities and Meals"
                  : " + إضافة الأنشطة والوجبات الغذائية"}
              </ButtonSecondary>
              {!isFoodOrderForAll ? (
                <ButtonSecondary
                  disabled={!can_order_all_food}
                  onClick={onOrderAllFoodModalShow}
                >
                  {language === "en" ? "Order All Food" : "طلب كل الطعام"}
                </ButtonSecondary>
              ) : (
                <p
                  className={`w-auto min-w-[120px] flex items-center justify-center gap-2 bg-transparent border border-black
                py-[6px] px-[14px] md:py-[8px] md:px-[20px] rounded-[100px] text-black
                 font-medium leading-6 text-[12px] md:text-[16px]  
                  cursor-not-allowed xs:min-w-[80px] xs:py-[7px] xs:px-[14px] opacity-60 `}
                >
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.04065 9.81033L0.480469 5.25015L1.62051 4.1101L5.04065 7.53024L12.3809 0.189941L13.521 1.32999L5.04065 9.81033Z"
                      fill="#2A2A2A"
                    />
                  </svg>

                  {language === "en"
                    ? "Food Ordered For All"
                    : "تم طلب اكل للجميع"}
                </p>
              )}

              <ButtonCancel
                disabled={!can_cancel_booking}
                onClick={onCancelModalShow}
              >
                {" "}
                {language === "en" ? "Cancel Booking" : " الغاء الحجز"}
              </ButtonCancel>
            </div>
            {/* Booking Info */}
            <div className="w-full h-auto my-[48px]">
              <div className="w-full flex items-start justify-start gap-2">
                <div className="self-center w-[20px] h-[20px] overflow-hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="#2A2A2A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.25"
                      d="M6.667 1.667v2.5M13.333 1.667v2.5M2.917 7.575h14.167M17.5 7.083v7.083c0 2.5-1.25 4.167-4.167 4.167H6.667c-2.917 0-4.167-1.666-4.167-4.166V7.082c0-2.5 1.25-4.167 4.167-4.167h6.666c2.917 0 4.167 1.667 4.167 4.167z"
                    ></path>
                    <path
                      stroke="#2A2A2A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.667"
                      d="M13.08 11.417h.007M13.08 13.917h.007M9.995 11.417h.008M9.995 13.917h.008M6.912 11.417h.008M6.912 13.917h.008"
                    ></path>
                  </svg>
                </div>
                <h5 className="text-[#2A2A2A] text-[16px] font-semibold leading-7 tracking-[0px]">
                  {language === "en" ? "Booking Information" : "معلومات الحجز"}
                </h5>
              </div>
              {/* Payment Summary */}
              <div className="kn-payment-summary">
                <h6 className="text-[#2A2A2A] text-[20px] leading-6 font-medium mb-[28px]">
                  {language === "en" ? "Payment Summery" : "ملخص الدفع"}
                </h6>
                {/* booking info - payment */}
                <div className="w-full flex flex-col items-start justify-start gap-[23px]">
                  {/* here wanna loop over bookingDetails.booking_users array and every user represents an item */}
                  {bookingDetails?.booking_users?.map((user, index) => {
                    return (
                      <div
                        className="relative w-full flex items-start justify-between gap-[3rem]"
                        key={uuidv4()}
                      >
                        {/* accordion with all the guest activites and meals */}
                        <Accordion
                          className="w-full h-auto "
                          allowZeroExpanded
                          key={uuidv4()}
                        >
                          <AccordionItem className="w-full  ">
                            <AccordionItemHeading className="w-auto">
                              <AccordionItemButton className="w-full flex items-start justify-start md:gap-[0.6rem] lg:gap-[1rem]">
                                <p className="text-[#3A3A3A] text-[12px] md:text-[14px] lg:text-[16px] font-medium leading-5 ">
                                  {user?.name}
                                </p>
                                <AccordionItemState>
                                  {({ expanded }) =>
                                    expanded ? <ArrowUp /> : <ArrowDown />
                                  }
                                </AccordionItemState>
                              </AccordionItemButton>
                            </AccordionItemHeading>

                            <AccordionItemPanel className="w-full my-[1rem]">
                              <ul className="w-full flex flex-col items-start justify-start gap-2">
                                {user?.items_in_details?.map((detail) => (
                                  <li
                                    className="w-full flex items-start justify-between"
                                    key={uuidv4()} // Assuming each detail has a unique id
                                  >
                                    <div className="flex items-start justify-start gap-3">
                                      <p className="text-[#2A2A2A] text-[12px] font-medium leading-5 self-center">
                                        {detail.item?.name[language]}
                                      </p>
                                      <p className="text-[#2A2A2A] text-[12px] font-medium leading-5 self-center">
                                        {" "}
                                        {detail?.quantity}X{" "}
                                      </p>
                                    </div>

                                    <h5 className="text-[#2A2A2A] text-[12px] font-semibold leading-5">
                                      {detail?.price * detail?.quantity}{" "}
                                      {translate("currency")}
                                      {/* Use translate("currency") here */}
                                    </h5>
                                  </li>
                                ))}
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>

                        <p className="absolute top-0 rtl:left-0 ltr:right-0 text-[#2A2A2A] text-[12px] md:text-[14px] lg:text-[16px] font-medium leading-6 self-start">
                          {user?.payment_amount} {translate("currency")}
                        </p>
                      </div>
                    );
                  })}
                  <div className="kn-total-payment-summary">
                    <h6 className="text-[#2A2A2A] text-[14px] font-semibold leading-5 self-center">
                      {language === "en" ? " Total" : "المجموع"}
                    </h6>
                    <p className="text-[#D03C32] text-[20px] font-semibold leading-6 self-center">
                      {bookingDetails?.total_amount} {translate("currency")}
                    </p>
                  </div>
                </div>
              </div>

              {/* booking info - summary */}
              {bookingDetails?.booking_users?.map((user, index) => {
                const activities = user.items_in_details.filter(
                  (item) => item.type === "Activity"
                );
                const meals = user.items_in_details.filter(
                  (item) => item.type === "Food"
                );
                return (
                  <div className="kn-payment-summary overflow-hidden">
                    {/* Header */}
                    <div className="w-full flex items-start justify-between mb-[20px]">
                      <div className="kn-order-summary">
                        <div className="w-[16px] h-[16px] self-center overflow-hidden">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              stroke="#2A2A2A"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.2"
                              d="M8 8.5a3.333 3.333 0 100-6.667A3.333 3.333 0 008 8.5zM13.727 15.167c0-2.58-2.567-4.667-5.727-4.667s-5.727 2.087-5.727 4.667"
                            ></path>
                          </svg>
                        </div>
                        <p>{user.name}</p>
                      </div>
                      {user.food_fully_booked ? (
                        <p
                          className={`w-auto min-w-[120px] flex items-center justify-center gap-2 bg-transparent border border-black
                      py-[6px] px-[14px] md:py-[8px] md:px-[20px] rounded-[100px] text-black
                       font-medium leading-6 text-[12px] md:text-[16px]  
                        cursor-not-allowed xs:min-w-[80px] xs:py-[7px] xs:px-[14px] opacity-70 `}
                        >
                          <svg
                            width="14"
                            height="10"
                            viewBox="0 0 14 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.04065 9.81033L0.480469 5.25015L1.62051 4.1101L5.04065 7.53024L12.3809 0.189941L13.521 1.32999L5.04065 9.81033Z"
                              fill="#2A2A2A"
                            />
                          </svg>

                          {language === "en" ? "Food Ordered " : "تم طلب اكل "}
                        </p>
                      ) : (
                        user.can_order_food && (
                          <ButtonPrimary
                            onClick={() => onOrderFoodForUserModalShow(user.id)}
                          >
                            {language === "en" ? "Order Food" : "طلب الطعام"}
                          </ButtonPrimary>
                        )
                      )}
                    </div>
                    {/* Sports Activities */}
                    {activities.length > 0 && (
                      <div className="relative w-full flex flex-col items-start justify-start gap-[18px]  pb-4">
                        <h4 className="text-[#1D1D1D] text-[16px] font-semibold  leading-6">
                          {language === "en"
                            ? "Sports Activities"
                            : "أنشطة رياضية"}
                        </h4>
                        {/* activity item */}
                        {activities.map((activity, index) => (
                          <div
                            key={uuidv4()}
                            className="w-full flex items-start justify-start gap-[10px] mb-4"
                          >
                            <div className="w-[90px] h-[75px] overflow-hidden rounded-[8px]">
                              <img
                                src={activity?.item.image}
                                className="w-full h-full object-cover"
                                alt=""
                                srcSet=""
                              />
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                              <h1 className="text-[#1D1D1D] text-[16px] font-semibold leading-5">
                                {activity?.item?.name[language]} -
                                <span className="text-[#1D1D1D] text-[14px] font-medium leading-5">
                                  {" "}
                                  x{activity?.quantity}
                                </span>
                              </h1>

                              <div className="flex items-start justify-start gap-[16px]">
                                <h6 className="text-[#D03C32] font-semibold text-[14px] leading-5">
                                  {activity?.price * activity?.quantity}{" "}
                                  {translate("currency")}
                                </h6>
                                <div className="flex flex-col items-start justify-start">
                                  {activity?.time_slot && (
                                    <div className="flex items-start justify-start gap-2">
                                      <svg
                                        className="self-center"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        fill="none"
                                        viewBox="0 0 17 17"
                                      >
                                        <path
                                          fill="#1D1D1D"
                                          d="M8.43 14.8a6.545 6.545 0 110-13.09 6.545 6.545 0 010 13.09zm0-1.308a5.236 5.236 0 100-10.473 5.236 5.236 0 000 10.473zm.654-5.236h2.619v1.309H7.775V4.983h1.31v3.273z"
                                        ></path>
                                      </svg>

                                      <span className="text-black text-[14px] font-medium  self-center">
                                        {activity?.time_slot}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="absolute w-[100rem] h-[2px] bg-[#F3F3F3] left-[-50%] bottom-0"></div>
                      </div>
                    )}

                    {/* Meals  */}
                    {meals.length > 0 && (
                      <div className="relative w-full flex flex-col items-start justify-start gap-[18px]  pt-4">
                        <h4 className="text-[#1D1D1D] text-[16px] font-semibold  leading-6">
                          {language === "en" ? "Meals" : "وجبات"}
                        </h4>
                        {/* Meal item */}
                        {meals.map((meal, index) => (
                          <div
                            key={uuidv4()}
                            className="w-full flex items-start justify-start gap-[10px] mb-4"
                          >
                            <div className="w-[90px] h-[75px] overflow-hidden rounded-[8px]">
                              <img
                                src={meal?.item?.image}
                                className="w-full h-full object-cover"
                                alt=""
                                srcSet=""
                              />
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                              <h1 className="text-[#1D1D1D] text-[16px] font-semibold leading-5">
                                {meal?.item?.name[language]}{" "}
                                <span className="text-[#1D1D1D] text-[14px] font-medium leading-5">
                                  - x{meal?.quantity}
                                </span>
                              </h1>

                              <div className="flex items-start justify-start gap-[16px]">
                                <h6 className="text-[#D03C32] font-semibold text-[14px] leading-5">
                                  {meal?.price * meal?.quantity}{" "}
                                  {translate("currency")}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            {/* modal for order food for all */}
            <Modal footer={null} open={orderAllFoodOpen} closeIcon={null}>
              <div className="w-full flex flex-col items-start justify-start gap-4">
                <div className="w-full flex items-start justify-between ">
                  <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6">
                    {language === "en" ? "Order Food" : "اطلب الطعام"}
                  </h3>
                  <button
                    onClick={onOrderAllFoodModalClose}
                    className="self-center"
                  >
                    <svg
                      className="self-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      fill="none"
                      viewBox="0 0 20 21"
                    >
                      <path
                        fill="#2A2A2A"
                        fillRule="evenodd"
                        d="M10 12.268l-5.366 5.366-1.768-1.768L8.232 10.5 2.866 5.134l1.768-1.768L10 8.732l5.366-5.366 1.768 1.768-5.366 5.366 5.366 5.366-1.768 1.768L10 12.268z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="text-[#464646] text-[14px] font-normal leading-5 tracking-[0.1px]  ">
                  {language === "en"
                    ? "Write your umbrella number in which you’d like your food to be served, food usually take from 30-45 mins."
                    : "اكتب رقم المظلة الخاص بك الذي ترغب في تقديم طعامك فيه، يستغرق الطعام عادة من 30 إلى 45 دقيقة."}
                </p>
                <form className="w-full mt-2">
                  <Input
                    ID="umbrella-num-input"
                    type="text"
                    name="umbrella-num-input"
                    placeholder={
                      language === "en"
                        ? "Enter your umbrella number"
                        : "أدخل رقم المظلة الخاصة بك"
                    }
                    label={language === "en" ? "Umbrella Number" : "رقم المظلة"}
                    value={umbrellaNumber}
                    onChange={(e) => setUmbrellaNumber(e.target.value)}
                  />

                  <div className="w-full flex items-start justify-start gap-3 my-4">
                    <ButtonSecondary onClick={onOrderFoodForAll}>
                      {language === "en"
                        ? "Order Food For All"
                        : "اطلب اكل للجميع"}
                    </ButtonSecondary>
                  </div>
                </form>
              </div>
            </Modal>
            {/* modal for order food for one user */}
            <Modal footer={null} open={orderFoodForUserOpen} closeIcon={null}>
              <div className="w-full flex flex-col items-start justify-start gap-4">
                <div className="w-full flex items-start justify-between ">
                  <h3 className="text-[#2A2A2A] font-semibold text-[24px] leading-6 text-center w-full">
                    {language === "en" ? "Your Order" : "اطلب الطعام"}
                  </h3>
                  <button
                    onClick={onOrderFoodForUserModalClose}
                    className="self-center"
                  >
                    <svg
                      className="self-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      fill="none"
                      viewBox="0 0 20 21"
                    >
                      <path
                        fill="#2A2A2A"
                        fillRule="evenodd"
                        d="M10 12.268l-5.366 5.366-1.768-1.768L8.232 10.5 2.866 5.134l1.768-1.768L10 8.732l5.366-5.366 1.768 1.768-5.366 5.366 5.366 5.366-1.768 1.768L10 12.268z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>

                <form className="w-full mt-2">
                  <div className="w-full mb-4">
                    {menuItemsForOrder.map((item) => (
                      <div
                        key={item.id}
                        className="py-[0.4rem] mb-[6px] border-b border-[#F0F0F0] last:border-none"
                      >
                        <label className="kn-custom-checkbox w-full flex flex-row items-start justify-start gap-3 cursor-pointer ">
                          <input
                            className="kn-payment-checkbox self-center"
                            type="checkbox"
                            checked={selectedItems.includes(item.id)}
                            onChange={() => toggleItemSelection(item.id)}
                          />

                          <span className="flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                            {item.item.name[language]}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>

                  <Input
                    ID="umbrella-num-input"
                    type="text"
                    name="umbrella-num-input"
                    placeholder={
                      language === "en"
                        ? "Enter your umbrella number"
                        : "أدخل رقم المظلة الخاصة بك"
                    }
                    label={language === "en" ? "Umbrella Number" : "رقم المظلة"}
                    value={umbrellaNumber}
                    onChange={(e) => setUmbrellaNumber(e.target.value)}
                  />

                  <div className="w-full flex items-start justify-start gap-3 my-4">
                    <ButtonSecondary onClick={onOrderFoodForUser}>
                      {language === "en" ? "Request Food" : "اطلب اكل"}
                    </ButtonSecondary>
                  </div>
                </form>
              </div>
            </Modal>
            {/* cancel booking Modal */}
            <Modal
              onCancel={onCancelModalClose}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{
                hidden: true,
              }}
              // wanna hide x button
              closeIcon={null}
              open={onCancelModal}
            >
              <div className="w-[100%] flex flex-col items-center justify-center gap-2 py-[1rem]  mx-auto">
                <svg
                  className="w-[65px] h-[65px]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 42 36"
                >
                  <path
                    fill="#FFD530"
                    fillRule="evenodd"
                    d="M22.082 4.732a1.26 1.26 0 00-2.164 0L4.813 29.937a1.26 1.26 0 001.082 1.909H36.11a1.26 1.26 0 001.084-1.909L22.082 4.732zm-5.406-1.941c1.954-3.268 6.692-3.268 8.648-.003l15.11 25.203c2.014 3.36-.406 7.634-4.327 7.634H5.893c-3.921.003-6.341-4.27-4.327-7.632l15.11-25.202zm6.845 24.012a2.522 2.522 0 11-5.043 0 2.522 2.522 0 015.043 0zm-.63-14.497a1.891 1.891 0 10-3.782 0v7.564a1.891 1.891 0 003.782 0v-7.564z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <h3 className="text-[#2A2A2A] text-[16px] md:text-[18px] lg:text-[20px] font-semibold leading-6 text-center mt-4">
                  {language === "en"
                    ? "Are you sure you want to cancel this booking?"
                    : "هل أنت متأكد أنك تريد إلغاء هذا الحجز؟"}
                </h3>
                <p className="w-[100%] md:w-[75%] text-[#464646] text-[14px] md:text-[16px] font-normal leading-5 tracking-[0.1px] text-center  ">
                  {language === "en"
                    ? "By cancelling this booking, you will lose all the benefits and access to the club"
                    : "من خلال إلغاء هذا الحجز، سوف تفقد جميع المزايا والوصول إلى النادي"}
                </p>
                <div className="w-full flex items-center justify-center gap-3 my-4">
                  <ButtonSecondary onClick={onCancelModalClose}>
                    {language === "en" ? "Discard" : "الغاء "}
                  </ButtonSecondary>
                  <ButtonCancel onClick={onBookingCancel}>
                    {language === "en"
                      ? "Yes, I want to cancel"
                      : "نعم أريد الإلغاء"}
                  </ButtonCancel>
                </div>
              </div>
            </Modal>
          </section>
        </Reveal>
      )}
    </>
  );
};

export default BookingDetails;
