import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../../shared/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import SectionHeader from "../../../shared/section-header/SectionHeader";
import cartIcon from "../../../assets/ticketIcon.svg";
import BookNowInfo from "./BookNowInfo";
import UnorderedList from "../../../shared/lists/UnorderedList";
import axiosInstance from "../../../axios/axiosInstance";
import { useLanguage } from "../../../contexts/LanguageContext";
import { ThreeDots } from "react-loader-spinner";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import MinusIcon from "../../../shared/faq/MinusIcon";
import PlusIcon from "../../../shared/faq/PlusIcon";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ButtomSecondary from "../../../shared/buttons/ButtonSecondary";
import { Modal } from "antd";
import { useUser } from "../../../contexts/UserContext";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const ActivityPage = () => {
  const { language } = useLanguage();
  const location = useLocation();
  const { user } = useUser();

  // so pathname would be /activity/:slug and i wanna get the slug from the location state and use it to fetch the activity data

  const { id: stateId, slug: activitySlug } = location.state || {};
  console.log("🚀 ~ ActivityPage ~ activitySlug:", activitySlug);
  console.log("🚀 ~ ActivityPage ~ stateId:", stateId);
  const [homeData, setHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [desc, setDesc] = useState();

  const [modal2Open, setModal2Open] = useState(false);

  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect to extract desc from html markup string and set it to state
  useEffect(() => {
    if (homeData) {
      const htmlString = homeData.description[language];
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      setDesc(doc.body.textContent || "");
    }
  }, [language, homeData]);

  const [expanded, setExpanded] = useState([]);

  // Handle change event
  const handleChange = (keys) => {
    console.log("keys", keys);
    // If keys array is empty, or the key of the current item is not included (it has been collapsed),
    // then set the expanded state to the new array of keys.
    if (keys.length === 0 || !expanded.includes(keys[0])) {
      setExpanded(keys);
    } else {
      // If the key is already in the expanded state, it means we want to collapse it.
      // So we remove it from the array.
      setExpanded(expanded.filter((key) => key !== keys[0]));
    }
  };

  // current date = '2024-02-12'
  const date = new Date().toISOString().split("T")[0];

  useEffect(() => {
    const getPageData = async () => {
      setIsLoading(true);

      // Determine the slug based on the state or pathname
      let slug = activitySlug; // This comes from location.state if it exists
      if (!slug) {
        // Extract the slug from the pathname
        const pathParts = location.pathname.split("/");
        slug = pathParts[pathParts.length - 1]; // Assuming the slug is the last part of the pathname
      }

      try {
        const pageData = await axiosInstance.get(
          `/booking/pre-requests/data/activity/${slug}`,
          {
            params: {
              date: date,
            },
          }
        );
        if (pageData.status === 200) {
          const homeData = pageData.data.data;
          setHomeData(homeData);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getPageData();
  }, [language, stateId]);

  const listItems = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum",
  ];

  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15 * index,
      },
    }),
  }; // console.log("activitySlug", activitySlug);
  // console.log("activity location", location);
  console.log("activity page data", homeData);

  return (
    <>
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto mb-[4rem] ">
          <div className="container mx-auto">
            <div className="account-breadcrumb ">
              <Breadcrumb
                location={location}
                titles={{ en: homeData?.name.en, ar: homeData?.name.ar }}
              />
            </div>
            <div className="mt-[2rem]">
              <SectionHeader
                titleMarginBottom="mb-2"
                descriptionChoiceOfClass="section-desc-2"
                title={homeData?.name[language]}
                desc={desc}
              />
            </div>
            {/* Grid  Gallery*/}

            {homeData?.gallery && (
              <div className="w-full h-auto md:h-[550px] grid grid-cols-1 md:grid-cols-2 gap-4 my-[25px] overflow-hidden">
                <div className="w-full h-full rounded-[19px]">
                  <img
                    src={homeData?.gallery[0]}
                    className="w-full md:w-auto h-[200px] sm:h-[300px] md:h-full object-cover rounded-[19px]"
                    alt=""
                    srcSet=""
                  />
                </div>
                <div className="w-full h-full grid grid-cols-2 gap-4">
                  <div className="w-full h-full rounded-[19px]">
                    <img
                      src={homeData?.gallery[1]}
                      className=" h-full object-cover rounded-[19px]"
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <div className=" hidden md:block w-full h-full rounded-[19px]">
                    <img
                      src={homeData?.gallery[2]}
                      className=" h-full object-cover rounded-[19px]"
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <div className="hidden md:block h-full rounded-[19px]">
                    <img
                      src={homeData?.gallery[3]}
                      className=" h-full object-cover rounded-[19px]"
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <div
                    className="kn-lastActivityImage"
                    style={{
                      objectFit: "cover",
                      background: ` linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                                    url(${homeData?.gallery[4]})`,
                    }}
                  >
                    {/* <img src={activity4} className='w-full h-full object-cover rounded-[19px]' alt="" srcSet="" /> */}

                    <button
                      onClick={() => setModal2Open(true)}
                      className="w-auto  absolute right-4 bottom-4 bg-white py-2 px-4 rounded-[24px] flex items-center justify-center gap-2 "
                    >
                      <span className="text-[#141414] font-semibold text-[14px] leading-3 self-center">
                        {language === "en" ? "View More" : " عرض المزيد"}
                      </span>

                      <svg
                        className={`${
                          language === "en" ? "" : "rotate-180"
                        } self-center`}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.8333 16.167L16.1667 11.8336M16.1667 11.8336L11.8333 7.50031M16.1667 11.8336H7.5M22.6667 11.8336C22.6667 17.8167 17.8164 22.667 11.8333 22.667C5.85025 22.667 1 17.8167 1 11.8336C1 5.85056 5.85025 1.00031 11.8333 1.00031C17.8164 1.00031 22.6667 5.85056 22.6667 11.8336Z"
                          stroke="#141414"
                          strokeWidth="1.625"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>

                    <Modal
                      title={`${homeData?.name[language]} ${
                        language === "en" ? "Gallery" : "معرض الصور"
                      }`}
                      centered
                      open={modal2Open}
                      width={950}
                      onCancel={() => setModal2Open(false)}
                      footer={null}
                    >
                      <main className="w-[97%] mx-auto my-[2rem]  ">
                        <div className="w-full hidden md:flex items-end justify-end gap-2">
                          <div className="flex items-start justify-start gap-2 mb-4">
                            <button
                              ref={prevButtonRef}
                              className="arrow-left self-center rtl:rotate-180"
                            >
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.0001 9.66675L9.66675 15.0001M9.66675 15.0001L15.0001 20.3334M9.66675 15.0001H20.3334M28.3334 15.0001C28.3334 22.3639 22.3639 28.3334 15.0001 28.3334C7.63628 28.3334 1.66675 22.3639 1.66675 15.0001C1.66675 7.63628 7.63628 1.66675 15.0001 1.66675C22.3639 1.66675 28.3334 7.63628 28.3334 15.0001Z"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </button>
                            <button
                              ref={nextButtonRef}
                              className="arrow-right self-center rtl:rotate-180"
                            >
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.0001 21.3334L21.3334 16.0001M21.3334 16.0001L16.0001 10.6667M21.3334 16.0001H10.6667M29.3334 16.0001C29.3334 23.3639 23.3639 29.3334 16.0001 29.3334C8.63628 29.3334 2.66675 23.3639 2.66675 16.0001C2.66675 8.63628 8.63628 2.66675 16.0001 2.66675C23.3639 2.66675 29.3334 8.63628 29.3334 16.0001Z"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <Swiper
                          spaceBetween={14}
                          slidesPerView={1}
                          loop={true}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => {
                            console.log("swiper", swiper);
                            // Initialize the swiper navigation controls
                            swiper.params.navigation.nextEl =
                              nextButtonRef.current;
                            swiper.params.navigation.prevEl =
                              prevButtonRef.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                          }}
                          pagination={{ clickable: true }}
                          scrollbar={{ draggable: true }}
                          navigation={{
                            nextEl: nextButtonRef.current,
                            prevEl: prevButtonRef.current,
                          }}
                          modules={[Navigation, Pagination]}
                          dir={language === "ar" ? "rtl" : "ltr"}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                              spaceBetween: 0,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 20,
                              // remove pagination
                              // pagination: {
                              //   clickable: false,
                              // },
                            },
                          }}
                        >
                          {homeData.gallery.map((image, index) => (
                            <SwiperSlide
                              key={index}
                              style={{
                                height: "270px",
                                borderRadius: "8px",
                              }}
                            >
                              <img
                                className="w-full h-full object-cover rounded-[8px]"
                                src={image}
                                alt={`Slide ${index + 1}`}
                                style={{ width: "100%" }}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </main>
                    </Modal>
                  </div>
                </div>
              </div>
            )}

            {homeData?.image && !homeData?.gallery && (
              <div className="w-full my-[3rem] h-[500px] overflow-hidden rounded-[12px]">
                <img
                  src={homeData?.image}
                  className="w-full h-full object-cover rounded-[12px]"
                  alt=""
                  srcSet=""
                />
              </div>
            )}

            {/* Details */}
            <div className="w-full flex flex-col items-start justify-start mt-[3rem] ">
              <div className="w-full flex flex-col md:flex-row items-start justify-between gap-[3rem] ">
                <div className="flex flex-col items-start justify-start gap-4">
                  <UnorderedList listTitle="Details" listItems={listItems} />
                  <section className="w-full h-auto pt-[32px] pb-0 md:py-[42px] lg:py-[65px] my-[1.5rem]">
                    <div className="w-full container mx-auto">
                      <div className={`w-full grid grid-cols-1 gap-[3rem]`}>
                        <div className="w-full flex flex-col items-start justify-start gap-1">
                          <div>
                            <h2 className="text-[40px] text-[#2A2A2A] font-semibold leading-5 mb-[1.4rem] ">
                              {language === "en" ? "FAQS" : "االساله الشائعه"}
                            </h2>
                          </div>

                          <p className="text-[16px] text-[#767676] font-normal leading-6">
                            {language === "en"
                              ? "Have questions? We've got you covered. Find quick answers and helpful information about our beach club and water sports club. If you can’t find your question, please feel free to reach out."
                              : "هل لديك أسئلة؟ لقد حصلت على تغطيتها. احصل على إجابات سريعة ومعلومات مفيدة حول نادي الشاطئ ونادي الرياضات المائية. إذا لم تتمكن من العثور على سؤالك، فلا تتردد في التواصل معنا."}
                          </p>
                        </div>

                        <div className="w-full flex flex-col items-start justify-start gap-2">
                          <Accordion
                            preExpanded={expanded} // Pass the expanded state here
                            onChange={handleChange} // Set the handler that updates the state
                            // allowMultipleExpanded
                            allowZeroExpanded
                            className="w-full flex flex-col items-start justify-start "
                          >
                            {homeData?.faqs?.map((item, index) => (
                              <motion.div
                                className="w-full "
                                variants={fadeInAnimationsVariants}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                  once: true,
                                }}
                                custom={index}
                                key={index}
                              >
                                <AccordionItem
                                  uuid={item.id}
                                  className={`w-full border-b border-[#E2E2E2] ${
                                    index === 0
                                      ? "pt-0 pb-[1.2rem]"
                                      : "py-[1.2rem]"
                                  } `}
                                >
                                  <AccordionItemHeading className="w-full">
                                    <AccordionItemButton className="w-full flex items-start justify-between">
                                      <p className="text-[#3A3A3A] text-[14px] md:text-[16px] font-medium leading-5 ">
                                        {item.question[language]}
                                      </p>
                                      <AccordionItemState>
                                        {({ expanded }) =>
                                          expanded ? (
                                            <MinusIcon />
                                          ) : (
                                            <PlusIcon />
                                          )
                                        }
                                      </AccordionItemState>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel className="w-full my-[1rem]">
                                    <p className="text-[#3A3A3A] text-[14px] font-normal">
                                      {item.answer[language]}
                                    </p>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </motion.div>
                            ))}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                {user && (
                  <BookNowInfo
                    pricePerPerson="150"
                    buttonIcon={cartIcon}
                    buttonTitle="Book Now"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="kn-add-to-card-activity-container-mobile">
            <div className="flex items-start justify-start gap-2">
              <h3 className="text-[#DA4040] text-[20px] font-semibold leading-7 ">
                {150} SAR
              </h3>
              <p className="text-[#949494] text-[14px] font-normal leading-6 self-center">
                / Person
              </p>
            </div>
            <div className="w-full h-[1px] bg-[#E1E1E1]"></div>
            <div className="w-full">
              <Link to="/booking">
                <ButtomSecondary className={`w-full`}>
                  <img src={cartIcon} alt="" srcSet="" />
                  Book Now
                </ButtomSecondary>
              </Link>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ActivityPage;
