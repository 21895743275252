import React, { createContext, useContext, useState, useEffect } from "react";
import axiosInstance from "../axios/axiosInstance";

// Create a context for updating bookings
const UpdateBookingContext = createContext();

// Use this hook in components to access update booking context
export const useUpdateBooking = () => useContext(UpdateBookingContext);

export const UpdateBookingProvider = ({ children }) => {
  const [updatedBookingSummary, setUpdatedBookingSummary] = useState(null);

  // State to hold the update booking data
  const [updateBookingData, setUpdateBookingData] = useState(() => {
    // Get the initial state from localStorage if it exists
    const savedData = localStorage.getItem("updateBookingData");
    return savedData ? JSON.parse(savedData) : null;
  });

  // Persist `updateBookingData` to localStoaddActivityToUserrage on change
  useEffect(() => {
    localStorage.setItem(
      "updateBookingData",
      JSON.stringify(updateBookingData)
    );
  }, [updateBookingData]);

  const addActivityToUser = (userId, activityId, timeslots) => {
    console.log(
      "addActivityToUser called with:",
      userId,
      activityId,
      timeslots
    );

    // Determine if the userId belongs to an adult or a dependent
    const isAdult = updateBookingData.booking_users.some(
      (user) => user.user_id === userId
    );
    const userArray = isAdult
      ? updateBookingData.booking_users
      : updateBookingData.booking_dependents;

    const userIdField = isAdult ? "user_id" : "user_id";

    // Find the index of the user in the appropriate array
    const userIndex = userArray.findIndex(
      (user) => user[userIdField] === userId
    );

    if (userIndex === -1) {
      console.log("User not found.");
      return; // Exit if user is not found
    }

    // Reference to the user's activities
    const userActivities = userArray[userIndex].booking_activities;
    const activityIndex = userActivities.findIndex(
      (activity) => activity.item_id === activityId
    );

    if (activityIndex === -1) {
      // Add a new activity
      userActivities.push({ item_id: activityId, time_slots: timeslots });
    } else {
      // Update the existing activity
      userActivities[activityIndex] = {
        ...userActivities[activityIndex],
        time_slots: timeslots,
      };
    }

    // Update the state with the new activities list
    setUpdateBookingData((prevState) => {
      const newState = { ...prevState };
      if (isAdult) {
        newState.booking_users[userIndex].booking_activities = userActivities;
      } else {
        newState.booking_dependents[userIndex].booking_activities =
          userActivities;
      }
      return newState;
    });
  };

  const addFoodToUser = (userId, foodItem) => {
    console.log("addFoodToUser called with:", userId, foodItem);

    // Determine if the userId belongs to an adult or a dependent
    const isAdult = updateBookingData.booking_users.some(
      (user) => user.user_id === userId
    );
    const userArray = isAdult
      ? updateBookingData.booking_users
      : updateBookingData.booking_dependents;

    const userIdField = isAdult ? "user_id" : "user_id";

    // Find the index of the user in the appropriate array
    const userIndex = userArray.findIndex(
      (user) => user[userIdField] === userId
    );

    if (userIndex === -1) {
      console.log("User not found.");
      return; // Exit if user is not found
    }

    // Reference to the user's activities
    const userFood = userArray[userIndex].booking_food;
    const foodIndex = userFood.findIndex(
      (food) => food.item_id === foodItem.item_id
    );

    if (foodIndex === -1) {
      // Add a new activity
      userFood.push(foodItem);
    } else {
      // Update the existing activity
      userFood[foodIndex] = foodItem;
    }

    // Update the state with the new activities list
    setUpdateBookingData((prevState) => {
      const newState = { ...prevState };
      if (isAdult) {
        newState.booking_users[userIndex].booking_food = userFood;
      } else {
        newState.booking_dependents[userIndex].booking_food = userFood;
      }
      return newState;
    });
  };

  const clearUserActivitiesById = (userId) => {
    setUpdateBookingData((prevState) => {
      // Find the user in booking_users or booking_dependents
      let isUserADependent = false;
      let userIndex = prevState.booking_users.findIndex(
        (user) => user.user_id === userId
      );
      console.log("userIndex:", userIndex);

      if (userIndex === -1) {
        // User not found in booking_users, try booking_dependents
        userIndex = prevState.booking_dependents.findIndex(
          (dependent) => dependent.user_id === userId
        );
        console.log("userIndex:", userIndex);
        isUserADependent = true;
      }

      // If the user is not found, return the state as is
      if (userIndex === -1) return prevState;

      // User found, now clear activities
      if (isUserADependent) {
        // Clear activities for a dependent
        const updatedDependents = [...prevState.booking_dependents];
        updatedDependents[userIndex].booking_activities = [];
        console.log("updatedDependents:", updatedDependents);

        return { ...prevState, booking_dependents: updatedDependents };
      } else {
        // Clear activities for a booking user
        const updatedUsers = [...prevState.booking_users];
        console.log("updatedUsers:", updatedUsers);
        updatedUsers[userIndex].booking_activities = [];
        return { ...prevState, booking_users: updatedUsers };
      }
    });
  };

  const clearUserFoodById = (userId) => {
    setUpdateBookingData((prevState) => {
      // Find the user in booking_users or booking_dependents
      let isUserADependent = false;
      let userIndex = prevState.booking_users.findIndex(
        (user) => user.user_id === userId
      );
      console.log("userIndex:", userIndex);

      if (userIndex === -1) {
        // User not found in booking_users, try booking_dependents
        userIndex = prevState.booking_dependents.findIndex(
          (dependent) => dependent.user_id === userId
        );
        console.log("userIndex:", userIndex);
        isUserADependent = true;
      }

      // If the user is not found, return the state as is
      if (userIndex === -1) return prevState;

      // User found, now clear activities
      if (isUserADependent) {
        // Clear activities for a dependent
        const updatedDependents = [...prevState.booking_dependents];
        updatedDependents[userIndex].booking_food = [];
        console.log("updatedDependents:", updatedDependents);

        return { ...prevState, booking_dependents: updatedDependents };
      } else {
        // Clear activities for a booking user
        const updatedUsers = [...prevState.booking_users];
        console.log("updatedUsers:", updatedUsers);
        updatedUsers[userIndex].booking_food = [];
        return { ...prevState, booking_users: updatedUsers };
      }
    });
  };

  const clearUserFoodItemById = (userId, foodId) => {
    setUpdateBookingData((prevState) => {
      // Find the user in booking_users or booking_dependents
      let isUserADependent = false;
      let userIndex = prevState.booking_users.findIndex(
        (user) => user.user_id === userId
      );
      console.log("userIndex:", userIndex);

      if (userIndex === -1) {
        // User not found in booking_users, try booking_dependents
        userIndex = prevState.booking_dependents.findIndex(
          (dependent) => dependent.user_id === userId
        );
        console.log("userIndex:", userIndex);
        isUserADependent = true;
      }

      // If the user is not found, return the state as is
      if (userIndex === -1) return prevState;

      // User found, now clear activities
      if (isUserADependent) {
        // Clear activities for a dependent
        const updatedDependents = [...prevState.booking_dependents];
        updatedDependents[userIndex].booking_food = updatedDependents[
          userIndex
        ].booking_food.filter((food) => food.item_id !== foodId);
        console.log("updatedDependents:", updatedDependents);

        return { ...prevState, booking_dependents: updatedDependents };
      } else {
        // Clear activities for a booking user
        const updatedUsers = [...prevState.booking_users];
        console.log("updatedUsers:", updatedUsers);
        updatedUsers[userIndex].booking_food = updatedUsers[
          userIndex
        ].booking_food.filter((food) => food.item_id !== foodId);
        return { ...prevState, booking_users: updatedUsers };
      }
    });
  };

  const clearActivityForUser = (userId, activityId) => {
    setUpdateBookingData((prevState) => {
      // Find the user in booking_users or booking_dependents
      let isUserADependent = false;
      let userIndex = prevState.booking_users.findIndex(
        (user) => user.user_id === userId
      );
      console.log("userIndex:", userIndex);

      if (userIndex === -1) {
        // User not found in booking_users, try booking_dependents
        userIndex = prevState.booking_dependents.findIndex(
          (dependent) => dependent.user_id === userId
        );
        console.log("userIndex:", userIndex);
        isUserADependent = true;
      }

      // If the user is not found, return the state as is
      if (userIndex === -1) return prevState;

      // User found, now clear activities
      if (isUserADependent) {
        // Clear activities for a dependent
        const updatedDependents = [...prevState.booking_dependents];
        updatedDependents[userIndex].booking_activities = updatedDependents[
          userIndex
        ].booking_activities.filter(
          (activity) => activity.item_id !== activityId
        );
        console.log("updatedDependents:", updatedDependents);

        return { ...prevState, booking_dependents: updatedDependents };
      } else {
        // Clear activities for a booking user
        const updatedUsers = [...prevState.booking_users];
        console.log("updatedUsers:", updatedUsers);
        updatedUsers[userIndex].booking_activities = updatedUsers[
          userIndex
        ].booking_activities.filter(
          (activity) => activity.item_id !== activityId
        );
        return { ...prevState, booking_users: updatedUsers };
      }
    });
  };

  // Function to set the update booking object with the requested data at localStorage from update booking context
  const setRequestedData = (data) => {
    setUpdateBookingData((prevState) => ({
      ...prevState,
      ...data?.requested_data,
    }));
  };

  //   // Function to transform the booking object for the summary endpoint
  const transformUpdatedBookingForSummary = () => {
    // Clone the current booking state
    const transformedBooking = { ...updateBookingData };

    // Include all users
    transformedBooking.booking_users = transformedBooking.booking_users.map(
      (user) => ({
        name: user.name,
        booking_activities: user.booking_activities,
        booking_food: user.booking_food,
        // Include any other relevant fields needed for the summary
      })
    );

    // Transform booking dependents (assuming you always want to include all dependents)
    transformedBooking.booking_dependents =
      transformedBooking.booking_dependents.map((dependent) => ({
        name: dependent.name,
        booking_activities: dependent.booking_activities,
        booking_food: dependent.booking_food,
        // Include any other relevant fields needed for the summary
      }));

    // Return the transformed booking object
    return transformedBooking;
  };
  // useEffect to update booking summary
  useEffect(() => {
    const updateBookingSummary = async () => {
      // Guard clause to check for booking date or if the booking object is null/undefined
      if (!updateBookingData || !updateBookingData.booking_date) {
        console.log(
          "Booking date is not set or booking is null/undefined, skipping summary update."
        );
        return;
      }
      const summaryObject = transformUpdatedBookingForSummary();
      // Filter out empty users
      const filteredUsers = summaryObject?.booking_users?.filter(
        (user) => user.name && user.name.trim() !== ""
      );
      // Filter out empty dependents
      const filteredDependents = summaryObject?.booking_dependents?.filter(
        (dependent) => dependent.name && dependent.name.trim() !== ""
      );

      try {
        const response = await axiosInstance.post(`/booking/summery`, {
          booking_platform: "Web",
          booking_date: updateBookingData?.booking_date,
          payment_type: "pay in full",
          is_update: true,
          coupon_code: null,
          booking_users: updateBookingData.booking_users,
        });
        // console.log("response summary", response);
        if (response.status === 200) {
          setUpdatedBookingSummary(response.data.data);
          console.log("response from update booking summary", response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Call the function only if there is a valid booking date
    if (updateBookingData && updateBookingData.booking_date) {
      updateBookingSummary();
    }
  }, [updateBookingData]);

  // All context values here
  const contextValue = {
    updateBookingData,
    setRequestedData,
    setUpdateBookingData,
    addActivityToUser,
    clearUserActivitiesById,
    clearActivityForUser,
    addFoodToUser,
    clearUserFoodById,
    clearUserFoodItemById,
    transformUpdatedBookingForSummary,
    updatedBookingSummary,
  };

  return (
    <UpdateBookingContext.Provider value={contextValue}>
      {children}
    </UpdateBookingContext.Provider>
  );
};
