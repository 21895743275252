import React, { useEffect, useState } from "react";

const Countdown = ({ time , setTimedOut }) => {
    const [seconds, setSeconds] = useState(time);

    const countdown = () => {
        setTimeout(() =>
            setSeconds(seconds - 1)
            , 1000);

    };

    useEffect(() => {
        if (seconds > 0) countdown();
        if (seconds === 0) setTimedOut(true);
        // if seconds is less than 10 then add a 0 in front of it
    }, [seconds]);
    return (
        <>
            <div className="self-center flex items-center justify-start gap-2">

                <span className="font-semibold text-[#282828] leading-4 text-[14px]">
                    00:{seconds.toString().padStart(2, "0")}
                </span>
            </div>

        </>
    )
}

export default Countdown
