import React from "react";
import ButtomSecondary from "../../../shared/buttons/ButtonSecondary";
import { Link } from "react-router-dom";
const BookNowInfo = ({ pricePerPerson, buttonIcon, buttonTitle }) => {
  return (
    <div className="kn-add-to-card-activity-container">
      <div className="flex items-start justify-start gap-2">
        <h3 className="text-[#DA4040] text-[20px] font-semibold leading-7 ">
          {pricePerPerson} SAR
        </h3>
        <p className="text-[#949494] text-[14px] font-normal leading-6 self-center">
          / Person
        </p>
      </div>
      <div className="w-full h-[1px] bg-[#E1E1E1]"></div>
      <div className="w-full">
        <Link to="/booking">
          <ButtomSecondary className={`w-full`}>
            <img src={buttonIcon} alt="" srcSet="" />
            {buttonTitle}
          </ButtomSecondary>
        </Link>
      </div>
    </div>
  );
};

export default BookNowInfo;
