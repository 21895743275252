import React from "react";
import ButtonWhite from "../buttons/ButtonWhite";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { Link } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import DynamicPage from "../../components/dynamic-page/DynamicPage";
import { useNavigate } from "react-router-dom";
const PlanItem = ({ isDark, planItemData }) => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const cardStyles = {
    background: isDark ? "#1e1e1e" : "white",
    color: isDark ? "white" : "#1e1e1e",
  };

  // console.log('plan item data', planItemData.listItems[0].item.en)

  const onLearnMore = (e) => {
    e.preventDefault();
    navigate(`/${planItemData?.slug}`, { state: { id: planItemData.id } });
  };

  return (
    <div className="kn-planCard" style={cardStyles}>
      <h2 className="kn-planTitle">{planItemData?.title}</h2>
      <p className="kn-planDesc">{planItemData?.description}</p>

      <ul className="flex flex-col items-start justify-start gap-4">
        {/* loop over  planItemData.listItems */}
        {planItemData?.listItems?.map((item, index) => {
          return (
            <li className="kn-planListItem" key={index}>
              <div className="w-[25px] h-[25px]">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.3479 7.9696L9.7479 18.5696C9.65402 18.6643 9.52622 18.7175 9.3929 18.7175C9.25958 18.7175 9.13178 18.6643 9.0379 18.5696L3.6479 13.1796C3.55324 13.0858 3.5 12.958 3.5 12.8246C3.5 12.6913 3.55324 12.5635 3.6479 12.4696L4.3479 11.7696C4.44178 11.675 4.56958 11.6217 4.7029 11.6217C4.83622 11.6217 4.96402 11.675 5.0579 11.7696L9.3879 16.0996L18.9379 6.5496C19.1357 6.35781 19.4501 6.35781 19.6479 6.5496L20.3479 7.2596C20.4426 7.35348 20.4958 7.48128 20.4958 7.6146C20.4958 7.74792 20.4426 7.87571 20.3479 7.9696Z"
                    fill={isDark ? "white" : "#1e1e1e"}
                  />
                </svg>
              </div>
              <span>{item.item[language]}</span>
            </li>
          );
        })}
      </ul>
      {isDark && (
        <div className="w-full  flex items-end justify-start flex-grow mt-[2rem]">
          <button onClick={onLearnMore}>
            <ButtonWhite
              className={`w-full md:w-auto items-center justify-center md:items-start md:justify-start`}
            >
              {language === "en" ? "Learn More" : "اعرف المزيد"}
            </ButtonWhite>
          </button>
        </div>
      )}
      {!isDark && (
        <div className="w-full  flex items-end justify-start flex-grow mt-[2rem]">
          <button onClick={onLearnMore}>
            <ButtonPrimary
              className={`w-full md:w-auto items-center justify-center md:items-start md:justify-start`}
            >
              {language === "en" ? "Learn More" : "اعرف المزيد"}
            </ButtonPrimary>
          </button>
        </div>
      )}
    </div>
  );
};

export default PlanItem;
