import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { UserProvider } from "./contexts/UserContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { BookingProvider } from "./contexts/BookingContext";
import { UpdateBookingProvider } from "./contexts/UpdateBookingContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <UserProvider>
    <BookingProvider>
      <UpdateBookingProvider>
        <LanguageProvider>
          <ConfigProvider
            theme={{
              Menu: {
                // itemHoverColor: 'red',
                // itemHoverBg: 'blue',
                // itemColor: 'green',
              },
            }}
          >
            <App />
          </ConfigProvider>
        </LanguageProvider>
      </UpdateBookingProvider>
    </BookingProvider>
  </UserProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
