import React from 'react'
import Header from '../../shared/header/Header'
import Footer from '../../shared/footer/Footer'
import ButtonPrimary from '../../shared/buttons/ButtonPrimary'
import { Link } from 'react-router-dom'
import error404 from '../../assets/error404.webp'
const Error = () => {
    return (
        <>
            <Header />

            <section className="w-full h-auto pt-[2rem] pb-[6rem] lg:pb-[8rem] my-[4rem]  ">
                <div className="container mx-auto">

                    <div className="w-full flex flex-col items-center justify-center gap-2">
                        <img src={error404} alt="error" srcSet="Error icon" />
                        <p className="mb-4 text-2xl md:text-3xl lg:text-4xl tracking-tight font-bold text-[#1E1E1E] text-center mt-4">
                            Ooops something went wrong!
                        </p>
                        <p className="mb-4 text-[#1E1E1E] text-[14px] md:text-[17px] leading-7 font-normal text-center ">
                            We can’t find that you’re looking for, let’s find you a <br className='hidden md:block' /> better place to go
                        </p>
                        <Link
                            to="/"

                        >
                            <ButtonPrimary>


                                <p className='self-center'> Back to Home</p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="33"
                                    height="25"
                                    fill="none"
                                    viewBox="0 0 33 25"
                                >
                                    <path
                                        fill="#fff"
                                        d="M24.337 12.715a.878.878 0 000-1.242l-5.59-5.59a.878.878 0 10-1.242 1.242l4.969 4.97-4.969 4.968a.878.878 0 101.242 1.242l5.59-5.59zm-15.553.258h14.932v-1.757H8.784v1.757z"
                                    ></path>
                                </svg>
                            </ButtonPrimary>
                        </Link>
                    </div>

                </div>
            </section>

            <Footer />


        </>
    )
}

export default Error
