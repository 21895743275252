import React from 'react'

const SearchInput = ({ width, placeholder }) => {
    const inputStyles = {
        width: width,
        lineHeight: '28px',
        border: '2px solid transparent',
        color: '#0d0c22',
        transition: ' 0.3s ease',
        padding: '14px 0.5rem 14px 3rem',
        borderRadius: '67.06px',
        backgroundColor: '#ffffff',
        boxShadow: ' -2.6823530197143555px 4.023529529571533px 7.376470565795898px 0px #00000026'
    }

    return (
        <>
            <form className="kn-searh-form">

                <svg className='icon' width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.8146 22.3046L19.0986 18.6862M21.7529 12.4833C21.7529 17.3365 17.7123 21.2708 12.7279 21.2708C7.74351 21.2708 3.70288 17.3365 3.70288 12.4833C3.70288 7.6301 7.74351 3.6958 12.7279 3.6958C17.7123 3.6958 21.7529 7.6301 21.7529 12.4833Z" stroke="black" strokeWidth="2.68235" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <input type="search" className='kn-search-input' style={inputStyles} placeholder={placeholder} />
            </form>
        </>
    )
}

export default SearchInput
