import React, { useState, useEffect, useCallback } from "react";
import QuantityInput from "../../../shared/quantity/QuantityInput";
import DayItem from "./DayItem";
import ButtonSecondary from "../../../shared/buttons/ButtonSecondary";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import { useLanguage } from "../../../contexts/LanguageContext";
import { useBooking } from "../../../contexts/BookingContext";
import { motion } from "framer-motion";
import Reveal from "../../../shared/reveal/Reveal";
const Booking = React.memo(() => {
  const {
    updateBookingDate,
    initializeBookingUsers,
    initializeBookingDependents,
    setGuestPrice,
    setDependentPrice,
    updateCouponCode,
    clearBookingStateDefault,
  } = useBooking();
  const { language, translate } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState(location.state);
  const [adultsQuantity, setAdultsQuantity] = useState(1);
  const [kidsQuantity, setKidsQuantity] = useState(0);
  const [daysToShow, setDaysToShow] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [activeDay, setActiveDay] = useState(null);
  const [calender, setCalender] = useState(null);
  const [adultPrice, setAdultPrice] = useState(null);
  const [kidsPrice, setKidsPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date();
  // Add a state to track if the previous month is available
  const [prevMonthAvailable, setPrevMonthAvailable] = useState(false);
  // Add a state to track if the next month is available
  const [nextMonthAvailable, setNextMonthAvailable] = useState(false);
  // Add states for current month and year
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    currentDate.getMonth()
  );
  const [availableMonths, setAvailableMonths] = useState([]);
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  // New state to keep track of the displayed range within the calendar
  const [displayRange, setDisplayRange] = useState({ start: 0, end: 4 });

  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.01 * index,
      },
    }),
  };

  // fetch calender data
  useEffect(() => {
    const getCalender = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/booking/pre-requests/data/calendar`
        );
        if (response.status === 200) {
          const fetchedCalendar = response.data.data;
          setCalender(fetchedCalendar);
          const months = [
            ...new Set(
              fetchedCalendar.map((item) => new Date(item.date).getMonth())
            ),
          ];
          setAvailableMonths(months);
          // Set the currentMonthIndex to the first available month
          setCurrentMonthIndex(months[0]);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getCalender();
  }, []);

  // Add a useEffect to update the previous and next month availability
  useEffect(() => {
    if (calender) {
      const currentIndex = availableMonths.indexOf(currentMonthIndex);
      setPrevMonthAvailable(currentIndex > 0);
      setNextMonthAvailable(currentIndex < availableMonths.length - 1);
    }
  }, [calender, currentMonthIndex, availableMonths]);

  // days to show based on the current month
  useEffect(() => {
    // the calender only have 2 months
    if (calender && calender.length > 0) {
      const currentMonthDays = calender.filter(
        (day) => new Date(day.date).getMonth() === currentMonthIndex
      );
      setDaysToShow(
        currentMonthDays.slice(displayRange.start, displayRange.end)
      );
      // Set the first date as active by default
      const firstDay = currentMonthDays[0];
      setActiveDay(firstDay.date);
      // Set the prices for the first date by default
      setAdultPrice(firstDay.price); // Assuming 'price' is the adult price
      setKidsPrice(firstDay.dependent_price); // Assuming 'dependent_price' is the kids price
    }
  }, [calender, displayRange, currentMonthIndex]);

  // useEffect to set the state if it's not null (coming from BookingInformation.js)
  useEffect(() => {
    if (state) {
      setAdultsQuantity(state.adultsQuantity);
      setKidsQuantity(state.kidsQuantity);
      setActiveDay(state.selectedDay);
    }
  }, []);

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect to set the active day to the first available day
  useEffect(() => {
    if (calender && calender.length > 0) {
      const currentMonthDays = calender.filter(
        (day) => new Date(day.date).getMonth() === currentMonthIndex
      );

      // Find the first available day
      const firstAvailableDay = currentMonthDays.find(
        (day) => day.status !== "Fully Booked"
      );

      if (firstAvailableDay) {
        setActiveDay(firstAvailableDay.date);
        // Assuming 'price' is the adult price and 'dependent_price' is the kids price
        setAdultPrice(firstAvailableDay.price);
        setKidsPrice(firstAvailableDay.dependent_price);
      }

      setDaysToShow(
        currentMonthDays.slice(displayRange.start, displayRange.end)
      );
    }
  }, [calender, currentMonthIndex, displayRange.start, displayRange.end]);

  // handle day click function
  const handleDayClick = useCallback(
    (clickedDay) => {
      // Find the data for the clicked day
      const selectedDayData = calender.find((day) => day.date === clickedDay);
      // Check if the selected day is fully booked
      if (selectedDayData && selectedDayData.status === "Fully Booked") {
        // Optionally, provide feedback to the user that the day is fully booked
        console.warn("This day is fully booked. Please select another day.");
        return; // Exit the function to prevent setting the day as active
      } else {
        setActiveDay(clickedDay);
        setAdultPrice(selectedDayData.price);
        setKidsPrice(selectedDayData.dependent_price);
      }
    },
    [calender]
  );

  // handle adults quantity change function
  const handleQuantityChange = (newQuantity) => {
    setAdultsQuantity(newQuantity);
  };
  // handle kids quantity change function
  const handleKidsQuantityChange = (newQuantity) => {
    setKidsQuantity(newQuantity);
  };

  // handle load more days
  const load30DaysHandler = () => {
    // setNext7Days(next30Days); // Show all days
    setLoadMore(true);
    setDisplayRange({ start: 0, end: 30 });
  };

  // handle next button click to navigate to the next booking step as well as updating the booking date and users
  const handleNextClick = () => {
    clearBookingStateDefault();
    // Update the booking date
    updateBookingDate(activeDay);
    // Add the adults and kids to the booking
    initializeBookingUsers(adultsQuantity);
    initializeBookingDependents(kidsQuantity);
    setGuestPrice(adultPrice);
    setDependentPrice(kidsPrice);
    updateCouponCode(null);

    setTimeout(() => {
      navigate("/booking-information", {
        state: {
          guestPrice: adultPrice,
          dependentPrice: kidsPrice,
        },
      });
    }, 1000);
  };

  // fetch next month
  const fetchNextMonth = () => {
    const currentIndex = availableMonths.indexOf(currentMonthIndex);
    if (currentIndex < availableMonths.length - 1) {
      setCurrentMonthIndex(availableMonths[currentIndex + 1]);
    }
    setDisplayRange({ start: 0, end: 30 });
  };
  // fetch prev month
  const fetchPrevMonth = () => {
    const currentIndex = availableMonths.indexOf(currentMonthIndex);
    if (currentIndex > 0) {
      setCurrentMonthIndex(availableMonths[currentIndex - 1]);
    }
    setDisplayRange({ start: 0, end: 30 });
  };

  const prevMonthButtonDisabled =
    currentYear === currentDate.getFullYear() &&
    currentMonthIndex <= currentDate.getMonth();

  const nextMonthButtonDisabled =
    currentYear === currentDate.getFullYear() &&
    currentMonthIndex >= currentDate.getMonth();

  const currentMonthName = new Date(
    currentYear,
    currentMonthIndex
  ).toLocaleString("default", { month: "long" });

  console.log("adults quantity", adultsQuantity);
  console.log("kids quantity", kidsQuantity);

  return (
    <>
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto">
          <div className="gradient-overlay"></div>
          <div className="container mx-auto">
            <Reveal>
              <div className="w-full flex items-center justify-center xs:my-[1rem] sm:my-[2rem] lg:my-[4rem]">
                <h1 className="text-[#1E1E1E] text-[22px]  md:text-[32px] lg:text-[42px] font-semibold leading-[60px] text-center">
                  {language === "en" ? "Plan Your Enjoyment" : " خطط لاستمتاعك"}
                </h1>
              </div>
            </Reveal>

            <div className="kn-booking-grid">
              {/* time pickup */}
              <div className=" w-[100%] h-auto overflow-hidden">
                <div className="kn-calender-grid flex flex-col items-start justify-start gap-5 p-[24px] xs:p-[12px]">
                  <h3 className="text-[#1E1E1E] text-[24px] font-semibold leading-7 mb-3 ">
                    {language === "en" ? "Pick Your Day" : "اختر يومك"}
                  </h3>
                  <div className="w-full flex items-start justify-start gap-2">
                    <div className=" flex xs:flex-col flex-row  items-start justify-start gap-[1.2rem] self-center">
                      <div className="booking-day-info">
                        <div className="bg-[#EB5757] w-[10px] h-[10px] self-center"></div>
                        <p className="text-[10px] sm:text-[12px] font-semibold text-[#333333] leading-5 self-center">
                          {language === "en" ? "Fully Booked" : "محجوز بالكامل"}
                        </p>
                      </div>
                      <div className="booking-day-info">
                        <div className="bg-[#FCA82A] w-[10px] h-[10px] self-center"></div>
                        <p className="text-[10px] sm:text-[12px] font-semibold text-[#333333] leading-5 self-center">
                          {language === "en"
                            ? "   Low Availability"
                            : "التوفر منخفض"}
                        </p>
                      </div>
                      <div className="booking-day-info">
                        <div className="bg-[#27AE60] w-[10px] h-[10px] self-center"></div>
                        <p className="text-[10px] sm:text-[12px] font-semibold text-[#333333] leading-5 self-center">
                          {language === "en" ? " Available" : "متاح"}
                        </p>
                      </div>
                    </div>
                  </div>
                  {loadMore && (
                    <div className="w-full flex items-start justify-between mt-4">
                      {/* prev month */}
                      <button
                        disabled={prevMonthButtonDisabled}
                        onClick={fetchPrevMonth}
                        className="self-center disabled:cursor-not-allowed"
                      >
                        <svg
                          className="rtl:rotate-180"
                          width="21"
                          height="17"
                          viewBox="0 0 21 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.9951 9.64051C19.625 9.64051 20.1356 9.12989 20.1356 8.5C20.1356 7.87011 19.625 7.35949 18.9951 7.35949V9.64051ZM1.19354 7.69354C0.74814 8.13893 0.74814 8.86107 1.19354 9.30646L8.4517 16.5646C8.8971 17.01 9.61923 17.01 10.0646 16.5646C10.51 16.1192 10.51 15.3971 10.0646 14.9517L3.61293 8.5L10.0646 2.0483C10.51 1.6029 10.51 0.880769 10.0646 0.435371C9.61923 -0.010025 8.8971 -0.010025 8.4517 0.435371L1.19354 7.69354ZM18.9951 7.35949L2 7.35949V9.64051L18.9951 9.64051V7.35949Z"
                            fill={
                              prevMonthButtonDisabled ? "#E3E3E3" : "#FCA82A"
                            }
                          />
                        </svg>
                      </button>
                      {/* month */}
                      <h4 className="text-[#1E1E1E] text-[18px] font-semibold leading-7">
                        {currentMonthName} {currentYear}
                      </h4>

                      {/* next month */}
                      <button
                        disabled={!nextMonthAvailable}
                        onClick={fetchNextMonth}
                        className="self-center"
                      >
                        <svg
                          className="rtl:rotate-180"
                          width="21"
                          height="19"
                          viewBox="0 0 21 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.99512 8.33488C1.35164 8.33488 0.830001 8.85652 0.830001 9.5C0.830001 10.1435 1.35164 10.6651 1.99512 10.6651L1.99512 8.33488ZM19.819 10.3239C20.274 9.86885 20.274 9.13114 19.819 8.67614L12.4042 1.26139C11.9492 0.806379 11.2115 0.806379 10.7565 1.26139C10.3015 1.71639 10.3015 2.4541 10.7565 2.90911L17.3474 9.5L10.7565 16.0909C10.3015 16.5459 10.3015 17.2836 10.7565 17.7386C11.2115 18.1936 11.9492 18.1936 12.4042 17.7386L19.819 10.3239ZM1.99512 10.6651L18.9951 10.6651L18.9951 8.33488L1.99512 8.33488L1.99512 10.6651Z"
                            fill={!nextMonthAvailable ? "#E3E3E3" : "#FCA82A"}
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                  {/* available slots */}
                  <div
                    className={`w-full grid ${
                      loadMore
                        ? "xs:grid-cols-1 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
                        : "xs:grid-cols-1 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 "
                    } gap-[14px] mt-[1rem] `}
                  >
                    {daysToShow.map((day, index) => (
                      <motion.div
                        className="w-full "
                        variants={fadeInAnimationsVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                          once: true,
                        }}
                        custom={index}
                        key={index}
                      >
                        <DayItem
                          key={index}
                          date={day.date}
                          price={day.price}
                          availability={day.status}
                          onClick={handleDayClick}
                          isActive={day.date === activeDay} // Check if the day is active
                        />
                      </motion.div>
                    ))}
                    {!loadMore && (
                      <button
                        onClick={load30DaysHandler}
                        className="kn-loadDays-btn"
                      >
                        {language === "en" ? "More" : "المزيد"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* Guests */}
              <Reveal className=" w-full h-auto">
                <div className=" w-full h-auto">
                  <div className="kn-guests-grid p-[24px] xs:p-[12px]">
                    <h3 className="text-[#1E1E1E] text-[16px] md:text-[18px] lg:text-[24px] font-semibold leading-7 mb-[2.5rem]">
                      {language === "en"
                        ? "How Many Guests?"
                        : "كم عدد الضيوف؟"}
                    </h3>
                    <div className="w-[100%]  flex flex-col items-start justify-start gap-[2rem]">
                      <QuantityInput
                        htmlFor="no-of-adults-input"
                        labelTitle={
                          language === "en" ? "No. of Adults" : "عدد البالغين"
                        }
                        labelPrice={
                          adultPrice
                            ? `${adultPrice} ${translate("currency")}`
                            : null
                        }
                        quantity={adultsQuantity}
                        onQuantityChange={handleQuantityChange}
                      />
                      <QuantityInput
                        htmlFor="no-of-kids-input"
                        labelTitle={
                          language === "en" ? "No. of Kids" : "عدد الأطفال"
                        }
                        labelPrice={
                          kidsPrice
                            ? `${kidsPrice} ${translate("currency")}`
                            : null
                        }
                        quantity={kidsQuantity}
                        onQuantityChange={handleKidsQuantityChange}
                      />
                    </div>
                  </div>
                </div>
              </Reveal>
            </div>
            {/* NEXT Button Submition */}
            <div className="w-full flex items-center justify-center md:items-start md:justify-start my-[2rem]">
              <ButtonSecondary
                onClick={handleNextClick}
                className={`w-[250px] md:w-[275px] lg:w-[300px] `}
              >
                {language === "en" ? "Next" : "التالي"}
              </ButtonSecondary>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default Booking;
