import React, { useState, useEffect } from "react";
import logo from "../../assets/konaLogo.svg";
import googlePlay from "../../assets/google-pay.svg";
import appStore from "../../assets/app-store.svg";
import { Link } from "react-router-dom";

import axiosInstance from "../../axios/axiosInstance";
import { useLanguage } from "../../contexts/LanguageContext";

const Footer = () => {
  const { language } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [mainFooterData, setMainFooterData] = useState(null);
  const [subFooterData, setSubFooterData] = useState(null);
  const [socialData, setSocialData] = useState(null);
  const year = new Date().getFullYear();
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (address) {
      const addressArray = address.split(".");
      setStreet(addressArray[0]);
      setCity(addressArray[1]);
      setCountry(addressArray[2]);
    }
  }, [address, language]);
  // use effect to get the footer data
  useEffect(() => {
    const getFooterData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get("/lookup/menu", {
          params: {
            facility: "sports club",
          },
        });
        setMainFooterData(
          response.data.data.find((item) => item.type === "main_footer_menu")
        );
        setSubFooterData(
          response.data.data.find((item) => item.type === "footer_menu")
        );

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getFooterData();
  }, [language]);

  // social media data
  useEffect(() => {
    const getFooterData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get("/global-data/footer/data");

        setSocialData(response?.data?.data);
        setAddress(response?.data?.data?.address[language]);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    getFooterData();
  }, [language]);

  // console.log("mainFooterData", mainFooterData);
  // console.log("footer social", socialData);
  // console.log("subFooterData", subFooterData);

  return (
    <>
      <footer className="footer">
        <div className="container mx-auto">
          {/* Top */}
          <div className="w-full flex flex-col md:flex-row gap-[3rem] md:gap-[1rem] items-start justify-between pb-[2rem] sm:pb-[3rem] md:pb-[4rem] lg:pb-[6rem]">
            {/* left */}
            <div className="flex flex-col items-start justify-start gap-4">
              <Link to="/">
                <img src={logo} alt="" srcSet="" />
              </Link>
              <div className="flex flex-col items-start justify-start gap-1">
                <p className="font-normal text-[16px] text-black leading-6">
                  {street}
                </p>
                <p className="font-normal text-[16px] text-black leading-6">
                  {city}
                </p>
                <p className="font-normal text-[16px] text-black leading-6">
                  {country}
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <a href={socialData?.social_links?.playStore} target="_blank">
                  <img src={googlePlay} alt="" srcSet="" />
                </a>
                <a href={socialData?.social_links?.appStore} target="_blank">
                  <img src={appStore} alt="" srcSet="" />
                </a>
              </div>
            </div>
            {/* right */}
            <div className="flex  flex-col md:flex-row gap-[3rem] md:gap-[4rem] items-start justify-start ">
              <div className="flex flex-col items-start justify-start gap-3">
                <h3 className="footer-header-title">
                  {language === "en" ? "About Kona" : "عن كونا"}
                </h3>

                <ul className="flex flex-col items-start justify-start gap-4">
                  {mainFooterData &&
                    mainFooterData?.items?.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.slug}
                          className="text-black text-[16px] font-medium leading-6 no-underline"
                        >
                          {item.title[language]}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="flex flex-col items-start justify-start gap-3">
                <h3 className="footer-header-title">
                  {language === "en" ? "Follow Us" : "تابعنا"}
                </h3>

                <ul className="flex flex-col items-start justify-start gap-4">
                  {/* gonna loop over social links array */}
                  {socialData?.social_links?.map((socialItem, index) => (
                    <li key={index}>
                      <a
                        href={socialItem?.repeater_link}
                        target="_blank"
                        className="text-black text-[16px] font-medium leading-6 no-underline flex items-start justify-start gap-4"
                      >
                        <img
                          className="self-center"
                          src={socialItem?.repeater_icon}
                          alt="facebook logo icon"
                          srcSet="facebook logo icon"
                        />
                        <span className="self-center text-[16px] font-normal leading-4 text-black">
                          {socialItem?.repeater_title[language]}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Divider */}
          <div className="w-full h-[1px] bg-black"> </div>
          {/* Bottom */}
          <div className="flex flex-col md:flex-row gap-[1.2rem] items-start justify-between pt-4 pb-0  md:pt-6   lg:pt-8  ">
            <h6 className="text-black font-normal leading-6">
              © {year} {socialData?.copyRights}
            </h6>

            <div className="flex items-start justify-start gap-6">
              <Link
                to="/privacy-policy"
                className="text-black font-normal leading-6 underline"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-and-conditions"
                className="text-black font-normal leading-6 underline"
              >
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
