import React, { useEffect, useState } from "react";
import ButtonSecondary from "../../../shared/buttons/ButtonSecondary";
import Button from "../../../shared/buttons/Button";
import Reveal from "../../../shared/reveal/Reveal";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../../../contexts/LanguageContext";
import { useUser } from "../../../contexts/UserContext";

const SportCardItem = ({ sportCardItemData, onBookTicket }) => {
  const { user } = useUser();

  const navigate = useNavigate();
  const { language } = useLanguage();

  const [descriptionText, setDescriptionText] = useState("");

  useEffect(() => {
    const htmlString = sportCardItemData.description[language];
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    setDescriptionText(doc.body.textContent || "");
  }, [sportCardItemData, language]);

  const onLearnMore = () => {
    // wanna add sportCardItemData.slug as param to the navigate function
    // navigate("/activity", { state: { id: sportCardItemData.id } });
    navigate(`/activities/${sportCardItemData.slug}`, {
      state: { id: sportCardItemData.id, slug: sportCardItemData.slug },
    });
  };
  return (
    <div
      dir={`${language === "ar" ? "rtl" : "ltr"}`}
      className="kn-sport-item-card  "
    >
      <div className="w-full h-[275px] overflow-hidden rounded-[12px]">
        <img
          className="w-full h-full  object-cover rounded-[12px]"
          src={sportCardItemData.image}
          alt=""
        />
      </div>

      <div className="w-full flex flex-col items-start justify-between gap-2 pt-4">
        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h4 className="text-black text-[18px] md:text-[22px] lg:text-[24px] font-semibold leading-5 sm:leading-6 lg:leading-7 line-clamp-1">
            {sportCardItemData.name[language]}
          </h4>
          <p className="text-black h-[4.5rem]  text-[14px] md:text-[16px] font-normal leading-6 tracking-[0] line-clamp-3">
            {descriptionText}
          </p>
        </div>

        <div className="w-full flex  items-start justify-start gap-3 py-5 mt-2 border-t border-[#E7E7E7]">
          <Link to={user ? "/booking" : "/contact-us"}>
            <ButtonSecondary className={`self-center`} onClick={onBookTicket}>
              {user
                ? language === "en"
                  ? "Book Ticket"
                  : "احجز تذكرة"
                : language === "en"
                ? "Book a Tour"
                : "احجز جولة"}
            </ButtonSecondary>
          </Link>

          <Button className={`self-center`} onClick={onLearnMore}>
            {language === "en" ? "Learn More" : "اعرف المزيد"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SportCardItem;
