import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import SectionHeader from "../../../shared/section-header/SectionHeader";
import ServiceItem from "./ServiceItem";
import { useLanguage } from "../../../contexts/LanguageContext";
const Services = ({ data }) => {
  const { language } = useLanguage();

  const featureItems = data?.items;

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const photoControls = useAnimation();
  const servicesControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      photoControls.start({
        opacity: 1,
        translateY: 0,
      });
      servicesControls.start({
        // { opacity: 1, translateY: 0, translateX: 0 }
        opacity: 1,
        translateY: 0,
        translateX: 0,
      });
    }
  }, [isInView]);

  return (
    <>
      <section className="w-full h-auto kn-linear-gradient pt-[32px] pb-0 md:py-[42px] lg:py-[65px]">
        <div className="container mx-auto">
          <div className="w-full sm:w-[85%] md:w-[75%] lg:w-[55%]">
            <SectionHeader
              title={data?.title[language]}
              titleMarginBottom="mb-2"
              desc={data?.description[language]}
              descriptionChoiceOfClass="section-desc"
            />
          </div>
          <div
            ref={ref}
            className="w-full grid grid-cols-1 md:grid-cols-2  gap-[2rem]  my-[4rem]"
          >
            <motion.div
              initial={{ opacity: 0, translateY: -50 }}
              animate={photoControls}
              transition={{ duration: 0.5, delay: 0.25 }}
              className="w-full h-full rounded-[8px]"
            >
              <img
                src={data?.image}
                className="w-full h-full object-cover rounded-[8px]"
                alt="serice thumbnail"
                srcSet="A Picture of a swimming pool activity"
              />
            </motion.div>
            <div className="w-full flex flex-col items-start justify-between gap-[1rem]">
              {featureItems?.map((serviceItem, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, translateY: -50, translateX: -50 }}
                  animate={servicesControls}
                  transition={{ duration: 0.3, delay: index * 0.4 }}
                >
                  <ServiceItem serviceItem={serviceItem} />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
