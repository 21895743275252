import React, { useState } from "react";
import Input from "../inputs/Input";
import TextArea from "../inputs/TextArea";
import ButtonSecondary from "../buttons/ButtonSecondary";
import ContactInfo from "./ContactInfo";
import EmailIcon from "../../assets/EmailIcon.webp";
import LocationIcon from "../../assets/LocationIcon.webp";
import PhoneIcon from "../../assets/PhoneIcon.webp";
import axiosInstance from "../../axios/axiosInstance";
import { useLanguage } from "../../contexts/LanguageContext";
import { notification } from "antd";

const ContactUs = ({ data }) => {
  const { language } = useLanguage();
  const [contactUsData, setContactUsData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    // errors
    nameError: "",
    emailError: "",
    phoneError: "",
    messageError: "",
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      message: errorMessage,
      description: null,
      placement: "top",
      className: "mb-0",
      duration: 3,
    });
  };

  // Function to validate the contact us form
  const validateContactUsForm = (contactUsData) => {
    const fields = ["name", "email", "phone", "message"];
    let isValid = true;
    const errors = {};

    // Loop through each field to check if it's empty and set the corresponding error message
    fields.forEach((field) => {
      if (!contactUsData[field] || contactUsData[field].trim() === "") {
        errors[`${field}Error`] = `${
          field[0].toUpperCase() + field.slice(1)
        } is required`;
        isValid = false;
      } else {
        errors[`${field}Error`] = "";
      }
    });

    // Update all errors at once
    setContactUsData({
      ...contactUsData,
      ...errors,
    });

    return isValid; // Optional: return whether the form is valid
  };

  const onContactUsSubmition = async (e) => {
    e.preventDefault();

    // validate contact us form
    if (!validateContactUsForm(contactUsData)) return;
    else {
      // hit the endpoint post to send contact message "/contact-messages"
      try {
        const response = await axiosInstance.post("/contact-messages", {
          name: contactUsData.name,
          email: contactUsData.email,
          phone: contactUsData.phone,
          message: contactUsData.message,
          type: "contact",
        });
        if (response.status === 200) {
          openNotificationWithIcon(
            "success",
            language === "en"
              ? "Message Sent Successfully."
              : " تم ارسال الرساله بنجاح"
          );

          setContactUsData({
            name: "",
            email: "",
            phone: "",
            message: "",
            // errors
            nameError: "",
            emailError: "",
            phoneError: "",
            messageError: "",
          });
        }
      } catch (error) {
        console.log("error", error);
        openNotificationWithIcon("error", error?.response?.data?.message);
      }
    }

    console.log("contact us data", contactUsData);
  };
  return (
    <>
      {contextHolder}
      <section className="w-full h-auto kn-linear-gradient-high pt-[64px] ">
        <div className="container mx-auto">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[1.5rem] md:gap-[2.5rem] lg:gap-[4rem] ">
            {/* left */}
            <div className="w-full  flex flex-col items-start justify-start">
              <h1 className="text-[#2A2A2A] font-bold text-[24px] md:text-[32px] lg:text-[40px] leading-[25px] md:leading-[35px] lg:leading-[46px] mb-5">
                {data?.title[language]}
              </h1>
              <p className="text-[#767676] text-[14px] md:text-[16px] leading-5">
                {data?.description[language]}
              </p>

              <form
                onSubmit={onContactUsSubmition}
                className="w-full flex flex-col items-start justify-start gap-0 my-[1.5rem] md:my-[2rem] lg:my-[3rem]"
              >
                <div className="w-full flex flex-col md:flex-row items-start justify-between gap-0 md:gap-4">
                  <div className="w-full">
                    <Input
                      ID="name-input"
                      type="text"
                      placeholder={
                        language === "en" ? "Add your Name" : "أضف اسمك"
                      }
                      label={language === "en" ? "Name" : "الاسم"}
                      value={contactUsData.name}
                      onChange={(e) => {
                        setContactUsData({
                          ...contactUsData,
                          name: e.target.value,
                        });
                      }}
                      error={contactUsData.nameError}
                      errorMessage={contactUsData.nameError}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      ID="phone-input"
                      type="phone"
                      placeholder={
                        language === "en" ? "Add your Phone" : "أضف هاتفك"
                      }
                      label={language === "en" ? "Phone" : "هاتف"}
                      value={contactUsData.phone}
                      onChange={(e) => {
                        setContactUsData({
                          ...contactUsData,
                          phone: e.target.value,
                        });
                      }}
                      error={contactUsData.phoneError}
                      errorMessage={contactUsData.phoneError}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <Input
                    ID="email-input"
                    type="email"
                    placeholder={
                      language === "en"
                        ? "Add your Email"
                        : "أضف بريدك الإلكتروني"
                    }
                    label={language === "en" ? "Email" : "البريد الإلكتروني"}
                    value={contactUsData.email}
                    onChange={(e) => {
                      setContactUsData({
                        ...contactUsData,
                        email: e.target.value,
                      });
                    }}
                    error={contactUsData.emailError}
                    errorMessage={contactUsData.emailError}
                  />
                </div>

                <div className="w-full">
                  <TextArea
                    ID="message-input"
                    type="text"
                    placeholder={
                      language === "en" ? "Add your Message" : "أضف رسالتك"
                    }
                    label={language === "en" ? "Message" : "رسالة"}
                    value={contactUsData.message}
                    onChange={(e) => {
                      setContactUsData({
                        ...contactUsData,
                        message: e.target.value,
                      });
                    }}
                    error={contactUsData.messageError}
                    errorMessage={contactUsData.messageError}
                  />
                </div>

                <div className="w-full my-[3rem]">
                  <ButtonSecondary
                    type="submit"
                    className={`shadow w-full lg:w-[15rem]`}
                  >
                    {language === "en" ? "Send Message" : "إرسال الرسالة"}
                  </ButtonSecondary>
                </div>
              </form>
            </div>

            {/* right */}
            <div className="w-full self-center grid grid-cols-1 md:grid-cols-2 gap-[3rem] ">
              <ContactInfo
                icon={EmailIcon}
                infoTitle={data?.contact_email_title[language]}
                infoDesc={data?.contact_email}
              />
              <ContactInfo
                icon={PhoneIcon}
                infoTitle={data?.contact_phone_title[language]}
                infoDesc={data?.contact_phone}
              />
              <ContactInfo
                icon={LocationIcon}
                infoTitle={data?.contact_location_title[language]}
                infoDesc={data?.contact_location[language]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
