import React from "react";
import GuestForm from "./GuestForm";
import ActivitiesDrawer from "./ActivitiesDrawer";
import FoodDrawer from "./FoodDrawer";
import Reveal from "../../../shared/reveal/Reveal";

const GuestBookingInfo = React.memo(({ activities, food, index, type }) => {
  return (
    <>
      <Reveal>
        <div className="kn-guest-booking-info">
          <GuestForm index={index} />
          {/* Activity Add Cta */}
          <ActivitiesDrawer
            activities={activities}
            currentIndex={index}
            currentUserType={type}
          />
          {/* Meals Add Cta */}
          <FoodDrawer currentIndex={index} currentUserType={type} food={food} />
        </div>
      </Reveal>
    </>
  );
});

export default GuestBookingInfo;
