import React, { useState, useEffect } from "react";
import ButtonPrimaryOutline from "../../shared/buttons/ButtonPrimaryOutline";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import ButtonPrimary from "../../shared/buttons/ButtonPrimary";
import QRCode from "react-qr-code";
import AppleWalletSvg from "../../assets/appleWallet.svg";
import GoogleWalletSvg from "../../assets/googleWallet.svg";
import DownloadAppPic from "../../assets/mobileAppSnapshot.png";
import GooglePlayPic from "../../assets/googel-play-cta.svg";
import AppStore from "../../assets/app-store.svg";
import { Link, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import axiosInstance from "../../axios/axiosInstance";
import { useLanguage } from "../../contexts/LanguageContext";
import { useBooking } from "../../contexts/BookingContext";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
const PaymentSuccess = () => {
  const { language, translate } = useLanguage();
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [mainEmail, setMainEmail] = useState("");

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("booking");
  }, []);

  console.log("location", location);

  // Effect to set booking ID from location state
  useEffect(() => {
    const id =
      location?.state?.bookingId || localStorage.getItem("bookingDataId");
    if (id) {
      setBookingId(id);
      return;
    } else {
      console.log("No booking ID found in location state");
      navigate("/page-not-found");
    }
  }, []);

  // Effect to fetch booking data
  useEffect(() => {
    const fetchBookingData = async () => {
      if (!bookingId) {
        return;
      }

      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`/booking/show/${bookingId}`);
        console.log("Booking response from payment success:", response);

        if (response.status === 200 && response.data?.data) {
          setBookingData(response.data.data);
        } else {
          throw new Error("Booking data is not found or is incomplete.");
        }
      } catch (error) {
        console.error("Error fetching booking data:", error);
        // if ((error.response.data.message = "complete your profile first.")) {
        //   navigate("/complete-registeration");
        // }
        navigate("/page-not-found");
        // }
      } finally {
        setIsLoading(false);
      }
    };

    if (bookingId) {
      fetchBookingData();
    }
  }, [bookingId]);

  console.log("booking data from payment success", bookingData);
  console.log("location from payment success", location);
  return (
    <>
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto my-[2rem] md:my-[4rem] ">
          <div className="w-full flex flex-col items-center justify-center gap-2">
            <svg
              className="w-[80px] h-[80px] md:w-[120px] md:h-[120px]"
              width="121"
              height="121"
              viewBox="0 0 121 121"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="60.5"
                cy="60.5"
                r="56"
                stroke="#FCD12A"
                strokeOpacity="0.07"
                strokeWidth="9"
              />
              <path
                d="M104.639 60.4999C104.639 85.1539 84.6533 105.14 59.9994 105.14C35.3454 105.14 15.3594 85.1539 15.3594 60.4999C15.3594 35.846 35.3454 15.8599 59.9994 15.8599C84.6533 15.8599 104.639 35.846 104.639 60.4999Z"
                fill="url(#paint0_linear_317_3205)"
              />
              <path
                d="M41.3984 59.5908L54.2818 72.4742C54.6723 72.8647 55.3055 72.8647 55.696 72.4742L78.8304 49.3398"
                stroke="white"
                strokeWidth="6"
                strokeLinecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_317_3205"
                  x1="17.9995"
                  y1="23.0001"
                  x2="87.9995"
                  y2="94.0001"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FCD12A" />
                  <stop
                    offset="0.113762"
                    stopColor="#FCD12A"
                    stopOpacity="0.37"
                  />
                  <stop offset="1" stopColor="#FCD12A" />
                </linearGradient>
              </defs>
            </svg>
            <h2 className="text-[32px] mdtext-[48px] font-semibold leading-[55px] text-center text-[#2A2A2A]">
              {language === "en" ? "Booking Confirmed!" : "تم تأكيد الحجز!"}
            </h2>

            {language === "en" ? (
              <p className="w-[95%] md:w-[55%] text-[#666666] text-[16px] leading-6 font-normal text-center ">
                Your booking has been confirmed successfully, you will be asked
                to show the reservation QR code on door which you can find it
                here on the website, And a copy of your receipt will be sent to
                your email via {user?.email}
                <span className="font-semibold">{mainEmail}</span>
              </p>
            ) : (
              <p className="w-[95%] md:w-[55%] text-[#666666] text-[16px] leading-6 font-normal text-center ">
                تم تأكيد حجزك بنجاح ، سيُطلب منك عرض رمز الاستجابة السريعة للحجز
                على الباب والذي يمكنك العثور عليه هنا على الموقع الإلكتروني ،
                {""} وسيتم إرسال نسخة من إيصالك إلى بريدك الإلكتروني عبر
                <span className="font-semibold">{mainEmail}</span>
              </p>
            )}
          </div>
          <div className="w-full flex items-center justify-center gap-3 mt-[2rem]">
            <Link to="/account/my-booking">
              <ButtonSecondary
                style={{
                  width: "170px",
                  padding: "12px 20px",
                  border: "1px solid #FCD12A",
                }}
              >
                {language === "en" ? " My Bookings" : "حجوزاتي"}
              </ButtonSecondary>
            </Link>
            <Link to="/">
              <ButtonPrimaryOutline
                style={{
                  width: language === "en" ? "170px" : "220px",
                  padding: "12px 20px",
                }}
              >
                {language === "en"
                  ? "Back To Home"
                  : "العودة إلى الصفحة الرئيسية"}
              </ButtonPrimaryOutline>
            </Link>
          </div>

          {/* booking card info */}
          <div className="w-full h-auto bg-[#fcd22a20] pt-[5rem] pb-[3rem] mt-[5rem]">
            <div className="w-full flex items-center justify-center">
              <div className="kn-booking-card-info">
                <div className="kn-booking-card-info-inner-grid">
                  <div className="kn-booking-card-info-header">
                    <p className="text-white text-[20px] font-bold leading-7 ">
                      {language === "en"
                        ? " AQUA PARK TICKET"
                        : "تذكرة حديقة المياه"}
                    </p>
                  </div>
                  <div className="kn-booking-card-details">
                    {/* first col info */}
                    <div className="w-full flex items-start justify-start gap-[2.5rem]">
                      <div className="flex flex-col items-start justify-start gap-3">
                        <h6 className="text-[#B1B1B1] text-[12px] leading-4 font-medium">
                          {language === "en" ? "Ticket" : "تذكرة"}
                        </h6>
                        <p className="text-[#2A2A2A] text-[16px] font-medium leading-5">
                          Aqua Park
                        </p>
                      </div>
                      <div className="flex flex-col items-start justify-start gap-3">
                        <h6 className="text-[#B1B1B1] text-[12px] leading-4 font-medium">
                          {language === "en" ? "Booking No" : "رقم الحجز"}
                        </h6>
                        <p className="text-[#2A2A2A] text-[16px] font-medium leading-5">
                          {bookingData?.booking_reference}
                        </p>
                      </div>
                    </div>
                    {/* second col info */}
                    <div className="w-full flex items-start justify-between ">
                      <div className="flex flex-col items-start justify-start gap-2">
                        <h6 className="text-[#B1B1B1] text-[12px] leading-4 font-medium">
                          {language === "en" ? "Date" : "التاريخ"}
                        </h6>
                        <div className="flex items-start justify-start gap-1">
                          <svg
                            className="self-center"
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                          >
                            <path
                              stroke="#292D32"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeMiterlimit="10"
                              strokeWidth="1.079"
                              d="M5.756 2.153v2.159M11.512 2.153v2.159M2.519 7.254h12.23M15.108 6.83v6.115c0 2.159-1.079 3.597-3.597 3.597H5.755c-2.518 0-3.597-1.439-3.597-3.597V6.83c0-2.159 1.08-3.598 3.597-3.598h5.756c2.518 0 3.597 1.44 3.597 3.598z"
                            ></path>
                            <path
                              stroke="#292D32"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.439"
                              d="M11.292 10.57h.007M11.292 12.73h.007M8.63 10.57h.007M8.63 12.73h.007M5.967 10.57h.007M5.967 12.73h.007"
                            ></path>
                          </svg>
                          <p className="text-[#2A2A2A] text-[12px] font-normal leading-4 self-center mt-[2px]">
                            {bookingData?.booking_date}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start gap-2">
                        <h6 className="text-[#B1B1B1] text-[12px] leading-4 font-medium">
                          {language === "en" ? "Guest" : "الضيف"}
                        </h6>
                        <div className="flex items-start justify-start gap-1">
                          <svg
                            className="self-center"
                            width="18"
                            height="18"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.67415 8.06085C9.44013 8.06085 10.8717 6.62925 10.8717 4.86327C10.8717 3.09729 9.44013 1.66568 7.67415 1.66568C5.90817 1.66568 4.47656 3.09729 4.47656 4.86327C4.47656 6.62925 5.90817 8.06085 7.67415 8.06085Z"
                              stroke="#2A2A2A"
                              strokeWidth="0.959276"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.1666 14.4561C13.1666 11.9812 10.7045 9.97948 7.67314 9.97948C4.64183 9.97948 2.17969 11.9812 2.17969 14.4561"
                              stroke="#2A2A2A"
                              strokeWidth="0.959276"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <p className="text-[#2A2A2A] text-[12px] font-normal leading-4 self-center mt-[2px]">
                            {bookingData?.adults_users_count}{" "}
                            {language === "en" ? "Adults" : "الكبار"}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start gap-2">
                        <h6 className="text-[#B1B1B1] text-[12px] leading-4 font-medium">
                          {language === "en" ? "Child" : "طفل"}
                        </h6>
                        <div className="flex items-start justify-start gap-1">
                          <svg
                            className="self-center"
                            width="18"
                            height="18"
                            viewBox="0 0 9 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_317_3232)">
                              <path
                                d="M2.69954 2.69034C2.69954 2.21295 2.88919 1.75511 3.22675 1.41754C3.56432 1.07998 4.02216 0.890335 4.49954 0.890335C4.97693 0.890335 5.43477 1.07998 5.77234 1.41754C6.1099 1.75511 6.29954 2.21295 6.29954 2.69034C6.29954 3.16772 6.1099 3.62556 5.77234 3.96313C5.43477 4.30069 4.97693 4.49034 4.49954 4.49034C4.02216 4.49034 3.56432 4.30069 3.22675 3.96313C2.88919 3.62556 2.69954 3.16772 2.69954 2.69034ZM4.04954 11.6903V14.3903C4.04954 14.8881 3.64736 15.2903 3.14954 15.2903C2.65173 15.2903 2.24954 14.8881 2.24954 14.3903V8.98471L1.66173 9.91846C1.39736 10.3403 0.840482 10.4641 0.42142 10.1997C0.00235702 9.93534 -0.127018 9.38127 0.137357 8.96221L1.25954 7.1819C1.95986 6.06534 3.18329 5.39034 4.49954 5.39034C5.81579 5.39034 7.03923 6.06534 7.73954 7.17909L8.86173 8.96221C9.12611 9.38409 8.99955 9.93815 8.58048 10.2025C8.16142 10.4669 7.60454 10.3403 7.34017 9.92127L6.74955 8.98471V14.3903C6.74955 14.8881 6.34736 15.2903 5.84955 15.2903C5.35173 15.2903 4.94954 14.8881 4.94954 14.3903V11.6903H4.04954Z"
                                fill="#2A2A2A"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_317_3232">
                                <rect
                                  width="9"
                                  height="14.4"
                                  fill="white"
                                  transform="translate(0 0.890335)"
                                />
                              </clipPath>
                            </defs>
                          </svg>

                          <p className="text-[#2A2A2A] text-[12px] font-normal leading-4 self-center mt-[2px]">
                            {bookingData?.children_users_count}{" "}
                            {language === "en" ? "Children" : "الأطفال"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* third col info */}
                    <div className="w-full flex items-start justify-between ">
                      <div className="flex flex-col items-start justify-start gap-2">
                        <h6 className="text-[#B1B1B1] text-[12px] leading-4 font-medium">
                          {language === "en"
                            ? "Total Cost"
                            : "التكلفة الإجمالية"}
                        </h6>
                        <p className="text-[#D03C32] text-[14px] font-semibold leading-4 self-center mt-[2px]">
                          {bookingData?.total_amount} {translate("currency")}
                        </p>
                      </div>
                    </div>
                    <ButtonPrimary
                      style={{
                        fontSize: "12px",
                        padding: "4px 8px",
                        maxWidth: "100px",
                      }}
                    >
                      {language === "en" ? "Learn More" : "المزيد"}
                    </ButtonPrimary>
                  </div>
                  <div className="kn-booking-card-qr relative">
                    <div className="w-full flex flex-col items-center justify-center gap-2">
                      <p className="text-[#2F383F] text-[12px] font-medium leading-5 tracking-[2%] uppercase">
                        {language === "en"
                          ? "Scan to check in"
                          : "مسح للتحقق من الوصول"}
                      </p>
                      <div className="w-[130px] h-[130px] overflow-hidden border-[6px] rounded-[12px] border-black p-[0.6rem]  ">
                        {bookingData && (
                          <QRCode
                            size={256}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            value={bookingData?.booking_reference}
                            viewBox={`0 0 256 256`}
                            level="H"
                            title="Ticket Booking Reference Code"
                          />
                        )}
                      </div>
                      <button className="text-[#2F383F] text-[14px] font-semibold underline  leading-5 tracking-[2%] uppercase">
                        {language === "en"
                          ? "Expand QR Code"
                          : "قم بتوسيع رمز الاستجابة السريعة"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* booking card actions */}
            <div className="w-full flex items-center justify-center gap-4 mt-[2rem]">
              <button className="kn-booking-details-button-wallet">
                <img
                  className="w-[30px] h-[25px] self-center"
                  src={GoogleWalletSvg}
                  alt="Google Wallet"
                />
                <div className="flex flex-col items-start justify-start self-center gap-[2px]">
                  <p className="text-white text-[11px] font-normal leading-3">
                    {language === "en" ? "Add to" : "أضف إلى"}
                  </p>
                  <p className="text-white text-[13px] font-medium leading-4">
                    {language === "en" ? "Google Wallet" : "محفظة جوجل"}
                  </p>
                </div>
              </button>
              <button className="kn-booking-details-button-wallet">
                <img
                  className="w-[30px] h-[25px] self-center"
                  src={AppleWalletSvg}
                  alt="Apple Wallet"
                />
                <div className="flex flex-col items-start justify-start self-center gap-[2px]">
                  <p className="text-white text-[11px] font-normal leading-3">
                    {language === "en" ? "Add to" : "أضف إلى"}
                  </p>
                  <p className="text-white text-[13px] font-medium leading-4">
                    {language === "en" ? "Apple Wallet" : "محفظة آبل"}
                  </p>
                </div>
              </button>
            </div>
          </div>
          {/* Booking Confirmed */}
          <div className="w-full h-auto bg-[#1E1E1E] py-[4.5rem]">
            <div className="container mx-auto">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[4rem]">
                <div className="flex flex-col items-start justify-start gap-4">
                  <h2 className="text-[24px] md:text-[28px] lg:text-[36px] font-bold leading-[35px] md:leading-[45px] lg:leading-[53px] text-white ">
                    {language === "en"
                      ? `booking confirmed, now what?`
                      : `تم تأكيد الحجز، الآن  ماذا؟`}
                  </h2>

                  <img
                    className="w-full object-contain h-[500px]"
                    src={DownloadAppPic}
                    alt="app mobile pic"
                    srcSet="Snapshot from Appliation Mobile Picture"
                  />
                </div>
                <div className=" h-full w-full flex items-center justify-center">
                  <ol className="w-[90%] md:w-full mx-auto  relative border-s border-gray-200 dark:border-gray-700 ">
                    <li className="mb-10 ms-[5rem]">
                      <span className="absolute flex items-center justify-center w-[2rem] h-[2rem]  rounded-full -start-3 ring-8 ring-[#1E1E1E] ">
                        <span className="text-[#D2D2D2] text-[46px] font-semibold leading-10 bg-[#1E1E1E]">
                          {language === "en" ? "01" : "٠١"}
                        </span>
                      </span>
                      <div className="flex flex-col items-start justify-start gap-3">
                        <h3 className="text-[#D2D2D2] text-[20px] font-bold leading-7">
                          {language === "en"
                            ? "Download Our Mobile App"
                            : "قم بتنزيل تطبيقنا المحمول"}
                        </h3>
                        <p className="text-[#D2D2D2] text-[16px] font-normal leading-5">
                          {language === "en"
                            ? " Download our mobile app to easily access your booking information."
                            : "قم بتنزيل تطبيقنا المحمول للوصول بسهولة إلى معلومات حجزك."}
                        </p>
                        <div className="w-full flex  items-start justify-start gap-4 mt-2">
                          <a
                            href="#"
                            className="w-[100px] h-[30px] cursor-pointer"
                          >
                            <img
                              src={GooglePlayPic}
                              alt="Google Play Logo"
                              srcSet="Google Play Logo"
                            />
                          </a>
                          <a
                            href="#"
                            className="w-[100px] h-[30px] cursor-pointer"
                          >
                            <img
                              className="w-[100px] h-[30px] cursor-pointer"
                              src={AppStore}
                              alt="Apple Store Logo"
                              srcSet="Apple Store Logo"
                            />
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="mb-10 ms-[5rem]">
                      <span className="absolute flex items-center justify-center w-[2rem] h-[2rem]  rounded-full -start-3 ring-8 ring-[#1E1E1E] ">
                        <span className="text-[#D2D2D2] text-[46px] font-semibold leading-10 bg-[#1E1E1E]">
                          {language === "en" ? "02" : "٠٢"}
                        </span>
                      </span>
                      <div className="flex flex-col items-start justify-start gap-3">
                        <h3 className="text-[#D2D2D2] text-[20px] font-bold leading-7">
                          {language === "en"
                            ? "Get To Kona"
                            : "الوصول إلى كونا"}
                        </h3>
                        <p className="text-[#D2D2D2] text-[16px] font-normal leading-5">
                          {language === "en"
                            ? "Don’t no how to get here, get directions."
                            : "لا تعرف كيف تصل إلى هنا، احصل على الاتجاهات."}
                        </p>

                        <button className="flex items-start justify-start gap-3 mt-2">
                          <span className="text-[#D2D2D2] text-[16px] font-normal leading-6">
                            {" "}
                            {language === "en"
                              ? "Get Directions"
                              : "احصل على الاتجاهات"}
                          </span>
                          <svg
                            className="self-center rtl:rotate-180"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M15.1478 11.7326C15.3675 11.9523 15.3675 12.3084 15.1478 12.5281L9.41291 18.2629C9.19323 18.4826 8.83713 18.4826 8.61746 18.2629L8.35226 17.9978C8.13258 17.7781 8.13258 17.4219 8.35226 17.2023L13.4242 12.1303L8.35226 7.05839C8.13258 6.83871 8.13258 6.48261 8.35226 6.26294L8.61746 5.99774C8.83713 5.77806 9.19323 5.77806 9.41291 5.99774L15.1478 11.7326Z"
                              fill="#D2D2D2"
                            />
                          </svg>
                        </button>
                      </div>
                    </li>
                    <li className="mb-10 ms-[5rem]">
                      <span className="absolute flex items-center justify-center w-[2rem] h-[2rem]  rounded-full -start-3 ring-8 ring-[#1E1E1E] ">
                        <span className="text-[#D2D2D2] text-[46px] font-semibold leading-10 bg-[#1E1E1E]">
                          {language === "en" ? "03" : "٠٣"}
                        </span>
                      </span>
                      <div className="flex flex-col items-start justify-start gap-3">
                        <h3 className="text-[#D2D2D2] text-[20px] font-bold leading-7">
                          {language === "en"
                            ? "Show Your Booking QR Code to Security"
                            : "أظهر رمز الاستجابة السريعة للحجز للأمان"}
                        </h3>
                        <p className="text-[#D2D2D2] text-[16px] font-normal leading-5">
                          {language === "en"
                            ? `You can access your booking QR code through our mobile
                          app or here from the website.`
                            : `يمكنك الوصول إلى رمز الاستجابة السريعة لحجزك من خلال تطبيقنا المحمول أو هنا من الموقع الإلكتروني.`}
                        </p>
                      </div>
                    </li>
                    <li className="mb-10 ms-[5rem]">
                      <span className="absolute flex items-center justify-center w-[2rem] h-[2rem]  rounded-full -start-3 ring-8 ring-[#1E1E1E] ">
                        <span className="text-[#D2D2D2] text-[46px] font-semibold leading-10 bg-[#1E1E1E]">
                          {language === "en" ? "04" : "٠٤"}
                        </span>
                      </span>
                      <div className="flex flex-col items-start justify-start gap-3">
                        <h3 className="text-[#D2D2D2] text-[20px] font-bold leading-7">
                          {language === "en"
                            ? "Enjoy Your Day!"
                            : "استمتع بيومك!"}
                        </h3>
                        <p className="text-[#D2D2D2] text-[16px] font-normal leading-5">
                          {language === "en"
                            ? `Relax and enjoy your booking! you can also book extra
                          activities through our mobile app when you are there.`
                            : `استرخ واستمتع بحجزك! يمكنك أيضًا حجز أنشطة إضافية من خلال تطبيقنا المحمول عندما تكون هناك.`}
                        </p>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* Club rules */}
          <div className="w-full h-auto py-[4rem]">
            <div className="container mx-auto">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[4rem]">
                <div className="w-full flex flex-col items-start justify-start gap-4">
                  <h4 className="text-[#2A2A2A] text-[28px] md:text-[32px] lg:text-[48px] font-bold leading-10 mb-4">
                    {language === "en" ? "Club Rules" : "قواعد النادي"}
                  </h4>
                  <p className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                    {language === "en"
                      ? "Welcome to Kona Beach Club! We want to ensure a safe and enjoyable experience for all our beachgoers. To maintain aharmonious atmosphere, we have a few simple rules."
                      : `مرحبًا بكم في نادي شاطئ كونا! نريد أن نضمن آمنة و
                  تجربة ممتعة لجميع رواد الشاطئ لدينا. للحفاظ على أ
                  جو متناغم، لدينا بعض القواعد البسيطة.`}
                  </p>
                  <p className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                    {language === "en"
                      ? `Failure to adhere to these rules may result in a warning or,
                    in severe cases, expulsion from the beach club without
                    refund. We appreciate your cooperation in making Sandy
                    Shores Beach Club a wonderful and safe place to enjoy the
                    sun, sand, and sea! Have a fantastic time!`
                      : `
                      قد يؤدي عدم الالتزام بهذه القواعد إلى تحذير أو،
                     وفي الحالات الشديدة، الطرد من نادي الشاطئ بدون
                     استرداد. نحن نقدر تعاونك في صنع ساندي
                     يعد Shores Beach Club مكانًا رائعًا وآمنًا للاستمتاع
                     الشمس والرمال والبحر! أتمنى لك وقتا رائعا!
                      `}
                  </p>
                  <p className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                    {language === "en"
                      ? `If you have questions feel free to contact us:`
                      : `إن كان لديك اسئلة، لا تتردد بالاتصال بنا:`}
                  </p>
                </div>
                <div className="w-full">
                  <ol className="list-decimal flex flex-col items-start justify-start gap-2 ml-[15px]">
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>Beach Attire:</strong> Proper beachwear is
                        required at all times. Please wear appropriate swimwear
                        or cover-ups while inside the club premises. Nudity is
                        strictly prohibited.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        ملابس الشاطئ:<strong> ملابس البحر</strong>
                        المناسبة هي المطلوبة في جميع الأوقات. يرجى ارتداء ملابس
                        السباحة المناسبة أو التستر أثناء التواجد داخل مباني
                        النادي. العري هو يمنع منعا باتا.
                      </li>
                    )}

                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> Sunscreen:</strong> For your safety, we
                        encourage everyone to apply sunscreen regularly.
                        Sunburns are no fun!
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> واقي الشمس: </strong>
                        من أجل سلامتك، نشجعك على ذلك الجميع لتطبيق واقي الشمس
                        بانتظام. حروق الشمس لا هزار!
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> Beach Access:</strong> Beach access is limited
                        to club members and their guests. Please present your
                        membership card at the entrance.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> الوصول إلى الشاطئ: </strong>
                        يقتصر الوصول إلى الشاطئ على أعضاء النادي وضيوفهم. يرجى
                        تقديم الخاص بك بطاقة العضوية عند المدخل.
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>No Smoking:</strong> Smoking is prohibited in
                        all designated beach areas. Please use the designated
                        smoking zones if you wish to smoke.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>ممنوع التدخين: </strong>
                        التدخين ممنوع في جميع مناطق الشاطئ المخصصة. يرجى استخدام
                        المعينة مناطق التدخين إذا كنت ترغب في التدخين.
                      </li>
                    )}

                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> Respect the Environment:</strong> Help us keep
                        the beach clean and beautiful by disposing of trash
                        properly. Use the provided trash bins and recycling
                        containers.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> احترم البيئة: </strong>
                        ساعدنا في الحفاظ عليها الشاطئ نظيف وجميل من خلال التخلص
                        من القمامة على وجه صحيح. استخدم صناديق القمامة المتوفرة
                        وإعادة التدوير حاويات.
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>No Loud Music:</strong> While we encourage
                        having fun, please keep the volume of your music at a
                        considerate level, so it doesn't disturb other
                        beachgoers.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>لا توجد موسيقى صاخبة:</strong>
                        بينما نشجع على ذلك ممتع، يرجى الحفاظ على مستوى صوت
                        الموسيقى الخاص بك في الاعتبار المستوى، حتى لا يزعج
                        مرتادي الشاطئ الآخرين.
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>No Pets:</strong> For the safety and comfort of
                        all our guests, pets are not allowed on the beach club
                        premises.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>ممنوع الحيوانات الأليفة: </strong>
                        من أجل السلامة والراحة لجميع ضيوفنا، لا يسمح بالحيوانات
                        الأليفة في نادي الشاطئ مقدمات.
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>Water Activities:</strong> When participating in
                        water sports or activities, follow the safety guidelines
                        and instructions provided by our staff.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>أنشطة مائية:</strong> عند المشاركة في الرياضات
                        المائية أو الأنشطة، اتبع الإرشادات الأمان والتعليمات
                        المقدمة من قبل موظفينا.
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>No Littering:</strong> Be mindful of the beach
                        and marine life. Do not throw anything into the ocean,
                        and avoid disturbing natural habitats.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>عدم التلوث:</strong> تكون على بالشاطئ و الحياة
                        البحرية. لا تلقي أي شيء في المحيط، و تجنب إزعاج المواطن
                        الطبيعية.
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> Respect Others:</strong> Treat all fellow
                        beachgoers, staff, and visitors with kindness and
                        respect. Any form of harassment or aggressive behavior
                        will not be tolerated.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>احترم الآخرين:</strong> عامل جميع الزوار الآخرين
                        الشاطئ، الموظفين، والزوار بلطف واحترام. أي شكل من أشكال
                        التحرش أو السلوك العدواني لن يتم التسامح معه.
                      </li>
                    )}
                    {language === "en" ? (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong> First Aid:</strong> In case of any emergencies
                        or injuries, immediately seek assistance from our
                        trained staff.
                      </li>
                    ) : (
                      <li className="text-[#2A2A2A] text-[16px] font-normal leading-6">
                        <strong>الإسعافات الأولية:</strong> في حالة حدوث أي
                        حالات طوارئ أو إصابات، اطلب على الفور المساعدة من
                        موظفينا المدربين.
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PaymentSuccess;
