import React from "react";
import SearchInput from "../inputs/SearchInput";
import { useLanguage } from "../../contexts/LanguageContext";
import ButtonSecondary from "../buttons/ButtonSecondary";
import ButtonWhite from "../buttons/ButtonWhite";
import { Link } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

const CtaAction = ({ data }) => {
  console.log("🚀 ~ CtaAction ~ data:", data);
  const { user } = useUser();
  const navigate = useNavigate();
  const { language } = useLanguage();

  const handleNavigation = (button_type, page_slug) => {
    console.log("🚀 ~ handleNavigation ~ page_slug:", page_slug);
    console.log("🚀 ~ handleNavigation ~ button_type:", button_type);
    if (button_type === "bookings") {
      navigate("/booking");
    } else if (button_type === "page") {
      navigate(`/${page_slug}`);
    }
  };

  return (
    <>
      <section
        style={{
          backgroundImage: `url(${data?.image})`,
        }}
        className="cta-action h-auto lg:h-[400px] py-[64px] "
      >
        <div className="container mx-auto">
          <div className="w-full flex flex-col md:flex-row items-start justify-between gap-[2rem] md:gap-2 ">
            <div className="flex flex-col items-start justify-start gap-4 w-full md:w-[55%]">
              <h2 className="text-white font-bold text-[24px] md:text-[32px] lg:text-[40px] leading-[35px] md:leading-[45px] lg:leading-[60px] text-center md:text-start">
                {data?.title[language]}
              </h2>

              <p className="text-[14px] md:text-[16px] text-white font-normal leading-[26px] text-center md:text-start">
                {data?.description[language]}
              </p>
            </div>
            {data?.has_button === "yes" && (
              <div className=" self-center md:self-start">
              
                  {/* <SearchInput className='cursor-pointer' placeholder={data?.button_text[language]} width='275px' /> */}
                  <ButtonWhite
                    onClick={() => {
                      if (user) {
                        handleNavigation(
                          data.button_type,
                          data.page_slug
                        );
                      } else {
                        navigate("/contact-us");
                      }
                    }}
                    style={{
                      padding: "0.8rem 1.6rem",
                    }}
                  >
                    {user
                      ? data?.button_text[language]
                      : language === "en"
                      ? "Book a Tour"
                      : "احجز جولة"}
                  </ButtonWhite>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CtaAction;
