import React from 'react'
import arrowDown from '../../assets/arrow-down.svg'
const CustomArrow = () => {
    return (
        <>
            <span className='self-center'>
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.3977 7.16292C7.17802 7.38259 6.82192 7.38259 6.60225 7.16292L0.867387 1.42804C0.64772 1.20837 0.64772 0.852268 0.867387 0.632593L1.13256 0.367393C1.35222 0.147718 1.70838 0.147718 1.92805 0.367393L6.99997 5.43934L12.0719 0.367393C12.2916 0.147718 12.6477 0.147718 12.8674 0.367393L13.1326 0.632593C13.3522 0.852268 13.3522 1.20837 13.1326 1.42804L7.3977 7.16292Z" fill="black" />
                </svg>

            </span>

        </>
    )
}

export default CustomArrow
