import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { useUser } from "../../contexts/UserContext";
import axiosInstance from "../../axios/axiosInstance";
import creditIcon from "../../assets/payment-icons/credit-icon.svg";
import creditIconActive from "../../assets/payment-icons/credit-icon-active.svg";
import appleIcon from "../../assets/payment-icons/apple-icon.svg";
import appleIconActive from "../../assets/payment-icons/apple-icon-active.svg";
import madaIcon from "../../assets/payment-icons/mada-icon.svg";
import madaIconActive from "../../assets/payment-icons/mada-icon-active.svg";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import { notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import { ThreeDots } from "react-loader-spinner";

const UserSplitPayment = () => {
  const [userId, setUserId] = useState(null);
  const location = useLocation();

  const { user } = useUser();
  const { language, translate } = useLanguage();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([
    {
      id: 1,
      name: {
        en: "creditcard",
        ar: "بطاقة الائتمان",
      },
      icon: creditIcon,
      activeIcon: creditIconActive,
    },
    {
      id: 2,
      name: {
        en: "applepay",
        ar: "أبل باي",
      },
      icon: appleIcon,
      activeIcon: appleIconActive,
    },
    {
      id: 3,
      name: {
        en: "mada",
        ar: "مادا",
      },
      icon: madaIcon,
      activeIcon: madaIconActive,
    },
  ]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [userData, setUserData] = useState(null);
  const activities = userData?.activities_summery;
  const meals = userData?.food_summery;

  const [isUpdating, setIsUpdating] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    termsAndConditions: false,
    cancellationPolicy: false,
    responsibilityAcknowledge: false,
    waiverAgreement: false,
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      // message: `${
      //   type === "success"
      //     ? language === "en"
      //       ? "Booking Successful! Welcome Aboard"
      //       : "حجز ناجح! مرحبا بك"
      //     : language === "en"
      //     ? `Booking Failed. Please try again`
      //     : `فشل الحجز. يرجى المحاولة م`
      // }`,
      message: errorMessage,
      description: null,
      placement: "top",
      className: "mb-0",
      duration: 3,
    });
  };

  // i wanna to make a useEffect to first when this page is loaded to remove any token/user from local storage
  // and then when the user click on the payment button to save the token in the local storage
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }, []);

  // GET USER DATA
  const getUserData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/booking/user-data/${userId}`);
      console.log("response from getting user data", response);
      if (response.status === 200) {
        setUserData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const allCheckboxesChecked = () => {
    return Object.values(checkboxStates).every((checked) => checked);
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxStates((prevState) => ({ ...prevState, [name]: checked }));
  };

  const getPaymentIcon = (method) => {
    return selectedPaymentMethod === method.name
      ? method.activeIcon
      : method.icon;
  };

  useEffect(() => {
    // Function to parse query parameters
    const searchParams = new URLSearchParams(location.search);
    const userID = searchParams.get("user_id");
    setUserId(userID);
  }, [location, navigate]);

  const handlePaymentMethodSelection = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setPaymentMethod(paymentMethod?.en);
  };

  // Handler for when the payment button is clicked
  const handlePayment = async () => {
    try {
      const response = await axiosInstance.post(
        `/booking/complete-booking-user-payment-data/${userId}`,
        {
          payment_method: paymentMethod,
        }
      );
      console.log(response);
      if (response.status === 200) {
        window.open(response.data.data.payment_url, "_blank");
        localStorage.setItem("token", response?.data?.data?.token);
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon("error", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }
  }, [userId]);

  console.log("User ID from Split Payment", userId);
  console.log("Selected Payment Method", paymentMethod);

  return (
    <>
      {contextHolder}
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto">
          <div className="container mx-auto">
            <h1 className="text-[#2A2A2A] text-[18px] xs:text-[16px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-semibold leading-7 mb-4">
              {language === "en" ? "Payment" : "الدفع"}
            </h1>

            <div className="w-full flex items-start justify-start gap-1">
              <div className="w-[35px] h-[22px] sm:w-[19px] sm:h-[22px] ">
                <svg
                  className="w-full h-full self-start sm:self-center"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.6671 11.3337C9.23981 11.3337 8.83002 11.1932 8.52787 10.9431C8.22573 10.6931 8.05599 10.3539 8.05599 10.0003C8.05599 9.26033 8.77293 8.66699 9.6671 8.66699C10.0944 8.66699 10.5042 8.80747 10.8063 9.05752C11.1085 9.30757 11.2782 9.6467 11.2782 10.0003C11.2782 10.3539 11.1085 10.6931 10.8063 10.9431C10.5042 11.1932 10.0944 11.3337 9.6671 11.3337ZM14.5004 13.3337V6.66699H4.83377V13.3337H14.5004ZM14.5004 5.33366C14.9277 5.33366 15.3375 5.47413 15.6397 5.72418C15.9418 5.97423 16.1115 6.31337 16.1115 6.66699V13.3337C16.1115 13.6873 15.9418 14.0264 15.6397 14.2765C15.3375 14.5265 14.9277 14.667 14.5004 14.667H4.83377C4.40647 14.667 3.99668 14.5265 3.69454 14.2765C3.3924 14.0264 3.22266 13.6873 3.22266 13.3337V6.66699C3.22266 5.92699 3.9396 5.33366 4.83377 5.33366H5.63932V4.00033C5.63932 3.11627 6.06368 2.26842 6.81903 1.6433C7.57439 1.01818 8.59887 0.666992 9.6671 0.666992C10.196 0.666992 10.7198 0.753211 11.2085 0.920727C11.6971 1.08824 12.1412 1.33377 12.5152 1.6433C12.8892 1.95283 13.1859 2.3203 13.3883 2.72471C13.5907 3.12913 13.6949 3.56259 13.6949 4.00033V5.33366H14.5004ZM9.6671 2.00033C9.02616 2.00033 8.41147 2.21104 7.95826 2.58611C7.50505 2.96118 7.25043 3.46989 7.25043 4.00033V5.33366H12.0838V4.00033C12.0838 3.46989 11.8292 2.96118 11.3759 2.58611C10.9227 2.21104 10.308 2.00033 9.6671 2.00033Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <p className="text-[14px] md:text-[16px] text-[#565656] leading-4  font-normal self-center">
                {language === "en"
                  ? "This form is encrypted and your information is always guaranteed secure."
                  : "هذا النموذج مشفر ومعلوماتك دائمًا مضمونة بأمان."}
              </p>
            </div>

            <div className="relative kn-payment-grid xs:my-[14px] my-[40px]">
              {/* left */}
              <div className="w-full flex flex-col items-start justify-start gap-[24px]">
                {/* select payment method */}
                <div className="w-full flex flex-col items-start justify-start gap-4">
                  <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6 ">
                    {language === "en"
                      ? "Select Payment Method?"
                      : "اختر طريقة الدفع"}
                  </h3>
                  <div className="w-full flex flex-col items-start justify-start gap-4">
                    {paymentMethods.map((type, index) => (
                      <div
                        key={index}
                        className={`kn-payment-type ${
                          selectedPaymentMethod === type.name
                            ? "kn-payment-type-active"
                            : ""
                        } `}
                      >
                        <label key={index} className={`kn-payment-type-radio `}>
                          <div className="flex items-start justify-start gap-3">
                            {/* to add icon */}
                            <img
                              className="self-center"
                              src={getPaymentIcon(type)}
                              alt={`${type.name} icon`}
                            />

                            <span className="self-center">
                              {type?.name[language]}
                            </span>
                          </div>
                          <input
                            className="self-center"
                            type="radio"
                            name={`paymentType${type.id}`}
                            value={type}
                            checked={
                              selectedPaymentMethod === type.name ? true : false
                            }
                            onChange={() =>
                              handlePaymentMethodSelection(type.name)
                            }
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-[#DADADA] my-[0.5rem]"></div>
                {/* credit cards payment details */}

                {/* checklist */}
                <div className="w-full flex flex-col items-start justify-start gap-3 mb-[1.6rem]">
                  <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                    <input
                      className="kn-payment-checkbox self-center"
                      type="checkbox"
                      checked={checkboxStates.termsAndConditions}
                      name="termsAndConditions"
                      onChange={handleCheckboxChange}
                    />

                    <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                      {language === "en" ? "I agree to the" : "أوافق على "}
                      <button
                        onClick={() => navigate("/terms-and-conditions")}
                        className="underline  font-semibold"
                      >
                        {" "}
                        {language === "en"
                          ? "Terms & Conditions"
                          : "الشروط والأحكام"}
                      </button>
                    </span>
                  </label>
                  <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                    <input
                      className="kn-payment-checkbox self-center"
                      type="checkbox"
                      checked={checkboxStates.cancellationPolicy}
                      name="cancellationPolicy"
                      onChange={handleCheckboxChange}
                    />

                    <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                      {language === "en" ? "I agree to the" : "أوافق على "}
                      <button
                        className="underline  font-semibold"
                        onClick={() => navigate("/cancellation-policy")}
                      >
                        {" "}
                        {language === "en"
                          ? "Cancellation Policy"
                          : "سياسة الإلغاء"}
                      </button>
                    </span>
                  </label>
                  <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                    <input
                      className="kn-payment-checkbox self-center"
                      type="checkbox"
                      checked={checkboxStates.responsibilityAcknowledge}
                      name="responsibilityAcknowledge"
                      onChange={handleCheckboxChange}
                    />

                    <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                      {language === "en"
                        ? "I understand that kona will not be held responsible for any accidents that may occur."
                        : "أنا أفهم أن كونا لن تكون مسؤولة عن أي حوادث قد تحدث."}
                    </span>
                  </label>
                  <label className="kn-custom-checkbox flex flex-row items-start justify-start gap-3">
                    <input
                      className="kn-payment-checkbox self-center"
                      type="checkbox"
                      checked={checkboxStates.waiverAgreement}
                      name="waiverAgreement"
                      onChange={handleCheckboxChange}
                    />

                    <span className="cursor-pointer flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                      {language === "en"
                        ? "I agree to sign a waiver."
                        : "أوافق على توقيع إعفاء."}
                    </span>
                  </label>
                </div>

                {/* Procceed */}
                <div className="w-full flex items-center md:items-start justify-center md:justify-start my-[1rem]">
                  <ButtonSecondary
                    className={`xs:w-[200px] md:w-[250px] lg:w-[300px]`}
                    onClick={handlePayment}
                    disabled={!allCheckboxesChecked()}
                  >
                    Pay
                  </ButtonSecondary>
                </div>
              </div>

              {/* right  */}

              {userData && (
                <section className="w-full">
                  <div className="w-full flex items-start justify-start gap-2">
                    <div className="self-center w-[20px] h-[20px] overflow-hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="#2A2A2A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.25"
                          d="M6.667 1.667v2.5M13.333 1.667v2.5M2.917 7.575h14.167M17.5 7.083v7.083c0 2.5-1.25 4.167-4.167 4.167H6.667c-2.917 0-4.167-1.666-4.167-4.166V7.082c0-2.5 1.25-4.167 4.167-4.167h6.666c2.917 0 4.167 1.667 4.167 4.167z"
                        ></path>
                        <path
                          stroke="#2A2A2A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.667"
                          d="M13.08 11.417h.007M13.08 13.917h.007M9.995 11.417h.008M9.995 13.917h.008M6.912 11.417h.008M6.912 13.917h.008"
                        ></path>
                      </svg>
                    </div>
                    <h5 className="text-[#2A2A2A] text-[16px] font-semibold leading-7 tracking-[0px]">
                      {language === "en"
                        ? "Booking Information"
                        : "معلومات الحجز"}
                    </h5>
                  </div>

                  <div className="kn-payment-summary overflow-hidden">
                    {/* Header */}
                    <div className="w-full flex items-start justify-between mb-[20px]">
                      <div className="kn-order-summary">
                        <div className="w-[16px] h-[16px] self-center overflow-hidden">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              stroke="#2A2A2A"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.2"
                              d="M8 8.5a3.333 3.333 0 100-6.667A3.333 3.333 0 008 8.5zM13.727 15.167c0-2.58-2.567-4.667-5.727-4.667s-5.727 2.087-5.727 4.667"
                            ></path>
                          </svg>
                        </div>
                        <p>{userData?.user_name}</p>
                      </div>
                    </div>
                    {/* Sports Activities */}
                    {activities.length > 0 && (
                      <div className="relative w-full flex flex-col items-start justify-start gap-[18px]  pb-4">
                        <h4 className="text-[#1D1D1D] text-[16px] font-semibold  leading-6">
                          {language === "en"
                            ? "Sports Activities"
                            : "أنشطة رياضية"}
                        </h4>
                        {/* activity item */}
                        {activities.map((activity, index) => (
                          <div
                            key={uuidv4()}
                            className="w-full flex items-start justify-start gap-[10px] mb-4"
                          >
                            <div className="w-[90px] h-[75px] overflow-hidden rounded-[8px]">
                              <img
                                src={activity?.image}
                                className="w-full h-full object-cover"
                                alt=""
                                srcSet=""
                              />
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                              <h1 className="text-[#1D1D1D] text-[16px] font-semibold leading-5">
                                {activity?.name[language]} -
                                <span className="text-[#1D1D1D] text-[14px] font-medium leading-5">
                                  {" "}
                                  x{activity?.quantity}
                                </span>
                              </h1>

                              <div className="flex items-start justify-start gap-[16px]">
                                <h6 className="text-[#D03C32] font-semibold text-[14px] leading-5">
                                  {activity?.price_after_discount *
                                    activity?.quantity}{" "}
                                  {translate("currency")}
                                </h6>
                                <div className="flex flex-col items-start justify-start">
                                  {activity?.time_slot && (
                                    <div className="flex items-start justify-start gap-2">
                                      <svg
                                        className="self-center"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        fill="none"
                                        viewBox="0 0 17 17"
                                      >
                                        <path
                                          fill="#1D1D1D"
                                          d="M8.43 14.8a6.545 6.545 0 110-13.09 6.545 6.545 0 010 13.09zm0-1.308a5.236 5.236 0 100-10.473 5.236 5.236 0 000 10.473zm.654-5.236h2.619v1.309H7.775V4.983h1.31v3.273z"
                                        ></path>
                                      </svg>

                                      <span className="text-black text-[14px] font-medium  self-center">
                                        {activity?.time_slot}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="absolute w-[100rem] h-[2px] bg-[#F3F3F3] left-[-50%] bottom-0"></div>
                      </div>
                    )}

                    {/* Meals  */}
                    {meals.length > 0 && (
                      <div className="relative w-full flex flex-col items-start justify-start gap-[18px]  pt-4">
                        <h4 className="text-[#1D1D1D] text-[16px] font-semibold  leading-6">
                          {language === "en" ? "Meals" : "وجبات"}
                        </h4>
                        {/* Meal item */}
                        {meals.map((meal, index) => (
                          <div
                            key={uuidv4()}
                            className="w-full flex items-start justify-start gap-[10px] mb-4"
                          >
                            <div className="w-[90px] h-[75px] overflow-hidden rounded-[8px]">
                              <img
                                src={meal?.image}
                                className="w-full h-full object-cover"
                                alt=""
                                srcSet=""
                              />
                            </div>
                            <div className="flex flex-col items-start justify-start gap-[10px]">
                              <h1 className="text-[#1D1D1D] text-[16px] font-semibold leading-5">
                                {meal?.name[language]}{" "}
                                <span className="text-[#1D1D1D] text-[14px] font-medium leading-5">
                                  - x{meal?.quantity}
                                </span>
                              </h1>

                              <div className="flex items-start justify-start gap-[16px]">
                                <h6 className="text-[#D03C32] font-semibold text-[14px] leading-5">
                                  {meal?.price_after_discount * meal?.quantity}{" "}
                                  {translate("currency")}
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Payment Summary */}
                  <div className="kn-payment-summary">
                    <h6 className="text-[#2A2A2A] text-[20px] leading-6 font-medium mb-[28px]">
                      {language === "en" ? "Payment Summery" : "ملخص الدفع"}
                    </h6>
                    {/* booking info - payment */}
                    <div className="w-full flex flex-col items-start justify-start gap-[23px]">
                      <div className="kn-total-payment-summary-seperate">
                        <h6 className="text-[#2A2A2A] text-[14px] font-semibold leading-5 self-center">
                          {language === "en" ? " Total" : "المجموع"}
                        </h6>
                        <p className="text-[#D03C32] text-[20px] font-semibold leading-6 self-center">
                          {userData?.total} {translate("currency")}
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default UserSplitPayment;
