import React, { createContext, useContext, useState, useEffect } from "react";
import { useUser } from "./UserContext";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../axios/axiosInstance";

const BookingContext = createContext();

export const useBooking = () => useContext(BookingContext);

export const BookingProvider = ({ children }) => {
  const { user } = useUser();

  // Function to load the initial state from localStorage
  const loadInitialState = () => {
    const savedBooking = localStorage.getItem("booking");
    return savedBooking
      ? JSON.parse(savedBooking)
      : {
          booking_platform: "Web",
          booking_date: null,
          coupon_code: null,
          payment_type: "pay in full",
          payment_method: null,
          booking_users: [],
          booking_dependents: [],
        };
  };

  // function to initialize default booking state
  const clearBookingStateDefault = () => {
    setBooking({
      booking_platform: "Web",
      booking_date: null,
      coupon_code: null,
      payment_type: "pay in full",
      payment_method: null,
      booking_users: [],
      booking_dependents: [],
    });
    setBookingUsers([]);
    setBookingDependents([]);
    setBookingSummary(null);
    set_coupon_code(null);
    localStorage.removeItem("booking");
  };

  const [booking, setBooking] = useState(loadInitialState());
  const [bookingUsers, setBookingUsers] = useState([]);
  const [bookingDependents, setBookingDependents] = useState([]);
  const [bookingSummary, setBookingSummary] = useState(null);
  const [coupon_code, set_coupon_code] = useState(null);

  const [guestPrice, setGuestPrice] = useState(null);
  const [dependentPrice, setDependentPrice] = useState(null);
  const [selectedUsersForPayment, setSelectedUsersForPayment] = useState([0]);

  const setPaymentMethod = (method) => {
    setBooking((prevState) => {
      console.log("Updating payment method:", method);
      return {
        ...prevState,
        payment_method: method,
      };
    });
  };

  const handleUserCheckboxToggle = (index, isChecked) => {
    setBooking((prevState) => {
      const updatedUsers = prevState.booking_users.map((user, i) => {
        if (i === index) {
          return { ...user, payment_status: isChecked ? "paid" : "not paid" };
        }
        return user;
      });

      return { ...prevState, booking_users: updatedUsers };
    });

    if (isChecked) {
      setSelectedUsersForPayment((prevSelected) => [...prevSelected, index]);
    } else {
      setSelectedUsersForPayment((prevSelected) =>
        prevSelected.filter((i) => i !== index)
      );
    }
  };

  const clearBookingState = () => {
    setBooking({
      booking_platform: "Web",
      booking_date: null,
      coupon_code: null,
      payment_type: "pay in full",
      payment_method: null,
      booking_users: [],
      booking_dependents: [],
    });
    setBookingUsers([]);
    setBookingDependents([]);
    setBookingSummary(null);
    set_coupon_code(null);
    localStorage.removeItem("booking");
  };
  useEffect(() => {
    localStorage.setItem("booking", JSON.stringify(booking));
  }, [booking]);

  const updateBookingSummary = async () => {
    if (!booking || !booking.booking_date) {
      console.log(
        "Booking date is not set or booking is null/undefined, skipping summary update."
      );
      return;
    }
    if (!booking.booking_users || booking.booking_users.length === 0) {
      console.log("The booking users field is required.");
      return;
    }
    const coupon_code = booking.coupon_code;
    const summaryObject = transformBookingForSummary();

    const filteredUsers = summaryObject?.booking_users?.filter(
      (user) =>
        user?.name?.trim() !== "" &&
        user?.email?.trim() !== "" &&
        user?.phone?.trim() !== ""
    );

    const filteredDependents = summaryObject?.booking_dependents?.filter(
      (dependent) => dependent?.name && dependent?.name.trim() !== ""
    );

    try {
      const response = await axiosInstance.post(`/booking/summery`, {
        booking_platform: "Web",
        booking_date: booking?.booking_date,
        payment_type: "pay in full",
        coupon_code: coupon_code || null,
        booking_users: filteredUsers,
        booking_dependents: filteredDependents,
      });
      if (response.status === 200) {
        setBookingSummary(response.data.data);
      }
    } catch (error) {
      console.error(error);
      setBookingSummary(null);
    }
  };
  useEffect(() => {
    updateBookingSummary();
  }, []);

  const getPersonByIndexAndType = (index, type) => {
    if (type !== "adult" && type !== "dependent") {
      console.error("Invalid type specified. Must be 'adult' or 'dependent'.");
      return null;
    }

    const arrayToSearch =
      type === "adult" ? booking.booking_users : booking.booking_dependents;

    if (index < 0 || index >= arrayToSearch.length) {
      console.error("Index out of bounds");
      return null;
    }

    return arrayToSearch[index];
  };

  const updateBookingDate = (date) => {
    setBooking({
      ...booking,
      booking_date: date,
    });
  };

  const updateCouponCode = (code) => {
    console.log("coupon code", code);
    set_coupon_code(code);
    setBooking((prevState) => ({
      ...prevState,
      coupon_code: code,
    }));
    console.log("booking", booking);
  };

  const getCouponCode = () => {
    return coupon_code;
  };

  const updatePaymentType = (type) => {
    setBooking((prevState) => {
      const updatedState = { ...prevState, payment_type: type };

      const updatedUsers = prevState.booking_users.map((user, index) => {
        if (type === "pay in full") {
          return { ...user, payment_status: "paid" };
        } else {
          return { ...user, payment_status: index === 0 ? "paid" : "not paid" };
        }
      });

      updatedState.booking_users = updatedUsers;

      return updatedState;
    });

    if (type === "split") {
      setSelectedUsersForPayment([0]);
    } else {
      setSelectedUsersForPayment([]);
    }
  };

  const initializeBookingUsers = (adultQuantity) => {
    const users = [];
    for (let i = 0; i < adultQuantity; i++) {
      const paymentStatus = i === 0 ? "paid" : "not paid";

      if (i === 0 && user) {
        users.push({
          name: user.name || "",
          email: user.email || "",
          phone: user.phone || "",
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          type: "adult",
          id: uuidv4(),
        });
      } else {
        users.push({
          name: "",
          email: "",
          phone: "",
          payment_status: paymentStatus,
          booking_activities: [],
          booking_food: [],
          id: uuidv4(),
          type: "adult",
        });
      }
    }

    setBooking((prevState) => ({
      ...prevState,
      booking_users: users,
    }));
  };

  const addBookingUser = () => {
    setBookingUsers([
      ...bookingUsers,
      {
        name: "",
        email: "",
        phone: "",
        payment_status: "not paid",
        booking_activities: [],
        booking_food: [],
        id: uuidv4(),
        type: "adult",
      },
    ]);
    setBooking((prevState) => ({
      ...prevState,
      booking_users: [
        ...prevState.booking_users,
        {
          name: "",
          email: "",
          phone: "",
          payment_status: "not paid",
          booking_activities: [],
          booking_food: [],
          id: uuidv4(),
          type: "adult",
        },
      ],
    }));
  };

  const removeBookingUser = (index) => {
    setBooking((prevState) => ({
      ...prevState,
      booking_users: prevState?.booking_users?.filter((_, i) => i !== index),
    }));
    setBookingUsers(bookingUsers.filter((_, i) => i !== index));
  };

  const updateCurrentUser = (index, updatedUser) => {
    setBookingUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, ...updatedUser } : user
      )
    );
    setBooking((prevState) => ({
      ...prevState,
      booking_users: prevState.booking_users.map((user, i) =>
        i === index ? { ...user, ...updatedUser } : user
      ),
    }));
  };

  const initializeBookingDependents = (dependentQuantity) => {
    const dependents = [];
    for (let i = 0; i < dependentQuantity; i++) {
      dependents.push({
        name: "",
        birthdate: "",
        gender: "",
        relationship: "",
        booking_activities: [],
        booking_food: [],
        id: uuidv4(),
        type: "dependent",
      });
    }

    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: dependents,
    }));
    setBookingDependents(dependents);
  };

  const addBookingDependent = () => {
    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: [
        ...prevState.booking_dependents,
        {
          name: "",
          birthdate: "",
          gender: "",
          relationship: "",
          booking_activities: [],
          booking_food: [],
          id: uuidv4(),
          type: "dependent",
        },
      ],
    }));
    setBookingDependents([
      ...bookingDependents,
      {
        name: "",
        birthdate: "",
        gender: "",
        relationship: "",
        booking_activities: [],
        booking_food: [],
        id: uuidv4(),
        type: "dependent",
      },
    ]);
  };

  const removeBookingDependent = (index) => {
    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: prevState?.booking_dependents?.filter(
        (_, i) => i !== index
      ),
    }));
    setBookingDependents(bookingDependents.filter((_, i) => i !== index));
  };

  const updateBookingDependent = (index, updatedDependent) => {
    setBookingDependents((prevDependents) =>
      prevDependents.map((dependent, i) =>
        i === index ? { ...dependent, ...updatedDependent } : dependent
      )
    );
    setBooking((prevState) => ({
      ...prevState,
      booking_dependents: prevState.booking_dependents.map((dependent, i) =>
        i === index ? { ...dependent, ...updatedDependent } : dependent
      ),
    }));
  };

  const onRemoveCouponFromBooking = () => {
    set_coupon_code(null);
    setBooking((prevState) => ({
      ...prevState,
      coupon_code: null,
    }));
  };

  const transformBookingForSummary = () => {
    const transformedBooking = { ...booking };

    if (booking.payment_type === "split") {
      transformedBooking.booking_users = transformedBooking.booking_users
        .filter((_, index) => selectedUsersForPayment.includes(index))
        .map((user) => ({
          name: user.name,
          booking_activities: user.booking_activities,
          booking_food: user.booking_food,
        }));
    } else {
      transformedBooking.booking_users = transformedBooking.booking_users.map(
        (user) => ({
          name: user.name,
          booking_activities: user.booking_activities,
          booking_food: user.booking_food,
        })
      );
    }

    transformedBooking.booking_dependents =
      transformedBooking.booking_dependents.map((dependent) => ({
        name: dependent.name,
        booking_activities: dependent.booking_activities,
        booking_food: dependent.booking_food,
      }));

    return transformedBooking;
  };

  const removeActivityFromUser = (index, activityId, type) => {
    console.log(
      `cuurent index for the ${type} is ${index} and the activity id is ${activityId}`
    );

    const targetArray =
      type === "adult" ? booking?.booking_users : booking?.booking_dependents;

    if (index < 0 || index >= targetArray?.length) {
      console.error("Invalid index");
      return;
    }

    const user = targetArray[index];
    console.log("user is ", user);

    const activityIndex = user.booking_activities.findIndex(
      (activity) => activity?.item_id === activityId
    );
    console.log("activity index is ", activityIndex);

    if (activityIndex === -1) {
      console.error("Activity not found");
      return;
    }

    const updatedActivities = user.booking_activities.filter(
      (_, i) => i !== activityIndex
    );

    const updatedUser = { ...user, booking_activities: updatedActivities };

    if (type === "adult") {
      setBooking((prevState) => ({
        ...prevState,
        booking_users: prevState.booking_users.map((user, i) => {
          if (i === index) {
            return {
              ...user,
              booking_activities: updatedActivities,
              selectedItems: [],
            };
          }
          return user;
        }),
      }));
    } else {
      setBooking((prevState) => ({
        ...prevState,
        booking_dependents: prevState.booking_dependents.map((dependent, i) => {
          if (i === index) {
            return {
              ...dependent,
              booking_activities: updatedActivities,
              selectedItems: [],
            };
          }
          return dependent;
        }),
      }));
    }
    updateBookingSummary(); // Update summary when activity is removed
  };

  const addOrUpdateFoodItem = (userIndex, foodItem, type) => {
    setBooking((prevState) => {
      const newState = { ...prevState };

      const user =
        type === "adult"
          ? newState?.booking_users[userIndex]
          : newState?.booking_dependents[userIndex];

      const existingIndex = user?.booking_food?.findIndex(
        (item) => item.item_id === foodItem.item_id
      );

      if (existingIndex > -1) {
        user.booking_food[existingIndex] = foodItem;
      } else {
        user.booking_food.push(foodItem);
      }

      return newState;
    });
    updateBookingSummary(); // Update summary when food item is added or updated
  };

  const removeFoodItem = (userIndex, foodItemId, type) => {
    console.log("user index", userIndex);
    console.log("food item id", foodItemId);
    console.log("type", type);
    setBooking((prevState) => {
      const newState = { ...prevState };

      console.log("new state from delete food item booking context", newState);

      const user =
        type === "adult"
          ? newState?.booking_users[userIndex]
          : newState?.booking_dependents[userIndex];

      console.log("user from delete food item booking context", user);
      user.booking_food = user.booking_food.filter(
        (item) => item.item_id !== foodItemId
      );

      return newState;
    });
    updateBookingSummary(); // Update summary when food item is removed
  };

  const clearActivitiesForUserByIndex = (userIndex, userType) => {
    setBooking((prevState) => {
      const arrayName =
        userType === "adult" ? "booking_users" : "booking_dependents";
      const updatedUsersArray = [...prevState[arrayName]];
      if (userIndex >= 0 && userIndex < updatedUsersArray.length) {
        updatedUsersArray[userIndex].booking_activities = [];
        return { ...prevState, [arrayName]: updatedUsersArray };
      } else {
        console.error("Index out of bounds");
        return prevState;
      }
    });
    updateBookingSummary(); // Update summary when activities are cleared
  };

  const clearFoodForUserByIndex = (userIndex, userType) => {
    setBooking((prevState) => {
      const arrayName =
        userType === "adult" ? "booking_users" : "booking_dependents";
      const updatedUsersArray = [...prevState[arrayName]];
      if (userIndex >= 0 && userIndex < updatedUsersArray.length) {
        updatedUsersArray[userIndex].booking_food = [];
        return { ...prevState, [arrayName]: updatedUsersArray };
      } else {
        console.error("Index out of bounds");
        return prevState;
      }
    });
    updateBookingSummary(); // Update summary when food items are cleared
  };

  return (
    <BookingContext.Provider
      value={{
        booking,
        updateBookingDate,
        updatePaymentType,
        bookingUsers,
        initializeBookingUsers,
        addBookingUser,
        updateCurrentUser,
        removeBookingUser,
        bookingDependents,
        initializeBookingDependents,
        addBookingDependent,
        updateBookingDependent,
        removeBookingDependent,
        getPersonByIndexAndType,
        transformBookingForSummary,
        removeActivityFromUser,
        addOrUpdateFoodItem,
        removeFoodItem,
        bookingSummary,
        setBookingSummary,
        updateCouponCode,
        getCouponCode,
        onRemoveCouponFromBooking,
        guestPrice,
        setGuestPrice,
        dependentPrice,
        setDependentPrice,
        clearActivitiesForUserByIndex,
        clearFoodForUserByIndex,
        handleUserCheckboxToggle,
        selectedUsersForPayment,
        setPaymentMethod,
        clearBookingStateDefault
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
