import React from "react";
import { useLanguage } from "../../../contexts/LanguageContext";
import { useOutletContext } from "react-router-dom";
import { Progress } from "antd"; // Import Progress from Ant Design
import BlurFade from "../../ui/blur-fade";

const Membership = () => {
  const { language, translate } = useLanguage();
  const { userProfile } = useOutletContext();

  // Function to calculate the progress percentage
  const calculateProgress = (used, total) => {
    if (total === 0) return 0; // Avoid division by zero
    return (used / total) * 100;
  };

  // Function to calculate available spots
  const calculateAvailable = (used, total) => {
    return total - used;
  };

  return (
    <>
      <section className="w-full h-auto">
        <BlurFade className={`w-full`} delay={0.07} inView>
          <h2 className="text-[#2A2A2A] text-[28px] leading-6 font-semibold mb-[32px] ">
            {language === "en" ? "My Membership" : "عضويتي"}
          </h2>
        </BlurFade>

        <main className="w-full flex flex-col items-start justify-start gap-6">
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <div className="w-full border border-[#F0F0F0] rounded-[24px] px-[20px] py-[26px] flex flex-col items-start justify-start gap-2">
              {/* membership info */}
              <div className="w-full flex flex-col items-start justify-start gap-2">
                <BlurFade delay={0.07} inView>
                  <p className="text-black text-[18px] font-medium leading-6 uppercase">
                    {userProfile?.my_membership?.name}
                  </p>
                </BlurFade>
                <BlurFade className={`w-full`} delay={0.08} inView>
                  <div className="w-full flex items-baseline justify-start gap-2">
                    <p className="text-black text-[30px] font-semibold leading-none">
                      {new Intl.NumberFormat("en-US").format(
                        userProfile?.my_membership?.price
                      )}
                    </p>
                    <span className="text-black text-[20px] font-semibold leading-none">
                      {translate("currency")}
                    </span>
                  </div>
                </BlurFade>
                <BlurFade delay={0.09} inView>
                  <p className="text-black text-[18px] font-normal leading-6 ">
                    {userProfile?.my_membership?.short_description[language]}
                  </p>
                </BlurFade>
              </div>
              {/* seperator */}
              <div className="w-full h-[1.5px] bg-[#F0F0F0] my-[1rem]"></div>

              {/* membership features */}
              <div className="w-full flex flex-col items-start justify-start gap-[24px]">
                <BlurFade className={`w-full`} delay={0.09} inView>
                  {/* Free Guests */}
                  {userProfile?.my_membership
                    ?.number_of_free_guests_per_month !== 0 && (
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                      <div className="w-full flex items-start justify-between">
                        <h3 className="text-black text-[16px] font-medium leading-5">
                          {
                            userProfile?.my_membership
                              ?.number_of_free_guests_per_month
                          }{" "}
                          free guests/Month
                        </h3>
                        <p className="text-[#565656] text-[16px] font-normal leading-5">
                          {calculateAvailable(
                            userProfile?.my_membership
                              ?.used_number_of_free_guests_per_month,
                            userProfile?.my_membership
                              ?.number_of_free_guests_per_month
                          )}{" "}
                          Available Of{" "}
                          {
                            userProfile?.my_membership
                              ?.number_of_free_guests_per_month
                          }
                        </p>
                      </div>
                      <Progress
                        strokeColor={"#FCD746"}
                        trailColor={"#FEF7DB"}
                        percent={calculateProgress(
                          userProfile?.my_membership
                            ?.used_number_of_free_guests_per_month,
                          userProfile?.my_membership
                            ?.number_of_free_guests_per_month
                        )}
                        showInfo={false}
                        status="active"
                      />
                    </div>
                  )}
                </BlurFade>

                <BlurFade className={`w-full`} delay={0.09} inView>
                  {/* Payable Guests */}
                  {userProfile?.my_membership
                    ?.number_of_payable_guests_per_day !== 0 && (
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                      <div className="w-full flex items-start justify-between">
                        <h3 className="text-black text-[16px] font-medium leading-5">
                          {
                            userProfile?.my_membership
                              ?.number_of_payable_guests_per_day
                          }{" "}
                          payable guests/day
                        </h3>
                        <p className="text-[#565656] text-[16px] font-normal leading-5">
                          {calculateAvailable(
                            userProfile?.my_membership
                              ?.used_number_of_payable_guests_per_day,
                            userProfile?.my_membership
                              ?.number_of_payable_guests_per_day
                          )}{" "}
                          Available Of{" "}
                          {
                            userProfile?.my_membership
                              ?.number_of_payable_guests_per_day
                          }
                        </p>
                      </div>
                      <Progress
                        percent={calculateProgress(
                          userProfile?.my_membership
                            ?.used_number_of_payable_guests_per_day,
                          userProfile?.my_membership
                            ?.number_of_payable_guests_per_day
                        )}
                        status="active"
                        showInfo={false}
                        strokeColor={"#FCD746"}
                        trailColor={"#FEF7DB"}
                      />
                    </div>
                  )}
                </BlurFade>

                {/* Activities */}
                {userProfile?.my_membership?.activities.map(
                  (activity, index) => (
                    <BlurFade
                      key={index}
                      className={`w-full`}
                      delay={index * 0.09}
                      inView
                    >
                      <div
                        key={index}
                        className="w-full flex flex-col items-start justify-start gap-2"
                      >
                        <div className="w-full flex items-start justify-between">
                          <h3 className="text-black text-[16px] font-medium leading-5">
                            {activity.number_times} x {activity.duration}{" "}
                            {activity.name[language]}
                          </h3>
                          <p className="text-[#565656] text-[16px] font-normal leading-5">
                            {calculateAvailable(
                              activity.used,
                              activity.number_times
                            )}{" "}
                            Available Of {activity.number_times}
                          </p>
                        </div>
                        <Progress
                          percent={calculateProgress(
                            activity.used,
                            activity.number_times
                          )}
                          showInfo={false}
                          status="active"
                          strokeColor={"#FCD746"}
                          trailColor={"#FEF7DB"}
                        />
                      </div>
                    </BlurFade>
                  )
                )}
              </div>
            </div>
          </div>
        </main>
      </section>
    </>
  );
};

export default Membership;
