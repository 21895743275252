import React, { useState } from "react";
import { useLanguage } from "../../../contexts/LanguageContext";
const AccountInfoItem = ({ accLabel, accValue, Modal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { language } = useLanguage();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = (data) => {
    console.log("data modal", data);

    if (!data) return;
    else if (data.isSuccess === true) {
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="account-settings-item ">
        <h5 className="text-black font-medium text-[14px] leading-5">
          {accLabel}
        </h5>
        <div className="flex items-start justify-start gap-4">
          <p className="text-[#333333] text-[14px] font-normal leading-6 self-center">
            {accValue}
          </p>
          <button
            onClick={showModal}
            className="self-center underline text-[#FCD12A] border-none outline-none text-[14px] font-medium "
          >
            {language === "en" ? "Edit" : "تعديل"}
          </button>

          <Modal
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            handleOk={handleOk}
          />
        </div>
      </div>
    </>
  );
};

export default AccountInfoItem;
