import React from 'react'
import { useLanguage } from '../../contexts/LanguageContext'

const FeatureItem = ({ featureItemData }) => {
    const { language } = useLanguage();

    return (
        <>
            <div className='kn-featureItem w-full flex flex-col place-items-start md:place-items-center'>
                <img src={featureItemData.image} className=' mb-[15px] md:mb-[25px] lg:mb-[35px]' alt="feature icon" srcSet="An icon" />

                <h5 className='text-[#1E1E1E]  text-[18px] md:text-[20px] lg:text-[22px] font-semibold leading-8 tracking-[0]  mb-[12px]'>
                    {featureItemData.title[language]}
                </h5>


                <p className='text-[#1E1E1E] text-[14px] md:text-[16px] font-normal leading-5 tracking-[0] text-start md:text-center  opacity-75  '>
                    {featureItemData.description[language]}
                </p>


            </div>

        </>
    )
}

export default FeatureItem
