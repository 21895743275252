import React, { useState } from 'react'
import signInPic from '../../assets/signinPhoto.webp'
import logo from '../../assets/kona-logo.webp';
import Input from '../../shared/inputs/Input';
import ButtonSecondary from '../../shared/buttons/ButtonSecondary';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axios/axiosInstance';
import { useNavigate } from 'react-router-dom';
import Reveal from '../../shared/reveal/Reveal';
import { useLanguage } from '../../contexts/LanguageContext';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();

    const BASE_API_URL = process.env.REACT_APP_BASE_URL;


    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const now = new Date();
    const year = now.getFullYear();


    const onForgetPassword = async () => {
        try {
            const response = await axiosInstance.post(`/password/send-forget-email`, {
                type: 'Sports Guests',
                email: email,

            });
            console.log('response', response);
            if (response.status === 200) {
                navigate('/otp', { state: { email: email } });
            }
        } catch (error) {
            console.log('error', error);
            setEmailError(true);
            setErrorMessage(error.response.data.message);

        }
    };



    const loginSubmissionHandler = (e) => {
        e.preventDefault();
        if (!email) {
            setEmailError(true);
            setErrorMessage('Please enter your email address.');
            return;
        }
        else {
            setEmailError(false);
            setErrorMessage('');
            onForgetPassword();

        }

    };
    const goBack = () => {
        navigate(-1);
    }
    return (
        <>
            <div className="w-full h-auto lg:h-screen flex items-start justify-start md:justify-center md:items-center ">
                {/* Right: Login Form */}
                <div className="p-8 w-full lg:w-1/2 h-full ">
                    <Reveal>
                        <div className='w-full flex flex-col items-start justify-start gap-[1rem]'>
                            <Link to='/'>
                                <img src={logo} alt="" srcSet="" />
                            </Link>
                            <button onClick={goBack}>
                                <svg
                                    className='cursor-pointer rtl:rotate-180'
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="48"
                                    height="48"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                >
                                    <path
                                        fill="#575757"
                                        d="M15.01 23.317a.966.966 0 000 1.366l6.143 6.144a.965.965 0 101.366-1.366L17.057 24l5.462-5.461a.965.965 0 10-1.366-1.366l-6.144 6.144zm17.299-.282H15.692v1.93h16.617v-1.93z"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </Reveal>
                    <Reveal>
                        <div className='w-full lg:w-[90%]  mx-auto lg:p-15 md:p-30 sm:p-20 py-8 px-0 '>
                            <div className='w-full flex flex-col items-start justify-start gap-3'>

                                <h1 className="w-full text-[#2a2a2a] text-[24px] md:text-[28px] font-bold leading-6 md:leading-10 tracking-[0px] text-start md:text-center ">

                                    {language === 'en' ? ' Forgot Password' : ' نسيت كلمة المرور'}
                                </h1>
                                {/* tracking-[10px] */}
                                <p className='text-[#3D3D3D] text-[14px] font-normal leading-5  text-start md:text-center  '>
                                
                                   {
                                        language === 'en' ? 'Enter the email address associated with your account, we’ll send you a code to reset your password' : 'أدخل عنوان البريد الإلكتروني المرتبط بحسابك ، وسنرسل لك رمزًا لإعادة تعيين كلمة المرور الخاصة بك'
                                   }
                                </p>
                            </div>
                            {/* signin form */}
                            <form onSubmit={loginSubmissionHandler} action="#" method="POST" className='mt-[3rem]'>

                                <Input
                                    ID='email-input'
                                    type='email'
                                    name='emailInput'
                                    placeholder={language === 'en' ? 'Email' : 'البريد الإلكتروني'}
                                    label={language === 'en' ? 'Email' : 'البريد الإلكتروني'}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}

                                />

                                {emailError && <div className='kn-error-wrapper'>
                                    <div className='w-full flex items-start justify-start gap-2'>
                                        <div className='self-center w-[25px] h-[25px]'>
                                            <svg width="25" height="25" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 16.25C7.66498 16.25 6.35994 15.8541 5.2499 15.1124C4.13987 14.3707 3.27471 13.3165 2.76382 12.0831C2.25292 10.8497 2.11925 9.49251 2.3797 8.18314C2.64015 6.87377 3.28303 5.67104 4.22703 4.72703C5.17104 3.78303 6.37377 3.14015 7.68314 2.8797C8.99251 2.61925 10.3497 2.75292 11.5831 3.26382C12.8165 3.77471 13.8707 4.63987 14.6124 5.7499C15.3541 6.85994 15.75 8.16498 15.75 9.5C15.748 11.2896 15.0362 13.0054 13.7708 14.2708C12.5054 15.5362 10.7896 16.248 9 16.25ZM9 4.1C7.93198 4.1 6.88795 4.41671 5.99992 5.01007C5.1119 5.60343 4.41977 6.44679 4.01105 7.43351C3.60234 8.42023 3.4954 9.50599 3.70376 10.5535C3.91212 11.601 4.42642 12.5632 5.18163 13.3184C5.93683 14.0736 6.89902 14.5879 7.94651 14.7962C8.99401 15.0046 10.0798 14.8977 11.0665 14.489C12.0532 14.0802 12.8966 13.3881 13.4899 12.5001C14.0833 11.6121 14.4 10.568 14.4 9.5C14.3984 8.06833 13.8289 6.69575 12.8166 5.6834C11.8043 4.67105 10.4317 4.10161 9 4.1Z" fill="#C81E1E" />
                                                <path d="M9.95445 9.5L11.5022 7.95223C11.6252 7.82492 11.6932 7.65441 11.6917 7.47743C11.6901 7.30045 11.6192 7.13115 11.494 7.006C11.3689 6.88085 11.1996 6.80986 11.0226 6.80832C10.8456 6.80678 10.6751 6.87482 10.5478 6.99778L9 8.54555L7.45223 6.99778C7.32492 6.87482 7.15441 6.80678 6.97743 6.80832C6.80045 6.80986 6.63115 6.88085 6.506 7.006C6.38085 7.13115 6.30986 7.30045 6.30832 7.47743C6.30678 7.65441 6.37482 7.82492 6.49778 7.95223L8.04555 9.5L6.49778 11.0478C6.43331 11.11 6.38188 11.1845 6.34651 11.2669C6.31113 11.3492 6.29251 11.4378 6.29173 11.5274C6.29095 11.6171 6.30803 11.7059 6.34197 11.7889C6.37591 11.8719 6.42603 11.9472 6.48941 12.0106C6.55279 12.074 6.62815 12.1241 6.71111 12.158C6.79406 12.192 6.88295 12.209 6.97257 12.2083C7.0622 12.2075 7.15077 12.1889 7.23313 12.1535C7.31548 12.1181 7.38996 12.0667 7.45223 12.0022L9 10.4545L10.5478 12.0022C10.6751 12.1252 10.8456 12.1932 11.0226 12.1917C11.1996 12.1901 11.3689 12.1192 11.494 11.994C11.6192 11.8689 11.6901 11.6996 11.6917 11.5226C11.6932 11.3456 11.6252 11.1751 11.5022 11.0478L9.95445 9.5Z" fill="#C81E1E" />
                                            </svg>
                                        </div>

                                        <p className='kn-error self-center'>
                                            {errorMessage}
                                        </p>
                                    </div>
                                </div>}

                                <div className='w-full md:w-[75%] mx-auto flex flex-col items-center justify-center gap-6 mt-[3rem]'>
                                    <ButtonSecondary type='submit' className={`w-full`}>
                                       {
                                        language === 'en' ? 'Send Code' : 'إرسال الرمز'
                                       }
                                    </ButtonSecondary>


                                </div>


                            </form>
                        </div>
                    </Reveal>

                </div>
                {/* Left: Image */}
                <div className="kn-signin w-1/2 h-full overflow-hidden hidden lg:block " >
                    {/* <div className='h-full w-full ' style={signInStyle}></div> */}
                    <div className='sign-in-photo-wrapper'>
                        <img src={signInPic} alt="sign in cover" className="object-cover w-full h-full" />
                    </div>
                </div>

            </div>
            <div className='w-full h-auto border-t border-black py-6 '>
                        <div className="container mx-auto">
                            <div className='w-full flex flex-col md:flex-row items-start justify-between gap-4'>


                                <p className='text-black text-[16px] font-normal leading-6 tracking-[0px]'>
                                    © {year} {language === 'en' ? 'Kona. All rights reserved.' : 'كونا. كل الحقوق محفوظة.'}
                                </p>
                                <div className='flex items-start justify-start gap-4'>
                                    <a href='#' className='text-black font-normal text-[16px] leading-6 underline'>
                                        {
                                            language === 'en' ? 'Privacy Policy' : 'سياسة الخصوصية'
                                        }
                                    </a>
                                    <a href='#' className='text-black font-normal text-[16px] leading-6 underline'>
                                        {
                                            language === 'en' ? 'Terms of Service' : 'شروط الخدمة'
                                        }
                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>

        </>
    )
}

export default ForgetPassword
