import React from 'react'

const SectionHeader = ({ subtitle, title, titleMarginBottom = 'mb-5', desc, descriptionChoiceOfClass = 'section-desc-2' }) => {
    return (
        <>
            <div className='w-full flex flex-col items-start justify-start'>
                {subtitle && <h4 className='section-sub-title mb-2'>
                    {subtitle}
                </h4>}
                <h2 className={`section-title ${titleMarginBottom}`}>
                    {title}
                </h2>
                <p className={`${descriptionChoiceOfClass}`}>
                    {desc}
                </p>
            </div>

        </>
    )
}

export default SectionHeader
