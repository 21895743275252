import React from "react";
import ActivitiesDrawer from "../activity/booking/ActivitiesDrawer";
import FoodDrawer from "../activity/booking/FoodDrawer";
import Reveal from "../../shared/reveal/Reveal";

const UpdateBookingUser = React.memo(({ user, activities, food }) => {
  
  return (
    <>
      <div
        className="kn-guest-booking-info"
        style={{
          paddingBottom: "12px",
        }}
      >
        <div className="w-full flex items-start justify-between px-[16px] pb-[24px]">
          <span className="w-auto inline-block bg-[#EBEBEB] py-[8px] px-[16px] rounded-[8px]">
            <svg
              className="self-center inline-block ltr:mr-[8px] rtl:ml-[8px]"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              fill="none"
              viewBox="0 0 16 17"
          >
              <path
                stroke="#2A2A2A"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.2"
                d="M8 8.5a3.333 3.333 0 100-6.667A3.333 3.333 0 008 8.5zM13.727 15.167c0-2.58-2.567-4.667-5.727-4.667s-5.727 2.087-5.727 4.667"
              ></path>
            </svg>
            <span className="text-[#2A2A2A] text-[14px] font-medium leading-5 self-center">
              {user?.name}
            </span>
          </span>
        </div>
        {/* Activity Add Cta */}
        <ActivitiesDrawer
          user={user}
          isUpdating={true}
          activities={activities}
        />
        {/* Meals Add Cta */}
        <FoodDrawer user={user} food={food} isUpdating={true} />
      </div>
    </>
  );
});

export default UpdateBookingUser;
