import React from 'react';

const SplitPaymentUserSelection = ({ booking, selectedUsersForPayment, handleUserCheckboxToggle }) => {
  return (
    <div className="w-full flex flex-col items-start justify-start gap-4">
      <h3 className="text-[#2A2A2A] text-[18px] font-medium leading-6">
        Who are you paying for?
      </h3>
      {booking?.booking_users?.map((user, index) => (
        <div
          className={`kn-payment-user-checkbox ${
            index === 0 ? 'kn-payment-user-checkbox-disabled' : ''
          }`}
          key={index}
        >
          <label className="kn-custom-checkbox w-full flex flex-row items-start justify-between cursor-pointer">
            <span className="flex items-center justify-start gap-2">
              <svg
                className="self-center"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99935 8C9.8403 8 11.3327 6.50762 11.3327 4.66667C11.3327 2.82572 9.8403 1.33334 7.99935 1.33334C6.1584 1.33334 4.66602 2.82572 4.66602 4.66667C4.66602 6.50762 6.1584 8 7.99935 8Z"
                  stroke="#2A2A2A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                  stroke="#2A2A2A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{' '}
              <span className="flex items-start justify-start gap-1 text-[#2A2A2A] text-[14px] font-normal leading-5">
                {user.name}
              </span>
            </span>
            <input
              className="kn-payment-checkbox self-center"
              type="checkbox"
              checked={
                selectedUsersForPayment.includes(index) || index === 0 // Always check the first user
              }
              onChange={(e) =>
                handleUserCheckboxToggle(index, e.target.checked)
              }
              disabled={index === 0} // Disable the first user checkbox
            />
          </label>
        </div>
      ))}
    </div>
  );
};

export default SplitPaymentUserSelection;
