import React, { useRef } from "react";
import SectionHeader from "../../../shared/section-header/SectionHeader";
import SportCardItem from "./SportCardItem";
import Reveal from "../../../shared/reveal/Reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import { useLanguage } from "../../../contexts/LanguageContext";

const Sports = ({ data }) => {
  const { language } = useLanguage();

  const activities = data?.activities;

  const sliderRef = useRef(null);

  var slickCarouselSettings = {
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const onBookTicket = (title) => {
    console.log(title);
  };
  const onLearnMore = (title) => {
    console.log(title);
  };

  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.01 * index,
      },
    }),
  };
  return (
    <>
      <section className="w-full h-auto overflow-hidden mt-[0.5rem] mb-[2rem] md:my-[2rem] lg:my-[4rem]">
        <div className="container mx-auto">
          <Reveal>
            <SectionHeader
              title={data?.title[language]}
              titleMarginBottom="mb-2"
              desc={data?.description[language]}
              descriptionChoiceOfClass="section-desc"
            />
          </Reveal>

          <div className="w-full hidden md:block h-auto mt-[2rem] mb-[0.5rem]">
            <div className="w-full hidden md:flex items-end justify-end gap-2">
              <div className="flex items-start justify-start gap-2">
                <button
                  className="self-center rtl:rotate-180"
                  onClick={previousSlide}
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0001 9.66675L9.66675 15.0001M9.66675 15.0001L15.0001 20.3334M9.66675 15.0001H20.3334M28.3334 15.0001C28.3334 22.3639 22.3639 28.3334 15.0001 28.3334C7.63628 28.3334 1.66675 22.3639 1.66675 15.0001C1.66675 7.63628 7.63628 1.66675 15.0001 1.66675C22.3639 1.66675 28.3334 7.63628 28.3334 15.0001Z"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  className="self-center rtl:rotate-180"
                  onClick={nextSlide}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0001 21.3334L21.3334 16.0001M21.3334 16.0001L16.0001 10.6667M21.3334 16.0001H10.6667M29.3334 16.0001C29.3334 23.3639 23.3639 29.3334 16.0001 29.3334C8.63628 29.3334 2.66675 23.3639 2.66675 16.0001C2.66675 8.63628 8.63628 2.66675 16.0001 2.66675C23.3639 2.66675 29.3334 8.63628 29.3334 16.0001Z"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="w-full relative  pb-6 md:pb-3">
            {activities && (
              <Slider
                ref={sliderRef}
                {...slickCarouselSettings}
                className="kn-sports-carousel kn-slick-carousel w-full py-5 grid grid-cols-1 sm:grid-cols-2
                             lg:grid-cols-4 gap-5 md:gap-6 2xl:gap-8"
              >
                {activities.map((sportCardItem, index) => {
                  return (
                    <motion.div
                      className={`w-full ${
                        language === "en" ? "mr-4" : "ml-4"
                      }`}
                      variants={fadeInAnimationsVariants}
                      initial="initial"
                      whileInView="animate"
                      viewport={{
                        once: true,
                      }}
                      custom={index}
                      key={index}
                    >
                      <SportCardItem
                        sportCardItemData={sportCardItem}
                        onBookTicket={() => onBookTicket(sportCardItem.title)}
                        onLearnMore={() => onLearnMore(sportCardItem.title)}
                      />
                    </motion.div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Sports;
