import React from 'react'
import { Select } from 'antd';
import { DownOutlined } from '@ant-design/icons'
import CustomArrow from './CustomArrow';
const SelectInput = ({ options, onChange, initialValue }) => {
  return (
    <>

      <Select
        dropdownStyle={{ backgroundColor: '#f5f5f5' }}
        suffixIcon={<CustomArrow />}
        labelInValue
        defaultValue={initialValue}
        style={{
          alignSelf: 'center',
          width: 'auto',
        }}
        onChange={onChange}
        options={options}
      />

    </>
  )
}

export default SelectInput
