import React from "react";

const Button = ({ type = "button", style, className, children, ...props }) => {
  return (
    <>
      <button
        type={type}
        style={style}
        className={`w-auto  min-w-[120px] flex items-center justify-center gap-2 bg-transparent
        py-[6px] px-[14px]  md:py-[8px] md:px-[20px] rounded-[100px] text-black
                 font-medium leading-6 xs:min-w-[80px] xs:py-[7px] xs:px-[14px] xs:text-[12px] text-[16px] cursor-pointer ${className}`}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
