import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ActiveBookings from "./active-bookings/ActiveBookings";
import PastBookings from "./past-bookings/PastBookings";
import Reveal from "../../../shared/reveal/Reveal";
import { useLanguage } from "../../../contexts/LanguageContext";
import axiosInstance from "../../../axios/axiosInstance";
import { TailSpin } from "react-loader-spinner";
const MyBookings = () => {
  const { language } = useLanguage();
  const [mybookings, setMyBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getBookings = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(`/user/bookings`);
        if (response.status === 200) {
          // console.log("response", response);
          setMyBookings(response.data.data);
        }
        // console.log("response", response);
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };
    getBookings();
  }, []);

  return (
    <>
      <Reveal>
        <section className="w-full h-auto">
          <h1 className="text-[#2A2A2A] text-[28px] font-semibold leading-7">
            {language === "en" ? "My Bookings" : "حجوزاتي"}
          </h1>

          <Tabs className="w-full mt-[32px]">
            <TabList className={`kn-booking-tabs-list`}>
              <Tab className={`kn-booking-tabs-list-item`}>
                <svg
                  className="self-center"
                  width="24"
                  height="20"
                  viewBox="0 0 24 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.49559 5.88041V4.84659M7.49559 10.5326V9.49879M7.49559 15.1848V14.151M6.22147 18.2863H17.2638C19.0478 18.2863 19.9397 18.2863 20.6211 17.9483C21.2204 17.6509 21.7077 17.1764 22.0131 16.5929C22.3603 15.9294 22.3603 15.0609 22.3603 13.3239V6.70747C22.3603 4.97048 22.3603 4.10199 22.0131 3.43855C21.7077 2.85497 21.2204 2.38051 20.6211 2.08316C19.9397 1.74512 19.0478 1.74512 17.2638 1.74512H6.22147C4.43754 1.74512 3.54557 1.74512 2.8642 2.08316C2.26485 2.38051 1.77756 2.85497 1.47218 3.43855C1.125 4.10199 1.125 4.97048 1.125 6.70747V13.3239C1.125 15.0609 1.125 15.9294 1.47218 16.5929C1.77756 17.1764 2.26485 17.6509 2.8642 17.9483C3.54557 18.2863 4.43754 18.2863 6.22147 18.2863Z"
                    stroke="#9E9E9E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {language === "en" ? "Active Booking" : "الحجوزات النشطة"}
              </Tab>
              <Tab className={`kn-booking-tabs-list-item`}>
                {language === "en" ? "Past Booking" : "الحجوزات السابقة"}
              </Tab>
            </TabList>

            <TabPanel>
              {isLoading ? (
                <div className="w-full my-[6rem] mx-auto flex items-center justify-center">
                  <TailSpin color="#FCD12A" height="60" width="60" />
                </div>
              ) : (
                <ActiveBookings activeBookings={mybookings?.current_bookings} />
              )}
            </TabPanel>
            <TabPanel>
              {isLoading ? (
                <div className="w-full my-[6rem] mx-auto flex items-center justify-center">
                  <TailSpin color="#FCD12A" height="60" width="60" />
                </div>
              ) : (
                <PastBookings pastBookings={mybookings?.past_bookings} />
              )}
            </TabPanel>
          </Tabs>
        </section>
      </Reveal>
    </>
  );
};

export default MyBookings;
