import React, { useState, useEffect } from "react";
// Importing React and two essential hooks: `useState` and `useEffect`.
// - `useState`: to manage the state of the countdown timer (time left).
// - `useEffect`: to trigger the countdown behavior when the component mounts or when its dependencies change.

import { Oval } from "react-loader-spinner";
// Importing a spinner component (`Oval`) from the `react-loader-spinner` package, which will be used to show a loading spinner while waiting for the countdown to start.

const CountdownTimer = ({ expirationTime, onExpire }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(expirationTime) - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
        
      } else {
        onExpire(); // Notify parent that QR has expired
        timeLeft = { minutes: 0, seconds: 0 };
      }
      if(timeLeft.minutes === 0 && timeLeft.seconds === 0){
        onExpire(); // Notify parent that QR has expired
      }

      return timeLeft;
    };

    const formatTime = (timeLeft) => {
      const { minutes = 0, seconds = 0 } = timeLeft;
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      return `${formattedMinutes}:${formattedSeconds}`;
    };

    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setTimeLeft(formatTime(timeLeft));

      if (timeLeft.minutes === 0 && timeLeft.seconds === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [expirationTime, onExpire]);

  if (!timeLeft) {
    return (
      <div className="w-auto py-2 px-6 bg-white rounded-[30px] mt-[2rem] counter-shadow flex items-center justify-center">
        <Oval
          visible={true}
          height="30"
          width="30"
          ariaLabel="oval-loading"
          color="#FCD12A"
          secondaryColor="#FCD12A"
        />
      </div>
    );
  }

  return (
    <div className="w-auto py-2 px-6 bg-white rounded-[30px] mt-[2rem] counter-shadow flex items-center justify-center">
      <p className="text-[20px] font-medium ">{timeLeft}</p>
    </div>
  );
};

export default CountdownTimer;
// The `CountdownTimer` component is exported to be used in other parts of the application.
