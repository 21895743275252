// axiosInstance.js
import axios from "axios";
import { useUser } from "../contexts/UserContext";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

// Add a request interceptor to axiosInstance
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    // If token is present, set the Authorization header
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error;

    if (response?.status === 401) {
      const token = localStorage.getItem("token");

      if (token) {
        // If token exists, retry the request with the new token
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;
        return axiosInstance(error.config); // retry the request
      } else {
        // If no token, log out the user and redirect to login
        const { logout, goToLogin } = useUser(); // Access logout and redirect from context
        logout();
        goToLogin();
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
