import React from "react";
import GridItem from "./GridItem";
import { motion } from "framer-motion";
import { useLanguage } from "../../contexts/LanguageContext";
const Grid = ({ data, GridWrapperClasses }) => {
  const { language } = useLanguage();

  // Ensure that data is always an array
  const gridData = Array.isArray(data) ? data : [data];

  const fadeInAnimationsVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.15 * index,
      },
    }),
  };
  return (
    <>
      <div
        className={`grid-container ${GridWrapperClasses} my-[0.6rem] lg:my-[1rem]`}
      >
        <div className="container mx-auto flex flex-col gap-[3rem] md:gap-[5rem] lg:gap-[6rem]">
          {gridData.map((item, index) => {
            const itemTitle = item?.title && item.title[language];
            const itemDescription =
              item?.description && item.description[language];
            const itemHasButton = item?.has_button === "yes";
            const itemCtaTitle = itemHasButton
              ? item?.button_text[language]
              : null;

            return (
              <motion.div
                key={index}
                className="w-full "
                variants={fadeInAnimationsVariants}
                initial="initial"
                whileInView="animate"
                viewport={{
                  once: true,
                }}
                custom={index}
              >
                <GridItem
                  image={item?.image}
                  subTitle={item?.name?.[language] || null}
                  title={itemTitle || ""}
                  desc={itemDescription || ""}
                  isThereCtaBtn={itemHasButton}
                  ctaTitle={itemCtaTitle}
                  ctaIcon={item?.ctaIcon}
                  isReverse={item?.direction === "right"}
                  button_type={item?.button_type}
                  page_slug={item?.page_slug}
                />
              </motion.div>
            );
          })}

          {/* {gridData?.map((item, index) =>
                     (

                        <motion.div
                            key={index}
                            className='w-full '
                            variants={fadeInAnimationsVariants}
                            initial='initial'
                            whileInView='animate'
                            viewport={
                                {
                                    once: true
                                }
                            }
                            custom={index}
                        >
                            <GridItem
                                image={item?.image}
                                subTitle={item?.name[language] ? item?.name[language] : null}
                                title={item?.title[language] ? item?.title[language] : null}
                                desc={item?.description[language] ? item?.description[language] : null}
                                isThereCtaBtn={item?.has_button === 'yes' ? true : false}
                                ctaTitle={item?.has_button === 'yes' ? item?.button_text[language] : null}
                                ctaIcon={item?.ctaIcon}
                                isReverse={item?.direction === 'right' ? true : false}
                            />
                        </motion.div>
                    ))} */}
        </div>
      </div>
    </>
  );
};

export default Grid;
