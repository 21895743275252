import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Create context for booking page tracking
const BookingTrackingContext = createContext();

export const useBookingTracking = () => useContext(BookingTrackingContext);

export const BookingTrackingProvider = ({ children }) => {
  const location = useLocation();

  // Retrieve the last saved pages from localStorage, if available
  const savedCurrentPage = localStorage.getItem("currentBookingPage");
  const savedPreviousPage = localStorage.getItem("previousBookingPage");

  // Hardcoded mapping for current and previous pages in the booking flow
  const getPageTracking = (pathname) => {
    switch (pathname) {
      case "/booking-information":
        return {
          currentPage: "/booking-information",
          previousPage: "/booking",
        };
      case "/payment":
        return {
          currentPage: "/payment",
          previousPage: "/booking-information",
        };

      default:
        return { currentPage: pathname, previousPage: null };
    }
  };

  // Initialize state with saved data or fallback to static mapping
  const [currentPage, setCurrentPage] = useState(
    savedCurrentPage || location.pathname
  );
  const [previousPage, setPreviousPage] = useState(
    savedPreviousPage || getPageTracking(location.pathname).previousPage
  );

  // Function to update the current and previous pages in both state and localStorage
  const updatePages = (newPage) => {
    setPreviousPage(currentPage);
    setCurrentPage(newPage);
    localStorage.setItem("currentBookingPage", newPage);
    localStorage.setItem("previousBookingPage", currentPage);
  };

  // Update the page tracking when the location changes
  useEffect(() => {
    const { currentPage: newCurrentPage, previousPage: newPreviousPage } =
      getPageTracking(location.pathname);
    setCurrentPage(newCurrentPage);
    setPreviousPage(newPreviousPage);

    // Persist the latest page tracking in localStorage
    localStorage.setItem("currentBookingPage", newCurrentPage);
    localStorage.setItem("previousBookingPage", newPreviousPage);
  }, [location.pathname]);

  return (
    <BookingTrackingContext.Provider
      value={{ currentPage, previousPage, updatePages }}
    >
      {children}
    </BookingTrackingContext.Provider>
  );
};
