import React from "react";
import { useLanguage } from "../../contexts/LanguageContext";
const DownloadApp = ({ data, isDark = true }) => {
  const { language } = useLanguage();

  return (
    <>
      <section
        className={`w-full h-auto  pt-[3rem] mt-[3rem] ${
          isDark ? "kn-dark-bg" : "kn-linear-gradient"
        } `}
      >
        <div className="container mx-auto">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[6rem]">
            <div className="self-center flex flex-col items-start justify-start gap-6">
              <h2
                className={`text-[24px] md:text-[28px] lg:text-[36px] font-bold leading-[35px] md:leading-[45px] lg:leading-[53px] ${
                  isDark ? "text-white" : "#1E1E1E"
                } `}
              >
                {data?.title[language] ? data?.title[language] : data?.title}
              </h2>
              <p
                className={`text-[14px] md:text-[17.8px]  font-normal leading-[25px] opacity-80 ${
                  isDark ? "text-white" : "#1F1F1F"
                }  `}
              >
                {data?.description[language]
                  ? data?.description[language]
                  : data?.description}
              </p>
              <div className="w-full flex flex-col md:flex-row items-start justify-start gap-4">
                <img
                  src={data?.first_button_image}
                  className="cursor-pointer"
                  alt="Google Play Logo"
                  srcSet="Google Play Logo"
                />
                <img
                  src={data?.second_button_image}
                  className="cursor-pointer"
                  alt="Apple Store Logo"
                  srcSet="Apple Store Logo"
                />
              </div>
            </div>
            <div>
              <img
                src={data?.image}
                alt="app mobile pic"
                srcSet="Snapshot from Appliation Mobile Picture"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DownloadApp;
