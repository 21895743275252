import React from 'react'

const MinusIcon = () => {
    return (
        <>
            <svg
                className='self-center ml-[0.5rem]'
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="2"
                fill="none"
                viewBox="0 0 16 2"
            >
                <path
                    stroke="#3A3A3A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.625"
                    d="M1.5 1h13"
                ></path>
            </svg>

        </>
    )
}

export default MinusIcon
