import React, { useRef, useEffect, useState } from "react";
import SectionHeader from "../../shared/section-header/SectionHeader";
import SportCardItem from "../home/sports/SportCardItem";
import Reveal from "../../shared/reveal/Reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axiosInstance from "../../axios/axiosInstance";
import { useLanguage } from "../../contexts/LanguageContext";
import { ThreeDots } from "react-loader-spinner";
const Activities = ({ data }) => {
  const { language } = useLanguage();
  const [activities, setActivities] = useState([]);
  console.log("🚀 ~ Activities ~ activities:", activities);
  const [isLoading, setIsLoading] = useState(false);


  const onBookTicket = (title) => {
    console.log(title);
  };
  const onLearnMore = (title) => {
    console.log(title);
  };

  //   wanna get todays date like this: 2024-04-01
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  // fetch activities
  useEffect(() => {
    const getActivities = async () => {
      setIsLoading(true);
      // disable body scroll
      document.body.style.overflow = "hidden";

      try {
        const activitiesData = await axiosInstance.get(
          `/booking/pre-requests/data/activities-list`,
          {
            params: {
              date: formattedDate,
            },
          }
        );
        if (activitiesData.status === 200) {
          setActivities(activitiesData.data.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        // enable body scroll
        document.body.style.overflow = "auto";
      }
    };
    getActivities();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto overflow-hidden mt-[0.5rem] mb-[2rem] md:my-[2rem] lg:my-[4rem]">
          <div className="container mx-auto">
            <Reveal>
              <SectionHeader
                title={data?.title[language]}
                titleMarginBottom="mb-2"
                desc={data?.description[language]}
                descriptionChoiceOfClass="section-desc"
              />
            </Reveal>

            <div className="w-full relative  pb-6 md:pb-3">
              {activities && (
                <div
                  className="kn-sports-carousel kn-slick-carousel w-full py-5 grid grid-cols-1 sm:grid-cols-2
                             lg:grid-cols-4 gap-5 md:gap-6 2xl:gap-8"
                >
                  {activities.map((sportCardItem, index) => {
                    return (
                      <div className="w-full ">
                        <SportCardItem
                          sportCardItemData={sportCardItem}
                          onBookTicket={() => onBookTicket(sportCardItem.title)}
                          onLearnMore={() => onLearnMore(sportCardItem.title)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Activities;
