import axios from "axios";
import History from "./History";
import { useUser } from "../contexts/UserContext";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

const token = localStorage?.getItem("token");

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

// Add a request interceptor to axiosInstance
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from local storage or user context
    // If token is present, set the Authorization header
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      History.push("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
