import React, { createContext, useContext, useState, useEffect } from "react";

// Step 1: Create a User Context and now we can pass the value to the context provider
const UserContext = createContext();

// export const useUser = () => useContext(UserContext);
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

// Step 2: Create a Context Provider
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  // const navigate = useNavigate(); // Create navigate function

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");
    // wanna set the token and user state in coo
    if (storedToken && storedUser) {
      setToken(storedToken);
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (newUser, newToken) => {
    // Save user and token in state and localStorage
    localStorage.setItem("token", newToken);
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
    setToken(newToken);
  };

  const logout = () => {
    // Clear user and token from state and localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    setToken(null);
  };

  const updateUser = (newUser) => {
    // Save user and token in state and localStorage
    localStorage.setItem("user", JSON.stringify(newUser));
    setUser(newUser);
  };

  const isLoggedIn = () => {
    return !!user && !!token;
  };

  // Step 3: Provide the context to children
  return (
    <UserContext.Provider
      value={{ user, token, login, logout, updateUser, isLoggedIn }}
    >
      {children}
    </UserContext.Provider>
  );
};
