import React from 'react'

const PlusIcon = () => {
    return (
        <>
            <svg className='self-center ml-[0.5rem]' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 8.00049H8M8 8.00049H14.5M8 8.00049V1.50049M8 8.00049V14.5005" stroke="#3A3A3A" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
            </svg>


        </>
    )
}

export default PlusIcon
