import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import axiosInstance from "../../axios/axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import UpdateBookingUser from "./UpdateBookingUser";
import { v4 as uuidv4 } from "uuid";
import pic from "../../assets/beach-access/beach-access-grid-1.webp";
import { BookingInfoSummary } from "../activity/booking/BookingInfoSummary";
import { useUpdateBooking } from "../../contexts/UpdateBookingContext";
import UpdateBookingSummary from "./UpdateBookingSummary";

const UpdateBooking = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const location = useLocation();
  const { allowedUsers, booking_date, bookingId } = location.state || {};
  const [activities, setActivities] = useState([]);
  const [food, setFood] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // console.log("allowed users from updateBookingData ", allowedUsers);
  const { updateBookingData } = useUpdateBooking();

  // console.log("updateBookingData", updateBookingData);
  // console.log("bookingId", bookingId);

  // fetch activities
  useEffect(() => {
    const getActivities = async () => {
      setIsLoading(true);
      // disable body scroll
      document.body.style.overflow = "hidden";

      try {
        const activitiesData = await axiosInstance.get(
          `/booking/pre-requests/data/activities-list`,
          {
            params: {
              date: booking_date,
            },
          }
        );
        if (activitiesData.status === 200) {
          setActivities(activitiesData.data.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        // enable body scroll
        document.body.style.overflow = "auto";
      }
    };
    getActivities();
  }, [booking_date]);

  // fetch Food
  useEffect(() => {
    const getActivities = async () => {
      setIsLoading(true);
      // disable body scroll
      document.body.style.overflow = "hidden";

      try {
        const foodList = await axiosInstance.get(
          `/booking/pre-requests/data/food-category-list`,
          {
            params: {
              date: booking_date,
            },
          }
        );
        if (foodList.status === 200) {
          setFood(foodList.data.data);
          // console.log("food list", foodList.data.data);
          //   enable body scroll
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        // enable body scroll
        document.body.style.overflow = "auto";
      }
    };
    getActivities();
  }, [booking_date]);

  const handleBack = () => {
    navigate(-1);
  };

  const processToPayment = async () => {
    // wanna hit the /booking/add-items-to-booking/{bookingId} endpoint and passing the
    try {
      const response = await axiosInstance.post(
        `/booking/add-items-to-booking/${bookingId}`,
        updateBookingData
      );
      console.log("response", response);

      if (response.status === 200) {
        window.open(response.data.data.payment_url, "_blank");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="kn-loader-wrapper">
          <div className="">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <section className="w-full h-auto">
          <div className="container mx-auto">
            <button
              onClick={handleBack}
              className="w-full flex items-start justify-start gap-[16px] my-[2rem]"
            >
              <svg
                className="self-center rtl:rotate-180 xs:w-[18px] xs:h-[18px]"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
                viewBox="0 0 25 24"
              >
                <path
                  stroke="#292D32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  d="M15.418 4.08l-6.52 6.52c-.77.77-.77 2.03 0 2.8l6.52 6.52"
                ></path>
              </svg>

              <span className="text-[#2A2A2A] text-[18px] xs:text-[16px] sm:text-[22px] md:text-[24px] lg:text-[28px] font-semibold leading-7">
                {language === "en" ? "Update Booking" : "تحديث الحجز"}
              </span>
            </button>

            <div
              className="kn-booking-info-grid xs:my-[14px] my-[40px]"
              style={{
                marginTop: "3.5rem",
              }}
            >
              <div className="w-full flex flex-col items-start justify-start gap-[24px]">
                {allowedUsers.map((user) => {
                  return (
                    <UpdateBookingUser
                      key={uuidv4()}
                      user={user}
                      activities={activities}
                      food={food}
                    />
                  );
                })}
              </div>
              <UpdateBookingSummary
                booking_date={booking_date}
                isUpdating={true}
                data={{
                  activity_image: pic,
                  activity_name: "Aqua Park Ticket",
                }}
              />
            </div>
            {/* Procceed */}
            <div className="w-full flex items-center md:items-start justify-center md:justify-start my-[0.5rem]">
              <ButtonSecondary
                onClick={processToPayment}
                // disabled={!checkIfBookingExists()} // Here we use the function to set the disabled property
                className={`xs:w-[200px] md:w-[250px] lg:w-[300px] `}
                style={{
                  paddingTop: "12px ",
                  paddingBottom: "12px ",
                }}
              >
                {language === "en" ? "Proceed To Payment" : "المتابعة للدفع"}
              </ButtonSecondary>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default UpdateBooking;
