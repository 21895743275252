import React from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";
import { useLanguage } from "../../contexts/LanguageContext";
import { motion } from "framer-motion";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

const Hero = ({
  heroHeight,
  heroImage,
  sectiohTitle = "",
  sectionDescription = "",
  isThereSectionCtaButton,
  sectionCtaButtonTitle,
  onClick,
}) => {
  const { user } = useUser();
  const { language } = useLanguage();
  const navigate = useNavigate();

  
  const heroWrapperStyle = {
    position: "relative",

    backgroundImage:
      language === "en"
        ? `linear-gradient(
      101.08deg,
      rgba(254, 229, 131, 0.8) 23.39%,
      rgba(254, 228, 130, 0) 79.82%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url(${heroImage})`
        : `linear-gradient(
      101.08deg,
      rgba(254, 228, 130, 0) 20.18%, 
      rgba(254, 229, 131, 0.8) 76.61%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
    url(${heroImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: `${heroHeight}px`,
    borderRadius: "16px",
    padding: "1.2rem 2.4rem",
    overFlow: "hidden",
  };

  const heroImageAnimationVariants = {
    initial: {
      opacity: 0,
      // clipPath: "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)",
      clipPath:
        language === "en"
          ? `polygon(0 0, 0 0, 0 100%, 0 100%)`
          : `polygon(100% 0, 100% 0, 100% 100%, 100% 100%)`,
    },
    animate: () => ({
      opacity: 1,
      clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`,
      transition: {
        duration: 1,
        delay: 0.15,
      },
    }),
  };
  const heroContentAnimationVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: () => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.3,
        delay: 0.05,
      },
    }),
  };

  const handleNavigation = (button_type, page_slug) => {
    console.log("🚀 ~ handleNavigation ~ page_slug:", page_slug);
    console.log("🚀 ~ handleNavigation ~ button_type:", button_type);
    if (button_type === "bookings") {
      navigate("/booking");
    } else if (button_type === "page") {
      navigate(`/${page_slug}`);
    }
  };

  return (
    <>
      {heroImage && (
        <section className="hero-section w-full mb-[3rem]">
          <div className="container mx-auto">
            <motion.div
              variants={heroImageAnimationVariants}
              initial="initial"
              whileInView="animate"
              viewport={{
                once: true,
              }}
              className="hero-anim"
              style={heroWrapperStyle}
            >
              <motion.div
                // i want to animate this div after the hero image animation is done

                variants={heroContentAnimationVariants}
                initial="initial"
                whileInView="animate"
                viewport={{
                  once: true,
                }}
                className="w-full md:w-[85%] lg:w-[50%] h-full flex flex-col items-center md:items-start justify-center gap-2"
              >
                <h1 className="kn-hero-section-title">{sectiohTitle}</h1>

                <p className="kn-hero-section-description">
                  {sectionDescription}
                </p>
                {isThereSectionCtaButton === "yes" && (
                  <ButtonPrimary
                    onClick={onClick}
                    style={{
                      marginTop: "2rem",
                      padding: "0.7rem 1.4rem",
                    }}
                  >
                    {user
                      ? sectionCtaButtonTitle // If the user exists, display the original ctaTitle
                      : language === "en"
                      ? "Book a Tour"
                      : "احجز جولة"}{" "}
                  </ButtonPrimary>
                )}
              </motion.div>
            </motion.div>
          </div>
        </section>
      )}
    </>
  );
};

export default Hero;
