import React, { useState, useEffect } from "react";
import signUpPic from "../../assets/signup.webp";
import logo from "../../assets/konaLogo.svg";
import Input from "../../shared/inputs/Input";
import ButtonSecondary from "../../shared/buttons/ButtonSecondary";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import Reveal from "../../shared/reveal/Reveal";
import { ThreeDots } from "react-loader-spinner";
import axiosInstance from "../../axios/axiosInstance";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useLanguage } from "../../contexts/LanguageContext";

const Signup = () => {
  const { language } = useLanguage();

  const navigate = useNavigate();
  // states
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");

  const [selectedGender, setSelectedGender] = useState();
  const [selectedGenderError, setSelectedGenderError] = useState(false);
  const [selectedGenderErrorMessage, setSelectedGenderErrorMessage] =
    useState("");

  const [identityCardType, setIdentityCardType] = useState("id card");

  // states inside the tabs
  const [nationalId, setNationalId] = useState("");
  const [nationalIdError, setNationalIdError] = useState(false);
  const [nationalIdErrorMessage, setNationalIdErrorMessage] = useState("");

  const [birthDate, setBirthDate] = useState("");
  const [birthDateError, setBirthDateError] = useState(false);
  const [birthDateErrorMessage, setBirthDateErrorMessage] = useState("");

  const [passportNumber, setPassportNumber] = useState("");
  const [passportNumberError, setPassportNumberError] = useState(false);
  const [passportNumberErrorMessage, setPassportNumberErrorMessage] =
    useState("");

  const [residencyNumber, setResidencyNumber] = useState("");
  const [residencyNumberError, setResidencyNumberError] = useState(false);
  const [residencyNumberErrorMessage, setResidencyNumberErrorMessage] =
    useState("");

  const [registerStatus, setRegisterStatus] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, errorMessage) => {
    api[type]({
      message: `${
        type === "success"
          ? language === "en"
            ? "Registration successful! Welcome aboard"
            : "تم التسجيل بنجاح! مرحبًا بك على متن الطائرة"
          : language === "en"
          ? `Registration failed. Please try again`
          : `فشل التسجيل. حاول مرة أخرى`
      }`,
      description: null,
      placement: "top",
      className: "mb-0",
      // duration: 3000,
    });
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const now = new Date();
  const year = now.getFullYear();

  const onSelectedGender = (e) => {
    setSelectedGender(e.target.value);
  };

  const handleTabClick = (value) => {
    setIdentityCardType(value);
  };

  const validateForm = () => {
    // Validate full name
    // here wanna check if all the fields are filled
    if (
      !fullName &&
      !email &&
      !phone &&
      !password &&
      !confirmPassword &&
      !birthDate &&
      !selectedGender &&
      !nationalId &&
      !passportNumber &&
      !residencyNumber
    ) {
      setFullNameError(true);
      language === "en"
        ? setFullNameErrorMessage("Please enter your full name")
        : setFullNameErrorMessage("الرجاء إدخال اسمك الكامل");
      setEmailError(true);
      language === "en"
        ? setEmailErrorMessage("Please enter your email")
        : setEmailErrorMessage("الرجاء إدخال بريدك الإلكتروني");
      setPhoneError(true);
      language === "en"
        ? setPhoneErrorMessage("Please enter your phone")
        : setPhoneErrorMessage("الرجاء إدخال رقم هاتفك");
      setPasswordError(true);
      language === "en"
        ? setPasswordErrorMessage("Please enter your password")
        : setPasswordErrorMessage("الرجاء إدخال كلمة المرور");
      setConfirmPasswordError(true);
      language === "en"
        ? setConfirmPasswordErrorMessage("Please confirm your password")
        : setConfirmPasswordErrorMessage("الرجاء تأكيد كلمة المرور");
      setBirthDateError(true);
      language === "en"
        ? setBirthDateErrorMessage("Please enter your birth date")
        : setBirthDateErrorMessage("الرجاء إدخال تاريخ ميلادك");
      setSelectedGenderError(true);
      language === "en"
        ? setSelectedGenderErrorMessage("Please select your gende")
        : setSelectedGenderErrorMessage("الرجاء تحديد جنسك");
      setNationalIdError(true);
      language === "en"
        ? setNationalIdErrorMessage("Please enter your national id")
        : setNationalIdErrorMessage("الرجاء إدخال الهوية الوطنية");
      setPassportNumberError(true);
      language === "en"
        ? setPassportNumberErrorMessage("Please enter your passport number")
        : setPassportNumberErrorMessage("الرجاء إدخال رقم جواز سفرك");
      setResidencyNumberError(true);
      language === "en"
        ? setResidencyNumberErrorMessage("Please enter your residency number")
        : setResidencyNumberErrorMessage("الرجاء إدخال رقم الإقامة");
      return false;
    }

    if (!fullName) {
      setFullNameError(true);
      language === "en"
        ? setFullNameErrorMessage("Please enter your full name")
        : setFullNameErrorMessage("الرجاء إدخال اسمك الكامل");
      return false;
    }
    setFullNameError(false);
    setFullNameErrorMessage("");

    // Validate email
    if (!email) {
      setEmailError(true);
      language === "en"
        ? setEmailErrorMessage("Please enter your email")
        : setEmailErrorMessage("الرجاء إدخال بريدك الإلكتروني");
      return false;
    }
    setEmailError(false);
    setEmailErrorMessage("");

    // Validate phone
    if (!phone) {
      setPhoneError(true);
      language === "en"
        ? setPhoneErrorMessage("Please enter your phone")
        : setPhoneErrorMessage("الرجاء إدخال رقم هاتفك");
      return false;
    }
    setPhoneError(false);
    setPhoneErrorMessage("");

    // Validate password
    if (!password) {
      setPasswordError(true);
      language === "en"
        ? setPasswordErrorMessage("Please enter your password")
        : setPasswordErrorMessage("الرجاء إدخال كلمة المرور");
      return false;
    }
    if (password.length < 8) {
      setPasswordError(true);
      language === "en"
        ? setPasswordErrorMessage("Password must be at least 8 characters")
        : setPasswordErrorMessage(
            "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل"
          );
      return false;
    }
    setPasswordError(false);
    setPasswordErrorMessage("");

    // Validate confirm password
    if (!confirmPassword) {
      setConfirmPasswordError(true);
      language === "en"
        ? setConfirmPasswordErrorMessage("Please confirm your password")
        : setConfirmPasswordErrorMessage("الرجاء تأكيد كلمة المرور");
      return false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      language === "en"
        ? setConfirmPasswordErrorMessage("Passwords do not match")
        : setConfirmPasswordErrorMessage("كلمات المرور غير متطابقة");
      return false;
    }
    setConfirmPasswordError(false);
    setConfirmPasswordErrorMessage("");

    // Validate birth date
    if (!birthDate) {
      setBirthDateError(true);
      language === "en"
        ? setBirthDateErrorMessage("Please enter your birth date")
        : setBirthDateErrorMessage("الرجاء إدخال تاريخ ميلادك");
      return false;
    }
    setBirthDateError(false);
    setBirthDateErrorMessage("");

    // Validate selected gender
    if (!selectedGender) {
      setSelectedGenderError(true);
      language === "en"
        ? setSelectedGenderErrorMessage("Please select your gender")
        : setSelectedGenderErrorMessage("الرجاء تحديد جنسك");
      return false;
    }
    setSelectedGenderError(false);
    setSelectedGenderErrorMessage("");

    // Validate national ID based on selected identity card type
    if (identityCardType === "id card" && !nationalId) {
      setNationalIdError(true);
      language === "en"
        ? setNationalIdErrorMessage("Please enter your national id")
        : setNationalIdErrorMessage("الرجاء إدخال الهوية الوطنية");
      return false;
    }
    if (identityCardType === "passport" && !passportNumber) {
      setPassportNumberError(true);
      language === "en"
        ? setPassportNumberErrorMessage("Please enter your passport number")
        : setPassportNumberErrorMessage("الرجاء إدخال رقم جواز سفرك");
      return false;
    }
    if (identityCardType === "iqama" && !residencyNumber) {
      setResidencyNumberError(true);
      language === "en"
        ? setResidencyNumberErrorMessage("Please enter your residency number")
        : setResidencyNumberErrorMessage("الرجاء إدخال رقم الإقامة");
      return false;
    }

    // If we get this far, all validations have passed
    setNationalIdError(false);
    setNationalIdErrorMessage("");
    setPassportNumberError(false);
    setPassportNumberErrorMessage("");
    setResidencyNumberError(false);
    setResidencyNumberErrorMessage("");

    return true;
  };

  const handleServerErrors = (errors) => {
    // Assuming errorResponse is the object you received from the server

    if (errors?.email) {
      setEmailError(true);
      setEmailErrorMessage(errors.email);
    }

    if (errors?.phone) {
      setPhoneError(true);
      setPhoneErrorMessage(errors.phone);
    }
  };

  const onSignup = async () => {
    try {
      const response = await axiosInstance.post("/sports/register", {
        name: fullName,
        email: email,
        password: password,
        phone: phone,
        gender: selectedGender,
        identity_card_type: identityCardType,
        identity_card_number: nationalId,
        birthdate: birthDate,
        lang: language,
      });
      if (response.status === 200) {
        openNotificationWithIcon("success");
        setRegisterStatus("success");
        console.log("response", response);
        setTimeout(() => {
          navigate("/login");
        }, 2000);

        setFullName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setConfirmPassword("");
        setSelectedGender("");
        setBirthDate("");
        setNationalId("");
        setPassportNumber("");
        setResidencyNumber("");
      }
      console.log("response", response);
    } catch (error) {
      console.log("error", error);
      console.log("errors", error.response.data.errors);
      setRegisterStatus("error");
      handleServerErrors(error.response.data.errors);
      openNotificationWithIcon("error");
    }
  };

  const signupSubmissionHandler = (e) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if (!isFormValid) return;
    else {
      onSignup();
    }
  };

  // This function will handle the div click and call the onSelectedGender function
  // with a new event object that has the target value set to the gender you want to set.
  const handleDivClick = (gender) => {
    const fakeEvent = {
      target: {
        value: gender,
        name: "gender",
      },
    };
    onSelectedGender(fakeEvent);
  };

  return (
    <>
      {contextHolder}
      {loading ? (
        <div className="kn-loader-wrapper">
          <div className="kn-loader">
            <ThreeDots color="#FCD12A" />
          </div>
        </div>
      ) : (
        <Reveal>
          <div className="w-full h-auto  grid grid-flow-col lg:grid-cols-2  ">
            {/* Right: Login Form */}
            <div className=" md:p-8 container mx-auto w-full  ">
              <div className="w-ful mx-auto py-8 px-0 sm:p-20  md:p-14 lg:pt-10 lg:pb-0   ">
                <div className="w-full flex flex-col items-start justify-start gap-2">
                  <Link className="mb-[2rem]" to="/">
                    <img src={logo} alt="" srcSet="" />
                  </Link>
                  {/* tracking-[10px] */}
                  <p className="text-[#2A2A2A] text-[14px] font-medium leading-4  tracking-[1px]  mb-2 ">
                    {language === "en"
                      ? "Create New Account"
                      : "إنشاء حساب جديد"}
                  </p>

                  <h1 className="text-[#2a2a2a] text-[24px] md:text-[28px] font-bold leading-7 md:leading-6 lg:leading-10 tracking-[0px] mb-4">
                    {language === "en"
                      ? "Get access to the happiness"
                      : "احصل على الوصول إلى السعادة"}
                  </h1>
                </div>
                {/* signin form */}
                <form
                  onSubmit={signupSubmissionHandler}
                  action="#"
                  method="POST"
                  className="mt-[3rem]"
                >
                  <Input
                    ID="full-name-input"
                    type="text"
                    name="fullnameInput"
                    placeholder={
                      language === "en"
                        ? "Enter your full name"
                        : "أدخل اسمك الكامل"
                    }
                    label={language === "en" ? "Full Name" : "الاسم الكامل"}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    error={fullNameError}
                    errorMessage={fullNameErrorMessage}
                  />

                  <Input
                    ID="phone-input"
                    type="text"
                    name="phoneInput"
                    placeholder={
                      language === "en"
                        ? "Enter your phone number"
                        : "أدخل رقم هاتفك"
                    }
                    label={language === "en" ? "Phone" : "هاتف"}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    error={phoneError}
                    errorMessage={phoneErrorMessage}
                  />

                  <Input
                    ID="email-input"
                    type="email"
                    name="emailInput"
                    placeholder={
                      language === "en"
                        ? "Enter your email"
                        : "أدخل بريدك الإلكتروني"
                    }
                    label={language === "en" ? "Email" : "البريد الإلكتروني"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={emailError}
                    errorMessage={emailErrorMessage}
                  />

                  <Input
                    ID="password-input"
                    type="password"
                    name="phoneInput"
                    placeholder={
                      language === "en"
                        ? "Enter your password"
                        : "أدخل كلمة المرور"
                    }
                    label={language === "en" ? "Password" : "كلمة السر"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordError}
                    errorMessage={passwordErrorMessage}
                  />
                  <Input
                    ID="password-input-confirm"
                    type="password"
                    name="phoneInputConfirm"
                    placeholder={
                      language === "en"
                        ? "Confirm your password"
                        : "تأكيد كلمة المرور"
                    }
                    label={
                      language === "en"
                        ? "Confirm Password"
                        : "تأكيد كلمة المرور"
                    }
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={confirmPasswordError}
                    errorMessage={confirmPasswordErrorMessage}
                  />
                  <Input
                    ID="birth-date-input"
                    type="date"
                    name="birthDateInput"
                    placeholder={
                      language === "en"
                        ? "Enter your birth date"
                        : "أدخل تاريخ ميلادك"
                    }
                    label={language === "en" ? "Birth Date" : "تاريخ الميلاد"}
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                    error={birthDateError}
                    errorMessage={birthDateErrorMessage}
                  />
                  <div className="w-full flex flex-col items-start justify-start mb-8">
                    <label
                      htmlFor="bordered-radio-1"
                      className="relative inline-block mb-4 text-black font-semibold leading-4 text-[16px] "
                    >
                      {language === "en" ? "Gender" : "الجنس"}
                    </label>
                    <div className="w-full flex  items-start justify-between gap-4 mb-2">
                      <div
                        onClick={() => handleDivClick("male")} // Call the new function on click
                        style={{ cursor: "pointer" }}
                        className={`kn-radio-input ${
                          selectedGenderError ? "kn-inputField-error" : ""
                        } `}
                      >
                        <label
                          htmlFor="gender-radio"
                          className="text-[12px] text-[#353535] font-medium leading-4"
                        >
                          {language === "en" ? "Male" : "ذكر"}
                        </label>
                        <input
                          id="gender-radio"
                          type="radio"
                          value="male"
                          name="gender"
                          style={{
                            widows: "100%",
                            height: "100%",
                            cursor: "pointer",
                            alignSelf: "center",
                          }}
                          onChange={onSelectedGender}
                          checked={selectedGender === "male"}
                        />
                      </div>
                      <div
                        onClick={() => handleDivClick("female")} // Call the new function on click
                        style={{ cursor: "pointer" }}
                        className={`kn-radio-input ${
                          selectedGenderError ? "kn-inputField-error" : ""
                        } `}
                      >
                        <label
                          htmlFor="female-radio"
                          className="text-[12px] text-[#353535] font-medium leading-4"
                        >
                          {language === "en" ? "Female" : "أنثى"}
                        </label>
                        <input
                          id="female-radio"
                          type="radio"
                          value="female"
                          name="gender"
                          style={{
                            widows: "100%",
                            height: "100%",
                            cursor: "pointer",
                            alignSelf: "center",
                          }}
                          // className="w-full h-full cursor-pointer self-center"
                          onChange={onSelectedGender}
                          checked={selectedGender === "female"}
                        />
                      </div>
                    </div>
                    {selectedGenderError && (
                      <div className="w-full mt-2 flex items-start justify-start gap-2">
                        <svg
                          className="self-center"
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          fill="none"
                          viewBox="0 0 9 9"
                        >
                          <rect
                            width="9"
                            height="9"
                            fill="#C81E1E"
                            rx="4.5"
                          ></rect>
                          <path
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="0.692"
                            d="M6.115 2.86L3 5.975M3 2.86l3.115 3.115"
                          ></path>
                        </svg>
                        <span className="text-[#D03C32] text-[12px] font-normal leading-5 tracking-[0.1px]">
                          {selectedGenderErrorMessage}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="w-full flex flex-col items-start justify-start mb-2">
                    <label
                      htmlFor="id-type-input"
                      className="inline-block mb-4 text-black font-semibold leading-4 text-[16px] "
                    >
                      {language === "en" ? "ID Type" : "نوع الهوية"}
                    </label>
                    <Tabs className="kn-signup-id-type-tabs-list">
                      <TabList
                        className={`w-full flex  items-start justify-start flex-wrap gap-2 mb-[2rem]`}
                      >
                        <Tab onClick={() => handleTabClick("id card")}>
                          {language === "en" ? "National ID" : "الهوية الوطنية"}
                        </Tab>
                        <Tab onClick={() => handleTabClick("passport")}>
                          <p className="hidden sm:block">
                            {language === "en"
                              ? "Passport Number"
                              : "رقم الجواز"}
                          </p>
                          <p className="block sm:hidden">
                            {language === "en" ? "Passport No" : "رقم الجواز"}
                          </p>
                        </Tab>
                        <Tab onClick={() => handleTabClick("iqama")}>
                          <p className="hidden sm:block">
                            {language === "en"
                              ? "Residency Number"
                              : "رقم الإقامة"}
                          </p>
                          <p className="block sm:hidden">
                            {language === "en" ? "Residency No" : "رقم الإقامة"}
                          </p>
                        </Tab>
                      </TabList>

                      <TabPanel>
                        <>
                          <Input
                            ID="national-id-input"
                            type="number"
                            name="nationalIdInput"
                            placeholder={
                              language === "en"
                                ? "Enter your national id"
                                : "أدخل الهوية الوطنية"
                            }
                            label={
                              language === "en"
                                ? "National ID"
                                : "الهوية الوطنية"
                            }
                            value={nationalId}
                            onChange={(e) => setNationalId(e.target.value)}
                            error={nationalIdError}
                            errorMessage={nationalIdErrorMessage}
                          />
                        </>
                      </TabPanel>

                      <TabPanel>
                        <Input
                          ID="passport-number-input"
                          type="number"
                          name="passportNumberInput"
                          placeholder={
                            language === "en"
                              ? "Enter your passport number"
                              : "أدخل رقم جواز سفرك"
                          }
                          label={
                            language === "en" ? "Passport Number" : "رقم الجواز"
                          }
                          value={passportNumber}
                          onChange={(e) => setPassportNumber(e.target.value)}
                          error={passportNumberError}
                          errorMessage={passportNumberErrorMessage}
                        />
                      </TabPanel>

                      <TabPanel>
                        <Input
                          ID="residency-number-input"
                          type="number"
                          name="residencyNumberInput"
                          placeholder={
                            language === "en"
                              ? "Enter your residency number"
                              : "أدخل رقم الإقامة"
                          }
                          label={
                            language === "en"
                              ? "Residency Number"
                              : "رقم الإقامة"
                          }
                          value={residencyNumber}
                          onChange={(e) => setResidencyNumber(e.target.value)}
                          error={residencyNumberError}
                          errorMessage={residencyNumberErrorMessage}
                        />
                      </TabPanel>
                    </Tabs>
                  </div>

                  <div className="w-full md:w-[75%] mx-auto flex flex-col items-center justify-center gap-6 ">
                    <ButtonSecondary
                      type="submit"
                      className={`w-full md:w-[20rem] `}
                    >
                      {language === "en" ? "Sign Up" : "سجل"}
                    </ButtonSecondary>
                    <p className="text-black text-[14px] font-normal leading-4">
                      {language === "en" ? "Have an account?" : "لديك حساب؟"}{" "}
                      {"  "}
                      <Link
                        to="/login"
                        className="self-center text-[#FCD12A] text-[14px] font-bold leading-5"
                      >
                        {" "}
                        {language === "en" ? "Sign In" : "تسجيل الدخول"}
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
            {/* Left: Image */}
            <div className="kn-signin w-full h-full overflow-hidden hidden lg:block ">
              {/* <div className='h-full w-full ' style={signInStyle}></div> */}
              <div className="sign-in-photo-wrapper">
                <img
                  src={signUpPic}
                  alt="sign in cover"
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>

          <div className="w-full h-auto border-t border-black py-6 ">
            <div className="container mx-auto">
              <div className="w-full flex flex-col md:flex-row items-start justify-between gap-4">
                <p className="text-black text-[16px] font-normal leading-6 tracking-[0px]">
                  © {year}{" "}
                  {language === "en"
                    ? "Kona. All rights reserved."
                    : "كونا. كل الحقوق محفوظة."}
                </p>
                <div className="flex items-start justify-start gap-4">
                  <a
                    href="#"
                    className="text-black font-normal text-[16px] leading-6 underline"
                  >
                    {language === "en" ? "Privacy Policy" : "سياسة الخصوصية"}
                  </a>
                  <a
                    href="#"
                    className="text-black font-normal text-[16px] leading-6 underline"
                  >
                    {language === "en" ? "Terms of Service" : "شروط الخدمة"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Reveal>
      )}
    </>
  );
};

export default Signup;
