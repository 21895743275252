import React from "react";
import { useLanguage } from "../../contexts/LanguageContext";
const PromoCodeInput = ({
  error,
  className,
  onApply,
  onRemove,
  handleChange,
  coupon,
  couponApplied,
}) => {
  const { language } = useLanguage();

  const onSubmit = (e) => {
    e.preventDefault();
    onApply(e, coupon); // Pass the event and coupon to the onApply prop
  };

  return (
    <form onSubmit={onSubmit} className="w-full h-auto">
      <div className="w-full md:w-[25rem] lg:w-[22rem] flex flex-col items-start justify-start gap-3">
        <label
          htmlFor="promo-code"
          className="text-[#FCD12A] font-semibold text-[16px] underline leading-5"
        >
          {language === "en" ? "Have a Promocode?" : "لديك كود خصم؟"}
        </label>
        <div className="relative w-full">
          <input
            type="text"
            id="promo-code"
            placeholder={
              language === "en" ? "enter voucher code" : "أدخل كود الخصم"
            }
            disabled={couponApplied}
            value={coupon}
            onChange={handleChange}
            className={` kn-inputfield w-[20rem] ${
              error ? "kn-inputField-error" : ""
            } ${className} placeholder:xs:text-[12px] disabled:cursor-not-allowed disabled:bg-[#F4F4F4] disabled:opacity-75 disabled:text-[#A8A8A8]`}
          />
          <svg className={`w-6 h-6 absolute ${language==='en' ?"right-4" : "left-4"} top-1/2 -translate-y-1/2`} c xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle
              cx="65.1"
              cy="65.1"
              r="62.1"
              fill="none"
              stroke="#73AF55"
              strokeMiterlimit="10"
              strokeWidth="6"
              className="path circle"
            ></circle>
            <path
              fill="none"
              stroke="#73AF55"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="6"
              d="M100.2 40.2L51.5 88.8 29.8 67.5"
              className="path check"
            ></path>
          </svg>
          {couponApplied ? (
            <>
              {/* <div className="absolute ltr:right-2 rtl:left-2 top-[50%] translate-y-[-50%]">
                <img src={successGif} alt="success" />
              </div> */}
              <button
                type="button"
                onClick={onRemove}
                className="absolute ltr:right-[-10%] rtl:left-[-10%] top-[50%] translate-y-[-50%] "
              >
                <svg
                  className="self-center"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.87528 0.66626H11.0033C11.2041 0.66626 11.379 0.66626 11.5438 0.692172C11.8646 0.743574 12.169 0.86936 12.4325 1.05946C12.6961 1.24957 12.9115 1.49872 13.0615 1.78698C13.1392 1.93505 13.1938 2.1007 13.2577 2.29042L13.3604 2.60044C13.4313 2.85231 13.5854 3.07272 13.7977 3.22568C14.01 3.37863 14.2679 3.4551 14.5292 3.4426H17.3056C17.4897 3.4426 17.6662 3.51573 17.7964 3.64589C17.9265 3.77606 17.9997 3.9526 17.9997 4.13668C17.9997 4.32077 17.9265 4.49731 17.7964 4.62748C17.6662 4.75764 17.4897 4.83077 17.3056 4.83077H1.57299C1.38891 4.83077 1.21237 4.75764 1.0822 4.62748C0.952033 4.49731 0.878906 4.32077 0.878906 4.13668C0.878906 3.9526 0.952033 3.77606 1.0822 3.64589C1.21237 3.51573 1.38891 3.4426 1.57299 3.4426H4.43262C4.67981 3.43667 4.9186 3.35169 5.11396 3.20013C5.30932 3.04857 5.45099 2.8384 5.51817 2.60044L5.62182 2.29042C5.68475 2.1007 5.73935 1.93505 5.81616 1.78698C5.96626 1.4986 6.1818 1.24939 6.44551 1.05927C6.70923 0.869162 7.01379 0.743439 7.33482 0.692172C7.49955 0.66626 7.67446 0.66626 7.87436 0.66626H7.87528ZM6.67035 3.4426C6.73533 3.31534 6.78951 3.18284 6.8323 3.04651L6.92485 2.76887C7.00906 2.51623 7.0285 2.46533 7.04793 2.42831C7.09789 2.33207 7.1697 2.24888 7.25761 2.1854C7.34552 2.12191 7.44708 2.07991 7.55415 2.06276C7.67479 2.05257 7.79594 2.04979 7.91693 2.05443H10.9616C11.2282 2.05443 11.2837 2.05628 11.3244 2.06368C11.4314 2.08074 11.5329 2.1226 11.6208 2.18592C11.7087 2.24923 11.7806 2.33224 11.8306 2.42831C11.8501 2.46533 11.8695 2.51623 11.9537 2.7698L12.0463 3.04743L12.0824 3.15108C12.1185 3.25288 12.161 3.34913 12.2082 3.4426H6.67035Z"
                    fill="#D03C32"
                  />
                  <path
                    d="M3.80686 6.40407C3.79459 6.22036 3.70984 6.04904 3.57125 5.92782C3.43267 5.80659 3.25161 5.74538 3.06789 5.75765C2.88418 5.76992 2.71286 5.85467 2.59163 5.99325C2.47041 6.13184 2.40919 6.3129 2.42147 6.49662L2.85087 12.9303C2.92954 14.1167 2.99339 15.0755 3.14331 15.8288C3.29972 16.6108 3.56439 17.2642 4.11226 17.776C4.6592 18.2887 5.32922 18.5098 6.12048 18.6126C6.88119 18.7125 7.84181 18.7125 9.03193 18.7125H9.8454C11.0346 18.7125 11.9961 18.7125 12.7569 18.6126C13.5472 18.5098 14.2172 18.2887 14.7651 17.776C15.312 17.2642 15.5767 16.6099 15.7331 15.8288C15.883 15.0764 15.9459 14.1167 16.0255 12.9303L16.4549 6.49662C16.4672 6.3129 16.406 6.13184 16.2848 5.99325C16.1635 5.85467 15.9922 5.76992 15.8085 5.75765C15.6248 5.74538 15.4437 5.80659 15.3051 5.92782C15.1666 6.04904 15.0818 6.22036 15.0695 6.40407L14.6438 12.7897C14.5605 14.0362 14.5013 14.9043 14.3718 15.5567C14.245 16.1907 14.0691 16.5257 13.8165 16.7626C13.5629 16.9995 13.2168 17.1531 12.5764 17.2364C11.9165 17.3225 11.0466 17.3243 9.79635 17.3243H9.08005C7.8307 17.3243 6.96078 17.3225 6.30001 17.2364C5.6596 17.1531 5.31349 16.9995 5.05991 16.7626C4.80727 16.5257 4.63143 16.1907 4.50465 15.5577C4.37508 14.9043 4.31586 14.0362 4.23257 12.7887L3.80686 6.40407Z"
                    fill="#D03C32"
                  />
                  <path
                    d="M7.05608 8.07353C7.23917 8.05518 7.42205 8.11029 7.56452 8.22673C7.707 8.34317 7.7974 8.51143 7.81587 8.6945L8.27859 13.3217C8.29215 13.5023 8.23462 13.681 8.11829 13.8197C8.00196 13.9584 7.83602 14.0462 7.65589 14.0643C7.47575 14.0824 7.29566 14.0294 7.15404 13.9166C7.01242 13.8038 6.92048 13.6402 6.89783 13.4606L6.4351 8.83332C6.41675 8.65023 6.47186 8.46735 6.5883 8.32488C6.70475 8.1824 6.873 8.09199 7.05608 8.07353ZM11.8221 8.07353C12.005 8.092 12.1731 8.18228 12.2895 8.32455C12.406 8.46681 12.4612 8.64946 12.4431 8.8324L11.9804 13.4596C11.9574 13.6389 11.8654 13.8022 11.724 13.9147C11.5826 14.0272 11.4028 14.0801 11.2229 14.0622C11.0431 14.0442 10.8773 13.9568 10.7609 13.8185C10.6445 13.6802 10.5867 13.502 10.5996 13.3217L11.0623 8.6945C11.0808 8.5116 11.1711 8.3435 11.3133 8.22708C11.4556 8.11067 11.6392 8.05544 11.8221 8.07353Z"
                    fill="#D03C32"
                  />
                </svg>
              </button>
            </>
          ) : (
            <button
              type="submit"
              className="bg-[#F4F4F4] p-[10px] text-[#A8A8A8] text-[12px] uppercase font-normal leading-5 rounded-[6px] absolute ltr:right-2 rtl:left-2 top-[50%] translate-y-[-50%] "
            >
              {language === "en" ? "APPLY" : "تطبيق"}
            </button>
          )}
        </div>
        {error && (
          <p>
            <span className="text-red-500 text-[14px]">{error}</span>
          </p>
        )}
      </div>
    </form>
  );
};

export default PromoCodeInput;
